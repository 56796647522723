import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {Array} includes
 * @param {?Number} page
 * @param {?Number} limit
 * @param {?Object} sort
 * @param {?String} search
 */
const fetchGameBadges = async ({
  gameId,
  includes,
  page,
  limit,
  sort,
  search,
}) => {
  const { data: { data, meta } } = await api.get(`games/${gameId}/game-badges`, {
    params: {
      includes,
      page,
      limit,
      sort,
      search,
    },
  });

  return { data, pagination: meta.pagination };
};

export default fetchGameBadges;
