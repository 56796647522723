import api from '@/plugins/axios/api';

/**
 * @param {String} gameFormId
 * @param {Array} includes
 */
const fetchGameForm = async ({
  gameFormId,
  includes,
}) => {
  const { data: { data } } = await api.get(`game-forms/${gameFormId}`, {
    params: { includes },
  });

  return data;
};

export default fetchGameForm;
