<template>
  <div class="px-2 my-2 mb-1">
    <Multiselect
      v-model="value"
      value="id"
      label="name"
      :options="clients"
      :loading="isLoading"
      :show-labels="false"
      :allow-empty="false"
      :placeholder="$t('client.choose_client_scope')"
      @select="selectClient"
    >
      <template
        slot="singleLabel"
        slot-scope="props"
      >
        <div class="multiselect__single-label">
          <v-avatar
            size="22px"
            class="mr-4"
          >
            <v-img
              max-height="22"
              max-width="22"
              contain
              :src="clientLogo(props.option)"
            />
          </v-avatar>
          <span>{{ props.option.name }}</span>
        </div>
      </template>
      <template
        slot="option"
        slot-scope="props"
      >
        <div class="multiselect__single-label">
          <v-avatar
            size="22px"
            class="mr-3"
          >
            <v-img
              max-height="22"
              max-width="22"
              contain
              :src="clientLogo(props.option)"
            />
          </v-avatar>
          <span>{{ props.option.name }}</span>
        </div>
      </template>
      <span
        slot="noResult"
        class="multiselect__no-result"
      >
        {{ $t('client.no_results') }}
      </span>
    </Multiselect>
  </div>
</template>
<script>
import { LOGO } from '@/names/clientOptions.names';

export default {
  name: 'SelectClientScope',
  props: {
    clients: {
      type: Array,
      default: () => [],
    },
    selectedClient: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  watch: {
    selectedClient(newVal) {
      this.value = newVal;
    },
  },
  methods: {
    selectClient({ id }) {
      this.$emit('handle-select', { clientId: id });
    },
    clientLogo(client) {
      return client.options?.data.find((option) => option.key === LOGO).value;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css" />
<style lang="scss">
.multiselect__single,
.multiselect__input,
.multiselect__tags,
.multiselect__spinner {
  background: var(--gray);
}
.multiselect__single,
.multiselect__input,
.multiselect__element,
.multiselect__no-result {
  font-size: 14px;
  color: var(--grayDark);
}
.multiselect__single {
  font-weight: 500;
}
.multiselect__content-wrapper ul {
  padding: 0;
}
.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight {
  background: var(--primary);
}
.multiselect__single-label {
  display: flex;
  align-items: center;
}
.multiselect__spinner:after,.multiselect__spinner:before {
  border-top-color: var(--primary);
}
.multiselect__content-wrapper {
  box-shadow: 0px 4px 8px rgb(176 190 197 / 80%);
}
</style>
