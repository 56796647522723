import api from '@/plugins/axios/api';

/**
 * @param {Array} includes
 * @param {?Number} page
 * @param {?Number} limit
 * @param {?Object} sort
 * @param {?Object} filters
 * @param {?String} search
 * @param {String} recruitmentProcessId
 * @param {String} candidateId
 */
const fetchCandidate = async ({
  includes,
  recruitmentProcessId,
  candidateId,
}) => {
  const { data } = await api.get(`recruitment-processes/${recruitmentProcessId}/candidates/${candidateId}`, {
    params: {
      includes,
    },
  });

  return { data: data.data };
};

export default fetchCandidate;
