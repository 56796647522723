export default {
  title: 'Ocena kompetencji',
  recruitment_process: 'Proces rekrutacyjny',
  name: 'Nazwa',
  form: 'Formularz rekrutacyjny',
  game: 'Gra selekcyjna',
  summary: 'Podsumowanie',
  overall_score: 'Wynik ogólny',
  no_results: 'Nie znaleziono ocen kompetencji',
};
