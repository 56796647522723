export default {
  title: 'Candidates',
  invite: 'Invite',
  recruitment_process: 'Recruitment process',
  recruitment_process_hint: 'Choose a recruitment process',
  recruitment_status: 'Status',
  first_name: 'Name',
  last_name: 'Surname',
  email: 'Email',
  phone_number: 'Tel. number',
  score_percentage: 'Score',
  status: 'Status',
  stage: 'Recruitment stage',
  tags: 'Tags',
  tags_hint: 'Maximum 3 tags',
  changed_at: 'Date of stat. change ',
  created_at: 'Created at',
  download_files: 'Download file/s',
  manage_tags: 'Manage tags',
  send_message: 'Send a message',
  card: 'Candidate card',
  ratings: 'Competency assessment',
  videos: 'Video answers',
  report: 'Open report',
  no_results: 'No matching candidates were found',
  empty_fullname: 'Unregistered User',
  group_actions: 'Group actions',
  change_stage: 'Change stage',
  change_stages: 'Change stages',
  change_status: 'Change status',
  custom_message: 'Custom content',
  candidate: 'Applicant',
  basic_fields: 'Basic data',
  additional_fields: 'Additional data',
  note: 'Recruiter\'s note',
  file_loading: 'File is generating',
  report_loading: 'Candidate report is generating',
  file_downloading: 'Within seconds, the file download should begin',
  file_action: 'If the download has not started automatically, please click',
  no_recorded_video_response: 'No recorded video response',
  no_record_stage: 'There are no video recording type steps in the game',
  here: 'here',
  statuses: {
    all: 'All',
    invited: 'Invited',
    started: 'Started',
    end_of_time: 'Time ended',
    finished: 'Completed',
    accepted: 'Accepted',
    rejected: 'Rejected',
    resignation: 'Resigned',
    other: 'Other',
  },
  advanced_filter: {
    1: 'Selected',
    0: 'Unselected',
    from: 'from',
    to: 'to',
    min: 'from',
    max: 'to',
  },
  add: 'Invite to the recruitment process',
  create: {
    description: 'Enter an address or multiple email addresses',
    singularNominativ: 'address',
    pluralNominativ: 'addresses',
    pluralGenitive: 'addresses',
    tooltip: 'Enter candidates\' e-mail addresses. Separate addresses with a space, comma, semicolon or the Enter key.',
    email: 'E-mail',
    invitation_message: 'Inviting message',
    message_title: 'Message title',
    message_body: 'Message content',
    select_name: 'Recruitment Process',
    select_hint: 'Select recruitment process',
    custom_message: 'Custom content',
  },
  modal: {
    change_stage: 'Change stage/status',
    stage: 'Stage',
    status: 'Status',
    message: 'Message',
    message_template: 'Message template to candidate(s)',
    message_title: 'Message title',
    message_body: 'Message content',
  },
  filters: 'Filters',
  advanced_filters: 'Advanced filters',
  close_filters: 'Close filters',
  edit_filters: 'Edit filters',
  reset_filters: 'Reset filters',
  clear_dates: 'Clear dates',
  clear_fields: 'Clear fields',
  remove_candidates: 'Remove Candidates',
};
