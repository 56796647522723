import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 */
const gameStagesExport = async ({ gameId }) => {
  const response = await api.get(
    `games/${gameId}/game-stages/export`,
    {
      responseType: 'blob',
    },
  );

  return response;
};

export default gameStagesExport;
