import api from '@/plugins/axios/api';

/**
 * @param {String} name
 * @param {String} clientId
 * @param {Array} fields
 */
const addGameForm = async ({
  name,
  clientId,
  fields,
}) => {
  await api.post('game-forms', {
    name,
    client_id: clientId,
    fields,
  });
};

export default addGameForm;
