<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <v-row>
          <v-col
            sm="12"
            md="10"
            lg="8"
          >
            <slot />
            <BaseInput
              v-model="formData.title"
              rules="min:3|max:255|required"
              :label="$t('stages.video.title')"
            />
            <BaseVideoUploader
              v-model="formData.files.MP4"
              :label="$t('stages.video.video_mp4')"
              :vid="$t('stages.video.video_mp4')"
              :rules="videoValidationRules(formData.files.MP4, 'mp4')"
              accepted-ext="mp4"
              :max-size="maxSize"
            />
            <BaseVideoUploader
              v-model="formData.files.WEBM"
              :label="$t('stages.video.video_webm')"
              :vid="$t('stages.video.video_webm')"
              :rules="videoValidationRules(formData.files.WEBM, 'webm')"
              accepted-ext="webm"
              :max-size="maxSize"
            />
          </v-col>
        </v-row>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseVideoUploader from '@/components/Form/BaseVideoUploader';
import BaseInput from '@/components/Form/BaseInput';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import { DELETE } from '@/names/global.names';

export default {
  name: 'VideoQuestion',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseVideoUploader,
    BaseInput,
  },
  props: {
    backendErrors: {
      type: Array,
      default: () => [],
    },
    currentStage: {
      type: String,
      required: true,
    },
    stage: {
      type: Object,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxSize: 102400,
      formData: {
        title: '',
        files: {
          MP4: '',
          WEBM: '',
        },
      },
    };
  },
  created() {
    if (this.editMode) {
      this.formData.title = this.stage.stageable.data.title;
      this.formData.files = this.stage.stageable.data.files;
    }
  },
  methods: {
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const formData = new FormData();
      const { gameId, stageId } = this.$route.params;
      formData.set('type', this.currentStage);
      formData.set('title', this.formData.title);
      formData.set('files[MP4]', this.formData.files.MP4);
      formData.set('files[WEBM]', this.formData.files.WEBM);
      if (this.editMode) {
        formData.set('_method', 'PUT');

        return { gameId, stageId, params: formData };
      }

      return { gameId, params: formData };
    },
    videoValidationRules(file, ext) {
      return `${
        !this.editMode || (file === DELETE && this.editMode) ? 'video_required|' : ''
      }size:${this.maxSize}|extensions:${ext}`;
    },
  },
};
</script>
