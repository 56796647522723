export default {
  company: '313c',
  back_to_login: 'Wróć do logowania',
  form: {
    email: 'E-mail',
    password: 'Hasło',
    password_new: 'Nowe hasło',
    password_confirmation: 'Powtórz hasło',
  },
  login: {
    title: 'Zaloguj się do systemu',
    subtitle: 'Wpisz e-mail oraz hasło, aby zalogować się do panelu administracyjnego',
    title_password_changed: 'Hasło zostało zmienione',
    subtitle_password_changed: 'Możesz go teraz użyć aby zalogować się na swoje konto.',
    title_blocked: 'Twoje konto zostało zablokowane.',
    subtitle_blocked: 'Ze względu na 3 nieudane próby logowania konto zostało zablokowane. Spróbuj ponownie za 5 minut.',
    error: 'Niepoprawny adres e-mail i/lub hasło - spróbuj ponownie',
    forgot_password: 'Nie pamiętasz hasła?',
    button: 'Zaloguj się',
  },
  forget: {
    title: 'Zresetuj swoje hasło',
    subtitle: 'Podaj nam swój e-mail. Wyślemy Ci link do zmiany hasła.',
    title_sent: 'Sprawdź swoją pocztę',
    subtitle_sent: 'Na adres',
    subtitle_sent_continued: 'wysłaliśmy wiadomość z linkiem do strony, na której ustawisz swoje nowe hasło.',
    error: 'Niepoprawny adres e-mail - spróbuj ponownie',
    button: 'Wyślij',
  },
  reset: {
    title: 'Utwórz nowe hasło',
    subtitle: 'min. 8 znaków • wielka litera • mała litera • cyfra • znak specjalny',
    info: 'E-mail nie dotarł?',
    time: 'Wyślij ponownie za',
    resend: 'Wyślij ponownie',
    resend_successful: 'Wysłaliśmy jeszcze raz link na Twój E-mail',
    invalid_token: 'Token resetowania hasła wygasł',
    error: 'Niepoprawne hasło - spróbuj ponownie',
    button: 'Zapisz',
  },
};
