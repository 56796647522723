<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="$attrs.label"
    :rules="rules"
  >
    <v-select
      v-model="innerValue"
      :menu-props="{ bottom: true, offsetY: true }"
      :error-messages="errors"
      :no-data-text="$t('dashboard.no_data_available')"
      outlined
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template
        slot="selection"
        slot-scope="data"
      >
        <slot
          name="selection"
          v-bind="data"
        />
      </template>
      <template
        slot="item"
        slot-scope="data"
      >
        <slot
          name="item"
          v-bind="data"
        />
      </template>
    </v-select>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'BaseIconSelect',
  props: {
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: [String, Number, Object, Array],
      default: null,
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
