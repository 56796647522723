<template>
  <div>
    <div
      v-if="isPreloadingData"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title class="py-6">
          <div class="table-heading">
            <h5 class="table-heading__title text-h5">
              {{ $t("gamePage.title") }}
            </h5>
          </div>
        </v-card-title>
        <v-divider />
        <BaseTable
          :table-headers="tableHeaders"
          :table-data="tableData"
          :is-loading="isLoading"
          :no-results-text="$t('gamePage.no_results')"
          :pagination="pagination"
          :default-sort="sort"
          @change-page="fetchGamePages"
          @change-items-per-page="fetchGamePages"
          @change-sort="fetchGamePages"
        >
          <template v-slot:item="{ item }">
            <td>{{ item.display_name ? item.display_name : "-" }}</td>
            <td>{{ formatDate(item.updated_at) }}</td>
            <td>
              <Actions
                :game-id="$route.params.gameId"
                :page-id="item.id"
              />
            </td>
          </template>
        </BaseTable>
      </v-card>
    </div>
  </div>
</template>
<script>
import alerts from '@/plugins/alerts';
import { HTTP_UNAUTHORIZED, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { DESC } from '@/components/Table/sort.types';
import { formatDate } from '@/plugins/dates/dates';
import fetchGamePages from '@/components/Dashboard/GamePage/fetchGamePages';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import BaseTable from '@/components/Table/BaseTable';
import Actions from '@/components/Dashboard/GamePage/Actions';

export default {
  name: 'GamePageList',
  components: {
    Preloader,
    BaseTable,
    Actions,
  },
  data() {
    return {
      tableHeaders: [
        {
          value: 'display_name',
          text: this.$t('gamePage.name'),
          sortable: false,
        },
        {
          value: 'updated_at',
          text: this.$t('gamePage.updated_at'),
        },
        {
          value: 'actions',
          sortable: false,
          align: 'right',
          width: '50px',
        },
      ],
      tableData: [],
      pagination: null,
      isLoading: false,
      isPreloadingData: false,
      sort: {
        column: 'updated_at',
        direction: DESC,
      },
    };
  },
  async created() {
    this.isPreloadingData = true;
    await this.fetchGamePages();
    this.isPreloadingData = false;
  },
  methods: {
    ...alerts,
    formatDate,
    setParams(params = {}) {
      return {
        gameId: this.$route.params.gameId,
        limit: 99999,
        page: 1,
        sort: this.sort,
        ...this.$route.query,
        ...params,
      };
    },
    async fetchGamePages(params) {
      try {
        this.isLoading = true;
        const response = await fetchGamePages(this.setParams(params));
        this.tableData = response.data;
        this.pagination = response.pagination;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
