<template>
  <div>
    <v-app-bar
      class="topbar"
      height="72"
      fixed
      clipped-left
    >
      <v-app-bar-nav-icon
        v-if="!isSimplify"
        class="ml-0 mr-2"
        @click.stop="$emit('toggle-sidebar')"
      />
      <img
        :src="require('@/assets/images/logo.svg')"
        height="37"
      >
      <v-spacer />
      <FaqButton v-if="clientHasFaq && !isSimplify" />
      <LocaleMenu
        v-if="!isSimplify"
        @handle-locale-change="handleLocaleChange"
      />
      <UserMenu
        v-if="!isSimplify"
        @logout="handleLogout"
      />
    </v-app-bar>
    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import alerts from '@/plugins/alerts';
import { LOGIN } from '@/router/routes.names';
import { HTTP_UNAUTHORIZED, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import LocaleMenu from '@/components/Dashboard/Partials/Topbar/LocaleMenu';
import UserMenu from '@/components/Dashboard/Partials/Topbar/UserMenu';
import FaqButton from '@/components/Dashboard/Partials/Topbar/Faq/FaqButton';
import editUserLocale from '@/components/Dashboard/User/editUserLocale';

export default {
  name: 'Topbar',
  components: {
    LocaleMenu,
    UserMenu,
    FaqButton,
  },
  props: {
    isSimplify: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('session', ['clientHasFaq']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('session', ['clearSession', 'setLocale']),
    ...mapActions('layout', ['startPreloadingData', 'stopPreloadingData']),
    ...alerts,
    async handleLogout() {
      try {
        this.isLoading = true;
        await this.logout();
        this.$router.push({ name: LOGIN });
        await this.clearSession();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async handleLocaleChange({ value }) {
      try {
        this.startPreloadingData();
        this.setLocale(value);
        await editUserLocale({ locale: value });
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.stopPreloadingData();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.topbar {
  padding-right: 16px;
  background: var(--white) !important;
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24) !important;
}
</style>
