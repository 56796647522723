<template>
  <div>
    <div class="locales">
      <p class="d-flex align-center text-subtitle-2 orange--text font-weight-medium text-red">
        <span>{{ $t("client.translations") }}</span>
      </p>
      <button
        v-for="(locale, index) in locales"
        :key="`locale-${index}`"
        class="locale"
        :class="{ 'locale--active': locale.value === currentLocale.value }"
        type="button"
        @click="changeLocale(locale)"
      >
        {{ locale.name }}
      </button>
    </div>
    <div
      v-if="isLoading"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <FadeInTransition>
      <div
        v-if="!isLoading"
        class="wrapper"
      >
        <slot />
      </div>
    </FadeInTransition>
  </div>
</template>

<script>
import alerts from '@/plugins/alerts';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import FadeInTransition from '@/components/Transition/FadeInTransition';
import { sortArray } from '@/plugins/sort/sortArray';
import * as localeNames from '@/names/locale.names';

export default {
  name: 'TranslationsMenu',
  components: {
    Preloader,
    FadeInTransition,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    defaultLocale: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    hasChanges: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      locales: [],
      currentLocale: null,
    };
  },
  created() {
    this.initializeLocales();
  },
  methods: {
    ...alerts,
    sortArray,
    initializeLocales() {
      const filteredLocaleNames = Object.values(localeNames).filter(
        (name) => name !== localeNames.EN && name !== localeNames.PL,
      );
      this.locales = filteredLocaleNames.map((name) => ({
        name,
        value: name.toLowerCase(),
      }));

      this.sortArray(this.locales);
      this.addMainLocales();
    },
    addMainLocales() {
      const mainLocale = this.defaultLocale;
      const otherLocale = mainLocale === localeNames.EN ? localeNames.PL : localeNames.EN;
      const mainLocaleName = this.$t('client.default');

      this.locales.unshift(
        { name: `${mainLocale} (${mainLocaleName})`, value: mainLocale.toLowerCase() },
        { name: otherLocale, value: otherLocale.toLowerCase() },
      );

      this.currentLocale = this.locales.find((locale) => locale.value === this.value);
    },
    async changeLocale(locale) {
      if (this.hasChanges) {
        if (!(await this.cancelAlert())) return;
      }
      this.currentLocale = locale;
      this.$emit('change-locale', locale.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.locales {
  border-bottom: 2px solid #f3f3f3;
  margin-bottom: 40px;

  .locale {
    transform: translateY(2px);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 8px 12px;
    color: #263238;
    border-bottom: 2px solid #f3f3f3;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    transition: 0.3s background, 0.6s border;
    &--active {
      background: #fff;
      background: rgba(252, 175, 59, 0.14);
      border-color: rgb(252, 175, 59);
      box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.2);
    }
  }
}
</style>
