export default {
  title: 'Odznaki',
  search: 'Wyszukaj',
  name: 'Nazwa',
  stage_number: 'Nr kroku',
  type: 'Typ kroku',
  content: 'Treść',
  created_at: 'Data utworzenia',
  no_results: 'Nie znaleziono gier',
  types: {
    question_best_worst: 'Zamknięte podwójnie',
    question_closed: 'Zamknięte',
    message: 'Wiadomość',
    open: 'Otwarte',
    video: 'Video',
    image: 'Grafika',
    record: 'Video recording',
  },
  add: 'Dodaj odznakę',
  edit: 'Edytuj odznakę',
  create: {
    name: 'Nazwa',
    description: 'Treść',
    icon: 'Ikonka',
    image: 'Zdjęcie',
    video_mp4: 'Video MP4',
    video_webm: 'Video WEBM',
    stage: 'Krok',
    stage_body: 'Treść kroku',
    stage_answer: 'Odpowiedź',
    add_to_answer: 'Przypisz do odpowiedzi',
    select_add_to_answer: 'Zaznacz aby odznaka została przyznawana po zaznaczeniu wybranej odpowiedzi',
    tip_one: 'Do 1 pytania możesz dodać 1 odznakę',
    tip_two: 'Do gry możesz utworzyć max 6 odznak',
  },
};
