<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <v-row>
          <v-col
            sm="12"
            lg="6"
            class="pr-lg-8"
          >
            <BaseTextarea
              v-model="formData.emails"
              rules="emails_required|multiple_emails"
              mode="eager"
              :label="$t('candidate.create.email')"
            />
            <BaseSelect
              v-model="formData.selectedRecruitmentProcess"
              rules="select"
              :items="recruitmentProcesses"
              :label="$t('candidate.create.select_name')"
              :placeholder="$t('candidate.create.select_hint')"
              item-text="localized_name"
              return-object
              persistent-hint
              :loading="isLoading"
              :disabled="isLoading"
              @change="updateRecruitmentProcess"
            />
          </v-col>
          <v-col
            sm="12"
            lg="6"
          >
            <BaseSelect
              v-model="formData.selectedInvitationMessage"
              rules="select"
              :items="messageTemplates"
              :label="$t('candidate.create.invitation_message')"
              item-text="name"
              item-value="id"
              return-object
              persistent-hint
              :loading="isLoading"
              :disabled="isLoading"
              @change="updateMessageTemplate"
            />
            <BaseInput
              v-model="formData.messageTitle"
              rules="min:3|max:255|required"
              :label="$t('candidate.create.message_title')"
            />
            <BaseRichTextEditor
              v-model="formData.messageBody"
              rules="min:3|max:60000|required"
              :label="$t('candidate.create.message_body')"
              :variables-list="variablesList"
              extended-version
              hide-video-btn
            />
            <div class="pt-2 pb-12">
              <p>{{ $t("messageTemplate.create.variables") }}:</p>
              <span
                v-for="(variableName, index) in variablesList"
                :key="`list-item-${index}`"
                class="d-block"
              >
                <span class="subtitle-2">
                  {{ variableName }}
                </span>
                -
                <span class="text-caption">
                  {{ $t(`messageTemplate.variables.${variableWithoutBrackets(variableName)}`) }}
                </span>
              </span>
            </div>
          </v-col>
        </v-row>
      </div>
      <EditButtons
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import Axios from 'axios';
import { ValidationObserver } from 'vee-validate';
import alerts from '@/plugins/alerts';
import { polishPlurals } from '@/plugins/stringHelpers/polishPlurals';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseTextarea from '@/components/Form/BaseTextarea';
import BaseSelect from '@/components/Form/BaseSelect';
import BaseRichTextEditor from '@/components/Form/BaseRichTextEditor';
import BaseInput from '@/components/Form/BaseInput';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import wrapImagesToTable from '@/plugins/ckeditor/wrapImagesToTable';
import fetchCandidates from '@/components/Dashboard/Candidate/Api/fetchCandidates';
import { ASC } from '@/components/Table/sort.types';
import { HTTP_UNPROCESSABLE_ENTITY, HTTP_UNAUTHORIZED } from '@/plugins/axios/codes';

let cancelToken;

export default {
  name: 'CandidateForm',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseTextarea,
    BaseSelect,
    BaseRichTextEditor,
    BaseInput,
  },
  props: {
    recruitmentProcess: {
      type: String,
      default: '',
    },
    recruitmentProcesses: {
      type: Array,
      default: () => [],
    },
    messageTemplates: {
      type: Array,
      default: () => [],
    },
    variablesList: {
      type: Array,
      default: () => [],
    },
    candidateInvitationMessage: {
      type: Object,
      default: () => {},
    },
    backendErrors: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      existingCandidates: [],
      formData: {
        emails: null,
        selectedRecruitmentProcess: null,
        selectedInvitationMessage: null,
        messageTitle: '',
        messageBody: '',
      },
    };
  },
  computed: {
    parsedEmails() {
      return this.formData.emails.length && this.formData.emails.split(/[\s,;\t\n]+/);
    },
  },
  watch: {
    candidateInvitationMessage(newVal) {
      if (newVal) {
        this.formData.selectedInvitationMessage = newVal;
        this.updateMessageTemplate();
      }
    },
  },
  created() {
    if (this.recruitmentProcess) {
      this.formData.selectedRecruitmentProcess = this.recruitmentProcesses.find(
        (process) => process.id === this.recruitmentProcess,
      );
      this.$emit('fetch-message-templates');
    }
  },
  methods: {
    ...alerts,
    polishPlurals,
    wrapImagesToTable,
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }
      await this.fetchExistingCandidates();
      const isConfirmed = await this.showConfirmationAlert();
      if (!isConfirmed) {
        return;
      }
      this.$emit('handle-submit', {
        recruitmentProcessesId: this.formData.selectedRecruitmentProcess.id,
        emails: this.parsedEmails,
        customMessage: {
          title: this.formData.messageTitle,
          body: await wrapImagesToTable(this.formData.messageBody),
        },
      });
    },
    async fetchExistingCandidates() {
      try {
        if (typeof cancelToken !== typeof undefined) {
          cancelToken.cancel('Operation canceled due to new request.');
        }
        cancelToken = Axios.CancelToken.source();
        const { data } = await fetchCandidates({
          recruitmentProcessId: this.formData.selectedRecruitmentProcess.id,
          filters: {
            has_finished_first_game_stage: 1,
            emails: this.parsedEmails,
          },
          sort: {
            column: 'email',
            direction: ASC,
          },
          cancelToken,
        });
        this.existingCandidates = data;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();
        }
        throw new Error(e);
      }
    },
    async showConfirmationAlert() {
      const { isConfirmed } = await this.defaultAlert({
        title: this.$t('dashboard.modal.invite.title'),
        html: this.confirmAlertContent(),
        confirmButtonText: this.$t('dashboard.modal.invite.confirm'),
        cancelButtonText: this.$t('dashboard.modal.invite.cancel'),
      });

      return isConfirmed;
    },
    confirmAlertContent() {
      const basePart = `<p>${this.$t('dashboard.modal.invite.content', {
        quantity: this.parsedEmails.length,
        plural: this.polishPlurals(
          this.$t('candidate.create.singularNominativ'),
          this.$t('candidate.create.pluralNominativ'),
          this.$t('candidate.create.pluralGenitive'),
          this.parsedEmails.length,
        ),
      })}</p>`;
      const existingCandidatesPart = this.existingCandidates.length > 1
        ? `<p><strong class="swal2-text-uppercase">${this.$t(
          'dashboard.note',
        )}:</strong> ${this.$t('dashboard.modal.invite.content_for_multiple_candidates', {
          existingCandidatesLength: this.existingCandidates.length,
        })}</p> <div class="listing">${this.existingEmails()}</div>`
        : `<p><strong class="swal2-text-uppercase">${this.$t(
          'dashboard.note',
        )}:</strong> ${this.$t(
          'dashboard.modal.invite.content_for_single_candidate',
        )}</p> <div class="listing">${this.existingEmails()}</div>`;

      return this.existingCandidates.length
        ? `${basePart}</br>${existingCandidatesPart}`
        : basePart;
    },
    existingEmails() {
      return this.existingCandidates
        .map(({ email }, index) => {
          const isLastIndex = index === this.existingCandidates.length - 1;

          return `<p>${email}${isLastIndex ? '' : ','}</p>`;
        })
        .join('');
    },
    updateRecruitmentProcess() {
      this.$router.replace({
        query: {
          recruitment_process_id: this.formData.selectedRecruitmentProcess.id,
        },
      });
      this.$emit('fetch-message-templates');
    },
    updateMessageTemplate() {
      this.formData.messageTitle = this.formData.selectedInvitationMessage.title;
      this.formData.messageBody = this.formData.selectedInvitationMessage.body;
    },
    variableWithoutBrackets(variable) {
      return variable.replace(/[\])}[{(]/g, '');
    },
  },
};
</script>
