import i18n from '@/i18n';
import RouterOutlet from '@/components/Dashboard/Partials/RouterOutlet/RouterOutlet';
import Index from '@/views/Dashboard/ManagementAnalyst/Index';
import { GAME_SESSION_EVENT_LOG_INDEX } from '@/router/permissions.names';
import { MANAGEMENT_ANALYST_LIST } from '@/router/routes.names';
import { isAuthenticated } from '@/router/permissions';

const managementAnalystRoutes = [
  {
    path: '/management-analyst',
    component: RouterOutlet,
    children: [
      {
        path: '',
        name: MANAGEMENT_ANALYST_LIST,
        component: Index,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.management_analyst_list'),
              },
            ];
          },
          permissions: GAME_SESSION_EVENT_LOG_INDEX,
        },
      },
    ],
  },
];

export default managementAnalystRoutes;
