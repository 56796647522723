import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {?Number} page
 * @param {?Number} limit
 * @param {?Object} sort
 */
const fetchGamePages = async ({
  gameId,
  page,
  limit,
  sort,
}) => {
  const { data: { data, meta } } = await api.get(`games/${gameId}/game-pages`, {
    params: {
      page,
      limit,
      sort,
    },
  });

  return { data, pagination: meta.pagination };
};

export default fetchGamePages;
