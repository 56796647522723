import api from '@/plugins/axios/api';

/**
 * @param {String} name
 * @param {String} title
 * @param {String} body
 * @param {String} messageTemplateId
 */
const editMessageTemplate = async ({
  name,
  title,
  body,
  messageTemplateId,
}) => {
  await api.put(`message-templates/${messageTemplateId}`, {
    name,
    title,
    body,
  });
};

export default editMessageTemplate;
