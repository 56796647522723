import api from '@/plugins/axios/api';

/**
 * @param {String} name
 * @param {String} clientId
 * @param {Array} fields
 * @param {String} gameFormId
 */
const editGameForm = async ({
  name,
  clientId,
  fields,
  gameFormId,
}) => {
  await api.put(`game-forms/${gameFormId}`, {
    name,
    client_id: clientId,
    fields,
  });
};

export default editGameForm;
