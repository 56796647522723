export default {
  title: 'Profile kompetencyjne',
  search: 'Wyszukaj',
  name: 'Nazwa',
  competences_count: 'L. kompetencji',
  created_at: 'Data utworzenia',
  no_results: 'Nie znaleziono profili kompetencyjnych',
  add: 'Dodaj profil kompetencyjny',
  edit: 'Edytuj profil kompetencyjny',
  create: {
    name: 'Nazwa profilu kompetencyjnego',
    competences: 'Kompetencje',
    competences_hint: 'Wybierz kompetencję',
  },
};
