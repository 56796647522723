export default {
  login: {
    title: 'Success',
    content: 'Logged in successfully.',
  },
  edit_success: {
    title: 'Success',
    content: 'Changes have been saved successfully.',
  },
  confirm_modal: {
    title: 'Success',
    content: 'Action completed successfully.',
  },
  code_401: {
    title: 'Your session has expired',
    content: 'Your account has been logged out. Log in to the system again. ',
  },
  code_403: {
    title: 'No Access',
    content: 'You do not have access to this page.',
  },
  copy_file: {
    title: 'Success',
    content: 'Link has been copied.',
  },
};
