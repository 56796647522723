import { IMAGE, PDF } from '@/names/fileTypes.names';

const checkFileType = (fileType) => {
  if (fileType === 'application/pdf') return PDF;
  if (fileType.startsWith('image/')) return IMAGE;

  return null;
};

export default checkFileType;
