<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <span class="candidate-card__title">{{ $t('candidate.basic_fields') }}</span>
        <v-divider class="mt-2 mb-8" />
        <div class="candidate-card__basic-fields">
          <component
            :is="fieldComponentForType(field.type)"
            v-for="field in formData.basicFields"
            :key="field.id"
            v-model="field.value"
            rules="max:255"
            :label="field.name"
            :is-loading="isLoading"
            :disabled="field.form_name === 'email' ? true : false"
          />
        </div>
        <div v-if="formData.additionalFields.length">
          <span class="candidate-card__title">{{ $t('candidate.additional_fields') }}</span>
          <v-divider class="mt-2 mb-8" />
          <div class="candidate-card__basic-fields">
            <component
              :is="fieldComponentForType(field.type)"
              v-for="(field, index) in formData.additionalFields"
              :key="field.id"
              v-model="field.value"
              :rules="validationRulesForType(field)"
              :label="field.name"
              :is-loading="isLoading"
              :vid="`${field.name}-${index}`"
              v-bind="extraParamsForComponent(field)"
            />
          </div>
        </div>
        <span class="candidate-card__title">{{ $t('candidate.note') }}</span>
        <v-divider class="mt-2 mb-8" />
        <div class="candidate-card__basic-fields">
          <BaseTextarea
            v-model="formData.note"
            rules="min:3|max:3000"
            mode="eager"
            :label="$t('candidate.note')"
          />
        </div>
      </div>
      <EditButtons
        :is-sending="isSending"
        :edit-mode="editMode"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import { formatDate } from '@/plugins/dates/dates';
import { AGREEMENT_DATE_FORMAT } from '@/plugins/dates/dates.types';
import { HAS_FORM_CHANGES } from '@/names/emits.names';
import alerts from '@/plugins/alerts';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import BaseTextarea from '@/components/Form/BaseTextarea';
import BaseSelect from '@/components/Form/BaseSelect';
import BaseInput from '@/components/Form/BaseInput';
import BaseNumber from '@/components/Form/BaseNumber';
import BaseFileInput from '@/components/Form/BaseFileInput';
import BaseDatePicker from '@/components/Form/BaseDatePicker';
import BaseCheckbox from '@/components/Form/BaseCheckbox';
import * as fieldTypes from '@/components/Dashboard/GameForm/Fields/Field.types';

const FIELD_COMPONENTS = {
  TEXT: 'BaseInput',
  TEXTAREA: 'BaseTextarea',
  SELECT: 'BaseSelect',
  MULTISELECT: 'BaseSelect',
  NUMBER: 'BaseNumber',
  FILE: 'BaseFileInput',
  CHECKBOX: 'BaseCheckbox',
  DATE: 'BaseDatePicker',
};

const TYPES_VALIDATION_RULES = {
  TEXT: 'max:255',
  TEXTAREA: 'max:3000',
  SELECT: '',
  MULTISELECT: '',
  FILE: 'size:10240',
  CHECKBOX: '',
  DATE: '',
  HEADER: '',
  PHONE_NUMBER: '',
};

export default {
  name: 'CandidateCardForm',
  components: {
    BackendErrors,
    ValidationObserver,
    EditButtons,
    BaseTextarea,
    BaseSelect,
    BaseInput,
    BaseNumber,
    BaseFileInput,
    BaseDatePicker,
    BaseCheckbox,
  },
  props: {
    candidateCard: {
      type: Object,
      default: () => {},
    },
    backendErrors: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        basicFields: [],
        additionalFields: [],
        note: '',
      },
    };
  },
  watch: {
    formData: {
      handler() {
        this.checkFormChanges();
      },
      deep: true,
    },
  },
  created() {
    this.formData.basicFields = this.candidateCard.basic.data;
    this.formData.additionalFields = this.candidateCard.additional.data;
    this.formData.note = this.candidateCard.note;
  },
  methods: {
    ...alerts,
    formatDate,
    extraParamsForComponent({
      type,
      description = null,
      options = [],
      step = 0,
      min = 0,
      max = 0,
      value_changed_at: changedAt,
    }) {
      switch (type) {
        case fieldTypes.SELECT:
          return {
            items: options,
            clearable: true,
          };
        case fieldTypes.MULTISELECT:
          return {
            items: options,
            clearable: true,
            multiple: true,
            chips: true,
            deletableChips: true,
          };
        case fieldTypes.NUMBER:
          return {
            step,
            min,
            max,
          };
        case fieldTypes.CHECKBOX:
          return {
            description,
            date: changedAt ? formatDate(changedAt, AGREEMENT_DATE_FORMAT) : null,
          };
        default:
          return null;
      }
    },
    fieldComponentForType(type) {
      return FIELD_COMPONENTS[type];
    },
    validationRulesForType(field) {
      if (field.type === fieldTypes.NUMBER) {
        return `numeric|min_value:${field.min}|max_value:${field.max}`;
      }

      return TYPES_VALIDATION_RULES[field.type];
    },
    async checkFormChanges() {
      const { flags: { changed } } = await this.$refs.form.validateWithInfo();
      this.$emit(HAS_FORM_CHANGES, changed);
    },
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const { candidateId, recruitmentProcessId } = this.$route.params;
      const formData = new FormData();
      formData.set('_method', 'PUT');
      this.basicFields(formData);
      this.additionalFields(formData);
      formData.set('note', this.formData.note ? this.formData.note : '');

      return { candidateId, recruitmentProcessId, params: formData };
    },
    basicFields(formData) {
      return this.formData.basicFields.map((basicField, index) => {
        if (basicField.form_name === 'email') return false;

        formData.set(`basic[${index}][form_name]`, basicField.form_name);
        formData.set(`basic[${index}][value]`, basicField.value ? basicField.value : '');

        return basicField;
      });
    },
    additionalFields(formData) {
      return this.formData.additionalFields.map((additionalField, index) => {
        if (additionalField.type === fieldTypes.HEADER) return false;

        formData.set(`additional[${index}][id]`, additionalField.id);
        if (additionalField.type === fieldTypes.CHECKBOX || additionalField.type === fieldTypes.NUMBER) {
          return formData.set(`additional[${index}][value]`, +additionalField.value);
        }
        if (additionalField.type === fieldTypes.NUMBER) {
          return formData.set(`additional[${index}][value]`, +additionalField.value);
        }
        if (additionalField.type === fieldTypes.MULTISELECT) {
          if (!additionalField.value.length) {
            return formData.set(`additional[${index}][value]`, additionalField.value);
          }

          return additionalField.value.map((field, multiselectIndex) => {
            formData.set(`additional[${index}][value][${multiselectIndex}]`, field);
            formData.set(`additional[${index}][value][${multiselectIndex}]`, field);

            return field;
          });
        }

        return formData.set(`additional[${index}][value]`, additionalField.value ? additionalField.value : '');
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.candidate-card__basic-fields {
  max-width: 1400px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 20px;
  @media (min-width: 1200px) {
    gap: 0 60px;
  }
}
.candidate-card__title {
  font-size: 20px;
  font-weight: 500;
}
</style>
