export default {
  title: 'Procesy rekrutacyjne',
  search: 'Wyszukaj',
  name: 'Nazwa',
  work_position_display_name: 'Wyświetlana nazwa stanowiska w grze',
  work_position: 'Stanowisko',
  games: 'Narzędzia',
  recruiters: 'Rekruterzy',
  tags: 'Tagi',
  created_at: 'Data utworzenia',
  candidates: 'Kandydaci',
  copy_link: 'Kopiuj link',
  no_results: 'Nie znaleziono procesów rekrutacyjnych',
  add: 'Dodaj proces rekrutacyjny',
  edit: 'Edytuj proces rekrutacyjny',
  process_tools: 'Etapy w procesie',
  types: {
    all: 'Wszystkie',
    game: 'Gra',
    recruitment_form: 'Formularz rekrutacyjny',
    recruitive: 'Gra rekrutacyjna',
    selective: 'Gra selekcyjna',
    preselection: 'Preselekcja',
    initial_interview: 'Wywiad wstępny',
    selection_interview: 'Wywiad selekcyjny',
    meet: 'Spotkanie',
    hiring: 'Zatrudnienie',
    other: 'Inne',
  },
  report_template_types: {
    basic: 'Raport podstawowy',
    full: 'Raport z pełnymi danymi kandydata',
  },
  create: {
    name: 'Nazwa procesu rekrutacyjnego',
    work_position_display_name: 'Wyświetlana nazwa stanowiska w grze ( opcjonalnie )',
    work_position: 'Stanowisko',
    recruiters: 'Rekruterzy',
    stages: 'Etapy',
    stage_one: 'Etap 1 - narzędzie',
    stage_two: 'Etap 2 - narzędzie (opcjonalne)',
    other_stages: 'Pozostałe etapy (opcjonalne)',
    report: 'Raport',
    report_template: 'Szablon raportu',
    report_description: 'Opis w raporcie',
    notes: 'Notatki',
    is_open: 'Link aktywny',
    is_closed: 'Link nieaktywny',
    is_open_desc: 'Kandydaci mogą otworzyć link',
    is_closed_desc: 'Kandydaci nie mogą otworzyć linku',
    registration_url: 'Link do procesu rekrutacyjnego',
  },
};
