export default {
  required: 'Enter %{name}',
  image_required: 'Select an image',
  video_required: 'Select a video file',
  extensions: 'Correct file format: %{exts}',
  select: 'Select %{name}',
  min_length: 'The minimum number of characters is: %{length}',
  max_length: 'The maximum number of characters is: %{length}',
  max_value: 'The maximum value must be %{value} or less',
  min_value: 'The minimum value must be %{value} or more',
  scoring_incorrect: 'Incorrect score format',
  max_file_size: 'The maximum file size is %{size} mb',
  numeric: 'Incorrect format of the number',
  search: 'Enter at least %{length} characters',
  email_required: 'Enter your e-mail address',
  emails_required: 'Enter your e-mail address or addresses',
  email_incorrect: 'Invalid format',
  emails_incorrect: 'The given e-mail addresses are incorrect:',
  emails_end_characters: 'Illegal character at end of list',
  password_confirm: 'The password and the re-entered password are not the same',
  password_incorrect: 'Password must contain at least 8 characters, including at least: 1 digit, 1 lowercase letter, 1 uppercase letter and 1 special character',
  time_limit: 'Incorrect time format',
  max_array_length: 'The maximum number of elements that can be selected is: %{length}',
  url: 'Incorrect url address format',
  full_text_search: 'Enter minimum %{length} characters (no special characters)',
  is_not: 'Fields cannot have the same value',
};
