import api from '@/plugins/axios/api';

/**
 * @param {String} messageTemplateId
 */
const deleteMessageTemplate = async ({
  messageTemplateId,
}) => {
  await api.delete(`message-templates/${messageTemplateId}`);
};

export default deleteMessageTemplate;
