<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <v-row>
          <v-col
            sm="12"
            md="10"
            lg="8"
          >
            <slot />
            <BaseInput
              v-model="formData.title"
              rules="min:3|max:255|required"
              :label="$t('stages.image.title')"
            />
            <BaseImageUploader
              v-model="formData.image"
              :rules="imageValidationRules()"
              :label="$t('stages.image.question_image')"
              :vid="$t('stages.image.question_image')"
            />
            <BaseInput
              v-model="formData.continueBtn"
              rules="min:3|max:255|required"
              :label="$t('stages.image.continue_btn')"
            />
          </v-col>
        </v-row>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseImageUploader from '@/components/Form/BaseImageUploader';
import BaseInput from '@/components/Form/BaseInput';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import { DELETE } from '@/names/global.names';

export default {
  name: 'ImageQuestion',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseImageUploader,
    BaseInput,
  },
  props: {
    backendErrors: {
      type: Array,
      default: () => [],
    },
    currentStage: {
      type: String,
      required: true,
    },
    stage: {
      type: Object,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxSize: 102400,
      formData: {
        title: '',
        image: '',
        continueBtn: 'Kontynuuj',
      },
    };
  },
  created() {
    if (this.editMode) {
      this.formData.title = this.stage.stageable.data.title;
      this.formData.image = this.stage.stageable.data.image_url;
      this.formData.continueBtn = this.stage.stageable.data.continue_btn;
    }
  },
  methods: {
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const formData = new FormData();
      const { gameId, stageId } = this.$route.params;
      formData.set('type', this.currentStage);
      formData.set('title', this.formData.title);
      formData.set('image', this.formData.image);
      formData.set('continue_btn', this.formData.continueBtn);
      if (this.editMode) {
        formData.set('_method', 'PUT');

        return { gameId, stageId, params: formData };
      }

      return { gameId, params: formData };
    },
    imageValidationRules() {
      return `${
        !this.editMode || (this.formData.image === DELETE && this.editMode)
          ? 'image_required|'
          : ''
      }size:${this.maxSize}|extensions:jpeg,jpg,png,gif`;
    },
  },
};
</script>
