<template>
  <div>
    <div
      v-if="isPreloadingData"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title class="py-6">
          <div class="table-heading">
            <h5 class="table-heading__title text-h5">
              {{ $t("workPosition.title") }}
            </h5>
            <div class="table-heading__actions">
              <div class="table-heading__item">
                <BaseButton
                  color="grayDark"
                  dark
                  @click="goToEditDepartments"
                >
                  {{ $t("workPosition.edit_departments") }}
                  <v-icon right>
                    mdi-file-export
                  </v-icon>
                </BaseButton>
              </div>
              <div class="table-heading__item">
                <BaseButton @click="goToAddWorkPosition">
                  {{ $t("dashboard.add") }}
                  <v-icon right>
                    mdi-plus
                  </v-icon>
                </BaseButton>
              </div>
            </div>
          </div>
        </v-card-title>
        <div class="table-filters">
          <TableSearch
            is-full-text-search
            @input="fetchWorkPositions"
          />
        </div>
        <v-divider />
        <BaseTable
          :table-headers="tableHeaders"
          :table-data="tableData"
          :is-loading="isLoading"
          :no-results-text="$t('workPosition.no_results')"
          :pagination="pagination"
          :default-sort="sort"
          @change-page="fetchWorkPositions"
          @change-items-per-page="fetchWorkPositions"
          @change-sort="fetchWorkPositions"
        >
          <template v-slot:item="{ item }">
            <td>{{ item.name ? item.name : "-" }}</td>
            <td>{{ item.competency_profile ? item.competency_profile.data.name : "-" }}</td>
            <td>{{ item.work_department ? item.work_department.data.name : "-" }}</td>
            <td>{{ formatDate(item.created_at) }}</td>
            <td>
              <Actions
                :work-position-id="item.id"
                @handle-delete="deleteWorkPosition"
              />
            </td>
          </template>
        </BaseTable>
      </v-card>
    </div>
  </div>
</template>
<script>
import alerts from '@/plugins/alerts';
import { WORK_POSITION_ADD, WORK_DEPARTMENT_LIST } from '@/router/routes.names';
import { HTTP_UNAUTHORIZED, HTTP_CONFLICT, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { formatDate } from '@/plugins/dates/dates';
import { ASC } from '@/components/Table/sort.types';
import fetchWorkPositions from '@/components/Dashboard/WorkPosition/fetchWorkPositions';
import deleteWorkPosition from '@/components/Dashboard/WorkPosition/deleteWorkPosition';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import BaseButton from '@/components/Form/BaseButton';
import BaseTable from '@/components/Table/BaseTable';
import TableSearch from '@/components/Table/TableSearch';
import Actions from '@/components/Dashboard/WorkPosition/Actions';

export default {
  name: 'WorkPositionList',
  components: {
    Preloader,
    BaseButton,
    BaseTable,
    TableSearch,
    Actions,
  },
  data() {
    return {
      tableHeaders: [
        {
          value: 'name',
          text: this.$t('workPosition.name'),
        },
        {
          value: 'competency_profile_name',
          text: this.$t('workPosition.competency_profile_name'),
        },
        {
          value: 'work_department_name',
          text: this.$t('workPosition.work_department_name'),
        },
        {
          value: 'created_at',
          text: this.$t('workPosition.created_at'),
          width: '150px',
        },
        {
          value: 'actions',
          sortable: false,
          align: 'right',
          width: '50px',
        },
      ],
      tableData: [],
      pagination: null,
      isLoading: false,
      isPreloadingData: false,
      sort: {
        column: 'name',
        direction: ASC,
      },
    };
  },
  async created() {
    this.isPreloadingData = true;
    await this.fetchWorkPositions();
    this.isPreloadingData = false;
  },
  methods: {
    ...alerts,
    formatDate,
    goToEditDepartments() {
      this.$router.push({ name: WORK_DEPARTMENT_LIST });
    },
    goToAddWorkPosition() {
      this.$router.push({ name: WORK_POSITION_ADD });
    },
    setParams(params = {}) {
      return {
        includes: ['competency_profile', 'work_department'],
        sort: this.sort,
        ...this.$route.query,
        ...params,
      };
    },
    async fetchWorkPositions(params) {
      try {
        this.isLoading = true;
        const response = await fetchWorkPositions(this.setParams(params));
        this.tableData = response.data;
        this.pagination = response.pagination;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteWorkPosition({ workPositionId }) {
      try {
        this.isLoading = true;
        await deleteWorkPosition({ workPositionId });
        const elementIndex = this.tableData.findIndex(((workPosition) => workPosition.id === workPositionId));
        this.tableData.splice(elementIndex, 1);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_CONFLICT) {
          this.errorAlert({ text: e.response.data.message });

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
