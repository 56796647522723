export default {
  title: 'Użytkownicy',
  search: 'Wyszukaj',
  name: 'Imię nazwisko',
  email: 'E-mail',
  client: 'Klient',
  type: 'Typ konta',
  status: 'Status',
  created_at: 'Data utworzenia',
  suspend: 'Zablokuj',
  unsuspend: 'Odblokuj',
  no_results: 'Nie znaleziono użytkowników',
  types: {
    recruiter: 'Rekruter',
    admin: 'Admin',
    super_admin: 'Super Admin',
  },
  statuses: {
    active: 'Aktywny',
    blocked: 'Zablokowany',
  },
  add: 'Dodaj użytkownika',
  edit: 'Edytuj użytkownika',
  create: {
    email: 'Adres e-mail',
    name: 'Imię nazwisko',
    client: 'Klient',
    type: 'Typ konta',
    recruitment_process: 'Proces rekrutacyjny',
  },
};
