<template>
  <div class="table-filter">
    <slot :updateFilter="updateFilter" />
  </div>
</template>
<script>
import { removeNullProperties } from '@/plugins/objectHelpers/removeNullProperties';

export default {
  name: 'TableFilter',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filters: null,
    };
  },
  computed: {
    filtersWithoutNullProperties() {
      return this.removeNullProperties(this.filters);
    },
  },
  watch: {
    filters(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.filters = newVal;
    },
  },
  created() {
    if (this.value) {
      this.filters = this.value;
    }
  },
  methods: {
    removeNullProperties,
    updateFilter(key, value) {
      this.filters[key] = value;
      const { filtersWithoutNullProperties } = this;
      this.$router.replace({
        query: {
          ...this.$route.query,
          filters: {
            form_fields: this.$route.query?.filters?.form_fields,
            ...filtersWithoutNullProperties,
          },
        },
      }, () => {});
      this.$emit('change-filter', { filtersWithoutNullProperties });
    },
  },
};
</script>
<style lang="scss">
.table-filter {
  .v-select__selection, input {
    font-size: 14px;
  }
  label {
    font-size: 14px;
  }
}
</style>
