<template>
  <div>
    <div
      v-if="isLoading"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title>
          <v-row>
            <v-col
              class="px-3 px-lg-6"
              sm="12"
            >
              <p class="headline black--text mt-4">
                {{ editMode ? $t('competencyProfile.edit') : $t("competencyProfile.add") }}
              </p>
              <v-divider class="mt-3 mb-4" />
            </v-col>
          </v-row>
        </v-card-title>
        <div class="wrapper">
          <v-row class="px-3 px-lg-6">
            <v-col
              sm="12"
              md="10"
              lg="6"
            >
              <CompetencyProfileForm
                :edit-mode="editMode"
                :competency-profile="competencyProfile"
                :competences="competences"
                :is-loading="isLoading"
                :is-sending="isSending"
                :backend-errors="errors"
                @handle-submit="submit"
                @handle-cancel="returnToList"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import alerts from '@/plugins/alerts';
import { HTTP_UNAUTHORIZED, HTTP_NOT_FOUND, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { NOT_FOUND, COMPETENCY_PROFILE_LIST } from '@/router/routes.names';
import { ASC } from '@/components/Table/sort.types';
import fetchCompetencyProfile from '@/components/Dashboard/CompetencyProfile/fetchCompetencyProfile';
import fetchCompetences from '@/components/Dashboard/Competence/fetchCompetences';
import addCompetencyProfile from '@/components/Dashboard/CompetencyProfile/addCompetencyProfile';
import editCompetencyProfile from '@/components/Dashboard/CompetencyProfile/editCompetencyProfile';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import CompetencyProfileForm from '@/components/Dashboard/CompetencyProfile/Form';

export default {
  name: 'EditCompetencyProfile',
  components: {
    Preloader,
    CompetencyProfileForm,
  },
  data() {
    return {
      editMode: false,
      competencyProfile: null,
      competences: [],
      isLoading: false,
      isSending: false,
      errors: [],
    };
  },
  async created() {
    this.editMode = this.$route.meta.editMode;
    try {
      this.isLoading = true;
      const competences = await fetchCompetences({
        limit: 99999,
        sort: {
          column: 'name',
          direction: ASC,
        },
      });
      this.competences = competences.data;
      if (this.editMode) {
        this.competencyProfile = await fetchCompetencyProfile({
          competencyProfileId: this.$route.params.competencyProfileId,
          includes: ['competences'],
        });
      }
    } catch (e) {
      if (e.response?.status === HTTP_UNAUTHORIZED) return;
      if (e.response?.status === HTTP_NOT_FOUND) {
        this.$router.push({ name: NOT_FOUND });

        return;
      }
      if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
        this.errorAlert();

        return;
      }
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...alerts,
    returnToList() {
      this.$router.push({ name: COMPETENCY_PROFILE_LIST });
    },
    async submit(payload) {
      try {
        this.errors = [];
        this.isSending = true;
        this.editMode ? await editCompetencyProfile(payload) : await addCompetencyProfile(payload);
        this.$notify.save.success();
        this.returnToList();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isSending = false;
      }
    },
  },
};
</script>
