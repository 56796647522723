/* eslint-disable no-restricted-syntax */
import imageDimensions from '@/plugins/images/imageDimensions';

const EMAIL_TEMPLATE_WIDTH = '570';

const imageAlignment = (element, attribute = 'style') => {
  const value = attribute === 'style' ? element.getAttribute('style') : element.className;
  let alignment = 'left';
  if (value && attribute === 'style') {
    alignment = value.includes('right') ? 'right' : alignment;
    alignment = value.includes('center') ? 'center' : alignment;
  }

  if (attribute === 'class') {
    alignment = 'center';
    alignment = value.includes('right') ? 'right' : alignment;
    alignment = value.includes('left') ? 'left' : alignment;
  }

  return `text-align:${alignment}; max-width:${EMAIL_TEMPLATE_WIDTH}px;`;
};

const wrapImagesToTable = async (body) => {
  const temporaryDiv = document.createElement('div');
  temporaryDiv.innerHTML = body;
  const figures = temporaryDiv.querySelectorAll('figure');
  const imgs = temporaryDiv.querySelectorAll('img');

  await Promise.all(Array.from(imgs).map(async (img) => {
    const { width } = await imageDimensions(img.src);
    const styles = imageAlignment(img.parentNode);
    img.parentNode.setAttribute('style', styles);
    if (width > EMAIL_TEMPLATE_WIDTH) {
      img.setAttribute('width', '100%');
      img.setAttribute('style', 'width: 100%;');
    }
  }));

  for (const figure of figures) {
    const paragraph = document.createElement('p');
    const styles = imageAlignment(figure, 'class');
    paragraph.setAttribute('style', styles);

    for (const childNode of figure.childNodes) {
      if (childNode.tagName === 'IMG') {
        // eslint-disable-next-line no-await-in-loop
        const { width } = await imageDimensions(childNode.src);
        if (width > EMAIL_TEMPLATE_WIDTH) {
          childNode.setAttribute('width', '100%');
          childNode.setAttribute('style', 'width: 100%;');
        }
      }
      paragraph.appendChild(childNode.cloneNode(true));
    }

    figure.parentNode.insertBefore(paragraph, figure);
    figure.parentNode.removeChild(figure);
  }

  return temporaryDiv.innerHTML;
};

export default wrapImagesToTable;
