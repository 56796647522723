<template>
  <v-navigation-drawer
    :value="isCandidateSidebarActive"
    clipped
    app
    :width="sidebarWidth"
    disable-resize-watcher
    :mobile-breakpoint="0"
    style="padding-top: 72px"
  >
    <div class="py-6">
      <div class="d-flex justify-space-between align-center px-4">
        <span class="grayLight--text body-1">{{ $t("candidate.filters") }}</span>
        <button
          class="d-flex justify-center align-center primary--text body-2"
          @click="toggleCandidateSidebar"
        >
          <span class="mr-2">
            {{ $t("candidate.close_filters") }}
          </span>
          <v-icon color="primary">
            mdi-arrow-left
          </v-icon>
        </button>
      </div>
      <div
        v-if="isLoading"
        class="preloader-container"
      >
        <Preloader />
      </div>
      <div
        v-else
        class="mt-3"
      >
        <component
          :is="fieldComponentForType(filterField.type)"
          v-for="(filterField, index) in formFields"
          :id="filterField.id"
          :key="filterField.id"
          :label="filterField.name"
          :class="extraClassForComponent(filterField.type, index)"
          v-bind="extraParamsForComponent(filterField)"
          @change-filter="changeFilter"
        />
      </div>
    </div>
  </v-navigation-drawer>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { removeNullProperties } from '@/plugins/objectHelpers/removeNullProperties';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import HeaderField from '@/components/Dashboard/Candidate/AdvancedFilters/FilterFields/HeaderField';
import TextField from '@/components/Dashboard/Candidate/AdvancedFilters/FilterFields/TextField';
import SelectField from '@/components/Dashboard/Candidate/AdvancedFilters/FilterFields/SelectField';
import CheckboxField from '@/components/Dashboard/Candidate/AdvancedFilters/FilterFields/CheckboxField';
import DateField from '@/components/Dashboard/Candidate/AdvancedFilters/FilterFields/DateField';
import NumberField from '@/components/Dashboard/Candidate/AdvancedFilters/FilterFields/NumberField';
import * as fieldTypes from '@/components/Dashboard/GameForm/Fields/Field.types';

const FIELD_COMPONENTS = {
  TEXT: 'TextField',
  TEXTAREA: 'TextField',
  SELECT: 'SelectField',
  MULTISELECT: 'SelectField',
  CHECKBOX: 'CheckboxField',
  DATE: 'DateField',
  HEADER: 'HeaderField',
  NUMBER: 'NumberField',
};

export default {
  name: 'AdvancedFiltersSidebar',
  components: {
    Preloader,
    HeaderField,
    TextField,
    SelectField,
    CheckboxField,
    DateField,
    NumberField,
  },
  props: {
    formFields: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      sidebarWidth: 290,
      filters: {
        form_fields: null,
      },
    };
  },
  computed: {
    ...mapState('layout', ['isCandidateSidebarActive']),
    filtersWithoutNullProperties() {
      return this.removeNullProperties(this.filters.form_fields);
    },
  },
  watch: {
    isLoading(value) {
      if (value) {
        this.filters.form_fields = null;
      }
    },
  },
  methods: {
    ...mapActions('layout', ['toggleCandidateSidebar']),
    removeNullProperties,
    fieldComponentForType(type) {
      return FIELD_COMPONENTS[type];
    },
    extraClassForComponent(type, index) {
      const { HEADER } = fieldTypes;
      if ([HEADER].includes(type) && index !== 0) {
        return 'border-top';
      }

      return null;
    },
    extraParamsForComponent({
      type,
      description = null,
      options = [],
      step = 0,
    }) {
      const {
        CHECKBOX, SELECT, MULTISELECT, NUMBER,
      } = fieldTypes;
      switch (type) {
        case SELECT:
          return {
            items: options,
          };
        case MULTISELECT:
          return {
            items: options,
          };
        case CHECKBOX:
          return {
            description,
          };
        case NUMBER:
          return {
            step,
          };
        default:
          return null;
      }
    },
    changeFilter({ id, values }) {
      Object.assign(this.filters, {
        form_fields: {
          ...this.$route.query.filters?.form_fields,
          [id]: values,
        },
      });
      this.$router.replace(
        {
          query: {
            ...this.$route.query,
            filters: {
              ...this.$route.query?.filters,
              form_fields: this.filtersWithoutNullProperties,
            },
          },
        },
        () => {},
      );
      this.$emit('update-table-data');
    },
  },
};
</script>
<style lang="scss" scoped>
.border-top {
  border-top: 1px solid;
  border-color: rgba(0, 0, 0, 0.12);
  margin-top: 15px;
}
</style>
