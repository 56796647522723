<template>
  <div>
    <div
      v-if="isPreloadingData"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title class="py-6">
          <div class="table-heading">
            <h5 class="table-heading__title text-h5">
              {{ $t("game.title") }}
            </h5>
            <div class="table-heading__actions">
              <div class="table-heading__item">
                <BaseButton @click="goToAddGame">
                  {{ $t("dashboard.add") }}
                  <v-icon right>
                    mdi-plus
                  </v-icon>
                </BaseButton>
              </div>
            </div>
          </div>
        </v-card-title>
        <TableFilter
          v-model="activeFilters"
          @change-filter="fetchGames"
        >
          <template #default="{ updateFilter }">
            <div class="table-filters">
              <TableSearch
                is-full-text-search
                @input="fetchGames"
              />
              <BaseSelect
                v-model="activeFilters.client_id"
                :items="clients"
                :label="$t('game.client')"
                :disabled="isLoading"
                item-text="name"
                item-value="id"
                persistent-hint
                hide-details
                searchable
                clearable
                @change="updateFilter('client_id', activeFilters.client_id)"
              />
              <BaseSelect
                v-model="activeFilters.tags_assigned"
                :items="tags"
                :label="$t('game.tags')"
                :disabled="isLoading"
                item-text="name"
                item-value="id"
                persistent-hint
                multiple
                hide-details
                searchable
                clearable
                @change="updateFilter('tags_assigned', activeFilters.tags_assigned)"
              />
            </div>
          </template>
        </TableFilter>
        <v-divider />
        <BaseTable
          :table-headers="tableHeaders"
          :table-data="tableData"
          :is-loading="isLoading"
          :no-results-text="$t('game.no_results')"
          :pagination="pagination"
          :default-sort="sort"
          @change-page="fetchGames"
          @change-items-per-page="fetchGames"
          @change-sort="fetchGames"
        >
          <template v-slot:item="{ item }">
            <td>{{ item.name ? item.name : "-" }}</td>
            <td>{{ item.type ? $t(`game.types.${item.type.toLowerCase()}`) : "-" }}</td>
            <td>{{ item.locale ? $t(`game.locales.${item.locale.toLowerCase()}`) : "-" }}</td>
            <td>{{ item.client ? item.client.data.name : "-" }}</td>
            <td>
              {{ item.activated_at !== null ? $t("game.create.active") : $t("game.create.draft") }}
            </td>
            <td v-if="item.tags.data.length">
              <GameTags
                item-key="name"
                :game-id="item.id"
                :items="item.tags.data"
                @refetch-data="fetchCompleteData"
              />
            </td>
            <td v-else>
              -
            </td>
            <td>{{ formatDate(item.created_at) }}</td>
            <td>
              <Actions
                :game-id="item.id"
                @refetch-data="fetchCompleteData"
                @handle-delete="deleteGame"
              />
            </td>
          </template>
        </BaseTable>
      </v-card>
    </div>
  </div>
</template>
<script>
import alerts from '@/plugins/alerts';
import { GAME_ADD } from '@/router/routes.names';
import { HTTP_UNAUTHORIZED, HTTP_CONFLICT, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { formatDate } from '@/plugins/dates/dates';
import { ASC, DESC } from '@/components/Table/sort.types';
import fetchGames from '@/components/Dashboard/Game/fetchGames';
import deleteGame from '@/components/Dashboard/Game/deleteGame';
import fetchClients from '@/components/Dashboard/Client/fetchClients';
import fetchTags from '@/components/Dashboard/Game/fetchTags';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import BaseButton from '@/components/Form/BaseButton';
import BaseTable from '@/components/Table/BaseTable';
import TableSearch from '@/components/Table/TableSearch';
import Actions from '@/components/Dashboard/Game/Actions';
import GameTags from '@/components/Dashboard/Game/GameTags';
import TableFilter from '@/components/Table/TableFilter';
import BaseSelect from '@/components/Form/BaseSelect';

export default {
  name: 'GameList',
  components: {
    Preloader,
    BaseButton,
    BaseTable,
    TableSearch,
    Actions,
    GameTags,
    TableFilter,
    BaseSelect,
  },
  data() {
    return {
      tableHeaders: [
        {
          value: 'name',
          text: this.$t('game.name'),
        },
        {
          value: 'type',
          text: this.$t('game.type'),
        },
        {
          value: 'locale',
          text: this.$t('game.locale'),
          sortable: false,
        },
        {
          value: 'client_name',
          text: this.$t('game.client'),
        },
        {
          value: 'visibility',
          text: this.$t('game.visibility'),
          width: '150px',
          sortable: false,
        },
        {
          value: 'tags',
          text: this.$t('game.tags'),
          sortable: false,
        },
        {
          value: 'created_at',
          text: this.$t('game.created_at'),
          width: '150px',
        },
        {
          value: 'actions',
          sortable: false,
          align: 'right',
          width: '50px',
        },
      ],
      clients: [],
      tags: [],
      tableData: [],
      pagination: null,
      isLoading: false,
      isPreloadingData: false,
      sort: {
        column: 'name',
        direction: ASC,
      },
      activeFilters: {
        client_id: null,
        tags_assigned: [],
      },
    };
  },
  async created() {
    this.isPreloadingData = true;
    await this.fetchCompleteData();
    this.isPreloadingData = false;
  },
  methods: {
    ...alerts,
    formatDate,
    goToAddGame() {
      this.$router.push({ name: GAME_ADD });
    },
    async fetchCompleteData() {
      this.isLoading = true;
      try {
        await Promise.all([this.fetchGames(), this.fetchClients(), this.fetchGameTags()]);
      } finally {
        this.isLoading = false;
      }
    },
    setParams(params = {}) {
      return {
        includes: ['client', 'tags'],
        sort: this.sort,
        ...this.$route.query,
        ...params,
      };
    },
    async fetchGames(params) {
      try {
        this.isLoading = true;
        const response = await fetchGames(this.setParams(params));
        this.tableData = response.data;
        this.pagination = response.pagination;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchClients() {
      try {
        this.isLoading = true;
        const clients = await fetchClients({
          sort: {
            column: 'name',
            direction: ASC,
          },
        });
        this.clients = clients.data;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchGameTags() {
      try {
        this.isLoading = true;
        const { data } = await fetchTags({
          sort: {
            column: 'created_at',
            direction: DESC,
          },
          limit: 99999,
          filters: {
            only_assigned: 1,
          },
        });
        this.tags = data;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteGame({ gameId }) {
      try {
        this.isLoading = true;
        await deleteGame({ gameId });
        const elementIndex = this.tableData.findIndex((game) => game.id === gameId);
        this.tableData.splice(elementIndex, 1);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_CONFLICT) {
          this.errorAlert({ text: e.response.data.message });

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
