<template>
  <div class="not-found">
    <div class="not-found__topbar">
      <img
        :src="require('@/assets/images/logo.svg')"
        class="not-found__topbar--logo"
        height="37"
      >
    </div>
    <div class="not-found__content">
      <h2>{{ $t("notFound.code") }}</h2>
      <span>{{ $t("notFound.title") }}</span>
      <span>{{ $t("notFound.description") }}</span>
      <div
        v-if="isLogged"
        class="mt-16"
      >
        <BaseButton
          block
          @click="goBack"
        >
          {{ $t("notFound.back") }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { CLIENT_LIST, CANDIDATE_LIST } from '@/router/routes.names';
import BaseButton from '@/components/Form/BaseButton';

export default {
  name: 'NotFound',
  components: {
    BaseButton,
  },
  computed: {
    ...mapGetters('session', ['isSuperAdmin']),
    ...mapGetters('auth', ['isLogged']),
  },
  methods: {
    goBack() {
      this.$router.push({ name: this.isSuperAdmin ? CLIENT_LIST : CANDIDATE_LIST });
    },
  },
};
</script>
<style lang="scss" scoped>
.not-found {
  position: relative;
  min-height: 100vh;
  background: var(--white);
  &__topbar {
    background: var(--white);
    display: flex;
    align-items: center;
    height: 95px;
    width: 100%;
    border-bottom: 1px solid var(--grayLightest);
    &--logo {
      max-width: 143px;
      margin: 0 2rem 0 2rem;
    }
  }
  &__content {
    padding: 100px 0 80px;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      color: var(--primary);
      font-size: 12rem;
      line-height: 14rem;
      margin: 0;
      font-weight: 700;
    }
    span {
      &:nth-of-type(1) {
        color: var(--primary);
        padding: 2rem 0;
        text-transform: uppercase;
        font-size: 2.4rem;
        line-height: 2.6rem;
      }
      &:nth-of-type(2) {
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }
}
</style>
