export default {
  candidate_list: 'Kandydaci',
  candidate_add: 'Zaproszenie kandydata',
  client_list: 'Klienci',
  client_add: 'Dodaj klienta',
  client_edit: 'Edytuj klienta',
  competency_profile_list: 'Profile',
  competency_profile_add: 'Dodaj profil kompetencyjny',
  competency_profile_edit: 'Edytuj profil kompetencyjny',
  competence_list: 'Kompetencje',
  competence_add: 'Dodaj kompetencję',
  competence_edit: 'Edytuj kompetencję',
  dashboard: 'Panel Administracyjny',
  game_list: 'Gry',
  game_add: 'Dodaj grę',
  game_edit: 'Edytuj grę',
  game_badge_list: 'Odznaki',
  game_badge_add: 'Dodaj odznakę',
  game_badge_edit: 'Edytuj odznakę',
  game_form_list: 'Formularze',
  game_form_add: 'Dodaj formularz',
  game_form_edit: 'Edytuj formularz',
  game_page_list: 'Strony tekstowe',
  game_page_edit: 'Edytuj stronę tekstową',
  game_stage_list: 'Lista kroków',
  game_stage_add: 'Dodaj krok',
  game_stage_edit: 'Edytuj krok',
  management_analyst_list: 'Analityka',
  message_template_list: 'Komunikacja',
  message_template_add: 'Dodaj szablon wiadomości',
  message_template_edit: 'Edytuj szablon wiadomości',
  recruitment_list: 'Rekrutacje',
  recruitment_add: 'Dodaj proces rekrutacyjny',
  recruitment_edit: 'Edytuj proces rekrutacyjny',
  user_list: 'Użytkownicy',
  user_add: 'Dodaj użytkownika',
  user_edit: 'Edytuj użytkownika',
  work_position_list: 'Stanowiska',
  work_position_add: 'Dodaj stanowisko',
  work_position_edit: 'Edytuj stanowisko',
  work_department_list: 'Działy',
  work_department_add: 'Dodaj dział',
  work_department_edit: 'Edytuj dział',
};
