export default {
  title: 'Gry',
  search: 'Wyszukaj',
  name: 'Nazwa',
  type: 'Typ',
  locale: 'Język',
  client: 'Klient',
  visibility: 'Widoczość',
  tags: 'Tagi',
  manage_tags: 'Zarządzaj tagami',
  created_at: 'Data utworzenia',
  edit_game: 'Edytuj grę',
  edit_stages: 'Edytuj kroki',
  edit_badges: 'Edytuj odznaki',
  edit_pages: 'Edytuj strony',
  copy_game: 'Kopiuj',
  copy_game_to_client: 'Kopiuj grę do klienta',
  no_results: 'Nie znaleziono gier',
  types: {
    selective: 'Gra selekcyjna',
    recruitive: 'Gra rekrutacyjna',
    recruitment_form: 'Formularz rekrutacyjny',
  },
  locales: {
    cs: 'Czeski',
    de: 'Niemiecki',
    en: 'Angielski',
    fr: 'Francuski',
    hu: 'Węgierski',
    it: 'Włoski',
    nl: 'Hoelendeski',
    pl: 'Polski',
    ro: 'Rumuński',
    sk: 'Słowacki',
    hr: 'Chorwacki',
    pt: 'Portugalski',
    sl: 'Słoweński',
    uk: 'Ukraiński',
  },
  add: 'Dodaj grę',
  edit: 'Edytuj grę',
  create: {
    name: 'Nazwa gry',
    metaTitle: 'Tytuł meta',
    topBarTitle: 'Tytuł górnego paska',
    type: 'Typ gry',
    locale: 'Język gry',
    locale_hint: 'Wybierz język gry',
    other_games: 'Inne wersje językowe tej samej gry',
    recruitment_form: 'Formularz rekrutacyjny (opcjonalnie)',
    invitation_message: 'Wiadomość zapraszająca',
    reminder_message: 'Wiadomość przypominająca',
    result_message: 'Wiadomość z wynikiem',
    client: 'Klient',
    limit: 'Limit czasu',
    type_hint: 'Wybierz typ gry',
    client_hint: 'Wybierz klienta',
    limit_hint: '01h:25m:00s',
    active: 'Aktywne',
    draft: 'Wersja robocza',
    active_desc: 'Klient ma dostęp do narzędzia',
    draft_desc: 'Klient nie ma dostępu do narzędzia',
  },
};
