<template>
  <BaseGameFormField
    :value="value"
    :field-types="fieldTypes"
    :is-loading="isLoading"
    :dragging="dragging"
    :has-invalid-fields="hasInvalidFields"
    @update-type="type => updateType({ type })"
    @toggle-open="() => updateValue({ isOpen: !value.isOpen })"
    v-on="$listeners"
  >
    <div class="pb-2">
      <BaseInput
        :value="value.name"
        rules="max:255|required"
        :label="$t('gameForm.field.name')"
        :vid="`${$t('gameForm.field.name')}-${uniqueKey}`"
        @input="value => updateValue({ name: value })"
        @validate-field="validateField"
      />
      <BaseInput
        :value="value.placeholder"
        rules="max:255"
        :label="$t('gameForm.field.hint')"
        :vid="`${$t('gameForm.field.hint')}-${uniqueKey}`"
        @input="value => updateValue({ placeholder: value })"
        @validate-field="validateField"
      />
      <BaseInput
        :value="value.tooltip"
        rules="max:255"
        :label="$t('gameForm.field.tooltip')"
        :vid="`${$t('gameForm.field.tooltip')}-${uniqueKey}`"
        @input="value => updateValue({ tooltip: value })"
        @validate-field="validateField"
      />
      <BaseNumber
        :value="value.step"
        step="1"
        min="1"
        rules="numeric|min_value:1|required"
        :label="$t('gameForm.field.step')"
        :vid="`${$t('gameForm.field.step')}-${uniqueKey}`"
        @change="value => updateValue({ step: +value })"
        @validate-field="validateField"
      />
      <BaseNumber
        :value="value.min"
        step="1"
        min="0"
        max="2000000000"
        :rules="`numeric|min_value:0|max_value:${value.max !== 0 ? value.max : 2000000000}|is_not:${value.max}|required`"
        :label="$t('gameForm.field.min')"
        :vid="`${$t('gameForm.field.min')}-${uniqueKey}`"
        @change="value => updateValue({ min: +value })"
        @validate-field="validateField"
      />
      <BaseNumber
        :value="value.max"
        step="1"
        min="0"
        max="2000000000"
        :rules="`numeric|min_value:${value.min !== 0 ? value.min : 0}|max_value:2000000000|is_not:${value.min}|required`"
        :label="$t('gameForm.field.max')"
        :vid="`${$t('gameForm.field.max')}-${uniqueKey}`"
        @change="value => updateValue({ max: +value })"
        @validate-field="validateField"
      />
      <v-switch
        :input-value="value.required"
        class="v-input--reverse"
        @change="value => updateValue({ required: value })"
      >
        <template #label>
          {{ $t('gameForm.field.required') }}
        </template>
      </v-switch>
    </div>
  </BaseGameFormField>
</template>
<script>
import {
  props, variables, computed, methods,
} from '@/components/Dashboard/GameForm/Fields/Field.mixin';
import BaseGameFormField from '@/components/Dashboard/GameForm/BaseGameFormField';
import BaseInput from '@/components/Form/BaseInput';
import BaseNumber from '@/components/Form/BaseNumber';

const defaultState = {
  name: '',
  placeholder: '',
  tooltip: '',
  step: 1,
  min: 0,
  max: 9999999,
  required: true,
};

export default {
  name: 'NumberField',
  components: {
    BaseGameFormField,
    BaseInput,
    BaseNumber,
  },
  mixins: [props, variables, computed, methods],
  created() {
    this.updateValue({
      ...defaultState,
      ...this.value,
    });
  },
};
</script>
