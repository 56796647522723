export default {
  company: '313c',
  back_to_login: 'Back to login page',
  form: {
    email: 'E-mail',
    password: 'Password',
    password_new: 'New password',
    password_confirmation: 'Confirm password',
  },
  login: {
    title: 'Log in to the system',
    subtitle: 'Enter e-mail and password to log in to the administration panel',
    title_password_changed: 'Password has been changed',
    subtitle_password_changed: 'You can use it now to login to your account.',
    title_blocked: 'Your account has been blocked.',
    subtitle_blocked: 'Account has been locked due to 3 failed login attempts. Please try again in 5 minutes. ',
    error: 'Incorrect e-mail address and / or password - try again',
    forgot_password: 'Forgot your password?',
    button: 'Log in',
  },
  forget: {
    title: 'Reset your password',
    subtitle: 'Please enter your e-mail. We will send you a link to change your password. ',
    title_sent: 'Check your email',
    subtitle_sent: 'To the address',
    subtitle_sent_continued: 'we have sent a message with a link to the page where you can set up your new password.',
    error: 'Invalid e-mail address - try again',
    button: 'Send',
  },
  reset: {
    title: 'Create new password',
    subtitle: 'min. 8 characters • capital letter • lowercase letter • number • special character ',
    info: 'Didn\'t revieve the e-mail?',
    time: 'Resend in',
    resend: 'Resend',
    resend_successful: 'We have sent the link to your e-mail again',
    invalid_token: 'Password reset token has expired',
    error: 'Incorrect password - try again',
    button: 'Save',
  },
};
