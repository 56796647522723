<template>
  <div>
    <div
      v-if="isPreloadingData"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div v-else>
      <ValidationObserver
        ref="form"
        v-slot="{ handleSubmit }"
      >
        <v-form @submit.prevent="handleSubmit(submit)">
          <BaseList
            :table-headers="tableHeaders"
            :table-data="tableData"
            :is-loading="isLoading"
            :no-results-text="$t('competencyProfile.no_results')"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="(item, index) in items"
                  :key="item.id"
                >
                  <td>{{ item.name ? item.name : "-" }}</td>
                  <td
                    v-for="stage in item.stages.data"
                    :key="stage.id"
                  >
                    <div
                      v-if="index === tableData.length - 1"
                      class="competency-rating__field"
                    >
                      <v-chip
                        class="mr-2"
                        small
                      >
                        {{
                          stage.score && stage.score.data.score !== null
                            ? `${Math.round(stage.score.data.score)}%`
                            : "-"
                        }}
                      </v-chip>
                      <v-chip small>
                        {{
                          stage.score && stage.score.data.score !== null
                            ? stage.score.data.scoring_scale.data.name
                            : "-"
                        }}
                      </v-chip>
                    </div>
                    <div
                      v-if="item.id && isGameType(stage.type)"
                      class="competency-rating__field"
                    >
                      <v-chip
                        class="mr-2"
                        small
                      >
                        {{
                          stage.rating && stage.rating.data.score !== null
                            ? calcPercentageResult(stage.rating.data.score, stage.rating.data.max_score)
                            : "-"
                        }}
                      </v-chip>
                      <v-chip small>
                        {{
                          stage.rating && stage.rating.data.score !== null
                            ? stage.rating.data.scoring_scale.data.name
                            : "-"
                        }}
                      </v-chip>
                    </div>
                    <div
                      v-if="item.id && !isGameType(stage.type)"
                      class="competency-rating__score"
                    >
                      <BaseScoringScaleSelect
                        :value="stage.rating"
                        :items="scoringScales"
                        :competence-id="item.id"
                        :stage-id="stage.id"
                        @change="updateScores"
                      />
                    </div>
                  </td>
                  <td style="height:77px">
                    <div class="competency-rating__field">
                      <v-chip
                        class="mr-2"
                        small
                        :text-color="item.id ? null : 'white'"
                        :color="item.id ? null : 'grayDark'"
                      >
                        {{
                          item.rating && item.rating.data.score !== null
                            ? calcPercentageResult(item.rating.data.score, item.rating.data.max_score)
                            : "-"
                        }}
                      </v-chip>
                      <v-chip
                        small
                        :text-color="item.id ? null : 'white'"
                        :color="item.id ? null : 'grayDark'"
                      >
                        {{
                          item.rating && item.rating.data.score !== null
                            ? item.rating.data.scoring_scale.data.name
                            : "-"
                        }}
                      </v-chip>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </BaseList>
          <EditButtons
            :edit-mode="editMode"
            :is-sending="isSending"
            @submit="submit"
            @cancel="returnToList"
          />
        </v-form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import alerts from '@/plugins/alerts';
import { CANDIDATE_LIST, NOT_FOUND } from '@/router/routes.names';
import { HTTP_NOT_FOUND, HTTP_UNAUTHORIZED, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { isGameType } from '@/plugins/objectHelpers/candidateHelpers';
import { ASC } from '@/components/Table/sort.types';
import { HAS_FORM_CHANGES } from '@/names/emits.names';
import fetchCandidate from '@/components/Dashboard/Candidate/Api/fetchCandidate';
import fetchRecruitmentProcess from '@/components/Dashboard/Recruitment/fetchRecruitmentProcess';
import fetchScoringScales from '@/components/Dashboard/ScoringScale/fetchScoringScales';
import fetchCompetencyRatings from '@/components/Dashboard/CompetencyRating/fetchCompetencyRatings';
import editCompetencyRating from '@/components/Dashboard/CompetencyRating/editCompetencyRating';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import BaseList from '@/components/List/BaseList';
import BaseScoringScaleSelect from '@/components/Form/BaseScoringScaleSelect';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';

export default {
  name: 'CompetencyRating',
  components: {
    Preloader,
    BaseList,
    BaseScoringScaleSelect,
    EditButtons,
  },
  data() {
    return {
      tableHeaders: [],
      tableData: [],
      updatedScores: [],
      scoringScales: [],
      stagesSummary: [],
      overallScore: null,
      recruitmentProcess: null,
      isLoading: false,
      isSending: false,
      isPreloadingData: false,
      editMode: false,
    };
  },
  watch: {
    updatedScores: {
      handler(newValue) {
        this.$emit(HAS_FORM_CHANGES, !!newValue.length);
      },
    },
  },
  async created() {
    this.editMode = this.$route.meta.editMode;
    try {
      const { recruitmentProcessId } = this.$route.params;
      this.isPreloadingData = true;
      await this.fetchCandidate();
      await this.fetchCompetencyRatings({
        includes: 'candidate_recruitment_process_ratings',
      });
      const scoringScales = await fetchScoringScales({
        limit: 99999,
        sort: {
          column: 'percentage_from',
          direction: ASC,
        },
      });
      const recruitmentProcess = await fetchRecruitmentProcess({
        recruitmentProcessId,
        includes: ['stages'],
      });
      this.scoringScales = scoringScales.data;
      this.recruitmentProcess = recruitmentProcess;
      this.addDynamicColumns();
      this.addOverallScoreRow();
    } catch (e) {
      if (e.response?.status === HTTP_UNAUTHORIZED) return;
      if (e.response?.status === HTTP_NOT_FOUND) {
        this.$router.push({ name: NOT_FOUND });

        return;
      }
      if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
        this.errorAlert();

        return;
      }
      throw new Error(e);
    } finally {
      this.isPreloadingData = false;
    }
  },
  methods: {
    ...mapActions('candidate', ['setCandidate']),
    ...alerts,
    isGameType,
    async fetchCompetencyRatings() {
      try {
        this.isLoading = true;
        const { candidateId, recruitmentProcessId } = this.$route.params;
        const { data: { competences, rating, stages } } = await fetchCompetencyRatings({
          candidateId,
          recruitmentProcessId,
        });
        this.tableData = competences.data;
        this.overallScore = rating;
        this.stagesSummary = stages.data;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchCandidate() {
      const { candidateId, recruitmentProcessId } = this.$route.params;
      const { data } = await fetchCandidate({
        recruitmentProcessId,
        candidateId,
      });
      this.setCandidate(data);
    },
    updateScores({ scoringScaleId, competenceId, stageId }) {
      const newScore = {
        competence_id: competenceId,
        stage_id: stageId,
        scoring_scale_id: scoringScaleId,
      };

      this.updatedScores.push(newScore);
    },
    addDynamicColumns() {
      // eslint-disable-next-line camelcase
      const recruitmentStages = this.tableData[0].stages.data.map(({ sub_type, type }) => ({
        text: this.$t(
          `recruitment.types.${this.isGameType(type) ? sub_type.toLowerCase() : type.toLowerCase()}`,
        ),
        type,
        sortable: false,
      }));
      this.tableHeaders = [];
      this.tableHeaders.push({
        value: 'name',
        text: this.$t('competencyRating.name'),
        sortable: false,
      });
      this.tableHeaders.push(...recruitmentStages, {
        value: 'summary',
        text: this.$t('competencyRating.summary'),
        sortable: false,
      });
    },
    addOverallScoreRow() {
      this.tableData.push({
        name: this.$t('competencyRating.overall_score'),
        stages: {
          data: this.stagesSummary,
        },
        rating: this.overallScore,
      });
    },
    calcPercentageResult(score, maxScore) {
      if (maxScore === 0) return '0%';

      return `${Math.round((score / maxScore) * 100)}%`;
    },
    returnToList() {
      this.$router.push({ name: CANDIDATE_LIST, query: { recruitment_process_id: this.$route.params.recruitmentProcessId } });
    },
    async submit() {
      if (!this.updatedScores.length) return;

      try {
        const { candidateId, recruitmentProcessId } = this.$route.params;
        this.isSending = true;
        const baseFormData = {
          ratings: this.updatedScores,
          candidateId,
          recruitmentProcessId,
        };

        await editCompetencyRating(baseFormData);
        this.isPreloadingData = true;
        await this.fetchCompetencyRatings();
        this.addDynamicColumns();
        this.addOverallScoreRow();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isSending = false;
        this.isPreloadingData = false;
        this.$emit(HAS_FORM_CHANGES, false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.competency-rating {
  &__field {
    min-width: 100px;
  }
  &__score {
    max-width: 80px;
    margin: 10px 0;
  }
}
</style>
