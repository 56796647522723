<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <div class="wrapper">
          <v-row>
            <v-col
              sm="12"
              md="10"
              lg="6"
            >
              <BaseInput
                v-model="formData.name"
                rules="min:3|max:255|required"
                :label="$t('client.create.name')"
              />
              <BaseInput
                v-model="formData.website"
                rules="min:3|max:255|url|required"
                :label="$t('client.create.website')"
              />
              <BaseInput
                v-model="formData.subdomain"
                rules="min:3|max:63|required"
                :label="$t('client.create.subdomain')"
              />
              <BaseInput
                v-model="formData.supportEmail"
                rules="min:3|max:255|email|required"
                :label="$t('client.create.email')"
              />
              <BaseSelect
                v-model="formData.selectedATS"
                rules="select"
                :items="atsTypes"
                :label="$t('client.create.ats')"
                :placeholder="$t('client.create.ats_hint')"
                item-text="name"
                item-value="value"
                persistent-hint
                :loading="isLoading"
                :disabled="isLoading"
              />
              <BaseImageUploader
                v-model="formData.logo"
                :rules="logoValidationRules()"
                :label="$t('client.create.logo')"
                :vid="$t('client.create.logo')"
              />
              <BaseImageUploader
                v-model="formData.favicon"
                :rules="faviconValidationRules()"
                :label="$t('client.create.favicon')"
                :vid="$t('client.create.favicon')"
                :placeholder="$t('dashboard.upload_favicon_ext')"
                accepted-ext="image/x-icon"
              />
              <BaseColorPicker
                v-model="formData.color"
                rules="required"
                :label="$t('client.create.color')"
              />
              <BaseSelect
                v-model="formData.defaultLocale"
                rules="select"
                :items="localeTypes"
                :label="$t('client.create.locale')"
                item-text="name"
                item-value="value"
                persistent-hint
                :loading="isLoading"
                :disabled="isLoading"
              />
              <BaseInput
                v-model="formData.gameSessionReminderAfterTime"
                type="number"
                step="1"
                min="1"
                max="100"
                rules="numeric|min_value:1|max_value:2016|required"
                :label="$t('client.create.game_session_reminder_after_time')"
              />
              <BaseCheckbox
                v-model="formData.notifyAdmins"
                :label="$t('client.create.notify_admins')"
              />
              <BaseCheckbox
                v-model="formData.notifyRecruiters"
                :label="$t('client.create.notify_recruiters')"
              />
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col sm="12">
            <TranslationsMenu
              v-model="locale"
              :default-locale="clientDefaultLocale"
              :is-loading="isReportDescriptionLoading"
              :has-changes="hasChanges"
              @change-locale="updateLocale"
            >
              <v-row>
                <v-col
                  sm="12"
                  md="10"
                  lg="6"
                >
                  <BaseTextarea
                    v-model="formData.reportDescription"
                    rules="min:3|max:3000|required"
                    mode="eager"
                    :label="$t('client.create.report_description')"
                  />
                </v-col>
              </v-row>
            </TranslationsMenu>
          </v-col>
        </v-row>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { HAS_FORM_CHANGES } from '@/names/emits.names';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseInput from '@/components/Form/BaseInput';
import BaseSelect from '@/components/Form/BaseSelect';
import BaseImageUploader from '@/components/Form/BaseImageUploader';
import BaseColorPicker from '@/components/Form/BaseColorPicker';
import BaseTextarea from '@/components/Form/BaseTextarea';
import BaseCheckbox from '@/components/Form/BaseCheckbox';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import TranslationsMenu from '@/components/Dashboard/Partials/Translations/TranslationsMenu';

import {
  SUPPORT_EMAIL,
  ATS,
  LOGO,
  FAVICON,
  PRIMARY_COLOR,
  REPORT_DESCRIPTION,
  DEFAULT_LOCALE,
  GAME_SESSION_REMINDER_AFTER_TIME,
  ADMINS_SUMMARY_EMAILS_ENABLED,
  RECRUITERS_SUMMARY_EMAILS_ENABLED,
} from '@/names/clientOptions.names';
import { DELETE } from '@/names/global.names';

export default {
  name: 'Form',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseInput,
    BaseSelect,
    BaseImageUploader,
    BaseColorPicker,
    BaseTextarea,
    BaseCheckbox,
    TranslationsMenu,
  },
  props: {
    locale: {
      type: String,
      required: true,
    },
    client: {
      type: Object,
      default: () => {},
    },
    atsTypes: {
      type: Array,
      default: () => [],
    },
    localeTypes: {
      type: Array,
      default: () => [],
    },
    backendErrors: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isReportDescriptionLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasChanges: false,
      formData: {
        name: '',
        website: 'https://',
        subdomain: '',
        supportEmail: '',
        selectedATS: '',
        logo: null,
        favicon: null,
        color: this.$vuetify.theme.themes.light.primary,
        reportDescription: '',
        defaultLocale: '',
        gameSessionReminderAfterTime: 72,
        notifyAdmins: false,
        notifyRecruiters: false,
      },
    };
  },
  computed: {
    ...mapGetters('session', ['clientDefaultLocale']),
  },
  watch: {
    client: {
      handler(newValue) {
        if (this.editMode) {
          this.formData.reportDescription = newValue.options?.data.find((option) => option.key === REPORT_DESCRIPTION).value;
          this.hasChanges = false;
        }
      },
    },
    formData: {
      handler() {
        if (this.editMode) {
          this.checkFormChanges();
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.editMode) {
      this.formData.name = this.client.name;
      this.formData.website = this.client.website;
      this.formData.subdomain = this.client.subdomain;
      this.formData.supportEmail = this.client.options?.data.find((option) => option.key === SUPPORT_EMAIL).value;
      this.formData.selectedATS = this.client.options?.data.find((option) => option.key === ATS).value;
      this.formData.logo = this.client.options?.data.find((option) => option.key === LOGO).value;
      this.formData.favicon = this.client.options?.data.find((option) => option.key === FAVICON).value;
      this.formData.color = this.client.options?.data.find((option) => option.key === PRIMARY_COLOR).value.toUpperCase();
      this.formData.reportDescription = this.client.options?.data.find((option) => option.key === REPORT_DESCRIPTION).value;
      this.formData.defaultLocale = this.client.options?.data.find((option) => option.key === DEFAULT_LOCALE).value;
      if (this.client.options?.data.find((option) => option.key === GAME_SESSION_REMINDER_AFTER_TIME)?.value) {
        this.formData.gameSessionReminderAfterTime = this.client.options?.data.find((option) => option.key === GAME_SESSION_REMINDER_AFTER_TIME)?.value;
      }
      if (this.client.options?.data.find((option) => option.key === ADMINS_SUMMARY_EMAILS_ENABLED)?.value) {
        this.formData.notifyAdmins = this.client.options?.data.find((option) => option.key === ADMINS_SUMMARY_EMAILS_ENABLED)?.value;
      }
      if (this.client.options?.data.find((option) => option.key === RECRUITERS_SUMMARY_EMAILS_ENABLED)?.value) {
        this.formData.notifyRecruiters = this.client.options?.data.find((option) => option.key === RECRUITERS_SUMMARY_EMAILS_ENABLED)?.value;
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('handle-cancel');
    },
    async checkFormChanges() {
      const { changed } = this.$refs.form.flags;
      this.hasChanges = changed;
      this.$emit(HAS_FORM_CHANGES, changed);
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      this.$emit('handle-submit', this.baseFormData());
      this.hasChanges = false;
    },
    baseFormData() {
      const formData = new FormData();
      const { clientId } = this.$route.params;

      formData.set('name', this.formData.name);
      formData.set('website', this.formData.website);
      formData.set('subdomain', this.formData.subdomain);
      formData.set('support_email', this.formData.supportEmail);
      formData.set('ats', this.formData.selectedATS);
      formData.set('logo', this.formData.logo !== DELETE ? this.formData.logo : '');
      formData.set('favicon', this.formData.favicon !== DELETE ? this.formData.favicon : '');
      formData.set('color', this.formData.color);
      formData.set('report_description', this.formData.reportDescription);
      formData.set('default_locale', this.formData.defaultLocale);
      formData.set('locale', this.locale);
      formData.set('game_session_reminder_after_time', this.formData.gameSessionReminderAfterTime);
      formData.set('notify_admins', +this.formData.notifyAdmins);
      formData.set('notify_recruiters', +this.formData.notifyRecruiters);
      if (this.editMode) {
        formData.set('_method', 'PUT');

        return { clientId, params: formData };
      }

      return { params: formData };
    },
    async updateLocale(newLocale) {
      this.$emit('update-locale', newLocale);
    },
    logoValidationRules() {
      return `${
        !this.editMode || (this.formData.logo === DELETE && this.editMode)
          ? 'image_required|'
          : ''
      }size:10240|extensions:jpeg,jpg,png,gif`;
    },
    faviconValidationRules() {
      return `${
        !this.editMode || (this.formData.favicon === DELETE && this.editMode)
          ? 'image_required|'
          : ''
      }size:10240|extensions:ico`;
    },
  },
};
</script>
