export function getCookie(cookieName) {
  const name = `${cookieName}=`;
  const cookieDecoded = decodeURIComponent(document.cookie);
  const cookieArr = cookieDecoded.split('; ');
  let result;
  cookieArr.forEach((value) => {
    if (value.indexOf(name) === 0) result = value.substring(name.length);
  });

  return result;
}
export function setCookie(cookieName, cookieValue) {
  const cookieExpireDate = new Date(new Date().getTime() + 60 * 60 * 1000 * 24 * 365).toGMTString();
  document.cookie = `${cookieName}=${cookieValue};expires=${cookieExpireDate};path=/`;
}
