/* eslint-disable import/prefer-default-export */
export function convertHmsToSeconds(timeInHms) {
  const convertedTime = +(timeInHms
    .replaceAll(/[a-zA-Z]/g, '')
    .split(':')
    .reduce((acc, time) => (60 * acc) + +time)
  );

  return convertedTime || null;
}
