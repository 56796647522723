import api from '@/plugins/axios/api';

/**
 * @param {String} recruitmentProcessId
 * @param  {Array} candidateIds
 */
const deleteMultipleCandidates = async ({
  recruitmentProcessId,
  candidateIds,
}) => {
  await api.delete(`recruitment-processes/${recruitmentProcessId}/candidates/destroy-multiple`, {
    params: {
      ids: candidateIds,
    },
  });
};

export default deleteMultipleCandidates;
