import api from '@/plugins/axios/api';

/**
 * @param {String} recruitmentProcessId
 */
const fetchCandidateFormFields = async ({
  recruitmentProcessId,
}) => {
  const { data: { data: { additional: { data } } } } = await api.get(`recruitment-processes/${recruitmentProcessId}/candidates/form-fields`);

  return data;
};

export default fetchCandidateFormFields;
