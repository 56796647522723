<template>
  <div>
    <v-btn
      text
      @click="openModal"
    >
      <v-icon>
        mdi-help-circle-outline
      </v-icon>
    </v-btn>
    <FaqModal
      v-if="isModalOpen"
      v-model="isModalOpen"
      @close="isModalOpen = false"
    />
  </div>
</template>
<script>
import FaqModal from '@/components/Dashboard/Partials/Topbar/Faq/FaqModal';

export default {
  name: 'FaqButton',
  components: {
    FaqModal,
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
  },
};
</script>
