<template>
  <v-menu
    offset-y
    elevation="2"
    transition="fade-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-card class="actions-list">
      <v-list-item
        v-if="user.id !== userId"
        @click="handleSuspend"
      >
        <v-list-item-icon>
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{
            isSuspended ? $t("user.unsuspend") : $t("user.suspend")
          }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        :to="{ name: userEditRoute, params: { userId: userId } }"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("dashboard.edit") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="user.id !== userId"
        @click="handleDelete"
      >
        <v-list-item-icon>
          <v-icon>mdi-delete</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("dashboard.remove") }}</v-list-item-title>
      </v-list-item>
    </v-card>
  </v-menu>
</template>
<script>
import { mapGetters } from 'vuex';
import alerts from '@/plugins/alerts';
import { USER_EDIT } from '@/router/routes.names';
import { HTTP_UNAUTHORIZED, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import deleteUser from '@/components/Dashboard/User/deleteUser';
import suspendUser from '@/components/Dashboard/User/suspendUser';
import unsuspendUser from '@/components/Dashboard/User/unsuspendUser';

export default {
  name: 'UserActions',
  props: {
    userId: {
      type: String,
      required: true,
    },
    isSuspended: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('session', ['user']),
    userEditRoute() {
      return USER_EDIT;
    },
  },
  methods: {
    ...alerts,
    async handleDelete() {
      if (await this.confirmAlert()) {
        try {
          await deleteUser({ userId: this.userId });
          this.$emit('handle-delete');
        } catch (e) {
          if (e.response?.status === HTTP_UNAUTHORIZED) return;
          if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
            this.errorAlert();

            return;
          }
          throw new Error(e);
        }
      }
    },
    async handleSuspend() {
      if (await this.confirmAlert()) {
        try {
          if (this.isSuspended) {
            this.unsuspend();

            return;
          }
          this.suspend();
        } catch (e) {
          if (e.response?.status === HTTP_UNAUTHORIZED) return;
          if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
            this.errorAlert();

            return;
          }
          throw new Error(e);
        }
      }
    },
    async suspend() {
      await suspendUser({ userId: this.userId });
      this.$emit('handle-suspend');
    },
    async unsuspend() {
      await unsuspendUser({ userId: this.userId });
      this.$emit('handle-suspend');
    },
  },
};
</script>
