<template>
  <ExportLoader
    v-if="isLoading"
    :is-loading="isLoading"
  />
</template>
<script>
import alerts from '@/plugins/alerts';
import gameStagesExport from '@/components/Dashboard/GameStage/gameStagesExport';
import exportFile from '@/plugins/files/exportFile';
import { HTTP_UNAUTHORIZED } from '@/plugins/axios/codes';
import ExportLoader from '@/components/Dashboard/Partials/Export/ExportLoader';

export default {
  name: 'GameStagesExport',
  components: {
    ExportLoader,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  async created() {
    await this.generate();
  },
  methods: {
    ...alerts,
    async generate() {
      try {
        this.isLoading = true;
        const { gameId } = this.$route.params;
        const response = await gameStagesExport({ gameId });
        exportFile(response);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
