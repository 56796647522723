<template>
  <v-main
    class="auth-layout"
    :style="{ backgroundImage: `url(${background})`}"
  >
    <v-container
      fluid
      fill-height
    >
      <v-layout
        flex
        align-center
        justify-center
      >
        <v-flex
          xs12
          sm8
          md6
          lg3
        >
          <ZoomCenterTransition mode="out-in">
            <router-view :key="$route.path" />
          </ZoomCenterTransition>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
import { ZoomCenterTransition } from 'vue2-transitions';
import Background from '@/assets/images/auth_background.jpg';

export default {
  name: 'AuthLayout',

  components: {
    ZoomCenterTransition,
  },

  computed: {
    background() {
      return Background;
    },
  },
};
</script>

<style lang="scss">
.auth-layout {
  min-height: 100vh;
  height: 100%;
  background-size: cover;
  padding: 0;
  margin: 0;
}
</style>
