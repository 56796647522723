import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 */
const deleteGameForm = async ({ gameFormId }) => {
  await api.delete(`game-forms/${gameFormId}`);
};

export default deleteGameForm;
