<template>
  <div>
    <div
      v-if="isPreloadingData"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title class="py-6">
          <div class="table-heading">
            <h5 class="table-heading__title text-h5">
              {{ $t("user.title") }}
            </h5>
            <div class="table-heading__actions">
              <div class="table-heading__item">
                <BaseButton @click="goToAddUser">
                  {{ $t("dashboard.add") }}
                  <v-icon right>
                    mdi-plus
                  </v-icon>
                </BaseButton>
              </div>
            </div>
          </div>
        </v-card-title>
        <div class="table-filters">
          <TableSearch
            is-full-text-search
            @input="fetchUsers"
          />
        </div>
        <v-divider />
        <BaseTable
          :table-headers="tableHeaders"
          :table-data="tableData"
          :is-loading="isLoading"
          :no-results-text="$t('user.no_results')"
          :pagination="pagination"
          :default-sort="sort"
          @change-page="fetchUsers"
          @change-items-per-page="fetchUsers"
          @change-sort="fetchUsers"
        >
          <template v-slot:item="{ item }">
            <td>{{ item.name ? item.name : "-" }}</td>
            <td>{{ item.email ? item.email : "-" }}</td>
            <td>{{ item.client ? item.client.data.name : "-" }}</td>
            <td>
              <v-chip text-color="grayDark">
                {{ item.role ? $t(`user.types.${item.role.data.name.toLowerCase()}`) : "-" }}
              </v-chip>
            </td>
            <td>
              <v-chip
                :text-color="item.suspended_at ? 'red' : 'green'"
                :color="item.suspended_at ? 'redLight' : 'greenLight'"
              >
                {{ item.suspended_at ? $t('user.statuses.blocked') : $t('user.statuses.active') }}
              </v-chip>
            </td>
            <td>{{ formatDate(item.created_at) }}</td>
            <td>
              <Actions
                :user-id="item.id"
                :is-suspended="!!item.suspended_at"
                @handle-delete="fetchUsers"
                @handle-suspend="fetchUsers"
              />
            </td>
          </template>
        </BaseTable>
      </v-card>
    </div>
  </div>
</template>
<script>
import alerts from '@/plugins/alerts';
import { USER_ADD } from '@/router/routes.names';
import { HTTP_UNAUTHORIZED, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { formatDate } from '@/plugins/dates/dates';
import { DESC } from '@/components/Table/sort.types';
import fetchUsers from '@/components/Dashboard/User/fetchUsers';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import BaseButton from '@/components/Form/BaseButton';
import BaseTable from '@/components/Table/BaseTable';
import TableSearch from '@/components/Table/TableSearch';
import Actions from '@/components/Dashboard/User/Actions';

export default {
  name: 'UserList',
  components: {
    Preloader,
    BaseButton,
    BaseTable,
    TableSearch,
    Actions,
  },
  data() {
    return {
      tableHeaders: [
        {
          value: 'name',
          text: this.$t('user.name'),
        },
        {
          value: 'email',
          text: this.$t('user.email'),
        },
        {
          value: 'client_name',
          text: this.$t('user.client'),
        },
        {
          value: 'role_name',
          text: this.$t('user.type'),
        },
        {
          value: 'status',
          text: this.$t('user.status'),
        },
        {
          value: 'created_at',
          text: this.$t('user.created_at'),
          width: '150px',
        },
        {
          value: 'actions',
          sortable: false,
          align: 'right',
          width: '50px',
        },
      ],
      tableData: [],
      pagination: null,
      isLoading: false,
      isPreloadingData: false,
      sort: {
        column: 'created_at',
        direction: DESC,
      },
    };
  },
  async created() {
    this.isPreloadingData = true;
    await this.fetchUsers();
    this.isPreloadingData = false;
  },
  methods: {
    ...alerts,
    formatDate,
    goToAddUser() {
      this.$router.push({ name: USER_ADD });
    },
    setParams(params = {}) {
      return {
        includes: ['client', 'role'],
        sort: this.sort,
        ...this.$route.query,
        ...params,
      };
    },
    async fetchUsers(params) {
      try {
        this.isLoading = true;
        const response = await fetchUsers(this.setParams(params));
        this.tableData = response.data;
        this.pagination = response.pagination;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
