export default {
  title: 'Strony tekstowe',
  name: 'Nazwa',
  updated_at: 'Data aktualizacji',
  no_results: 'Nie znaleziono stron',
  edit: 'Edytuj',
  create: {
    body: 'Treść',
    variables: 'Znaczniki do wykorzystania',
    button: 'Treść przycisku kontynuacji',
    side_image: 'Obrazek boczny',
    mobile_side_image: 'Obrazek boczny mobile',
    side_image_requirements: 'Rekomendowane wymiary to: %{dimensions} px',
  },
};
