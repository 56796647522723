export default {
  title: 'Działy',
  search: 'Wyszukaj',
  name: 'Nazwa',
  created_at: 'Data utworzenia',
  no_results: 'Nie znaleziono działów',
  add: 'Dodaj dział',
  edit: 'Edytuj dział',
  create: {
    name: 'Nazwa działu',
  },
};
