import api from '@/plugins/axios/api';

/**
 * @param {String} userId
 */
const suspendUser = async ({
  userId,
}) => {
  await api.patch(`users/${userId}/suspend`);
};

export default suspendUser;
