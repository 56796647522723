<template>
  <div>
    <div
      v-if="isLoading"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title>
          <v-row>
            <v-col
              class="px-3 px-lg-6"
              sm="12"
            >
              <p class="headline black--text mt-4">
                {{ editMode ? $t('workPosition.edit') : $t("workPosition.add") }}
              </p>
              <v-divider class="mt-3 mb-4" />
            </v-col>
          </v-row>
        </v-card-title>
        <div class="wrapper">
          <v-row class="px-3 px-lg-6">
            <v-col
              sm="12"
              md="10"
              lg="6"
            >
              <WorkPositionForm
                :edit-mode="editMode"
                :work-position="workPosition"
                :work-departments="workDepartments"
                :competency-profiles="competencyProfiles"
                :is-loading="isLoading"
                :is-sending="isSending"
                :backend-errors="errors"
                @handle-submit="submit"
                @handle-cancel="returnToList"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import alerts from '@/plugins/alerts';
import { HTTP_UNAUTHORIZED, HTTP_NOT_FOUND, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { NOT_FOUND, WORK_POSITION_LIST } from '@/router/routes.names';
import { ASC } from '@/components/Table/sort.types';
import addWorkPosition from '@/components/Dashboard/WorkPosition/addWorkPosition';
import editWorkPosition from '@/components/Dashboard/WorkPosition/editWorkPosition';
import fetchWorkDepartments from '@/components/Dashboard/WorkDepartment/fetchWorkDepartments';
import fetchCompetencyProfiles from '@/components/Dashboard/CompetencyProfile/fetchCompetencyProfiles';
import fetchWorkPosition from '@/components/Dashboard/WorkPosition/fetchWorkPosition';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import WorkPositionForm from '@/components/Dashboard/WorkPosition/Form';

export default {
  name: 'EditWorkPosition',
  components: {
    Preloader,
    WorkPositionForm,
  },
  data() {
    return {
      editMode: false,
      workPosition: null,
      workDepartments: [],
      competencyProfiles: [],
      isLoading: false,
      isSending: false,
      errors: [],
    };
  },
  async created() {
    this.editMode = this.$route.meta.editMode;
    try {
      this.isLoading = true;
      const workDepartments = await fetchWorkDepartments({
        limit: 99999,
        sort: {
          column: 'name',
          direction: ASC,
        },
      });
      const competencyProfiles = await fetchCompetencyProfiles({
        limit: 99999,
        sort: {
          column: 'name',
          direction: ASC,
        },
      });
      this.workDepartments = workDepartments.data;
      this.competencyProfiles = competencyProfiles.data;
      if (this.editMode) {
        this.workPosition = await fetchWorkPosition({
          workPositionId: this.$route.params.workPositionId,
          includes: ['competency_profile', 'work_department'],
        });
      }
    } catch (e) {
      if (e.response?.status === HTTP_UNAUTHORIZED) return;
      if (e.response?.status === HTTP_NOT_FOUND) {
        this.$router.push({ name: NOT_FOUND });

        return;
      }
      if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
        this.errorAlert();

        return;
      }
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...alerts,
    returnToList() {
      this.$router.push({ name: WORK_POSITION_LIST });
    },
    async submit(payload) {
      try {
        this.errors = [];
        this.isSending = true;
        this.editMode ? await editWorkPosition(payload) : await addWorkPosition(payload);
        this.$notify.save.success();
        this.returnToList();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isSending = false;
      }
    },
  },
};
</script>
