<template>
  <div class="table-tabs">
    <label
      class="table-tabs__label"
      :class="{ 'table-tabs__label--active': recruitmentProcessStage === null }"
    >
      <input
        v-model="recruitmentProcessStage"
        class="d-none"
        type="radio"
        :value="null"
        :name="$t('recruitment.types.all')"
        :disabled="disabled"
      >
      <div
        class="table-tabs__text"
        :class="{ 'table-tabs__text--disabled': disabled }"
      >
        <span>{{ $t('recruitment.types.all') }}</span>
        <span class="ml-3">{{ sumCandidatesCount }}</span>
      </div>
    </label>
    <label
      v-for="stage in recruitmentProcessStages"
      :key="stage.id"
      class="table-tabs__label"
      :class="{ 'table-tabs__label--active': recruitmentProcessStage === stage.id }"
    >
      <input
        v-model="recruitmentProcessStage"
        class="d-none"
        type="radio"
        :value="stage.id"
        :name="isGameType(stage.type) ? stage.sub_type.toLowerCase() : stage.type.toLowerCase()"
        :disabled="disabled"
      >
      <div
        class="table-tabs__text"
        :class="{ 'table-tabs__text--disabled': disabled }"
      >
        <span>{{ $t(`recruitment.types.${isGameType(stage.type) ? stage.sub_type.toLowerCase() : stage.type.toLowerCase()}`) }}</span>
        <span class="ml-3">
          {{ stage.current_candidates_count }}
        </span>
      </div>
    </label>
  </div>
</template>
<script>
import { GAME } from '@/names/recruitmentStages.names';

export default {
  name: 'RecruitmentStages',
  props: {
    recruitmentProcessStages: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recruitmentProcessStage: null,
    };
  },
  computed: {
    sumCandidatesCount() {
      const mapCandidatesCount = this.recruitmentProcessStages.map((stage) => stage.current_candidates_count);

      return mapCandidatesCount.reduce((pv, cv) => pv + cv, 0);
    },
  },
  watch: {
    recruitmentProcessStage(newVal) {
      this.$emit('input', newVal);
      this.$emit('change');
    },
    value(value) {
      this.recruitmentProcessStage = value;
    },
  },
  created() {
    if (this.value) {
      this.recruitmentProcessStage = this.value;
    }
  },
  methods: {
    isGameType(type) {
      return type === GAME;
    },
  },
};
</script>
