export default {
  title: 'Games',
  search: 'Search',
  name: 'Name',
  type: 'Type',
  locale: 'Language',
  client: 'Client',
  visibility: 'Visibility',
  tags: 'Tags',
  manage_tags: 'Manage tags',
  created_at: 'Created at',
  edit_game: 'Edit game',
  edit_stages: 'Edit steps',
  edit_badges: 'Edit badges',
  edit_pages: 'Edit pages',
  copy_game: 'Copy',
  copy_game_to_client: 'Copy the game to the client',
  no_results: 'No games found',
  types: {
    selective: 'Selection game',
    recruitive: 'Recruitment game',
    recruitment_form: 'Recruitment form',
  },
  locales: {
    cs: 'Czech',
    de: 'German',
    en: 'English',
    fr: 'French',
    hu: 'Hungarian',
    it: 'Italian',
    nl: 'Dutch',
    pl: 'Polish',
    ro: 'Romanian',
    sk: 'Slovak',
    hr: 'Croatian',
    pt: 'Portuguese',
    sl: 'Slovenian',
    uk: 'Ukrainian',
  },
  add: 'Add a game',
  edit: 'Edit game',
  create: {
    name: 'Game name',
    metaTitle: 'Meta title',
    topBarTitle: 'Top bar title',
    type: 'Game type',
    locale: 'Game language',
    locale_hint: 'Select game language',
    other_games: 'Other language versions of the same game',
    recruitment_form: 'Recruitment form (optional)',
    invitation_message: 'Inviting message',
    reminder_message: 'Reminder message',
    result_message: 'Thank you message',
    client: 'Client',
    limit: 'Time limit',
    type_hint: 'Select game type',
    client_hint: 'Select client',
    limit_hint: '01h: 25m: 00s',
    active: 'Active',
    draft: 'Draft',
    active_desc: 'Client has access to the tool',
    draft_desc: 'Client has no access the tool',
  },
};
