<template>
  <div v-if="allowedRoutes.length">
    <div
      class="grayLight--text body-1 px-3 pt-3 pb-2"
      v-text="name"
    />
    <slot />
    <v-list
      class="sidebar-list"
      dense
      nav
    >
      <v-list-item
        v-for="route in allowedRoutes"
        :key="route.title"
        :to="route.name"
        :class="route.name === $route.name && 'highlighted'"
        active-class="highlighted"
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>{{ route.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ route.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SidebarList',
  props: {
    routes: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('session', ['permissions']),
    allowedRoutes() {
      return this.routes.filter((route) => {
        const routeDetails = this.$router.resolve({
          name: route.name.name,
        });
        if (!this.permissions.includes(routeDetails.route.meta.permissions)) {
          return false;
        }

        return route;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebar-list {
  .v-list-item__title {
    font-size: 14px !important;
    color: var(--grayDark) !important;
  }
}
</style>
