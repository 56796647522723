import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 */
const fetchVariablesList = async ({
  gameId,
}) => {
  const { data } = await api.get(`game-pages/${gameId}/variables-list`);

  return data.data;
};

export default fetchVariablesList;
