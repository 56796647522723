<template>
  <div>
    <div
      v-if="isLoading"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title>
          <v-row>
            <v-col
              class="px-3 px-lg-6"
              sm="12"
            >
              <p class="headline black--text mt-4">
                {{ $t('messageTemplate.edit') }}
              </p>
              <v-divider class="mt-3 mb-4" />
            </v-col>
          </v-row>
        </v-card-title>
        <div class="wrapper">
          <v-row class="px-3 px-lg-6">
            <v-col
              sm="12"
              md="10"
              lg="8"
            >
              <MessageTemplateForm
                :edit-mode="editMode"
                :message-template="messageTemplate"
                :variables-list="variablesList"
                :is-loading="isLoading"
                :is-sending="isSending"
                :backend-errors="errors"
                @handle-submit="submit"
                @handle-cancel="returnToList"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import alerts from '@/plugins/alerts';
import { HTTP_UNAUTHORIZED, HTTP_NOT_FOUND, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { NOT_FOUND, MESSAGE_TEMPLATE_LIST } from '@/router/routes.names';
import fetchMessageTemplate from '@/components/Dashboard/MessageTemplate/fetchMessageTemplate';
import addMessageTemplate from '@/components/Dashboard/MessageTemplate/addMessageTemplate';
import editMessageTemplate from '@/components/Dashboard/MessageTemplate/editMessageTemplate';
import fetchVariablesList from '@/components/Dashboard/MessageTemplate/fetchVariablesList';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import MessageTemplateForm from '@/components/Dashboard/MessageTemplate/Form';

export default {
  name: 'EditMessageTemplate',
  components: {
    Preloader,
    MessageTemplateForm,
  },
  data() {
    return {
      editMode: false,
      messageTemplate: null,
      variablesList: null,
      isLoading: false,
      isSending: false,
      errors: [],
    };
  },
  async created() {
    this.editMode = this.$route.meta.editMode;
    try {
      this.isLoading = true;
      this.variablesList = await fetchVariablesList({
        messageTemplateId: this.$route.params.messageTemplateId || null,
      });
      if (this.editMode) {
        this.messageTemplate = await fetchMessageTemplate({
          messageTemplateId: this.$route.params.messageTemplateId,
        });
      }
    } catch (e) {
      if (e.response?.status === HTTP_UNAUTHORIZED) return;
      if (e.response?.status === HTTP_NOT_FOUND) {
        this.$router.push({ name: NOT_FOUND });

        return;
      }
      if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
        this.errorAlert();

        return;
      }
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...alerts,
    returnToList() {
      this.$router.push({ name: MESSAGE_TEMPLATE_LIST });
    },
    async submit(payload) {
      try {
        this.errors = [];
        this.isSending = true;
        this.editMode ? await editMessageTemplate(payload) : await addMessageTemplate(payload);
        this.$notify.save.success();
        this.returnToList();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isSending = false;
      }
    },
  },
};
</script>
