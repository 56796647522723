import api from '@/plugins/axios/api';

/**
 * @param {String} name
 * @param {String} title
 * @param {String} body
 */
const addMessageTemplate = async ({
  name,
  title,
  body,
}) => {
  await api.post('message-templates', {
    name,
    title,
    body,
  });
};

export default addMessageTemplate;
