/* eslint-disable import/prefer-default-export */
export function removeNullProperties(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!(value === null || (Array.isArray(value) && value.length === 0))) {
      // eslint-disable-next-line no-param-reassign
      acc[key] = value;
    }

    return acc;
  }, {});
}
