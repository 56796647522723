/* eslint-disable import/prefer-default-export */
export function sortArray(array) {
  return array.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  });
}
