<template>
  <BaseFilterField
    :id="id"
    :title="label"
    :number-of-filters="value ? value.length : 0"
    @update="onCreated"
  >
    <ValidationObserver
      ref="form"
    >
      <ValidationProvider
        v-slot="{ errors }"
        :name="label"
        rules="min:3"
      >
        <v-combobox
          v-model="value"
          :items="items"
          :append-icon="null"
          :error-messages="errors"
          chips
          dense
          clearable
          multiple
          outlined
          @change="change"
        >
          <template v-slot:selection="{ attrs, item, selected }">
            <v-tooltip
              v-if="item.length > charactersLimit"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-chip
                  class="my-2"
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  v-on="on"
                  @click:close="remove(item)"
                >
                  {{ stringLimit(item, charactersLimit) }}
                </v-chip>
              </template>
              {{ item }}
            </v-tooltip>
            <v-chip
              v-else
              class="my-2"
              v-bind="attrs"
              :input-value="selected"
              close
              @click:close="remove(item)"
            >
              {{ item }}
            </v-chip>
          </template>
        </v-combobox>
      </ValidationProvider>
    </ValidationObserver>
  </BaseFilterField>
</template>
<script>
import { stringLimit } from '@/plugins/stringHelpers/stringLimit';
import BaseFilterField from '@/components/Dashboard/Candidate/Partials/BaseFilterField';

export default {
  name: 'TextField',
  components: {
    BaseFilterField,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      charactersLimit: 16,
      value: [],
      items: [],
    };
  },
  methods: {
    stringLimit,
    remove(item) {
      this.value.splice(this.value.indexOf(item), 1);
      this.updateFilters();
    },
    async change() {
      const { flags: { invalid } } = await this.$refs.form.validateWithInfo();
      if (invalid) return;
      this.updateFilters();
    },
    updateFilters() {
      this.$emit('change-filter', { id: this.id, values: this.value });
    },
    onCreated(filter) {
      this.value = filter;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-select input {
    font-size: 14px !important;
  }
</style>
