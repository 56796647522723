import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/trans';
import { EN } from '@/names/locale.names';

Vue.use(VueI18n);

export default new VueI18n({
  locale: EN,
  fallbackLocale: EN,
  messages,
});
