const imageDimensions = async (file) => new Promise((resolve) => {
  const img = new Image();
  try {
    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img;
      resolve({ width, height });
    };
  } catch (e) {
    console.error(e);
  }
  img.src = file;
});

export default imageDimensions;
