<template>
  <v-navigation-drawer
    :value="isSidebarActive"
    clipped
    app
    :width="sidebarWidth"
    disable-resize-watcher
    :mobile-breakpoint="0"
    style="padding-top: 72px"
  >
    <div class="pt-2">
      <SidebarList
        :name="$t('dashboard.sidebar.management')"
        :routes="managementRoutes"
      >
        <SelectClientScope
          v-if="isSuperAdmin"
          :clients="clients"
          :selected-client="selectedClient"
          :is-loading="isLoading"
          @handle-select="selectClient"
        />
      </SidebarList>
      <v-divider class="mb-3" />
      <SidebarList
        :name="$t('dashboard.sidebar.admin')"
        :routes="adminRoutes"
      />
    </div>
  </v-navigation-drawer>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';
import alerts from '@/plugins/alerts';
import { HTTP_UNAUTHORIZED, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { ASC } from '@/components/Table/sort.types';
import fetchClients from '@/components/Dashboard/Client/fetchClients';
import fetchClient from '@/components/Dashboard/Client/fetchClient';
import selectClientScope from '@/components/Dashboard/User/selectClientScope';
import SidebarList from '@/components/Dashboard/Partials/Sidebar/SidebarList';
import SelectClientScope from '@/components/Dashboard/Partials/Sidebar/SelectClientScope';
import {
  CANDIDATE_LIST,
  COMPETENCY_PROFILE_LIST,
  COMPETENCE_LIST,
  RECRUITMENT_PROCESS_LIST,
  WORK_POSITION_LIST,
  CLIENT_LIST,
  GAME_LIST,
  MANAGEMENT_ANALYST_LIST,
  MESSAGE_TEMPLATE_LIST,
  USER_LIST,
  GAME_FORM_LIST,
} from '@/router/routes.names';

export default {
  name: 'Sidebar',
  components: {
    SidebarList,
    SelectClientScope,
  },
  data() {
    return {
      sidebarWidth: 290,
      clients: [],
      selectedClient: null,
      isLoading: false,
      managementRoutes: [
        {
          title: this.$t('breadcrumbs.candidate_list'),
          name: { name: CANDIDATE_LIST },
          icon: 'mdi-account-supervisor',
        },
        {
          title: this.$t('breadcrumbs.recruitment_list'),
          name: { name: RECRUITMENT_PROCESS_LIST },
          icon: 'mdi-file-document',
        },
        {
          title: this.$t('breadcrumbs.work_position_list'),
          name: { name: WORK_POSITION_LIST },
          icon: 'mdi-briefcase',
        },
        {
          title: this.$t('breadcrumbs.competency_profile_list'),
          name: { name: COMPETENCY_PROFILE_LIST },
          icon: 'mdi-chart-scatter-plot-hexbin',
        },
        {
          title: this.$t('breadcrumbs.competence_list'),
          name: { name: COMPETENCE_LIST },
          icon: 'mdi-poll',
        },
      ],
      adminRoutes: [
        {
          title: this.$t('breadcrumbs.client_list'),
          name: { name: CLIENT_LIST },
          icon: 'mdi-city',
        },
        {
          title: this.$t('breadcrumbs.user_list'),
          name: { name: USER_LIST },
          icon: 'mdi-account-supervisor',
        },
        {
          title: this.$t('breadcrumbs.game_list'),
          name: { name: GAME_LIST },
          icon: 'mdi-cards-playing-outline',
        },
        {
          title: this.$t('breadcrumbs.management_analyst_list'),
          name: { name: MANAGEMENT_ANALYST_LIST },
          icon: 'mdi-chart-areaspline',
        },
        {
          title: this.$t('breadcrumbs.message_template_list'),
          name: { name: MESSAGE_TEMPLATE_LIST },
          icon: 'mdi-email',
        },
        {
          title: this.$t('breadcrumbs.game_form_list'),
          name: { name: GAME_FORM_LIST },
          icon: 'mdi-newspaper',
        },
      ],
    };
  },
  computed: {
    ...mapState('layout', ['isSidebarActive']),
    ...mapGetters('session', ['clientId', 'isSuperAdmin']),
  },
  async created() {
    if (this.isSuperAdmin) {
      await this.fetchClients();
    }
  },
  mounted() {
    eventBus.$on('fetchClientsInSidebar', async () => {
      await this.fetchClients();
      const client = await fetchClient({
        clientId: this.clientId,
        includes: ['options'],
      });
      await this.setClient(client);
    });
  },
  methods: {
    ...alerts,
    ...mapActions('session', ['setClient']),
    async fetchClients() {
      try {
        this.isLoading = true;
        const response = await fetchClients({
          sort: {
            column: 'name',
            direction: ASC,
          },
          includes: ['options'],
        });
        this.clients = response.data;
        this.selectedClient = this.clients.find((client) => client.id === this.clientId);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async selectClient({ clientId }) {
      this.isLoading = true;
      try {
        await selectClientScope(clientId);
        const client = await fetchClient({
          clientId,
          includes: ['options'],
        });
        await this.setClient(client);
        if (this.$route.name !== CLIENT_LIST) {
          this.$router.push({ name: CLIENT_LIST });
        }
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
