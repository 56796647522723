<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <v-row>
          <v-col
            sm="12"
            lg="6"
            class="pr-lg-8"
          >
            <slot />
            <BaseSelect
              v-model="formData.selectedCompetence"
              rules="select"
              :items="competences"
              :label="$t('stages.closed_question.competence')"
              :placeholder="$t('stages.closed_question.competence_hint')"
              item-text="label"
              item-value="value"
              persistent-hint
              return-object
              searchable
              :loading="isLoading"
              :disabled="isLoading"
            />
            <BaseInput
              v-model="formData.header"
              rules="min:3|max:255|required"
              :label="$t('stages.closed_question.header')"
            />
            <BaseRichTextEditor
              v-model="formData.body"
              rules="min:3|max:60000|required"
              :label="$t('stages.closed_question.body')"
            />
            <BaseInput
              v-model="formData.instruction"
              rules="min:3|max:255"
              :label="$t('stages.closed_question.instruction')"
            />
            <BaseImageUploader
              v-model="formData.image"
              rules="size:10240|extensions:jpeg,jpg,png,gif"
              :label="$t('stages.closed_question.question_image')"
              :vid="$t('stages.closed_question.question_image')"
            />
            <BaseImageUploader
              v-model="formData.sideImage"
              rules="size:10240|extensions:jpeg,jpg,png,gif"
              :label="$t('stages.closed_question.side_image')"
              :vid="$t('stages.closed_question.side_image')"
            />
            <v-alert
              class="mb-10"
              icon="mdi-information-outline"
              color="grayDark"
              text
            >
              <span class="body-2">
                {{ $t('stages.closed_question.side_image_requirements') }}
              </span>
            </v-alert>
            <BaseImageUploader
              v-model="formData.mobileSideImage"
              rules="size:10240|extensions:jpeg,jpg,png,gif"
              :label="$t('stages.closed_question.mobile_side_image')"
              :vid="$t('stages.closed_question.mobile_side_image')"
            />
            <v-alert
              icon="mdi-information-outline"
              color="grayDark"
              text
            >
              <span class="body-2">
                {{ $t('stages.closed_question.mobile_side_image_requirements') }}
              </span>
            </v-alert>
          </v-col>
          <v-col
            sm="12"
            lg="6"
          >
            <div v-if="formData.answers.length">
              <div
                v-for="(answer, index) in formData.answers"
                :key="`answer-${index}`"
                class="pb-10"
              >
                <p class="d-flex align-center title pb-2">
                  <span>{{ $t("stages.closed_question.answer") }} {{ index + 1 }}</span>
                  <button
                    class="pb-1 px-2"
                    type="button"
                    @click="removeAnswer(index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </button>
                </p>
                <BaseRichTextEditor
                  v-model="answer.body"
                  rules="min:3|max:60000|required"
                  :label="$t('stages.closed_question.answer')"
                  :vid="`${$t('stages.closed_question.answer')}-${index}`"
                />
                <BaseInput
                  v-model="answer.scoring"
                  type="number"
                  step="0.1"
                  min="-1"
                  max="1"
                  rules="min_value:-1|max_value:1|decimal|required"
                  :label="$t('stages.closed_question.score')"
                  :vid="`${$t('stages.closed_question.score')}-${index}`"
                />
                <p class="d-flex align-center title">
                  {{ $t("stages.closed_question.feedback_title") }}
                </p>
                <BaseInput
                  v-model="answer.feedback.body"
                  :rules="answerFeedbackValidationRules(answer)"
                  :label="$t('stages.closed_question.feedback')"
                  :vid="`${$t('stages.closed_question.feedback')}-${index}`"
                />
                <BaseImageUploader
                  v-model="answer.feedback.image"
                  :rules="answerImageValidationRules(answer)"
                  :label="$t('stages.closed_question.feedback_image')"
                  :vid="`${$t('stages.closed_question.feedback_image')}-${index}`"
                />
                <v-divider class="mt-10" />
              </div>
            </div>
            <div
              v-else
              class="d-flex justify-center pt-8 pb-12"
            >
              {{ $t("stages.closed_question.answer_empty") }}
            </div>
            <v-row>
              <v-col lg="3">
                <BaseButton
                  color="grayDark"
                  dark
                  @click="addAnswer"
                >
                  <span class="pl-4">{{ $t("stages.closed_question.add") }}</span>
                  <v-icon class="pl-2">
                    mdi-plus
                  </v-icon>
                </BaseButton>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseRichTextEditor from '@/components/Form/BaseRichTextEditor';
import BaseInput from '@/components/Form/BaseInput';
import BaseImageUploader from '@/components/Form/BaseImageUploader';
import BaseSelect from '@/components/Form/BaseSelect';
import BaseButton from '@/components/Form/BaseButton';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import { DELETE } from '@/names/global.names';

export default {
  name: 'ClosedQuestion',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseRichTextEditor,
    BaseInput,
    BaseImageUploader,
    BaseSelect,
    BaseButton,
  },
  props: {
    backendErrors: {
      type: Array,
      default: () => [],
    },
    competences: {
      type: Array,
      default: () => [],
    },
    currentStage: {
      type: String,
      required: true,
    },
    stage: {
      type: Object,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        selectedCompetence: '',
        header: '',
        body: '',
        instruction: '',
        image: null,
        sideImage: null,
        mobileSideImage: null,
        answers: [{
          body: '',
          scoring: '',
          feedback: {
            body: '',
            image: '',
          },
        }],
      },
    };
  },
  created() {
    if (this.editMode) {
      this.formData.header = this.stage.stageable.data.header;
      this.formData.body = this.stage.stageable.data.body;
      this.formData.instruction = this.stage.stageable.data.instruction ? this.stage.stageable.data.instruction : '';
      this.formData.image = this.stage.stageable.data.image_url;
      this.formData.sideImage = this.stage.side_image_url;
      this.formData.mobileSideImage = this.stage.mobile_side_image_url;
      this.formData.selectedCompetence = this.competences.find(
        (competence) => competence.id === this.stage.stageable.data.competence.data.id,
      );
      this.formData.answers = this.stage.stageable.data.answers.data.map((answer) => ({
        id: answer.id,
        body: answer.body,
        scoring: String(answer.scoring),
        feedback: {
          body: answer.feedback ? answer.feedback.data.body : '',
          image: answer.feedback ? answer.feedback.data.image_url : '',
        },
      }));
    }
  },
  methods: {
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const formData = new FormData();
      const { gameId, stageId } = this.$route.params;
      formData.set('type', this.currentStage);
      formData.set('competence_id', this.formData.selectedCompetence.id);
      formData.set('header', this.formData.header);
      formData.set('body', this.formData.body);
      formData.set('instruction', this.formData.instruction);
      formData.set('image', this.formData.image);
      formData.set('side_image', this.formData.sideImage);
      formData.set('mobile_side_image', this.formData.mobileSideImage);
      this.formData.answers.map((answer, index) => {
        if (this.editMode && answer.id !== undefined) {
          formData.set(`answers[${index}][id]`, answer.id);
        }
        formData.set(`answers[${index}][body]`, answer.body);
        formData.set(`answers[${index}][scoring]`, answer.scoring);
        if (this.formData.answers[index].feedback.body) {
          formData.set(`answers[${index}][feedback][body]`, answer.feedback.body);
          formData.set(`answers[${index}][feedback][image]`, answer.feedback.image);
        }

        return answer;
      });
      if (this.editMode) {
        formData.set('_method', 'PUT');

        return { gameId, stageId, params: formData };
      }

      return { gameId, params: formData };
    },
    removeAnswer(index) {
      this.formData.answers.splice(index, 1);
    },
    addAnswer() {
      this.formData.answers.push({
        body: '',
        scoring: '',
        feedback: {
          body: '',
          image: '',
        },
      });
    },
    answerImageValidationRules(answer) {
      return `${
        (answer.feedback.body && !answer.id)
        || (answer.feedback.body && answer.feedback.image === DELETE)
          ? 'image_required|'
          : ''
      }size:10240|extensions:jpeg,jpg,png,gif`;
    },
    answerFeedbackValidationRules(answer) {
      return `${
        answer.feedback.image && answer.feedback.image !== DELETE ? 'required|' : ''
      }min:3|max:255`;
    },
  },
};
</script>
