<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :vid="vid"
    :name="label"
    :rules="rules"
  >
    <div
      class="text-editor pb-6 mb-2"
      :class="{ 'text-editor-invalid': errors.length }"
    >
      <span
        v-if="!hideTitle"
        class="subtitle-2"
        :class="{ 'ck-read-only': disabled }"
      >
        {{ label }}
      </span>
      <ckeditor
        v-model="innerValue"
        :editor="editor"
        :config="editorConfig"
        :disabled="disabled"
        v-bind="$attrs"
        v-on="$listeners"
        @blur="$emit('validate-field', { label, isValid: valid })"
      />
      <div class="text-editor-error caption">
        <transition
          name="valid-animation"
          mode="out-in"
        >
          <span v-if="errors.length">{{ errors[0] }}</span>
        </transition>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
import { methods } from '@/plugins/global/locale.mixin';
import '@ckeditor/ckeditor5-basic-styles/build/translations/pl';
import '@ckeditor/ckeditor5-heading/build/translations/pl';
import '@ckeditor/ckeditor5-list/build/translations/pl';
import '@ckeditor/ckeditor5-alignment/build/translations/pl';
import '@ckeditor/ckeditor5-horizontal-line/build/translations/pl';
import '@ckeditor/ckeditor5-media-embed/build/translations/pl';
import '@ckeditor/ckeditor5-image/build/translations/pl';
import '@ckeditor/ckeditor5-basic-styles/build/translations/en-gb';
import '@ckeditor/ckeditor5-heading/build/translations/en-gb';
import '@ckeditor/ckeditor5-list/build/translations/en-gb';
import '@ckeditor/ckeditor5-alignment/build/translations/en-gb';
import '@ckeditor/ckeditor5-horizontal-line/build/translations/en-au';
import '@ckeditor/ckeditor5-media-embed/build/translations/en-gb';
import '@ckeditor/ckeditor5-image/build/translations/en-gb';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import AutoLinkPlugin from '@ckeditor/ckeditor5-link/src/autolink';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import List from '@ckeditor/ckeditor5-list/src/list';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage';
import UploadAdapter from '@ckeditor/ckeditor5-upload/src/filerepository';
import CustomTags from '@/plugins/ckeditor/CustomTags';
import UploadPlugin from '@/plugins/ckeditor/UploadPlugin';

export default {
  name: 'BaseRichTextEditor',
  mixins: [methods],
  props: {
    variablesList: {
      type: Array,
      default: () => [],
    },
    vid: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    extendedVersion: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    hideVideoBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      innerValue: '',
      editorConfig: {
        language: null,
        plugins: [
          EssentialsPlugin,
          ParagraphPlugin,
          BoldPlugin,
          ItalicPlugin,
          UnderlinePlugin,
          StrikethroughPlugin,
          Heading,
          LinkPlugin,
          AutoLinkPlugin,
          List,
          Alignment,
          HorizontalLine,
          MediaEmbed,
          Image,
          ImageStyle,
          ImageToolbar,
          LinkImage,
          ImageUpload,
          AutoImage,
          UploadAdapter,
        ],
        extraPlugins: [CustomTags, UploadPlugin],
        customTagsData: [],
        toolbar: {
          items: ['bold', 'underline', '|', 'bulletedList', 'numberedList'],
        },
        image: {
          toolbar: ['linkImage', 'imageTextAlternative', 'imageStyle:alignBlockLeft', 'imageStyle:block', 'imageStyle:alignBlockRight'],
        },
        mediaEmbed: {
          previewsInData: true,
        },
      },
    };
  },
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    this.editorConfig.language = this.currentLocale().toLowerCase();
    this.extendedVersion && this.extendedVersionToolbar();
    if (this.value) {
      this.innerValue = this.value;
    }
    if (this.variablesList.length) {
      this.editorConfig.customTagsData = this.variablesList;
      this.editorConfig.toolbar.items.unshift('customTags');
    }
    if (this.disabled) {
      this.editorConfig.toolbar.items = [];
    }
  },
  methods: {
    extendedVersionToolbar() {
      if (this.hideVideoBtn) {
        this.editorConfig.toolbar.items = [
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'link',
          'uploadImage',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'alignment',
          'horizontalLine',
        ];

        return;
      }
      this.editorConfig.toolbar.items = [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'mediaEmbed',
        'uploadImage',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'alignment',
        'horizontalLine',
      ];
    },
  },
};
</script>
<style lang="scss">
.text-editor {
  position: relative;
  &-error {
    padding-left: 12px;
    color: var(--red);
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    span {
      display: block;
    }
  }
  &-invalid .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
  &-invalid .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    border: 2px solid var(--red) !important;
  }
  .ck-editor__editable {
    min-height: 100px;
  }
}
.ck.ck-toolbar,
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: var(--grayLightest) !important;
  border-collapse: collapse;
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border-radius: 4px;
  border: 1px solid var(--primary) !important;
  box-shadow: inset 0px 0px 0px 1px var(--primary);
}
.valid-animation-enter-active {
  transition: all 0.3s ease;
}
.valid-animation-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.valid-animation-enter,
.valid-animation-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
.ck-read-only {
  color: rgba(0, 0, 0, 0.38);
}
</style>
