import api from '@/plugins/axios/api';

/**
 * @param {String} clientId
 */
const fetchVariablesList = async ({
  clientId,
}) => {
  const { data } = await api.get(`clients/${clientId}/static-pages/variables-list`);

  return data.data;
};

export default fetchVariablesList;
