import api from '@/plugins/axios/api';

/**
 * @param {Array} includes
 * @param {Number} limit
 * @param {?Object} sort
 * @param {?String} search
 * @param {?Number} page
 */
const fetchWorkDepartments = async ({
  includes,
  limit,
  sort,
  search,
  page,
}) => {
  const { data: { data, meta } } = await api.get('work-departments', {
    params: {
      includes,
      limit,
      sort,
      search,
      page,
    },
  });

  return { data, pagination: meta.pagination };
};

export default fetchWorkDepartments;
