<template>
  <select
    v-model="innerValue"
    @change="updateValue"
  >
    <option :value="null">
      -
    </option>
    <option
      v-for="(option, index) in items"
      :key="index"
      :value="option"
    >
      {{ option.name }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'BaseScoringScaleSelect',
  props: {
    value: {
      type: [String, Number, Object, Array],
      default: null,
    },
    items: {
      type: [Array],
      default: () => [],
    },
    competenceId: {
      type: String,
      default: null,
    },
    stageId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    innerValue: null,
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      if (this.value.data.score === null) {
        this.innerValue = null;

        return;
      }
      this.innerValue = this.value.data?.scoring_scale?.data;
    }
  },
  methods: {
    updateValue() {
      const scoringScaleId = this.innerValue ? this.innerValue.id : null;
      const data = {
        scoringScaleId,
        competenceId: this.competenceId,
        stageId: this.stageId,
      };
      this.$emit('change', data);
    },
  },
};
</script>
<style lang="scss" scoped>
select {
  border: 0;
  outline: 0;
  width: 63px;
  height: 40px;
  border: 1px solid #979797;
  border-radius: 3px;
  cursor: pointer;
  padding: 4px 10px;
  background: url("data:image/svg+xml,<svg width='30px' height='30px' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'><path d='M15.41 16L20 20.59L24.59 16L26 17.42L20 23.42L14 17.42L15.41 16Z' fill='orange' /></svg>")
    no-repeat;
  background-position: calc(100% - 0.25rem) center !important;
  option {
    background-color: #fff;
  }
  &:focus {
    outline: none;
  }
  &::-ms-expand {
    display: none;
  }
}
</style>
