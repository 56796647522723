<template>
  <v-menu
    v-model="languageMenu"
    offset-y
    left
    elevation="2"
    nudge-bottom="10"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="text-capitalize"
        v-bind="attrs"
        text
        v-on="on"
      >
        {{ activeLocale.value }}
        <v-icon
          small
          right
        >
          mdi-menu-down
        </v-icon>
      </v-btn>
    </template>
    <v-card
      class="user-details-list"
      min-width="220"
    >
      <div
        v-for="(lang, index) in languages"
        :key="index"
        @click="handleLocaleChange(lang)"
      >
        <v-list-item
          class="select-language"
          link
        >
          <v-list-item-title>{{ lang.name }}</v-list-item-title>
          <img
            :src="require(`@/assets/images/countries/${lang.value}.jpg`)"
            :alt="lang.name"
          >
        </v-list-item>
        <v-divider />
      </div>
    </v-card>
  </v-menu>
</template>
<script>
import { methods } from '@/plugins/global/locale.mixin';
import { mapActions } from 'vuex';
import { PL, EN } from '@/names/locale.names';

export default {
  name: 'LocaleMenu',
  mixins: [methods],
  data() {
    return {
      languageMenu: false,
      languages: [
        {
          value: PL,
          name: this.$t('dashboard.locale_types.polish'),
        },
        {
          value: EN,
          name: this.$t('dashboard.locale_types.english'),
        },
      ],
      activeLocale: {
        value: EN,
        name: this.$t('dashboard.locale_types.english'),
      },
    };
  },
  created() {
    this.activeLocale = this.languages.find((lang) => lang.value === this.currentLocale());
    this.setLocale(this.activeLocale.value);
  },
  methods: {
    ...mapActions('session', ['setLocale']),
    handleLocaleChange(lang) {
      this.activeLocale = lang;
      this.$emit('handle-locale-change', lang);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-language {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
