export default {
  title: 'Formularze',
  name: 'Nazwa',
  client: 'Klient',
  created_at: 'Data utworzenia',
  tags: 'Tagi',
  manage_tags: 'Zarządzaj tagami',
  no_results: 'Nie znaleziono formularzy',
  add: 'Dodaj formularz',
  edit: 'Edytuj formularz',
  create: {
    header: 'Nagłówek',
    client: 'Klient',
    client_hint: 'Wybierz klienta',
    fields_empty: 'Dodaj pola do formularza',
    add: 'Dodaj pole',
    field_type: 'Typ pola',
  },
  field_types: {
    text: 'Pole tekstowe',
    textarea: 'Pole tekstowe duże (textarea)',
    select: 'Pole wyboru (select)',
    multiselect: 'Pole wyboru (multiselect)',
    number: 'Pole numeryczne',
    file: 'Plik',
    header: 'Nagłówek',
    checkbox: 'Checkbox',
    date: 'Kalendarz',
  },
  field: {
    name: 'Nazwa pola',
    hint: 'Wskazówka (opcjonalne)',
    tooltip: 'Tooltip (opcjonalne)',
    required: 'Obowiązkowe',
    description: 'Opis',
    options: 'Opcje',
    step: 'Step',
    min: 'Min',
    max: 'Max',
  },
};
