import api from '@/plugins/axios/api';

/**
 * @param {String} clientId
 * @param {Array} includes
 * @param {String} locale
 */
const fetchClient = async ({
  clientId,
  includes,
  locale,
}) => {
  const { data: { data } } = await api.get(`clients/${clientId}`, {
    params: {
      includes,
      locale,
    },
  });

  return data;
};

export default fetchClient;
