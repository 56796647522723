<template>
  <ValidationProvider
    ref="provider"
    v-slot="{ errors }"
    :vid="vid"
    :name="label"
    :rules="rules"
  >
    <div class="file-input">
      <v-file-input
        v-model="file"
        prepend-icon="mdi-file-document"
        outlined
        :label="label"
        :error-messages="errors"
        v-bind="$attrs"
        v-on="$listeners"
        @change="updateValue"
      >
        <template v-slot:selection="{ text }">
          <v-chip
            dark
            label
            small
          >
            {{ text }}
          </v-chip>
        </template>
      </v-file-input>
      <router-link
        v-if="fileUrl"
        :to="candidateFileUrl"
        class="file-input__button"
        target="_blank"
      >
        <span>{{ $t('dashboard.download') }}</span>
      </router-link>
    </div>
  </ValidationProvider>
</template>
<script>
import { FILE } from '@/router/routes.names';
import { DELETE } from '@/names/global.names';

export default {
  name: 'BaseFileInput',
  props: {
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String, Array],
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, File, null],
      default: null,
    },
  },
  data() {
    return {
      file: null,
      fileUrl: null,
    };
  },
  computed: {
    candidateFileUrl() {
      return {
        name: FILE,
        params: {
          fileUrl: this.fileUrl,
        },
      };
    },
  },
  created() {
    if (this.value) {
      this.setFile(this.value);
    }
  },
  methods: {
    updateValue(value) {
      this.resetFileUrl();
      if (value === null) {
        this.$emit('input', DELETE);

        return;
      }

      this.$emit('input', value);
    },
    resetFileUrl() {
      this.fileUrl = null;
    },
    setFile(value) {
      const FILE_NAME_REGEX = /(.+)\/(.+)$/;
      const fileName = value.replace(FILE_NAME_REGEX, '$2');
      this.file = new File([], fileName);
      this.fileUrl = value;
      this.$emit('input', '');
    },
  },
};
</script>
<style lang="scss" scoped>
.file-input {
  display: flex;
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #263238;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
    border-radius: 3px;
    width: 134px;
    height: 40px;
    margin: 10px 20px;
    color: #fff !important;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
