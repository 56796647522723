<template>
  <div>
    <v-data-table
      v-bind="$attrs"
      :headers="tableHeaders"
      :items="tableData"
      :server-items-length="pagination.total"
      :loading="isLoading"
      :options.sync="options"
      :sort-by.sync="sort.column"
      :sort-desc.sync="sort.direction"
      :footer-props="{
        'items-per-page-text': $t('dashboard.table.pagination_results'),
        'items-per-page-options': itemsPerPageOptions,
        pageText: `{0} - {1} ${$t('dashboard.of')} {2}`
      }"
      v-on="$listeners"
      @update:page="updatePage"
      @update:items-per-page="updateItemsPerPage"
      @update:sort-by="updateSort"
      @update:sort-desc="updateSort"
    >
      <template
        v-if="!!$scopedSlots.body"
        #body="{ items, headers }"
      >
        <slot
          name="body"
          :items="items"
          :headers="headers"
        />
        <tbody v-if="!items.length">
          <tr>
            <td
              :colspan="headers.length"
              style="height: 500px; text-align: center"
            >
              <img
                :src="require(`@/assets/icons/no_results.svg`)"
                alt="noresults"
              >
              <div class="pt-3 pb-8">
                {{ isLoading ? $t("dashboard.table.loading") : noResultsText }}
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { ASC, DESC } from '@/components/Table/sort.types';

export default {
  name: 'DraggableTable',
  props: {
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [20, 50, 100, 200],
    },
    noResultsText: {
      type: String,
      required: true,
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    defaultSort: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sortPending: false,
      sort: {
        column: [],
        direction: [],
      },
      options: {
        itemsPerPage: +this.$route.query.limit || 50,
        page: +this.$route.query.page || 1,
        mustSort: true,
      },
    };
  },
  computed: {
    formattedSortDirection() {
      if (this.$route.query.sort) {
        return this.$route.query.sort.direction === DESC;
      }

      return this.defaultSort.direction === DESC;
    },
    convertedSortDirection() {
      return this.sort.direction[0] === true ? DESC : ASC;
    },
  },
  watch: {
    pagination(pagination) {
      this.options.page = pagination.current_page;
    },
  },
  created() {
    if (this.$route.query.sort) {
      this.sort.column[0] = this.$route.query.sort.column;
      this.sort.direction[0] = this.formattedSortDirection;

      return;
    }
    this.sort.column[0] = this.defaultSort.column;
    this.sort.direction[0] = this.formattedSortDirection;
  },
  methods: {
    updatePage(page) {
      if (+this.$route.query.page === page) {
        return;
      }
      this.$router.replace({
        query: {
          ...this.$route.query,
          page,
        },
      }, () => {});
      this.$emit('change-page', { page });
    },
    updateItemsPerPage(limit) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          limit,
        },
      }, () => {});
      this.$emit('change-items-per-page', { limit });
    },
    updateSort() {
      if (this.sortPending) {
        return;
      }
      this.sortPending = true;
      this.$nextTick(() => {
        this.sortPending = false;
        const column = this.sort.column[0];
        const direction = this.convertedSortDirection;
        this.$router.replace({
          query: {
            ...this.$route.query,
            sort: {
              column,
              direction,
            },
          },
        }, () => {});
        this.$emit('change-sort', { sort: { column, direction } });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-data-table {
  overflow-x: auto;

  &::v-deep {
    .v-data-table-header tr th {
      white-space: nowrap;
    }

    .v-data-table__wrapper {
      overflow: visible !important;
    }
    .v-data-table__wrapper {
      table {
        margin-bottom: 0;
      }
    }
    .v-data-footer__pagination {
      position: absolute;
      left: 0;
    }
    tbody tr {
      color: var(--grayDark) !important;
      &:hover {
        background: var(--gray) !important;
      }
    }
    .mdi-checkbox-marked.theme--light.v-icon,
    .mdi-minus-box.theme--light.v-icon {
      color: var(--primary) !important;
    }
  }
}
</style>
