/* eslint-disable import/prefer-default-export */
export function polishPlurals(singularNominativ, pluralNominativ, pluralGenitive, number) {
  if (number === 1) {
    return singularNominativ;
  }
  if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
    return pluralNominativ;
  }

  return pluralGenitive;
}
