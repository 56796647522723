import swal from 'sweetalert2';
import warningIcon from '@/assets/icons/error.svg';
import successIcon from '@/assets/icons/success.svg';

export default {
  defaultAlert(params = {}) {
    const defaultParams = {
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: this.$vuetify.theme.themes.light.primary,
      cancelButtonColor: this.$vuetify.theme.themes.light.primary,
      customClass: {
        confirmButton: 'swal2-confirm',
        cancelButton: 'swal2-cancel',
      },
      scrollbarPadding: false,
    };

    return swal.fire({
      ...defaultParams,
      ...params,
    });
  },
  successAlert(params = {}) {
    const defaultParams = {
      imageUrl: successIcon,
      imageWidth: 124,
      imageHeight: 124,
      confirmButtonColor: this.$vuetify.theme.themes.light.primary,
      cancelButtonColor: this.$vuetify.theme.themes.light.primary,
      customClass: {
        confirmButton: 'swal2-confirm',
      },
      scrollbarPadding: false,
    };

    return swal.fire({
      ...defaultParams,
      ...params,
    });
  },
  errorAlert(params = {}) {
    const defaultParams = {
      imageUrl: warningIcon,
      imageWidth: 124,
      imageHeight: 124,
      confirmButtonText: this.$t('dashboard.modal.error.button'),
      confirmButtonColor: this.$vuetify.theme.themes.light.primary,
      customClass: {
        confirmButton: 'swal2-confirm',
      },
      scrollbarPadding: false,
      title: this.$t('dashboard.modal.error.title'),
      text: this.$t('dashboard.modal.error.content'),
    };

    return swal.fire({
      ...defaultParams,
      ...params,
    });
  },
  async cancelAlert() {
    const defaultParams = {
      title: this.$t('dashboard.modal.cancel.title'),
      confirmButtonText: this.$t('dashboard.modal.cancel.confirm'),
      cancelButtonText: this.$t('dashboard.modal.cancel.cancel'),
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: this.$vuetify.theme.themes.light.primary,
      cancelButtonColor: this.$vuetify.theme.themes.light.primary,
      customClass: {
        confirmButton: 'swal2-confirm',
        cancelButton: 'swal2-cancel',
      },
      scrollbarPadding: false,
    };

    const { isConfirmed } = await swal.fire({
      ...defaultParams,
    });

    return isConfirmed;
  },
  async confirmAlert() {
    const defaultParams = {
      title: this.$t('dashboard.modal.confirm.title'),
      confirmButtonText: this.$t('dashboard.modal.confirm.confirm'),
      cancelButtonText: this.$t('dashboard.modal.confirm.cancel'),
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: this.$vuetify.theme.themes.light.primary,
      cancelButtonColor: this.$vuetify.theme.themes.light.primary,
      customClass: {
        confirmButton: 'swal2-confirm',
        cancelButton: 'swal2-cancel',
      },
      scrollbarPadding: false,
    };

    const { isConfirmed } = await swal.fire({
      ...defaultParams,
    });

    return isConfirmed;
  },
  async removeAlert() {
    const defaultParams = {
      title: this.$t('dashboard.modal.remove.title'),
      text: this.$t('dashboard.modal.remove.content'),
      confirmButtonText: this.$t('dashboard.modal.remove.confirm'),
      cancelButtonText: this.$t('dashboard.modal.remove.cancel'),
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: this.$vuetify.theme.themes.light.red,
      cancelButtonColor: this.$vuetify.theme.themes.light.primary,
      customClass: {
        confirmButton: 'swal2-confirm',
        cancelButton: 'swal2-cancel',
      },
      scrollbarPadding: false,
    };

    const { isConfirmed } = await swal.fire({
      ...defaultParams,
    });

    return isConfirmed;
  },
};
