<template>
  <div>
    <div
      v-if="isPreloading"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <FadeInTransition>
      <div
        v-if="!isPreloading"
        class="pb-6"
      >
        <v-row class="px-3 px-lg-6">
          <v-col>
            <ClientForm
              :edit-mode="editMode"
              :locale="locale"
              :client="client"
              :ats-types="atsTypes"
              :locale-types="localeTypes"
              :is-loading="isLoading"
              :is-report-description-loading="isReportDescriptionLoading"
              :is-sending="isSending"
              :backend-errors="errors"
              @handle-submit="submit"
              @handle-cancel="returnToList"
              @has-form-changes="hasFormChanges"
              @update-locale="updateLocale"
            />
          </v-col>
        </v-row>
      </div>
    </FadeInTransition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import eventBus from '@/plugins/eventBus';
import alerts from '@/plugins/alerts';
import {
  HTTP_UNAUTHORIZED,
  HTTP_NOT_FOUND,
  HTTP_UNPROCESSABLE_ENTITY,
} from '@/plugins/axios/codes';
import { NOT_FOUND, CLIENT_LIST, CLIENT_EDIT } from '@/router/routes.names';
import { HAS_FORM_CHANGES } from '@/names/emits.names';
import fetchClient from '@/components/Dashboard/Client/fetchClient';
import addClient from '@/components/Dashboard/Client/addClient';
import editClient from '@/components/Dashboard/Client/editClient';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import ClientForm from '@/components/Dashboard/Client/Form';
import FadeInTransition from '@/components/Transition/FadeInTransition';
import { PL, EN } from '@/names/locale.names';

export default {
  name: 'ClientEdit',
  components: {
    Preloader,
    ClientForm,
    FadeInTransition,
  },
  data() {
    return {
      locale: null,
      client: null,
      atsTypes: [
        {
          name: this.$t('client.ats_types.e_recruiter'),
          value: 'E_RECRUITER',
        },
        {
          name: this.$t('client.ats_types.e_recruiter_marketplace'),
          value: 'E_RECRUITER_MARKETPLACE',
        },
        {
          name: this.$t('client.ats_types.new_generation_recruitment'),
          value: 'NEW_GENERATION_RECRUITMENT',
        },
        {
          name: this.$t('client.ats_types.smart_recruiters'),
          value: 'SMART_RECRUITERS',
        },
        {
          name: this.$t('client.ats_types.hr_link'),
          value: 'HR_LINK',
        },
        {
          name: this.$t('client.ats_types.hr_link_anonymous_version'),
          value: 'HR_LINK_ANONYMOUS_VERSION',
        },
        {
          name: this.$t('client.ats_types.sap'),
          value: 'SAP',
        },
      ],
      localeTypes: [
        {
          name: this.$t('dashboard.locale_types.polish'),
          value: PL,
        },
        {
          name: this.$t('dashboard.locale_types.english'),
          value: EN,
        },
      ],
      isPreloading: false,
      isLoading: false,
      isReportDescriptionLoading: false,
      isSending: false,
      errors: [],
    };
  },
  computed: {
    ...mapGetters('session', ['isSuperAdmin', 'clientDefaultLocale']),
  },
  async created() {
    this.isPreloading = true;
    this.locale = this.clientDefaultLocale.toLowerCase();
    this.editMode = this.$route.meta.editMode;
    await this.fetchClient(true);
    this.isPreloading = false;
  },
  methods: {
    ...alerts,
    returnToList() {
      this.$router.push({ name: CLIENT_LIST });
    },
    clientEditRoute(clientId) {
      this.$router.push({ name: CLIENT_EDIT, params: { clientId } });
    },
    async fetchClient(loadFullData) {
      try {
        loadFullData ? this.isLoading = true : this.isReportDescriptionLoading = true;
        if (this.editMode) {
          this.client = await fetchClient({
            clientId: this.$route.params.clientId,
            includes: ['options'],
            locale: this.locale,
          });
        }
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_NOT_FOUND) {
          this.$router.push({ name: NOT_FOUND });

          return;
        }
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        loadFullData ? this.isLoading = false : this.isReportDescriptionLoading = false;
      }
    },
    async submit(payload) {
      try {
        this.errors = [];
        this.isSending = true;
        if (this.editMode) {
          await editClient(payload);
          this.$notify.save.success();
          if (this.isSuperAdmin) {
            eventBus.$emit('fetchClientsInSidebar');
          }

          return;
        }
        const { data: { id } } = await addClient(payload);
        this.clientEditRoute(id);
        this.$notify.save.success();
        if (this.isSuperAdmin) {
          eventBus.$emit('fetchClientsInSidebar');
        }
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.$emit('reset-form-changes');
        this.isSending = false;
      }
    },
    async updateLocale(newLocale) {
      this.locale = newLocale;
      await this.fetchClient(false);
    },
    hasFormChanges(changed) {
      this.$emit(HAS_FORM_CHANGES, changed);
    },
  },
};
</script>
<style lang="scss" scoped>
.client-edit__title {
  font-size: 20px;
  font-weight: 500;
}
</style>
