<template>
  <transition
    name="fade"
    :appear="appear"
  >
    <slot />
  </transition>
</template>
<script>
export default {
  name: 'FadeInTransition',
  props: {
    appear: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
