export default {
  candidate_list: 'Candidates',
  candidate_add: 'Candidate Invitation',
  client_list: 'Clients',
  client_add: 'Add client',
  client_edit: 'Edit Client',
  competency_profile_list: 'Profile',
  competency_profile_add: 'Add a competency profile',
  competency_profile_edit: 'Edit competency profile',
  competence_list: 'Competences',
  competence_add: 'Add competence',
  competence_edit: 'Edit competence',
  dashboard: 'Admin Panel',
  game_list: 'Games',
  game_add: 'Add a game',
  game_edit: 'Edit game',
  game_badge_list: 'Badges',
  game_badge_add: 'Add a badge',
  game_badge_edit: 'Edit Badge',
  game_form_list: 'Forms',
  game_form_add: 'Add Form',
  game_form_edit: 'Edit form',
  game_page_list: 'Text pages',
  game_page_edit: 'Edit text page',
  game_stage_list: 'Step list',
  game_stage_add: 'Add a step',
  game_stage_edit: 'Edit Step',
  management_analyst_list: 'Analytics',
  message_template_list: 'Communication',
  message_template_add: 'Add Message template',
  message_template_edit: 'Edit Message template',
  recruitment_list: 'Recruitments',
  recruitment_add: 'Add recruitment process',
  recruitment_edit: 'Edit recruitment process',
  user_list: 'Users',
  user_add: 'Add User',
  user_edit: 'Edit User',
  work_position_list: 'Positions',
  work_position_add: 'Add position',
  work_position_edit: 'Edit position',
  work_department_list: 'Departments',
  work_department_add: 'Add department',
  work_department_edit: 'Edit department',
};
