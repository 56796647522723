import getFilenameFromResponseHeaders from '@/plugins/files/getFilenameFromResponseHeaders';

const exportFile = ({ headers = null, data }) => {
  const filename = headers ? getFilenameFromResponseHeaders(headers) : null;
  const url = window.URL.createObjectURL(data);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;

  if (data.type === 'application/pdf' || data.type.startsWith('image/')) {
    return {
      url: downloadLink,
      filename,
    };
  }

  downloadLink.setAttribute('download', filename);
  downloadLink.click();
  downloadLink.remove();
  setTimeout(() => {
    window.close();
  }, 500);

  return null;
};

export default exportFile;
