import api from '@/plugins/axios/api';

/**
 * @param {Object} params
 */
const addClient = async ({
  params,
}) => {
  const { data } = await api.post('clients', params);

  return data;
};

export default addClient;
