import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {String} stageId
 * @param {Object} params
 */
const editQuestion = async ({
  gameId,
  stageId,
  params,
}) => {
  await api.post(`games/${gameId}/game-stages/${stageId}`, params);
};

export default editQuestion;
