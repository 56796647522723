<template>
  <div class="my-1">
    <span
      v-for="(item, index) in limitedList"
      :key="`list-item-${index}`"
    >
      <template v-if="index < limit">
        <v-tooltip
          v-if="item[itemKey].length > charactersLimit"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="mr-2"
              small
              v-bind="attrs"
              v-on="on"
            >
              <span
                class="cursor-pointer"
                @click="showAssignTagsModal"
              >
                {{ index + 1 == limit && items.length > limit ? '...' : stringLimit(item[itemKey], charactersLimit) }}
              </span>
            </v-chip>
          </template>
          {{ index + 1 == limit && items.length > limit ? '...' : item[itemKey] }}
        </v-tooltip>
        <v-chip
          v-else
          class="mr-2 my-1"
          small
        >
          <span
            class="cursor-pointer"
            @click="showAssignTagsModal"
          >
            {{ index + 1 == limit && items.length > limit ? '...' : stringLimit(item[itemKey], charactersLimit) }}
          </span>
        </v-chip>
      </template>
    </span>
    <AssignTagsModal
      v-model="isAssignTagsModalOpen"
      :game-form-id="gameFormId"
      @refetch-data="refetchData"
    />
  </div>
</template>
<script>
import { stringLimit } from '@/plugins/stringHelpers/stringLimit';
import AssignTagsModal from '@/components/Dashboard/GameForm/AssignTagsModal';

export default {
  name: 'GameFormTags',
  components: {
    AssignTagsModal,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      required: true,
    },
    limit: {
      type: String,
      default: '3',
    },
    gameFormId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      charactersLimit: 15,
      isAssignTagsModalOpen: false,
    };
  },
  computed: {
    limitedList() {
      return this.items.slice(0, this.limit);
    },
  },
  methods: {
    stringLimit,
    showAssignTagsModal() {
      this.isAssignTagsModalOpen = true;
    },
    refetchData() {
      this.$emit('refetch-data');
    },
  },
};
</script>
<style lang="scss" scoped>
.v-chip__content {
  font-weight: 500;
  color: #263238 !important;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
