import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FCAF3B',
        accent: '#FF7F22',
        success: '#4CAF50',
        error: '#FF3D3D',
        white: '#FFF',
        orangeLight: '#ffebcc',
        orange: '#FCAF3B',
        redLight: '#FFD4D4',
        red: '#FF3D3D',
        greenLight: '#C9F2CB',
        green: '#4CAF50',
        purpleLight: '#d4c7f0',
        purple: '#A080E1',
        blue: '#5C63FF',
        blueLight: '#dbddff',
        grayLightest: '#EAEAEA',
        grayLighter: '#949494',
        grayLight: '#737373',
        gray: '#F2F2F2',
        grayDark: '#263238',
        black: '#2D2D2D',
        teal: '#009688',
        tealLight: '#E0F2F1',
        pink: '#E91E63',
        pinkLight: '#F8BBD0',
      },
    },
  },
});
