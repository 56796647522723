<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <BaseInput
          v-model="formData.email"
          rules="min:3|max:255|email_required|email"
          :label="$t('user.create.email')"
        />
        <BaseInput
          v-model="formData.name"
          rules="min:3|max:255|required"
          :label="$t('user.create.name')"
        />
        <BaseSelect
          v-model="formData.selectedType"
          rules="select"
          :item-disabled="checkIsRoleDisabled"
          :items="availableRoles"
          :label="$t('user.create.type')"
          item-text="name"
          item-value="value"
          persistent-hint
          :loading="isLoading"
          :disabled="isLoading"
          @change="updateType"
        />
        <BaseSelect
          v-if="isSuperAdmin && isSelectedTypeAsAdminOrRecruiter"
          v-model="formData.selectedClient"
          rules="select"
          :items="clients"
          :label="$t('user.create.client')"
          item-text="name"
          item-value="id"
          persistent-hint
          :loading="isLoading"
          :disabled="isLoading || editMode"
          @change="updateClient"
        />
        <BaseSelect
          v-if="isSelectedTypeAsRecruiter && (formData.selectedClient || !isSuperAdmin)"
          v-model="formData.selectedRecruitmentProcess"
          :items="recruitmentProcesses"
          :label="$t('user.create.recruitment_process')"
          item-text="localized_name"
          item-value="id"
          persistent-hint
          multiple
          chips
          :loading="isRecruitmentProcessesFetching"
          :disabled="isRecruitmentProcessesFetching"
        />
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseInput from '@/components/Form/BaseInput';
import BaseSelect from '@/components/Form/BaseSelect';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import { SUPER_ADMIN, ADMIN, RECRUITER } from '@/router/roles.names';

export default {
  name: 'UserForm',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseInput,
    BaseSelect,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    roleTypes: {
      type: Array,
      default: () => [],
    },
    clients: {
      type: Array,
      default: () => [],
    },
    recruitmentProcesses: {
      type: Array,
      default: () => [],
    },
    backendErrors: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isRecruitmentProcessesFetching: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        email: '',
        name: '',
        selectedClient: '',
        selectedType: '',
        selectedRecruitmentProcess: [],
      },
    };
  },
  computed: {
    ...mapGetters('session', ['role', 'isSuperAdmin']),
    isSelectedTypeAsAdminOrRecruiter() {
      return this.formData.selectedType === ADMIN || this.formData.selectedType === RECRUITER;
    },
    isSelectedTypeAsRecruiter() {
      return this.formData.selectedType === RECRUITER;
    },
    availableRoles() {
      return this.roleTypes.filter((role) => role.hasAccess.includes(this.role));
    },
  },
  created() {
    if (this.editMode) {
      this.formData.email = this.user.email;
      this.formData.name = this.user.name;
      this.formData.selectedType = this.roleTypes.find((type) => type.value === this.user.role.data.name).value;
      if (this.formData.selectedType !== SUPER_ADMIN && this.isSuperAdmin) {
        this.formData.selectedClient = this.clients?.find((client) => client.id === this.user.client.data.id).id;
      }
      if (this.formData.selectedType === RECRUITER) {
        this.formData.selectedRecruitmentProcess = this.user.recruitment_processes.data.map((process) => process.id);
        if (this.isSuperAdmin) {
          this.updateClient();
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }
      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const baseFormData = {
        email: this.formData.email,
        name: this.formData.name,
        type: this.formData.selectedType,
        recruitmentProcessesId: this.formData.selectedType === RECRUITER ? this.formData.selectedRecruitmentProcess : [],
      };
      if (!this.editMode) {
        return {
          ...baseFormData,
          clientId: this.formData.selectedClient,
        };
      }

      return {
        ...baseFormData,
        userId: this.user.id,
      };
    },
    updateType() {
      if (this.formData.selectedType === SUPER_ADMIN) {
        this.formData.selectedClient = '';
      }
      if (this.editMode && this.formData.selectedType === RECRUITER && this.isSuperAdmin) {
        this.updateClient();
      }
    },
    updateClient() {
      const filters = {
        client_id: this.formData.selectedClient,
      };
      this.$emit('update-recruitment-processes', filters);
    },
    checkIsRoleDisabled(role) {
      if (this.editMode) {
        if (this.user.role.data.name === RECRUITER || this.user.role.data.name === ADMIN) {
          return role.value === SUPER_ADMIN;
        }
        if (this.user.role.data.name === SUPER_ADMIN) {
          return [ADMIN, RECRUITER].includes(role.value);
        }
      }

      return false;
    },
  },
};
</script>
