export default {
  title: 'Kandydaci',
  invite: 'Zaproś',
  recruitment_process: 'Proces rekrutacyjny',
  recruitment_process_hint: 'Wybierz proces rekrutacyjny',
  recruitment_status: 'Status',
  first_name: 'Imię',
  last_name: 'Nazwisko',
  email: 'Email',
  phone_number: 'Nr tel.',
  score_percentage: 'Wynik',
  status: 'Status',
  stage: 'Etap rekrutacji',
  tags: 'Tagi',
  tags_hint: 'Maksymalnie 3 tagi',
  changed_at: 'Data zm. stat.',
  created_at: 'Data utworzenia',
  download_files: 'Pobierz plik/i',
  manage_tags: 'Zarządzaj tagami',
  send_message: 'Wyślij wiadomość',
  card: 'Karta kandydata',
  ratings: 'Ocena kompetencji',
  videos: 'Odpowiedzi video',
  report: 'Otwórz raport',
  no_results: 'Nie znaleziono kandydatów spełniających kryteria',
  empty_fullname: 'Użytkownik niezarejestrowany',
  group_actions: 'Akcje grupowe',
  change_stage: 'Zmień etap',
  change_stages: 'Zmień etapy',
  change_status: 'Zmień status',
  custom_message: 'Własna treść',
  candidate: 'Kandydat_ka',
  basic_fields: 'Dane podstawowe',
  additional_fields: 'Pozostałe dane',
  note: 'Notatka rekruterska',
  file_loading: 'Plik jest generowany',
  report_loading: 'Raport kandydata jest generowany',
  file_downloading: 'W ciągu kilku sekund powinno rozpocząć się pobieranie pliku',
  file_action: 'Jeśli pobieranie nie rozpoczęło się automatycznie, proszę kliknąć',
  no_recorded_video_response: 'Brak nagranej odpowiedzi video',
  no_record_stage: 'W grze nie występują kroki typu nagrywanie video',
  here: 'tutaj',
  statuses: {
    all: 'Wszystkie',
    invited: 'Zaproszono',
    started: 'Rozpoczęto',
    end_of_time: 'Koniec czasu',
    finished: 'Zakończono',
    accepted: 'Zaakceptowano',
    rejected: 'Odrzucono',
    resignation: 'Zrezygnowano',
    other: 'Inne',
  },
  advanced_filter: {
    1: 'Zaznaczone',
    0: 'Niezaznaczone',
    from: 'od',
    to: 'do',
    min: 'od',
    max: 'do',
  },
  add: 'Zaproś do procesu rekrutacyjnego',
  create: {
    description: 'Wpisz adres lub wiele adresów e-mail',
    singularNominativ: 'adres',
    pluralNominativ: 'adresy',
    pluralGenitive: 'adresów',
    tooltip: 'Wpisz adresy e-mail kandydatów. Adresy oddziel spacją, przecinkiem, średnikiem lub klawiszem Enter.',
    email: 'E-mail',
    invitation_message: 'Wiadomość zapraszająca',
    message_title: 'Tytuł wiadomości',
    message_body: 'Treść wiadomości',
    select_name: 'Proces rekrutacyjny',
    select_hint: 'Wybierz proces rekrutacyjny',
    custom_message: 'Własna treść',
  },
  modal: {
    change_stage: 'Zmiana etapu/statusu',
    stage: 'Etap',
    status: 'Status',
    message: 'Wiadomość',
    message_template: 'Szablon wiadomości do kandydata/ów',
    message_title: 'Tytuł wiadomości',
    message_body: 'Treść wiadomości',
  },
  filters: 'Filtry',
  advanced_filters: 'Zaawansowane filtry',
  close_filters: 'Zamknij filtry',
  edit_filters: 'Edytuj filtry',
  reset_filters: 'Resetuj filtry',
  clear_dates: 'Wyczyść daty',
  clear_fields: 'Wyczyść pola',
  remove_candidates: 'Usuń Kandydatów',
};
