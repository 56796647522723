<template>
  <v-menu
    offset-y
    left
    elevation="2"
    nudge-bottom="10"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-avatar size="40px">
          <img :src="require('@/assets/images/avatar.png')">
        </v-avatar>
      </v-btn>
    </template>
    <v-card class="user-details-list">
      <v-list-item>
        <div class="py-4 text-center">
          <h3>
            {{ user.name }}
          </h3>
          <span>{{ user.email }}</span>
        </div>
      </v-list-item>
      <v-divider />
      <v-list-item
        link
        @click="$emit('logout')"
      >
        <v-list-item-title>{{ $t("dashboard.topbar.logout") }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon>mdi-logout-variant</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-card>
  </v-menu>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserMenu',
  computed: {
    ...mapGetters('session', ['user']),
  },
};
</script>
