import i18n from '@/i18n';
import { setCookie } from '@/plugins/cookies';
import * as names from '@/store/session/mutations.names';

const LOCALE = 'locale';

export default {
  [names.SET_PERMISSIONS](state, data) {
    state.permissions = data;
  },
  [names.SET_ROLE](state, data) {
    state.role = data;
  },
  [names.SET_CLIENT](state, data) {
    state.client = data;
  },
  [names.SET_USER](state, data) {
    state.user = data;
  },
  [names.SET_CURRENT_ROUTE](state, data) {
    state.currentRoute = data;
  },
  [names.RESET_SESSION](state) {
    state.permissions = null;
    state.role = null;
    state.client = null;
    state.user = null;
    state.currentRoute = null;
  },
  [names.SET_LOCALE](state, locale) {
    i18n.locale = locale;
    setCookie(LOCALE, locale);
  },
};
