<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="vid"
    :name="label"
    :rules="rules"
  >
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="dates"
      transition="fade-transition"
      offset-y
      nudge-top="25"
      nudge-right="35"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="dateInput"
          :error-messages="errors"
          :label="label"
          :disabled="disabled"
          :dense="dense"
          :prepend-icon="hasIcon ? 'mdi-calendar' : ''"
          outlined
          readonly
          :clearable="clearable"
          v-bind="attrs"
          v-on="on"
          @click:clear="clearDates"
        />
      </template>
      <v-date-picker
        v-model="dates"
        no-title
        scrollable
        :multiple="range"
        :range="range"
        :locale="locale"
        :first-day-of-week="1"
        @input="saveDate"
      />
    </v-menu>
  </ValidationProvider>
</template>

<script>
import { computed, methods } from '@/plugins/global/locale.mixin';

export default {
  name: 'BaseDatePicker',
  mixins: [computed, methods],
  props: {
    vid: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [Object, String, Array],
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    hasIcon: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dates: this.initializeDates(),
      menu: false,
      activeLocale: this.currentLocale(),
    };
  },
  computed: {
    dateInput() {
      if (this.range) {
        return this.dates ? this.dates.join(' ~ ') : '';
      }

      return this.dates;
    },
  },
  watch: {
    dates(newDates) {
      if (this.range && (!newDates || newDates.length === 0)) {
        this.emitInputAndChange(null);
      }
    },
    value(newValue) {
      if (!this.range) {
        this.dates = newValue;

        return;
      }

      this.dates = newValue ? Object.values(newValue) : [];
    },
  },
  methods: {
    initializeDates() {
      if (this.value) {
        return this.range ? Object.values(this.value) : this.value;
      }

      return null;
    },
    saveDate() {
      if (!this.range && this.dates) {
        this.emitInputAndChange(this.dates);
      } else if (this.range && this.dates.length > 1) {
        if (this.dates[0] > this.dates[1]) return;
        this.emitInputAndChange({ from: this.dates[0], to: this.dates[1] });
      }
    },
    clearDates() {
      this.dates = this.range ? [] : null;
      this.emitInputAndChange(this.dates);
    },
    emitInputAndChange(value) {
      if (this.$refs.menu) {
        this.$refs.menu.save(this.dates);
      }
      this.$emit('input', value);
      this.$emit('change');
    },
  },
};
</script>
