export default {
  title: 'Kompetencje',
  search: 'Wyszukaj',
  name: 'Kompetencja',
  label: 'Etykieta',
  created_at: 'Data utworzenia',
  no_results: 'Nie znaleziono kompetencji',
  add: 'Dodaj kompetencję',
  edit: 'Edytuj kompetencję',
  create: {
    name: 'Nazwa kompetencji',
    description: 'Opis kompetencji',
    description_for_candidate: 'Opis kompetencji dla Kandydata',
    scoring_scales: 'Opis poziomu',
    development_indications: 'Wskazania rozwojowe',
    scale: 'Poziom',
    label: 'Etykieta',
  },
};
