<template>
  <span>
    <span
      v-for="(item, key) in value"
      :key="`list-item-${key}-${item}`"
    >
      <v-tooltip
        v-if="item.length > charactersLimit"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-chip
            class="my-2 mr-2"
            close
            v-on="on"
            @click:close="remove(item, key)"
          >
            {{ `${label}: ${stringLimit(formatTitle(item, key), charactersLimit)}` }}
          </v-chip>
        </template>
        {{ `${label}: ${formatTitle(item, key)}` }}
      </v-tooltip>
      <v-chip
        v-else
        class="my-2 mr-2"
        close
        @click:close="remove(item, key)"
      >
        {{ `${label}: ${formatTitle(item, key)}` }}
      </v-chip>
    </span>
  </span>
</template>
<script>
import { stringLimit } from '@/plugins/stringHelpers/stringLimit';
import { CHECKBOX, DATE, NUMBER } from '@/components/Dashboard/GameForm/Fields/Field.types';

export default {
  name: 'BaseChip',
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      charactersLimit: 30,
      value: [],
    };
  },
  created() {
    this.value = this.$route.query?.filters?.form_fields[this.id];
  },
  methods: {
    stringLimit,
    formatTitle(name, key) {
      if (this.type === DATE) {
        return `${this.$t(`candidate.advanced_filter.${key}`)} ${name}`;
      }
      if (this.type === NUMBER) {
        return `${this.$t(`candidate.advanced_filter.${key}`)} ${name}`;
      }
      if (this.type === CHECKBOX) {
        return this.$t(`candidate.advanced_filter.${name}`);
      }

      return name;
    },
    remove(item, key) {
      if (this.type === DATE || this.type === NUMBER) {
        if (Object.keys(this.value).length === 1) {
          this.value = [];
          this.updateFilters();

          return;
        }
        this.value[key] = null;
        this.updateFilters();

        return;
      }
      this.value.splice(this.value.indexOf(item), 1);
      this.updateFilters();
    },
    updateFilters() {
      this.$emit('change-filter', {
        id: this.id,
        values: this.value,
        type: this.type,
      });
    },
  },
};
</script>
