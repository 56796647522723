import api from '@/plugins/axios/api';

/**
 * @param {String} recruitmentProcessId
 */
const deleteRecruitmentProcess = async ({ recruitmentProcessId }) => {
  await api.delete(`recruitment-processes/${recruitmentProcessId}`);
};

export default deleteRecruitmentProcess;
