<template>
  <v-breadcrumbs
    :items="breadcrumbs"
    large
  >
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        class="text-subtitle-2 crumb-item"
        :disabled="item.disabled"
        exact
      >
        {{ item.label }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  data() {
    return {
      items: {},
    };
  },
  computed: {
    breadcrumbs() {
      const routes = this.$route.meta.breadcrumb.call(this, this.$route);
      routes.unshift({
        label: this.$t('breadcrumbs.dashboard'),
      });

      return routes;
    },
  },
};
</script>
