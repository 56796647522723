import api from '@/plugins/axios/api';

/**
 * @param {String} name
 * @param {String} description
 * @param {String} descriptionForCandidate
 * @param {Array} scoringDescriptions
 * @param {String} competenceId
 * @param {String} locale
 * @param {String} label
 */
const editCompetence = async ({
  name,
  description,
  descriptionForCandidate,
  scoringDescriptions,
  competenceId,
  locale,
  label,
}) => {
  await api.put(`competences/${competenceId}`, {
    name,
    description,
    description_for_candidate: descriptionForCandidate,
    scoring_descriptions: scoringDescriptions,
    locale,
    label,
  });
};

export default editCompetence;
