<template>
  <v-menu
    :close-on-content-click="false"
    :offset-x="isOffsetX"
    :offset-y="isOffsetY"
    :open-on-hover="isOpenOnHover"
    :value="openMenu"
    left
    bottom
  >
    <template
      v-slot:activator="{ on }"
    >
      <v-list-item
        v-if="isSubMenu"
        class="d-flex justify-space-between"
        v-on="on"
      >
        {{ name }}
      </v-list-item>
      <BaseButton
        v-else
        color="grayDark"
        :disabled="disabled"
        dark
        @click="openMenu = true"
        v-on="on"
      >
        {{ name }}
        <v-icon right>
          mdi-menu-down
        </v-icon>
      </BaseButton>
    </template>
    <v-list>
      <template
        v-for="(item, index) in menuItems"
      >
        <sub-menu
          v-if="item.menu"
          :key="index"
          :is-offset-x="true"
          :is-offset-y="false"
          :is-open-on-hover="false"
          :is-sub-menu="true"
          :menu-items="item.menu"
          :name="item.name"
          @sub-menu-click="emitClickEvent"
        />
        <v-list-item
          v-else
          :key="index"
          @click="emitClickEvent(item)"
        >
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>
<script>
import BaseButton from '@/components/Form/BaseButton';

export default {
  name: 'SubMenu',
  components: {
    BaseButton,
  },
  props: {
    name: { type: String, default: '' },
    menuItems: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    isOffsetX: { type: Boolean, default: false },
    isOffsetY: { type: Boolean, default: true },
    isOpenOnHover: { type: Boolean, default: false },
    isSubMenu: { type: Boolean, default: false },
  },
  data: () => ({
    openMenu: false,
  }),
  methods: {
    emitClickEvent(item) {
      this.$emit('sub-menu-click', item);
      this.openMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: var(--grayDark) !important;
  cursor: not-allowed;
  pointer-events: all !important;
}
</style>
