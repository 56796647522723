import auth from './auth';
import badge from './game_badge';
import breadcrumbs from './breadcrumbs';
import candidate from './candidate';
import client from './client';
import competence from './competence';
import competencyProfile from './competency_profile';
import competencyRating from './competency_rating';
import dashboard from './dashboard';
import managementAnalyst from './management_analyst';
import messageTemplate from './message_template';
import notifications from './notifications';
import notFound from './not_found';
import game from './game';
import gamePage from './game_page';
import gameForm from './game_form';
import stages from './game_stage';
import recruitment from './recruitment';
import user from './user';
import validation from './validation';
import workDepartment from './work_department';
import workPosition from './work_position';

export default {
  auth,
  badge,
  breadcrumbs,
  candidate,
  client,
  competence,
  competencyProfile,
  competencyRating,
  dashboard,
  managementAnalyst,
  messageTemplate,
  notifications,
  notFound,
  game,
  gamePage,
  gameForm,
  stages,
  recruitment,
  user,
  validation,
  workDepartment,
  workPosition,
};
