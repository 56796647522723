import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from './auth';
import candidate from './candidate';
import session from './session';
import layout from './layout';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    candidate,
    session,
    layout,
  },
  plugins: [createPersistedState({
    key: '313c-state',
    paths: [
      'auth', 'session', 'layout',
    ],
  })],
});
