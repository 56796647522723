export default {
  login: {
    title: 'Sukces',
    content: 'Zalogowano pomyślnie.',
  },
  edit_success: {
    title: 'Sukces',
    content: 'Poprawnie zapisano zmiany.',
  },
  confirm_modal: {
    title: 'Sukces',
    content: 'Akcja wykonana pomyślnie.',
  },
  code_401: {
    title: 'Twoja sesja wygasła',
    content: 'Twoje konto zostało wylogowane. Zaloguj się ponownie do systemu.',
  },
  code_403: {
    title: 'Brak dostępu',
    content: 'Nie masz dostępu do tej strony.',
  },
  copy_file: {
    title: 'Sukces',
    content: 'Link został skopiowany.',
  },
};
