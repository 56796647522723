import api from '@/plugins/axios/api';

/**
 * @param {String} name
 * @param {String} workPositionDisplayName
 * @param {String} workPositionId
 * @param {String} recruitmentProcessId
 * @param {Array} recruiters
 * @param {Array} stages
 * @param {String} reportType
 * @param {String} reportDescription
 * @param {String} note
 * @param {Boolean} isOpen
 */
const editRecruitmentProcess = async ({
  name,
  workPositionDisplayName,
  workPositionId,
  recruitmentProcessId,
  recruiters,
  stages,
  reportType,
  reportDescription,
  note,
  isOpen,
}) => {
  const { data: { data } } = await api.put(`recruitment-processes/${recruitmentProcessId}`, {
    name,
    work_position_display_name: workPositionDisplayName,
    work_position_id: workPositionId,
    recruiters_ids: recruiters,
    stages,
    report_type: reportType,
    report_description: reportDescription,
    note,
    is_open: isOpen,
  });

  return data;
};

export default editRecruitmentProcess;
