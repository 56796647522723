import api from '@/plugins/axios/api';

/**
 * @param {String} clientId
 * @param {Array} scoringScales
 * @param {String} locale
 */
const editScoringScales = async ({
  clientId,
  scoringScales,
  locale,
}) => {
  await api.put(`clients/${clientId}/scoring-scales`, {
    scoring_scales: scoringScales,
    locale,
  });
};

export default editScoringScales;
