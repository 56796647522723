import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 */
const deleteGame = async ({ gameId }) => {
  await api.delete(`games/${gameId}`);
};

export default deleteGame;
