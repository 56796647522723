<template>
  <v-menu
    offset-y
    elevation="2"
    transition="fade-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-card class="actions-list">
      <v-list-item
        :to="{ name: clientEditRoute, params: { clientId } }"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("dashboard.edit") }}</v-list-item-title>
      </v-list-item>
    </v-card>
  </v-menu>
</template>
<script>
import { CLIENT_EDIT } from '@/router/routes.names';

export default {
  name: 'ClientActions',
  props: {
    clientId: {
      type: String,
      required: true,
    },
  },
  computed: {
    clientEditRoute() {
      return CLIENT_EDIT;
    },
  },
};
</script>
