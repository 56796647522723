export default {
  title: 'Users',
  search: 'Search',
  name: 'First name and surname',
  email: 'E-mail',
  client: 'Client',
  type: 'Account type',
  status: 'Status',
  created_at: 'Created at',
  suspend: 'Block',
  unsuspend: 'Unblock',
  no_results: 'No users found',
  types: {
    recruiter: 'Recruiter',
    admin: 'Admin',
    super_admin: 'Super Admin',
  },
  statuses: {
    active: 'Active',
    blocked: 'Blocked',
  },
  add: 'Add User',
  edit: 'Edit User',
  create: {
    email: 'E-mail address',
    name: 'First name and surname',
    client: 'Client',
    type: 'Account type',
    recruitment_process: 'Recruitment process',
  },
};
