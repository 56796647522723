import api from '@/plugins/axios/api';

/**
 * @param {String} candidateId
 * @param {String} recruitmentProcessId
 */
const fetchCandidateCard = async ({
  candidateId,
  recruitmentProcessId,
}) => {
  const { data } = await api.get(`recruitment-processes/${recruitmentProcessId}/candidates/${candidateId}/card`);

  return data;
};

export default fetchCandidateCard;
