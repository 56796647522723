<template>
  <div>
    <div
      v-if="isLoading"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title>
          <v-row>
            <v-col
              class="px-3 px-lg-6"
              sm="12"
            >
              <p class="headline black--text mt-4">
                {{ editMode ? $t("user.edit") : $t("user.add") }}
              </p>
              <v-divider class="mt-3 mb-4" />
            </v-col>
          </v-row>
        </v-card-title>
        <div class="wrapper">
          <v-row class="px-3 px-lg-6">
            <v-col
              sm="12"
              md="10"
              lg="6"
            >
              <UserForm
                :edit-mode="editMode"
                :user="user"
                :role-types="roleTypes"
                :clients="clients"
                :recruitment-processes="recruitmentProcesses"
                :is-loading="isLoading"
                :is-recruitment-processes-fetching="isRecruitmentProcessesFetching"
                :is-sending="isSending"
                :backend-errors="errors"
                @handle-submit="submit"
                @handle-cancel="returnToList"
                @update-recruitment-processes="fetchRecruitmentProcesses"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alerts from '@/plugins/alerts';
import { HTTP_UNAUTHORIZED, HTTP_NOT_FOUND, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { NOT_FOUND, USER_LIST } from '@/router/routes.names';
import { ASC } from '@/components/Table/sort.types';
import addUser from '@/components/Dashboard/User/addUser';
import editUser from '@/components/Dashboard/User/editUser';
import fetchRecruitmentProcesses from '@/components/Dashboard/Recruitment/fetchRecruitmentProcesses';
import fetchClients from '@/components/Dashboard/Client/fetchClients';
import fetchUser from '@/components/Dashboard/User/fetchUser';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import UserForm from '@/components/Dashboard/User/Form';

export default {
  name: 'EditUser',
  components: {
    Preloader,
    UserForm,
  },
  data() {
    return {
      editMode: false,
      user: null,
      roleTypes: [
        {
          name: this.$t('user.types.super_admin'),
          value: 'SUPER_ADMIN',
          hasAccess: ['SUPER_ADMIN'],
        },
        {
          name: this.$t('user.types.admin'),
          value: 'ADMIN',
          hasAccess: ['SUPER_ADMIN', 'ADMIN'],
        },
        {
          name: this.$t('user.types.recruiter'),
          value: 'RECRUITER',
          hasAccess: ['SUPER_ADMIN', 'ADMIN'],
        },
      ],
      clients: [],
      recruitmentProcesses: [],
      isRecruitmentProcessesFetching: false,
      isLoading: false,
      isSending: false,
      errors: [],
    };
  },
  computed: {
    ...mapGetters('session', ['isSuperAdmin']),
  },
  async created() {
    this.editMode = this.$route.meta.editMode;
    try {
      this.isLoading = true;
      if (this.isSuperAdmin) {
        const clients = await fetchClients({
          sort: {
            column: 'name',
            direction: ASC,
          },
        });
        this.clients = clients.data;
      }
      await this.fetchRecruitmentProcesses();
      if (this.editMode) {
        this.user = await fetchUser({
          userId: this.$route.params.userId,
          includes: ['client', 'role', 'recruitment_processes'],
        });
      }
    } catch (e) {
      if (e.response?.status === HTTP_UNAUTHORIZED) return;
      if (e.response?.status === HTTP_NOT_FOUND) {
        this.$router.push({ name: NOT_FOUND });

        return;
      }
      if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
        this.errorAlert();

        return;
      }
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...alerts,
    returnToList() {
      this.$router.push({ name: USER_LIST });
    },
    async fetchRecruitmentProcesses(filters = {}) {
      this.isRecruitmentProcessesFetching = true;
      const { data } = await fetchRecruitmentProcesses({ limit: 99999, filters });
      this.recruitmentProcesses = data;
      this.isRecruitmentProcessesFetching = false;
    },
    async submit(payload) {
      try {
        this.errors = [];
        this.isSending = true;
        this.editMode ? await editUser(payload) : await addUser(payload);
        this.$notify.save.success();
        this.returnToList();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isSending = false;
      }
    },
  },
};
</script>
