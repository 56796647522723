import api from '@/plugins/axios/api';

/**
 * @param {String} recruitmentProcessId
 * @param {String} candidateId
 */
const fetchCandidateVideos = async ({
  recruitmentProcessId,
  candidateId,
}) => {
  const { data } = await api.get(`recruitment-processes/${recruitmentProcessId}/candidates/${candidateId}/records`);

  return { data: data.data };
};

export default fetchCandidateVideos;
