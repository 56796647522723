<template>
  <SubMenu
    :name="$t('candidate.group_actions')"
    :menu-items="menuItems"
    :disabled="hasSelectedRows"
    @sub-menu-click="menuItemClicked"
  />
</template>
<script>
import { translateStage } from '@/plugins/objectHelpers/candidateHelpers';
import SubMenu from '@/components/Dashboard/Partials/SubMenu/SubMenu';

export default {
  name: 'CandidateGroupActionsMenu',
  components: {
    SubMenu,
  },
  props: {
    recruitmentProcessStages: { type: Array, default: () => [] },
    hasSelectedRows: { type: Boolean, required: true },
  },
  computed: {
    menuItems() {
      const changeStagesBase = {
        name: this.$t('candidate.change_stages'),
        menu: {},
      };
      const sendMessageOption = {
        name: this.$t('candidate.send_message'),
        action: () => {
          this.$emit('send-group-message');
        },
      };
      const deleteGroupCandidatesOption = {
        name: this.$t('candidate.remove_candidates'),
        action: () => {
          this.$emit('delete-group-candidates');
        },
      };
      changeStagesBase.menu = this.recruitmentProcessStages.map((stage) => ({
        name: this.translateStage(stage),
        type: stage.type,
        action: () => {
          this.$emit('change-group-stage', stage);
        },
      }));

      return [changeStagesBase, sendMessageOption, deleteGroupCandidatesOption];
    },
  },
  methods: {
    translateStage,
    menuItemClicked(item) {
      if (item.action) {
        item.action();
      }
    },
  },
};
</script>
