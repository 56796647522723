import api from '@/plugins/axios/api';

/**
 * @param {String} recruitmentProcessId
 * @param {Array} recruitmentProcessTagsNames
 */
const assignTags = async ({
  recruitmentProcessId,
  recruitmentProcessTagsNames,
}) => {
  await api.post(`recruitment-processes/${recruitmentProcessId}/tags`, {
    recruitment_process_tags_names: recruitmentProcessTagsNames,
  });
};

export default assignTags;
