import { RECRUITER, SUPER_ADMIN } from '@/router/roles.names';
import { STATIC_PAGES_HELP_SCREEN, DEFAULT_LOCALE } from '@/names/clientOptions.names';

export default {
  permissions(store) {
    return store.permissions;
  },
  hasPermission: (store) => (permission) => store.permissions.includes(permission),
  isRecruiter(store) {
    return store.role === RECRUITER;
  },
  isSuperAdmin(store) {
    return store.role === SUPER_ADMIN;
  },
  role(store) {
    return store.role;
  },
  clientId(store) {
    return store.client ? store.client.id : null;
  },
  clientDefaultLocale(store) {
    return store.client ? store.client.options?.data.find((option) => option.key === DEFAULT_LOCALE).value : null;
  },
  user(store) {
    return store.user ? store.user : null;
  },
  currentRoute(store) {
    return store.currentRoute;
  },
  clientHasFaq(store) {
    return store.client.options?.data.find((option) => option.key === STATIC_PAGES_HELP_SCREEN) !== undefined && store.client.options?.data.find((option) => option.key === STATIC_PAGES_HELP_SCREEN)?.value !== null;
  },
  faqContent(store) {
    return store.client.options?.data.find((option) => option.key === STATIC_PAGES_HELP_SCREEN)?.compiled_value;
  },
};
