export default {
  title: 'Recruitment processes',
  search: 'Search',
  name: 'Name',
  work_position_display_name: 'Position displayed in the game',
  work_position: 'Position',
  games: 'Tools',
  recruiters: 'Recruiters',
  tags: 'Tags',
  created_at: 'Created at',
  candidates: 'Candidates',
  copy_link: 'Copy link',
  no_results: 'No recruitment processes found',
  add: 'Add recruitment process',
  edit: 'Edit the recruitment process',
  process_tools: 'Stages',
  types: {
    all: 'All',
    game: 'Game',
    recruitment_form: 'Recruitment form',
    recruitive: 'Recruitment game',
    selective: 'Selection game',
    preselection: 'Preselection',
    initial_interview: 'Initial interview',
    selection_interview: 'Selection interview',
    meet: 'Personal meeting',
    hiring: 'Employment',
    other: 'Other',
  },
  report_template_types: {
    basic: 'Basic report',
    full: 'Report with complete candidate data',
  },
  create: {
    name: 'Name of the recruitment process',
    work_position_display_name: 'Position displayed in the game (optional)',
    work_position: 'Position',
    recruiters: 'Recruiters',
    stages: 'Stages',
    stage_one: 'Stage 1 - tool',
    stage_two: 'Stage 2 - tool (optional)',
    other_stages: 'Other stages (optional)',
    report: 'Report',
    report_template: 'Report template',
    report_description: 'Description in the report',
    notes: 'Notes',
    is_open: 'Link active',
    is_closed: 'Link inactive',
    is_open_desc: 'Candidates can open the link',
    is_closed_desc: 'Candidates cannot open the link',
    registration_url: 'Link to the recruitment process',
  },
};
