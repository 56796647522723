export default {
  required: 'Wpisz %{name}',
  image_required: 'Wybierz obrazek',
  video_required: 'Wybierz plik video',
  extensions: 'Poprawny format pliku: %{exts}',
  select: 'Wybierz %{name}',
  min_length: 'Minimalna ilość znaków wynosi: %{length}',
  max_length: 'Maksymalna ilość znaków wynosi: %{length}',
  max_value: 'Maksymalna wartość musi wynosić %{value} lub mniej',
  min_value: 'Minimalna wartość musi wynosić %{value} lub więcej',
  scoring_incorrect: 'Niepoprawny format punktacji',
  max_file_size: 'Maksymalny rozmiar pliku wynosi %{size} mb',
  numeric: 'Niepoprawny format liczby znaków',
  search: 'Wpisz minimum %{length} znaki',
  email_required: 'Podaj adres e-mail',
  emails_required: 'Podaj adres lub adresy e-mail',
  email_incorrect: 'Nieprawidłowy format',
  emails_incorrect: 'Podane adresy e-mail są nieprawidłowe:',
  emails_end_characters: 'Niedozwolony znak na końcu listy',
  password_confirm: 'Hasło i ponownie wpisane hasło nie są takie same',
  password_incorrect: 'Hasło musi zawierać co najmniej 8 znaków, w tym co najmniej: 1 cyfrę, 1 małą literę, 1 wielką literę i 1 znak specjalny',
  time_limit: 'Niepoprawny format czasu',
  max_array_length: 'Maksymalna ilość elementów możliwych do wybrania wynosi: %{length}',
  url: 'Niepoprawny format adresu url',
  full_text_search: 'Wpisz minimum %{length} znaki (bez znaków specjalnych)',
  is_not: 'Pola nie mogą mieć takiej samej wartości',
};
