import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {Array} includes
 */
const fetchGame = async ({
  gameId,
  includes,
}) => {
  const { data } = await api.get(`games/${gameId}`, {
    params: { includes },
  });

  return data.data;
};

export default fetchGame;
