<template>
  <div>
    <div
      v-if="isPreloadingData"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title class="py-6">
          <div class="table-heading">
            <h5 class="table-heading__title text-h5">
              {{ $t("badge.title") }}
            </h5>
            <div class="table-heading__actions">
              <div class="table-heading__item">
                <BaseButton @click="goToAddBadge">
                  {{ $t("dashboard.add") }}
                  <v-icon right>
                    mdi-plus
                  </v-icon>
                </BaseButton>
              </div>
            </div>
          </div>
        </v-card-title>
        <div class="table-filters">
          <TableSearch
            is-full-text-search
            @input="fetchGameBadges"
          />
        </div>
        <v-divider />
        <BaseTable
          :table-headers="tableHeaders"
          :table-data="tableData"
          :is-loading="isLoading"
          :no-results-text="$t('badge.no_results')"
          :pagination="pagination"
          :default-sort="sort"
          @change-page="fetchGameBadges"
          @change-items-per-page="fetchGameBadges"
          @change-sort="fetchGameBadges"
        >
          <template v-slot:item="{ item }">
            <td>{{ item.body ? item.body : "-" }}</td>
            <td>{{ item.stage_order + 1 }}</td>
            <td>{{ item.stage_type ? $t(`badge.types.${item.stage_type.toLowerCase()}`) : "-" }}</td>
            <td>{{ item.description ? stringLimit(item.description, 180) : "-" }}</td>
            <td>{{ formatDate(item.created_at) }}</td>
            <td>
              <Actions
                :badge-id="item.id"
                @handle-delete="deleteBadge"
              />
            </td>
          </template>
        </BaseTable>
      </v-card>
    </div>
  </div>
</template>
<script>
import alerts from '@/plugins/alerts';
import { GAME_BADGE_ADD } from '@/router/routes.names';
import { HTTP_UNAUTHORIZED, HTTP_CONFLICT } from '@/plugins/axios/codes';
import { formatDate } from '@/plugins/dates/dates';
import { ASC } from '@/components/Table/sort.types';
import { stringLimit } from '@/plugins/stringHelpers/stringLimit';
import fetchGameBadges from '@/components/Dashboard/Badge/fetchGameBadges';
import deleteBadge from '@/components/Dashboard/Badge/deleteBadge';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import BaseButton from '@/components/Form/BaseButton';
import BaseTable from '@/components/Table/BaseTable';
import TableSearch from '@/components/Table/TableSearch';
import Actions from '@/components/Dashboard/Badge/Actions';

export default {
  name: 'BadgeList',
  components: {
    Preloader,
    BaseButton,
    BaseTable,
    TableSearch,
    Actions,
  },
  data() {
    return {
      tableHeaders: [
        {
          value: 'name',
          text: this.$t('badge.name'),
          sortable: false,
        },
        {
          value: 'stage_order',
          text: this.$t('badge.stage_number'),
          width: '100px',
        },
        {
          value: 'type',
          text: this.$t('badge.type'),
          sortable: false,
        },
        {
          value: 'content',
          text: this.$t('badge.content'),
          sortable: false,
        },
        {
          value: 'created_at',
          text: this.$t('badge.created_at'),
          width: '180px',
          sortable: false,
        },
        {
          value: 'actions',
          align: 'right',
          width: '50px',
          sortable: false,
        },
      ],
      tableData: [],
      pagination: null,
      isLoading: false,
      isPreloadingData: false,
      sort: {
        column: 'stage_order',
        direction: ASC,
      },
    };
  },
  async created() {
    this.isPreloadingData = true;
    await this.fetchGameBadges();
    this.isPreloadingData = false;
  },
  methods: {
    ...alerts,
    formatDate,
    stringLimit,
    goToAddBadge() {
      this.$router.push({ name: GAME_BADGE_ADD });
    },
    setParams(params = {}) {
      return {
        gameId: this.$route.params.gameId,
        limit: 99999,
        page: 1,
        includes: ['badgeable'],
        sort: this.sort,
        ...this.$route.query,
        ...params,
      };
    },
    async fetchGameBadges(params) {
      try {
        this.isLoading = true;
        const response = await fetchGameBadges(this.setParams(params));
        this.tableData = response.data;
        this.pagination = response.pagination;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteBadge({ badgeId }) {
      try {
        const { gameId } = this.$route.params;
        this.isLoading = true;
        await deleteBadge({ gameId, badgeId });
        const elementIndex = this.tableData.findIndex(((badge) => badge.id === badgeId));
        this.tableData.splice(elementIndex, 1);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_CONFLICT) {
          this.errorAlert({ text: e.response.data.message });

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
