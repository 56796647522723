export default {
  title: 'Klienci',
  search: 'Wyszukaj',
  logo: 'Logo',
  name: 'Nazwa',
  created_at: 'Data dodania',
  no_results: 'Nie znaleziono klientów',
  choose_client_scope: 'Wpisz nazwę klienta',
  ats_types: {
    e_recruiter: 'E-Recruiter',
    e_recruiter_marketplace: 'E-Recruiter - Marketplace',
    new_generation_recruitment: '313C',
    smart_recruiters: 'SmartRecruiters',
    hr_link: 'HRlink',
    hr_link_anonymous_version: 'HR Link - wersja anonimowa',
    sap: 'SAP',
  },
  add: 'Dodaj klienta',
  edit: 'Edytuj klienta',
  create: {
    name: 'Nazwa',
    website: 'Strona www',
    subdomain: 'Adres subdomeny',
    email: 'E-mail supportowy',
    email_desc: 'Adres subdomeny wykorzystywanej w narzędziach Klienta np. xxx.313c.com.pl',
    ats: 'ATS',
    ats_hint: 'Wybierz ATS',
    locale: 'Domyślny język raportu i dla nowych użytkowników',
    logo: 'Logotyp',
    favicon: 'Favicon',
    color: 'Kolor',
    color_hint: 'Wybierz kolor',
    report_description: 'Domyślny opis w raporcie',
    faq_content: 'Treść ekranu Pomoc / FAQ',
    variables: 'Znaczniki do wykorzystania',
    level: 'Poziom',
    score_to: 'Do ( w % )',
    game_session_reminder_after_time: 'Wyślij przypomnienie o grze po (w godzinach)',
    feedback_label: 'Etykieta feedback',
    empty_scoring_scales: 'Dodaj poziomy',
    notify_admins: 'Wysyłka maili z podsumowaniem do administratorów',
    notify_recruiters: 'Wysyłka maili z podsumowaniem do rekruterów',
  },
  basic_data: 'Dane podstawowe',
  help_faq: 'Pomoc / FAQ',
  scoring_scales: 'Zarządzanie skalą',
  scoring_scales_warning: 'UWAGA: Usunięcie poziomu (nawet jeśli następnie doda się nowy poziom) jest operacją nieodwracalną i skutkuje usunięciem opisów dla danego poziomu z wszystkich istniejących kompetencji.',
  default: 'Domyślnie',
  translations: 'Tłumaczenia',
};
