import store from '@/store';
import fetchClient from '@/components/Dashboard/Client/fetchClient';
import Dashboard from '@/layouts/DashboardLayout';
import candidateRoutes from '@/router/dashboard/_candidate';
import competenceRoutes from '@/router/dashboard/_competence';
import competencyProfileRoutes from '@/router/dashboard/_competency_profile';
import clientRoutes from '@/router/dashboard/_client';
import gameRoutes from '@/router/dashboard/_game';
import gameFormRoutes from '@/router/dashboard/_game_form';
import managementAnalystRoutes from '@/router/dashboard/_management_analyst';
import messageTemplateRoutes from '@/router/dashboard/_message_template';
import recruitmentRoutes from '@/router/dashboard/_recruitment';
import workPositionRoutes from '@/router/dashboard/_work_position';
import userRoutes from '@/router/dashboard/_user';
import {
  LOGIN,
  DASHBOARD,
  CLIENT_LIST,
  CANDIDATE_LIST,
} from '@/router/routes.names';
import { HTTP_UNAUTHORIZED } from '@/plugins/axios/codes';

const authenticated = () => store.getters['auth/isLogged'];

const dashboardRoutes = [
  {
    path: '/',
    component: Dashboard,
    name: DASHBOARD,
    beforeEnter: async (to, from, next) => {
      await store.dispatch('layout/startPreloadingData');
      try {
        if (!authenticated() && to.name === DASHBOARD) {
          next({ name: LOGIN });

          return;
        }
        await store.dispatch('session/setCurrentRoute', {
          currentRoute: to.fullPath,
        });
        await store.dispatch('session/fetchSession', {
          includes: ['acl', 'client_scope', 'client'],
        });
        const client = await fetchClient({
          clientId: store.getters['session/clientId'],
          includes: ['options'],
        });
        await store.dispatch('session/setClient', client);

        if (to.name === DASHBOARD) {
          next({ name: store.getters['session/isSuperAdmin'] ? CLIENT_LIST : CANDIDATE_LIST });

          return;
        }

        next();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) {
          next();

          return;
        }
        throw new Error(e);
      } finally {
        await store.dispatch('layout/stopPreloadingData');
      }
    },
    children: [
      ...candidateRoutes,
      ...competenceRoutes,
      ...competencyProfileRoutes,
      ...clientRoutes,
      ...gameRoutes,
      ...gameFormRoutes,
      ...managementAnalystRoutes,
      ...messageTemplateRoutes,
      ...recruitmentRoutes,
      ...workPositionRoutes,
      ...userRoutes,
    ],
  },
];

export default dashboardRoutes;
