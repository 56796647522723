<template>
  <BaseGameFormField
    :value="value"
    :field-types="fieldTypes"
    :is-loading="isLoading"
    :dragging="dragging"
    :has-invalid-fields="hasInvalidFields"
    @update-type="type => updateType({ type })"
    @toggle-open="() => updateValue({ isOpen: !value.isOpen })"
    v-on="$listeners"
  >
    <div class="pb-2">
      <BaseInput
        :value="value.name"
        rules="max:255|required"
        :label="$t('gameForm.field.name')"
        :vid="`${$t('gameForm.field.name')}-${uniqueKey}`"
        @input="value => updateValue({ name: value })"
        @validate-field="validateField"
      />
      <BaseInput
        :value="value.placeholder"
        rules="max:255"
        :label="$t('gameForm.field.hint')"
        :vid="`${$t('gameForm.field.hint')}-${uniqueKey}`"
        @input="value => updateValue({ placeholder: value })"
        @validate-field="validateField"
      />
      <BaseInput
        :value="value.tooltip"
        rules="max:255"
        :label="$t('gameForm.field.tooltip')"
        :vid="`${$t('gameForm.field.tooltip')}-${uniqueKey}`"
        @input="value => updateValue({ tooltip: value })"
        @validate-field="validateField"
      />
      <v-switch
        :input-value="value.required"
        class="v-input--reverse"
        @change="value => updateValue({ required: value })"
      >
        <template #label>
          {{ $t('gameForm.field.required') }}
        </template>
      </v-switch>
    </div>
  </BaseGameFormField>
</template>
<script>
import {
  props, variables, computed, methods,
} from '@/components/Dashboard/GameForm/Fields/Field.mixin';
import BaseGameFormField from '@/components/Dashboard/GameForm/BaseGameFormField';
import BaseInput from '@/components/Form/BaseInput';

const defaultState = {
  name: '',
  placeholder: '',
  tooltip: '',
  required: true,
};

export default {
  name: 'RichTextField',
  components: {
    BaseGameFormField,
    BaseInput,
  },
  mixins: [props, variables, computed, methods],
  created() {
    this.updateValue({
      ...defaultState,
      ...this.value,
    });
  },
};
</script>
