import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {Object} params
 */
const addBadge = async ({
  gameId,
  params,
}) => {
  await api.post(`games/${gameId}/game-badges`, params);
};

export default addBadge;
