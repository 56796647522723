import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {Array} stages
 */
const updateOrders = async ({
  gameId,
  stages,
}) => {
  await api.put(`games/${gameId}/game-stages/update-orders`, {
    stages,
  });
};

export default updateOrders;
