<template>
  <div>
    <div
      v-if="showButton"
      class="mt-1"
    >
      <BaseButton @click="copyToClipboard">
        {{ copyText }}
        <v-icon right>
          {{ copiedIcon }}
        </v-icon>
      </BaseButton>
    </div>
    <div
      v-else
      class="copy-text__text"
    >
      <div class="copy-text__slot">
        <div class="copy-text__container">
          <slot />
        </div>
      </div>
      <div
        v-if="content"
        class="copy-text__icon"
        @click="copyToClipboard"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              right
              v-on="on"
            >
              {{ copiedIcon }}
            </v-icon>
          </template>
          {{ copyText }}
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import copy from 'copy-to-clipboard';
import BaseButton from '@/components/Form/BaseButton';

export default {
  name: 'CopyText',
  components: {
    BaseButton,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    showButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCopyClicked: false,
    };
  },
  computed: {
    copiedIcon() {
      return this.isCopyClicked ? 'mdi-check' : 'mdi-content-copy';
    },
    copyText() {
      return this.isCopyClicked ? this.$t('dashboard.copied') : this.$t('dashboard.copy');
    },
  },
  methods: {
    copyToClipboard() {
      try {
        this.isCopyClicked = true;
        setTimeout(() => {
          this.isCopyClicked = false;
        }, 1500);
        copy(this.content);
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-text {
  &__text {
    display: flex;
    align-items: center;
    padding: 0.05rem 0;
    &:hover > .copy-text__icon {
      opacity: 1;
    }
  }
  &__slot {
    display: flex;
    flex-direction: column;
    max-width: 160px;
    @media (min-width: 1366px) {
      max-width: 250px;
    }
    @media (min-width: 1600px) {
      flex-direction: row;
      max-width: 100%;
    }
  }
  &__container {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &__icon {
    opacity: 0;
    display: inline-block;
    cursor: pointer;
    transition: 0.2s opacity;
  }
}
</style>
