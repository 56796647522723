<template>
  <div class="candidate-name-column">
    <CopyText :content="fullName">
      <span class="candidate-name-column__full-name">{{ formattedFullName }}</span>
    </CopyText>
    <CopyText :content="candidate.email">
      <span class="candidate-name-column__content">{{ formattedEmail }}</span>
    </CopyText>
    <CopyText :content="candidate.phone_number">
      <span class="candidate-name-column__content">{{ formattedPhoneNumber }}</span>
    </CopyText>
  </div>
</template>

<script>
import CopyText from '@/components/Form/CopyText';

export default {
  name: 'CandidateBasicDataColumn',
  components: {
    CopyText,
  },
  props: {
    candidate: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullName() {
      const { first_name: firstName, last_name: lastName } = this.candidate;

      return firstName || lastName ? `${firstName} ${lastName}` : null;
    },
    formattedFullName() {
      return this.fullName ? this.fullName : '-';
    },
    formattedFirstName() {
      return this.candidate.first_name ? this.candidate.first_name : '-';
    },
    formattedLastName() {
      return this.candidate.last_name ? this.candidate.last_name : '-';
    },
    formattedEmail() {
      return this.candidate.email ? this.candidate.email : '-';
    },
    formattedPhoneNumber() {
      return this.candidate.phone_number ? this.candidate.phone_number : '-';
    },
  },
};
</script>

<style lang="scss" scoped>
.candidate-name-column {
  padding: 0.6rem 0;

  &__full-name {
    font-weight: 500;
  }
  &__content {
    color: var(--grayLighter);
    font-size: 13px;
  }
}
</style>
