<template>
  <div class="add-edit-buttons">
    <v-row
      class="mt-2 mb-3"
      justify="center"
    >
      <v-col
        class="add-edit-buttons__item"
        lg="2"
      >
        <BaseButton
          outlined
          @click="cancel"
        >
          {{ $t("dashboard.cancel") }}
        </BaseButton>
      </v-col>
      <v-col
        class="add-edit-buttons__item"
        lg="2"
      >
        <BaseButton
          type="submit"
          :loading="isSending"
        >
          {{ editMode ? $t("dashboard.save") : $t("dashboard.add") }}
        </BaseButton>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import alerts from '@/plugins/alerts';
import BaseButton from '@/components/Form/BaseButton';

export default {
  name: 'EditButtons',
  components: {
    BaseButton,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    ...alerts,
    async cancel() {
      if (await this.cancelAlert()) {
        this.$emit('cancel');
      }
    },
    submit() {
      this.$emit('submit');
    },
  },
};
</script>
<style lang="scss" scoped>
.add-edit-buttons {
  z-index: 4;
  background: var(--white);
  box-shadow: 0px -4px 8px rgba(176, 190, 197, 0.24);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  &__item {
    max-width: 240px;
  }
}
</style>
