import i18n from '@/i18n';
import RouterOutlet from '@/components/Dashboard/Partials/RouterOutlet/RouterOutlet';
import Index from '@/views/Dashboard/CompetencyProfile/Index';
import Edit from '@/views/Dashboard/CompetencyProfile/Edit';
import {
  COMPETENCY_PROFILE_LIST,
  COMPETENCY_PROFILE_ADD,
  COMPETENCY_PROFILE_EDIT,
} from '@/router/routes.names';
import {
  COMPETENCY_PROFILE_INDEX,
  COMPETENCY_PROFILE_CREATE,
  COMPETENCY_PROFILE_UPDATE,
} from '@/router/permissions.names';
import { isAuthenticated } from '@/router/permissions';

const competencyProfileRoutes = [
  {
    path: '/competency-profiles',
    component: RouterOutlet,
    children: [
      {
        path: '',
        name: COMPETENCY_PROFILE_LIST,
        component: Index,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.competency_profile_list'),
              },
            ];
          },
          permissions: COMPETENCY_PROFILE_INDEX,
        },
      },
      {
        path: 'add',
        name: COMPETENCY_PROFILE_ADD,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.competency_profile_list'),
                to: { name: COMPETENCY_PROFILE_LIST },
              },
              {
                label: i18n.t('breadcrumbs.competency_profile_add'),
              },
            ];
          },
          permissions: COMPETENCY_PROFILE_CREATE,
          editMode: false,
        },
      },
      {
        path: ':competencyProfileId/edit',
        name: COMPETENCY_PROFILE_EDIT,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.competency_profile_list'),
                to: { name: COMPETENCY_PROFILE_LIST },
              },
              {
                label: i18n.t('breadcrumbs.competency_profile_edit'),
              },
            ];
          },
          permissions: COMPETENCY_PROFILE_UPDATE,
          editMode: true,
        },
      },
    ],
  },
];

export default competencyProfileRoutes;
