<template>
  <div
    v-if="isLoading"
    class="export-loader"
  >
    <v-progress-circular
      indeterminate
      color="primary"
      class="mb-6"
      :size="50"
      :width="3"
    />
    <h2>{{ titleText }}...</h2>
  </div>
</template>

<script>
export default {
  name: 'ExportLoader',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    titleText() {
      return this.title || this.$t('candidate.file_loading');
    },
  },
};
</script>

<style lang="scss" scoped>
.export-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
