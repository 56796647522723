export default {
  title: 'Competency profiles',
  search: 'Search',
  name: 'Name',
  competences_count: 'Competence no.',
  created_at: 'Creation date',
  no_results: 'No competency profiles found',
  add: 'Add a competency profile',
  edit: 'Edit competency profile',
  create: {
    name: 'Name of the competency profile',
    competences: 'Competences',
    competences_hint: 'Choose a competency',
  },
};
