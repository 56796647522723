import api from '@/plugins/axios/api';

/**
 * @param {String} name
 * @param {Array} competences
 */
const addCompetencyProfile = async ({
  name,
  competences,
}) => {
  await api.post('competency-profiles', {
    name,
    competencies_ids: competences,
  });
};

export default addCompetencyProfile;
