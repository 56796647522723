export default {
  title: 'Competences',
  search: 'Search',
  name: 'Competence',
  label: 'Label',
  created_at: 'Created at',
  no_results: 'Competency not found',
  add: 'Add competence',
  edit: 'Edit competence',
  create: {
    name: 'Name of the competence',
    description: 'Competency description',
    description_for_candidate: 'Competency description for Candidate',
    scoring_scales: 'Level description',
    development_indications: 'Development indications',
    scale: 'Level',
    label: 'Label',
  },
};
