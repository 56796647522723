import api from '@/plugins/axios/api';

/**
 * @param {Object} params
 * @param {String} clientId
 */
const editClient = async ({
  params,
  clientId,
}) => {
  await api.post(`clients/${clientId}`, params);
};

export default editClient;
