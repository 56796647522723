import i18n from '@/i18n';
import RouterOutlet from '@/components/Dashboard/Partials/RouterOutlet/RouterOutlet';
import Index from '@/views/Dashboard/Recruitment/Index';
import Edit from '@/views/Dashboard/Recruitment/Edit';
import { RECRUITMENT_PROCESS_LIST, RECRUITMENT_PROCESS_ADD, RECRUITMENT_PROCESS_EDIT } from '@/router/routes.names';
import { RECRUITMENT_PROCESS_INDEX, RECRUITMENT_PROCESS_CREATE, RECRUITMENT_PROCESS_UPDATE } from '@/router/permissions.names';
import { isAuthenticated } from '@/router/permissions';

const recruitmentRoutes = [
  {
    path: '/recruitments',
    component: RouterOutlet,
    children: [
      {
        path: '',
        name: RECRUITMENT_PROCESS_LIST,
        component: Index,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.recruitment_list'),
              },
            ];
          },
          permissions: RECRUITMENT_PROCESS_INDEX,
        },
      },
      {
        path: 'add',
        name: RECRUITMENT_PROCESS_ADD,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.recruitment_list'),
                to: { name: RECRUITMENT_PROCESS_LIST },
              },
              {
                label: i18n.t('breadcrumbs.recruitment_add'),
              },
            ];
          },
          permissions: RECRUITMENT_PROCESS_CREATE,
          editMode: false,
        },
      },
      {
        path: ':recruitmentProcessId/edit',
        name: RECRUITMENT_PROCESS_EDIT,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.recruitment_list'),
                to: { name: RECRUITMENT_PROCESS_LIST },
              },
              {
                label: i18n.t('breadcrumbs.recruitment_edit'),
              },
            ];
          },
          permissions: RECRUITMENT_PROCESS_UPDATE,
          editMode: true,
        },
      },
    ],
  },
];

export default recruitmentRoutes;
