export default {
  title: 'Forms',
  name: 'Name',
  client: 'Client',
  created_at: 'Created at',
  tags: 'Tags',
  manage_tags: 'Manage tags',
  no_results: 'No forms found',
  add: 'Add form',
  edit: 'Edit form',
  create: {
    header: 'Header',
    client: 'Client',
    client_hint: 'Select a client',
    fields_empty: 'Add fields to the form',
    add: 'Add field',
    field_type: 'Field type',
  },
  field_types: {
    text: 'Text field',
    textarea: 'Large text field (textarea)',
    select: 'Singleselect',
    multiselect: 'Multiselect',
    number: 'Number field',
    file: 'File',
    header: 'Header',
    checkbox: 'Checkbox',
    date: 'Calendar',
  },
  field: {
    name: 'Field name',
    hint: 'Tip (optional)',
    tooltip: 'Tooltip (optional)',
    required: 'Obligatory',
    description: 'Description',
    options: 'Options',
    step: 'Step',
    min: 'Min',
    max: 'Max',
  },
};
