import api from '@/plugins/axios/api';

/**
 * @param {?Object} sort
 * @param {?Object} filters
 * @param {?String} search
 * @param {?Number} page
 */
const fetchScoringScales = async ({
  sort,
  filters,
  search,
  page,
}) => {
  const { data: { data, meta } } = await api.get('scoring-scales', {
    params: {
      sort,
      filters,
      search,
      page,
    },
  });

  return { data, pagination: meta.pagination };
};

export default fetchScoringScales;
