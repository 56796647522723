<template>
  <div>
    <div
      v-if="isPreloadingData"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-3 px-lg-6 py-6"
    >
      <CandidateVideosForm :candidate-videos="candidateVideos" />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import alerts from '@/plugins/alerts';
import { NOT_FOUND } from '@/router/routes.names';
import {
  HTTP_NOT_FOUND,
  HTTP_UNAUTHORIZED,
  HTTP_UNPROCESSABLE_ENTITY,
} from '@/plugins/axios/codes';
import fetchCandidate from '@/components/Dashboard/Candidate/Api/fetchCandidate';
import fetchCandidateVideos from '@/components/Dashboard/CandidateVideos/fetchCandidateVideos';
import CandidateVideosForm from '@/components/Dashboard/CandidateVideos/Form';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';

export default {
  name: 'CandidateVideos',
  components: {
    Preloader,
    CandidateVideosForm,
  },
  data() {
    return {
      candidateVideos: null,
      isPreloadingData: false,
    };
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    ...mapActions('candidate', ['setCandidate']),
    ...alerts,
    async fetchData() {
      try {
        this.isPreloadingData = true;
        await Promise.all([this.fetchCandidate(), this.fetchCandidateVideos()]);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_NOT_FOUND) {
          this.$router.push({ name: NOT_FOUND });

          return;
        }
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isPreloadingData = false;
      }
    },
    async fetchCandidateVideos() {
      const { candidateId, recruitmentProcessId } = this.$route.params;
      const { data } = await fetchCandidateVideos({
        candidateId,
        recruitmentProcessId,
      });
      this.candidateVideos = data;
    },
    async fetchCandidate() {
      const { candidateId, recruitmentProcessId } = this.$route.params;
      const { data } = await fetchCandidate({
        recruitmentProcessId,
        candidateId,
      });
      this.setCandidate(data);
    },
  },
};
</script>
