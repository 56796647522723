import api from '@/plugins/axios/api';

/**
 * @param {String} name
 * @param {String} competencyProfileId
 * @param {String} workDepartmentId
 * @param {String} workPositionId
 */
const editWorkPosition = async ({
  name,
  workDepartmentId,
  competencyProfileId,
  workPositionId,
}) => {
  await api.put(`work-positions/${workPositionId}`, {
    name,
    work_department_id: workDepartmentId,
    competency_profile_id: competencyProfileId,
  });
};

export default editWorkPosition;
