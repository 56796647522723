import api from '@/plugins/axios/api';

/**
 * @param {String} recruitmentProcessId
 * @param {String} status
 * @param {String} stageId
 * @param {Array} candidateIds
 * @param {Object} customMessage
 */
const updateCandidateStage = async ({
  recruitmentProcessId,
  status,
  stageId,
  candidateIds,
  customMessage,
}) => {
  await api.put(`recruitment-processes/${recruitmentProcessId}/stages/update-candidates-stage`, {
    status,
    stage_id: stageId,
    candidate_ids: candidateIds,
    custom_message: customMessage,
  });
};

export default updateCandidateStage;
