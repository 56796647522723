import api from '@/plugins/axios/api';

/**
 * @param {String} candidateId
 * @param {String} recruitmentProcessId
 * @param {Array} candidateTagsNames
 */
const assignTags = async ({
  candidateId,
  recruitmentProcessId,
  candidateTagsNames,
}) => {
  await api.post(`recruitment-processes/${recruitmentProcessId}/candidates/${candidateId}/tags`, {
    candidate_tags_names: candidateTagsNames,
  });
};

export default assignTags;
