import api from '@/plugins/axios/api';

/**
 * @param {String} name
 * @param {Array} competences
 * @param {String} competencyProfileId
 */
const editCompetencyProfile = async ({
  name,
  competences,
  competencyProfileId,
}) => {
  await api.put(`competency-profiles/${competencyProfileId}`, {
    name,
    competencies_ids: competences,
  });
};

export default editCompetencyProfile;
