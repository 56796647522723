<template>
  <div>
    <div
      v-if="isPreloadingData"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title class="py-6">
          <div class="table-heading">
            <h5 class="table-heading__title text-h5">
              {{ $t("managementAnalyst.title") }}
            </h5>
            <div class="table-heading__actions">
              <div class="table-heading__item">
                <BaseButton
                  :disabled="!selectedGame || !tableData.length"
                  :loading="isExporting"
                  @click="exportResults"
                >
                  {{ $t("dashboard.export") }}
                  <v-icon right>
                    mdi-file-export
                  </v-icon>
                </BaseButton>
              </div>
            </div>
          </div>
        </v-card-title>
        <TableFilter
          v-model="activeFilters"
          @change-filter="fetchManagementAnalyst"
        >
          <template #default="{ updateFilter }">
            <div class="table-filters">
              <BaseSelect
                v-model="selectedGame"
                :items="games"
                :label="$t('managementAnalyst.game')"
                item-text="game_name"
                item-value="game_id"
                persistent-hint
                searchable
                :disabled="isLoading"
                @change="updateFilter('game_id', selectedGame)"
              />
              <BaseDatePicker
                v-model="selectedDate"
                :label="$t('managementAnalyst.dates_range')"
                :disabled="isLoading"
                range
                @change="updateFilter('created_at', selectedDate)"
              />
            </div>
          </template>
        </TableFilter>
        <v-divider />
        <BaseTable
          :table-headers="tableHeaders"
          :table-data="tableData"
          :is-loading="isLoading"
          :no-results-text="$t('managementAnalyst.no_results')"
          :pagination="pagination"
          :default-sort="sort"
          @change-page="fetchManagementAnalyst"
          @change-items-per-page="fetchManagementAnalyst"
          @change-sort="fetchManagementAnalyst"
        >
          <template v-slot:item="{ item }">
            <td>{{ item.recruitment_process_name ? item.recruitment_process_name : "-" }}</td>
            <td>{{ item.game_session_events_counters.data.candidates_invited }}</td>
            <td>{{ item.game_session_events_counters.data.candidates_started }}</td>
            <td>{{ item.game_session_events_counters.data.candidates_finished }}</td>
          </template>
        </BaseTable>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { ANALYTICS_EXPORT, MANAGEMENT_ANALYST_LIST } from '@/router/routes.names';
import { GAME_SESSION_EVENT_LOG_EXPORT_ANALYTICS_DATA } from '@/router/permissions.names';
import alerts from '@/plugins/alerts';
import { HTTP_UNAUTHORIZED, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { DESC } from '@/components/Table/sort.types';
import fetchAnalytics from '@/components/Dashboard/ManagementAnalyst/fetchAnalytics';
import fetchGameList from '@/components/Dashboard/ManagementAnalyst/fetchGameList';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import BaseButton from '@/components/Form/BaseButton';
import BaseSelect from '@/components/Form/BaseSelect';
import BaseDatePicker from '@/components/Form/BaseDatePicker';
import BaseTable from '@/components/Table/BaseTable';
import TableFilter from '@/components/Table/TableFilter';

export default {
  name: 'ManagementAnalystList',
  components: {
    Preloader,
    BaseButton,
    BaseSelect,
    BaseDatePicker,
    BaseTable,
    TableFilter,
  },
  data() {
    return {
      games: [],
      tableHeaders: [
        {
          value: 'name',
          text: this.$t('managementAnalyst.recruitment_process'),
          sortable: false,
        },
        {
          value: 'candidates_invited',
          text: this.$t('managementAnalyst.candidates_invited'),
          sortable: false,
          width: '220px',
        },
        {
          value: 'candidates_started',
          text: this.$t('managementAnalyst.candidates_started'),
          sortable: false,
          width: '220px',
        },
        {
          value: 'candidates_finished',
          text: this.$t('managementAnalyst.candidates_finished'),
          sortable: false,
          width: '220px',
        },
      ],
      tableData: [],
      pagination: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total: 0,
        total_pages: 1,
      },
      isLoading: false,
      isPreloadingData: false,
      isExporting: false,
      selectedGame: '',
      selectedDate: '',
      activeFilters: {
        game_id: null,
        client_id: null,
        created_at: null,
        test_filter: [],
      },
      sort: {
        column: 'created_at',
        direction: DESC,
      },
    };
  },
  computed: {
    ...mapGetters('session', ['clientId', 'hasPermission']),
    hasAccess() {
      return this.hasPermission(GAME_SESSION_EVENT_LOG_EXPORT_ANALYTICS_DATA);
    },
  },
  async created() {
    this.isPreloadingData = true;
    await this.fetchGameList();
    await this.setRouteFilters();
    this.isPreloadingData = false;
  },
  methods: {
    ...alerts,
    setParams(params = {}) {
      return {
        filters: {
          client_id: this.clientId,
        },
        sort: this.sort,
        ...this.$route.query,
        ...params,
      };
    },
    async fetchManagementAnalyst(params) {
      if (!this.$route.query.filters?.game_id) {
        return;
      }
      try {
        this.isLoading = true;
        const response = await fetchAnalytics(this.setParams(params));
        this.tableData = response.data;
        this.pagination = response.pagination;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.$router.replace({
            name: MANAGEMENT_ANALYST_LIST,
            query: {
              filters: [],
            },
          });
          this.selectedGame = '';

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchGameList() {
      try {
        this.isLoading = true;
        this.tableData = [];
        this.games = await fetchGameList();
        this.activeFilters.client_id = this.clientId;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async setRouteFilters() {
      if (this.$route.query.filters?.created_at) {
        const createdAtFilter = this.$route.query.filters.created_at;
        this.selectedDate = createdAtFilter;
        this.activeFilters.created_at = createdAtFilter;
      }
      if (this.$route.query.filters?.game_id) {
        const gameIdFilter = this.$route.query.filters.game_id;
        this.selectedGame = gameIdFilter;
        this.activeFilters.game_id = gameIdFilter;
        await this.fetchManagementAnalyst();
      }
    },
    async exportResults() {
      if (!this.$route.query.filters?.game_id) {
        return;
      }
      try {
        this.isExporting = true;
        const analyticsExportRoute = this.$router.resolve({
          name: ANALYTICS_EXPORT,
          query: this.setParams(),
        });
        window.open(analyticsExportRoute.href, '_blank');
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.$router.replace({
            name: MANAGEMENT_ANALYST_LIST,
            query: {
              filters: [],
            },
          });
          this.selectedGame = '';

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isExporting = false;
      }
    },
  },
};
</script>
