<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <BaseRichTextEditor
          v-model="formData.content"
          rules="min:3|max:60000"
          :label="$t('client.create.faq_content')"
          :variables-list="variablesList"
          extended-version
        />
        <div class="pt-2 pb-12">
          <p>{{ $t('client.create.variables') }}:</p>
          <span
            v-for="(variableName, index) in variablesList"
            :key="`list-item-${index}`"
            class="d-block subtitle-2"
          >
            {{ variableName }}
          </span>
        </div>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import { HAS_FORM_CHANGES } from '@/names/emits.names';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseRichTextEditor from '@/components/Form/BaseRichTextEditor';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import { STATIC_PAGES_HELP_SCREEN } from '@/names/clientOptions.names';

export default {
  name: 'FaqForm',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseRichTextEditor,
  },
  props: {
    client: {
      type: Object,
      default: () => {},
    },
    variablesList: {
      type: Array,
      default: () => [],
    },
    backendErrors: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        content: '',
      },
    };
  },
  watch: {
    formData: {
      handler() {
        this.checkFormChanges();
      },
      deep: true,
    },
  },
  created() {
    if (this.editMode) {
      this.formData.content = this.client.options?.data.find((option) => option.key === STATIC_PAGES_HELP_SCREEN)?.value;
    }
  },
  methods: {
    cancel() {
      this.$emit('handle-cancel');
    },
    async checkFormChanges() {
      const { flags: { changed } } = await this.$refs.form.validateWithInfo();
      this.$emit(HAS_FORM_CHANGES, changed);
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const { clientId } = this.$route.params;

      return { clientId, params: { content: this.formData.content } };
    },
  },
};
</script>
