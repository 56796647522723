<template>
  <v-menu
    v-model="isOpen"
    offset-y
    elevation="2"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <div
        class="candidate-stage-select-label"
        v-on="on"
      >
        <span>{{ translateStage(selectedStage) }}</span>
        <div class="candidate-stage-select-arrow">
          <div class="candidate-stage-select-arrow__icon" />
        </div>
      </div>
    </template>
    <v-card>
      <v-list dense>
        <v-card-title class="candidate-stage-select-menu">
          <div class="candidate-stage-select-menu__title">
            <span>{{ $t("candidate.change_stage") }}</span>
            <v-icon class="candidate-stage-select-menu__close pl-2">
              mdi-close
            </v-icon>
          </div>
        </v-card-title>
        <v-list-item
          v-for="stage in recruitmentProcessStages"
          :key="stage.id"
          class="candidate-stage-select-list-item"
          :class="{ 'candidate-stage-select-list-item--current': stage.id === selectedStage.id }"
          @click="select(stage)"
        >
          <v-list-item-title>
            {{ translateStage(stage) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
import alerts from '@/plugins/alerts';
import { translateStage } from '@/plugins/objectHelpers/candidateHelpers';

export default {
  name: 'CandidateStageSelect',
  props: {
    recruitmentProcessStages: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {},
    },
    candidateId: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    selectedStage() {
      return this.recruitmentProcessStages.find((stage) => stage.id === this.value.data.id);
    },
  },
  methods: {
    ...alerts,
    translateStage,
    select(option) {
      this.$emit('input', option);
    },
  },
};
</script>
<style lang="scss" scoped>
.candidate-stage-select {
  &-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: var(--grayLight);
    background: var(--grayLightest);
    border-radius: 17px;
    padding: 2px 4px;
    cursor: pointer;
    span {
      padding: 0 4px;
    }
  }
  &-menu {
    padding: 4px 8px 8px 16px !important;
    &__title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px !important;
      font-weight: 600;
      color: #263238;
    }
    &__close {
      cursor: pointer;
    }
  }
  &-list-item {
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #263238 !important;
    &:hover {
      color: #fcaf3b !important;
      background: rgba(252, 175, 59, 0.03) !important;
    }
    &--current {
      color: #fcaf3b !important;
      background-color: rgba(252, 175, 59, 0.12) !important;
    }
  }
  &-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #fff;
    min-width: 14px;
    min-height: 14px;
    &__icon {
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 5px solid #737373;
    }
  }
}
</style>
