import api from '@/plugins/axios/api';
import * as names from '@/store/auth/mutations.names';

export default {
  async login({ commit }, payload) {
    await api.post('login', payload);

    commit(names.SET_USER, {});
  },
  async logout({ commit }) {
    await api.post('logout');
    commit(names.CLEAR_AUTH);
  },
  async refreshToken() {
    await api.get('refresh');
  },
  async forgetPassword(state, payload) {
    await api.post('password/token', payload);
  },
  async resetPassword(state, payload) {
    await api.post('password/reset', payload);
  },
  clearAuth({ commit }) {
    commit(names.CLEAR_AUTH);
  },
};
