import api from '@/plugins/axios/api';

/**
 * @param {String} locale
 */
const editUserLocale = async ({
  locale,
}) => {
  await api.put('users/locale', {
    locale,
  });
};

export default editUserLocale;
