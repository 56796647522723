<template>
  <div class="competence-edit__container">
    <div
      v-if="isPreloading"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title>
          <v-row>
            <v-col
              class="px-3 px-lg-6"
              sm="12"
            >
              <v-layout
                pt-3
                flex
                justify-start
              >
                {{ editMode ? $t("competence.edit") : $t("competence.add") }}
              </v-layout>
            </v-col>
          </v-row>
        </v-card-title>
        <v-row class="px-3 px-lg-6">
          <v-col>
            <CompetenceForm
              class="px-3 px-lg-6"
              :locale="locale"
              :edit-mode="editMode"
              :competence="competence"
              :scoring-scales="scoringScales"
              :is-loading="isLoading"
              :is-sending="isSending"
              :backend-errors="errors"
              @handle-submit="submit"
              @handle-cancel="returnToList"
              @update-locale="updateLocale"
            />
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alerts from '@/plugins/alerts';
import {
  HTTP_UNAUTHORIZED,
  HTTP_NOT_FOUND,
  HTTP_UNPROCESSABLE_ENTITY,
} from '@/plugins/axios/codes';
import { NOT_FOUND, COMPETENCE_LIST } from '@/router/routes.names';
import { ASC } from '@/components/Table/sort.types';
import fetchCompetence from '@/components/Dashboard/Competence/fetchCompetence';
import fetchScoringScales from '@/components/Dashboard/ScoringScale/fetchScoringScales';
import addCompetence from '@/components/Dashboard/Competence/addCompetence';
import editCompetence from '@/components/Dashboard/Competence/editCompetence';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import CompetenceForm from '@/components/Dashboard/Competence/Form';

export default {
  name: 'EditCompetence',
  components: {
    Preloader,
    CompetenceForm,
  },
  data() {
    return {
      locale: null,
      editMode: false,
      competence: null,
      scoringScales: [],
      isPreloading: false,
      isLoading: false,
      isSending: false,
      errors: [],
    };
  },
  computed: {
    ...mapGetters('session', ['clientDefaultLocale']),
  },
  async created() {
    this.isPreloading = true;
    this.locale = this.clientDefaultLocale.toLowerCase();
    this.editMode = this.$route.meta.editMode;
    await this.fetchCompetence();
    this.isPreloading = false;
  },
  methods: {
    ...alerts,
    returnToList() {
      this.$router.push({ name: COMPETENCE_LIST });
    },
    async fetchCompetence() {
      try {
        this.isLoading = true;
        const scoringScales = await fetchScoringScales({
          limit: 99999,
          sort: {
            column: 'percentage_from',
            direction: ASC,
          },
        });
        this.scoringScales = scoringScales.data;
        if (this.editMode) {
          this.competence = await fetchCompetence({
            competenceId: this.$route.params.competenceId,
            includes: ['scoring_descriptions', 'scoring_descriptions.scoring_scale'],
            locale: this.locale,
          });
        }
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_NOT_FOUND) {
          this.$router.push({ name: NOT_FOUND });

          return;
        }
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async submit(payload) {
      try {
        this.errors = [];
        this.isSending = true;
        this.editMode
          ? await editCompetence({
            ...payload,
            locale: this.locale,
          })
          : await addCompetence({
            ...payload,
            locale: this.locale,
          });
        this.$notify.save.success();
        if (!this.editMode) {
          this.returnToList();
        }
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isSending = false;
      }
    },
    async updateLocale(newLocale) {
      this.locale = newLocale;
      await this.fetchCompetence();
    },
  },
};
</script>
<style lang="scss" scoped>
.competence-edit {
  &__container {
    padding-bottom: 120px;
  }
}
</style>
