<template>
  <div class="advanced-filters">
    <button
      class="advanced-filters__button"
      @click="toggleCandidateSidebar"
    >
      <span class="mr-2">
        {{
          hasActiveAdvancedFilters ? $t("candidate.edit_filters") : $t("candidate.advanced_filters")
        }}
      </span>
      <v-icon color="primary">
        {{ hasActiveAdvancedFilters ? "mdi-pencil" : "mdi-filter" }}
      </v-icon>
    </button>
    <button
      v-if="hasActiveAdvancedFilters"
      class="advanced-filters__button"
      @click="clearAdvancedFilters"
    >
      <span class="mr-2">
        {{ $t("candidate.reset_filters") }}
      </span>
      <v-icon color="primary">
        mdi-filter-remove
      </v-icon>
    </button>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AdvancedFiltersActions',
  data() {
    return {
      hasActiveAdvancedFilters: false,
    };
  },
  computed: {
    ...mapState('layout', ['isCandidateSidebarActive']),
  },
  watch: {
    '$route.query.filters': {
      handler(newVal) {
        if (!newVal?.form_fields) {
          this.hasActiveAdvancedFilters = false;

          return;
        }
        this.hasActiveAdvancedFilters = !!Object.keys(newVal?.form_fields).length;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('layout', ['toggleCandidateSidebar']),
    clearAdvancedFilters() {
      this.$router.replace(
        {
          query: {
            ...this.$route.query,
            filters: {
              ...this.$route.query?.filters,
              form_fields: {},
            },
          },
        },
        () => {},
      );
      this.$emit('update-table-data');
      this.$emit('refresh-sidebar-filters');
    },
  },
};
</script>
<style lang="scss" scoped>
.advanced-filters {
  display: flex;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-left: 10px;
    color: var(--primary);
    font-size: 16px;
    min-width: 140px;
  }
}
</style>
