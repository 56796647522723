import api from '@/plugins/axios/api';

/**
 * @param {String} userId
 */
const deleteUser = async ({
  userId,
}) => {
  await api.delete(`users/${userId}`);
};

export default deleteUser;
