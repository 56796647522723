import i18n from '@/i18n';
import RouterOutlet from '@/components/Dashboard/Partials/RouterOutlet/RouterOutlet';
import Index from '@/views/Dashboard/Client/Index';
import Edit from '@/views/Dashboard/Client/Edit';
import { CLIENT_LIST, CLIENT_ADD, CLIENT_EDIT } from '@/router/routes.names';
import { CLIENT_INDEX, CLIENT_CREATE, CLIENT_UPDATE } from '@/router/permissions.names';
import { isAuthenticated } from '@/router/permissions';

const clientRoutes = [
  {
    path: '/clients',
    component: RouterOutlet,
    children: [
      {
        path: '',
        name: CLIENT_LIST,
        component: Index,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.client_list'),
              },
            ];
          },
          permissions: CLIENT_INDEX,
        },
      },
      {
        path: 'add',
        name: CLIENT_ADD,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.client_list'),
                to: { name: CLIENT_LIST },
              },
              {
                label: i18n.t('breadcrumbs.client_add'),
              },
            ];
          },
          permissions: CLIENT_CREATE,
          editMode: false,
        },
      },
      {
        path: ':clientId/edit',
        name: CLIENT_EDIT,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.client_list'),
                to: { name: CLIENT_LIST },
              },
              {
                label: i18n.t('breadcrumbs.client_edit'),
              },
            ];
          },
          permissions: CLIENT_UPDATE,
          editMode: true,
        },
      },
    ],
  },
];

export default clientRoutes;
