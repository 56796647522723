import api from '@/plugins/axios/api';

/**
 * @param {Object} params
 */
const uploadImage = async ({
  params,
}) => {
  const { data: { data } } = await api.post('message-templates/upload-image', params);

  return data;
};

export default uploadImage;
