import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {String} stageId
 * @param {Array} includes
 */
const fetchGameStage = async ({
  gameId,
  stageId,
  includes,
}) => {
  const { data } = await api.get(`games/${gameId}/game-stages/${stageId}`, {
    params: {
      includes,
    },
  });

  return data.data;
};

export default fetchGameStage;
