<template>
  <div>
    <span
      v-for="(item, index) in items"
      :key="`list-item-${index}`"
    >
      <v-tooltip
        v-if="item[itemKey].length > charactersLimit"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            class="mr-2"
            small
            v-bind="attrs"
            v-on="on"
          >
            {{ stringLimit(item[itemKey], charactersLimit) }}
          </v-chip>
        </template>
        {{ item[itemKey] }}
      </v-tooltip>
      <v-chip
        v-else
        class="mr-2"
        small
      >
        {{ stringLimit(item[itemKey], charactersLimit) }}
      </v-chip>
    </span>
  </div>
</template>
<script>
import { stringLimit } from '@/plugins/stringHelpers/stringLimit';

export default {
  name: 'RecruitmentStagesTags',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      charactersLimit: 40,
    };
  },
  methods: {
    stringLimit,
  },
};
</script>
<style lang="scss" scoped>
.v-chip__content {
  font-weight: 500;
  color: #263238 !important;
}
</style>
