<template>
  <div class="base-header body-2 px-5 pt-6 pb-4">
    {{ label }}
  </div>
</template>
<script>
export default {
  name: 'HeaderField',
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.base-header {
  color:  #737373;
}
</style>
