<template>
  <div>
    <div
      v-if="isLoading"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title>
          <v-row>
            <v-col
              class="px-3 px-lg-6"
              sm="12"
            >
              <p class="headline black--text mt-4">
                {{ editMode ? $t('game.edit') : $t("game.add") }}
              </p>
              <v-divider class="mt-3 mb-4" />
            </v-col>
          </v-row>
        </v-card-title>
        <div class="wrapper">
          <v-row class="px-3 px-lg-6">
            <v-col
              sm="12"
              lg="10"
            >
              <GameForm
                :edit-mode="editMode"
                :game="game"
                :collection-games="collectionGames"
                :game-types="gameTypes"
                :locales="locales"
                :clients="clients"
                :message-templates="messageTemplates"
                :game-forms="gameForms"
                :is-loading="isLoading"
                :is-selected-client-data-loading="isSelectedClientDataLoading"
                :is-sending="isSending"
                :backend-errors="errors"
                @handle-submit="submit"
                @handle-cancel="returnToList"
                @fetch-selected-client-data="fetchSelectedClientData"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import alerts from '@/plugins/alerts';
import { HTTP_UNAUTHORIZED, HTTP_NOT_FOUND, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { NOT_FOUND, GAME_LIST } from '@/router/routes.names';
import { SELECTIVE, RECRUITIVE, RECRUITMENT_FORM } from '@/names/gameTypes.names';
import { ASC } from '@/components/Table/sort.types';
import addGame from '@/components/Dashboard/Game/addGame';
import editGame from '@/components/Dashboard/Game/editGame';
import fetchClients from '@/components/Dashboard/Client/fetchClients';
import fetchMessageTemplates from '@/components/Dashboard/MessageTemplate/fetchMessageTemplates';
import fetchGameForms from '@/components/Dashboard/GameForm/fetchGameForms';
import fetchGames from '@/components/Dashboard/Game/fetchGames';
import fetchGame from '@/components/Dashboard/Game/fetchGame';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import GameForm from '@/components/Dashboard/Game/Form';

export default {
  name: 'EditGame',
  components: {
    Preloader,
    GameForm,
  },
  data() {
    return {
      editMode: false,
      collectionGames: [],
      game: null,
      gameTypes: [
        {
          name: this.$t('game.types.selective'),
          value: SELECTIVE,
        },
        {
          name: this.$t('game.types.recruitive'),
          value: RECRUITIVE,
        },
        {
          name: this.$t('game.types.recruitment_form'),
          value: RECRUITMENT_FORM,
        },
      ],
      locales: [
        {
          name: this.$t('game.locales.pl'),
          value: 'PL',
        },
        {
          name: this.$t('game.locales.en'),
          value: 'EN',
        },
        {
          name: this.$t('game.locales.cs'),
          value: 'CS',
        },
        {
          name: this.$t('game.locales.de'),
          value: 'DE',
        },
        {
          name: this.$t('game.locales.fr'),
          value: 'FR',
        },
        {
          name: this.$t('game.locales.hu'),
          value: 'HU',
        },
        {
          name: this.$t('game.locales.it'),
          value: 'IT',
        },
        {
          name: this.$t('game.locales.nl'),
          value: 'NL',
        },
        {
          name: this.$t('game.locales.ro'),
          value: 'RO',
        },
        {
          name: this.$t('game.locales.sk'),
          value: 'SK',
        },
        {
          name: this.$t('game.locales.hr'),
          value: 'HR',
        },
        {
          name: this.$t('game.locales.pt'),
          value: 'PT',
        },
        {
          name: this.$t('game.locales.sl'),
          value: 'SL',
        },
        {
          name: this.$t('game.locales.uk'),
          value: 'UK',
        },
      ],
      clients: [],
      messageTemplates: [],
      gameForms: [],
      isLoading: false,
      isSelectedClientDataLoading: false,
      isSending: false,
      errors: [],
    };
  },
  async created() {
    this.editMode = this.$route.meta.editMode;
    try {
      this.isLoading = true;
      const clients = await fetchClients({
        sort: {
          column: 'name',
          direction: ASC,
        },
      });
      this.clients = clients.data;
      if (this.editMode) {
        this.game = await fetchGame({
          gameId: this.$route.params.gameId,
          includes: ['client', 'options', 'message_templates', 'form', 'collections.games'],
        });
        await this.fetchSelectedClientData(this.game.client.data.id);
      }
    } catch (e) {
      if (e.response?.status === HTTP_UNAUTHORIZED) return;
      if (e.response?.status === HTTP_NOT_FOUND) {
        this.$router.push({ name: NOT_FOUND });

        return;
      }
      if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
        this.errorAlert();

        return;
      }
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...alerts,
    returnToList() {
      this.$router.push({ name: GAME_LIST });
    },
    async fetchSelectedClientData(clientId) {
      this.isSelectedClientDataLoading = true;
      await Promise.all([
        this.fetchMessageTemplates(clientId),
        this.fetchGameForms(clientId),
        this.fetchCollectionGames(clientId),
      ]);
      this.isSelectedClientDataLoading = false;
    },
    async fetchMessageTemplates(clientId) {
      try {
        const { data } = await fetchMessageTemplates({
          limit: 99999,
          filters: {
            client_id: clientId,
          },
          sort: {
            column: 'name',
            direction: ASC,
          },
        });
        this.messageTemplates = data;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      }
    },
    async fetchGameForms(clientId) {
      try {
        const { data } = await fetchGameForms({
          limit: 99999,
          filters: {
            client_id: clientId,
          },
          sort: {
            column: 'name',
            direction: ASC,
          },
        });
        this.gameForms = data;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      }
    },
    async fetchCollectionGames(clientId) {
      try {
        const { data } = await fetchGames({
          limit: 99999,
          filters: {
            client_id: clientId,
          },
          sort: {
            column: 'name',
            direction: ASC,
          },
        });
        if (!data) return;
        this.collectionGames = data.filter((game) => game.id !== this.$route.params.gameId);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      }
    },
    async submit(payload) {
      try {
        this.errors = [];
        this.isSending = true;
        this.editMode ? await editGame(payload) : await addGame(payload);
        this.$notify.save.success();
        this.returnToList();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isSending = false;
      }
    },
  },
};
</script>
