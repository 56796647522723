import api from '@/plugins/axios/api';

/**
 * @param {String} candidateId
 * @param {String} recruitmentProcessId
 * @param {Array} ratings
 */
const editCompetencyRating = async ({
  candidateId,
  recruitmentProcessId,
  ratings,
}) => {
  await api.post(`recruitment-processes/${recruitmentProcessId}/candidates/${candidateId}/ratings`, {
    ratings,
  });
};

export default editCompetencyRating;
