<template>
  <notifications
    class="notifications mb-4 mr-2"
    :position="position"
    :duration="duration"
  >
    <template
      slot="body"
      slot-scope="{ item }"
    >
      <v-alert
        :value="true"
        :type="item.type"
        class="my-0 pr-5"
      >
        <div v-if="item.title">
          <strong>{{ item.title }}</strong>
        </div>

        <div v-if="item.text">
          {{ item.text }}
        </div>
      </v-alert>
    </template>
  </notifications>
</template>
<script>
export default {
  name: 'Notification',
  data() {
    return {
      duration: 8000,
      position: 'bottom right',
    };
  },
};
</script>
<style lang="scss">
.notifications {
  max-width: 420px;
  width: 100% !important;
  .vue-notification-wrapper {
    margin-bottom: 14px;
    border-radius: 4px;
    .info {
      background: var(--primary) !important;
      border-left-color: var(--primary) !important;
    }
  }
}
</style>
