import api from '@/plugins/axios/api';

/**
 * @param {Object} params
 * @param {String} clientId
 */
const patchFaq = async ({
  params,
  clientId,
}) => {
  await api.patch(`clients/${clientId}/help-screen`, params);
};

export default patchFaq;
