import api from '@/plugins/axios/api';

/**
 * @param {String} name
 * @param {String} description
 * @param {String} descriptionForCandidate
 * @param {Array} scoringDescriptions
 * @param {String} locale
 * @param {String} label
 */
const addCompetence = async ({
  name,
  description,
  descriptionForCandidate,
  scoringDescriptions,
  locale,
  label,
}) => {
  await api.post('competences', {
    name,
    description,
    description_for_candidate: descriptionForCandidate,
    scoring_descriptions: scoringDescriptions,
    locale,
    label,
  });
};

export default addCompetence;
