<template>
  <v-menu
    v-model="isMenuOpen"
    offset-y
    origin="center center"
    elevation="2"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-card class="actions-list">
      <v-list-item @click.stop="openTagsModal">
        <v-list-item-icon>
          <v-icon>mdi-tag</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("candidate.manage_tags") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        :to="{ name: candidateListRoute, query: { recruitment_process_id: recruitmentProcessId } }"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-account-supervisor</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("recruitment.candidates") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isOpen"
        @click="handleCopyRegistrationUrl"
      >
        <v-list-item-icon>
          <v-icon>mdi-content-copy</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("recruitment.copy_link") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        :to="{ name: recruitmentProcessEditRoute, params: { recruitmentProcessId } }"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("dashboard.edit") }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="handleDelete">
        <v-list-item-icon>
          <v-icon>mdi-delete</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("dashboard.remove") }}</v-list-item-title>
      </v-list-item>
    </v-card>
    <AssignTagsModal
      v-model="isModalOpen"
      :recruitment-process-id="recruitmentProcessId"
      @refetch-data="refetchData"
    />
  </v-menu>
</template>
<script>
import alerts from '@/plugins/alerts';
import { HTTP_UNAUTHORIZED } from '@/plugins/axios/codes';
import { CANDIDATE_LIST, RECRUITMENT_PROCESS_EDIT } from '@/router/routes.names';
import AssignTagsModal from '@/components/Dashboard/Recruitment/AssignTagsModal';

export default {
  name: 'RecruitmentProcessActions',
  components: {
    AssignTagsModal,
  },
  props: {
    recruitmentProcessId: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    registrationUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isMenuOpen: false,
      isModalOpen: false,
    };
  },
  computed: {
    candidateListRoute() {
      return CANDIDATE_LIST;
    },
    recruitmentProcessEditRoute() {
      return RECRUITMENT_PROCESS_EDIT;
    },
  },
  methods: {
    ...alerts,
    async handleDelete() {
      const { recruitmentProcessId } = this;
      if (await this.removeAlert()) {
        this.$emit('handle-delete', { recruitmentProcessId });
      }
    },
    async handleCopyRegistrationUrl() {
      try {
        await navigator.clipboard.writeText(this.registrationUrl);
        this.$notify.success(
          this.$t('notifications.copy_file.content'),
          this.$t('notifications.copy_file.title'),
        );
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      }
    },
    openTagsModal() {
      this.isModalOpen = true;
      this.closeMenu();
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    refetchData() {
      this.$emit('refetch-data');
    },
  },
};
</script>
