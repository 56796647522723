import i18n from '@/i18n';
import RouterOutlet from '@/components/Dashboard/Partials/RouterOutlet/RouterOutlet';
import Index from '@/views/Dashboard/GameForm/Index';
import Edit from '@/views/Dashboard/GameForm/Edit';
import {
  GAME_FORM_LIST,
  GAME_FORM_ADD,
  GAME_FORM_EDIT,
} from '@/router/routes.names';
import {
  GAME_FORM_INDEX,
  GAME_FORM_CREATE,
  GAME_FORM_UPDATE,
} from '@/router/permissions.names';
import { isAuthenticated } from '@/router/permissions';

const gameFormRoutes = [
  {
    path: '/game-forms',
    component: RouterOutlet,
    children: [
      {
        path: '',
        name: GAME_FORM_LIST,
        component: Index,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.game_form_list'),
              },
            ];
          },
          permissions: GAME_FORM_INDEX,
        },
      },
      {
        path: 'add',
        name: GAME_FORM_ADD,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.game_form_list'),
                to: { name: GAME_FORM_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_form_add'),
              },
            ];
          },
          permissions: GAME_FORM_CREATE,
          editMode: false,
        },
      },
      {
        path: ':gameFormId/edit',
        name: GAME_FORM_EDIT,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.game_form_list'),
                to: { name: GAME_FORM_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_form_edit'),
              },
            ];
          },
          permissions: GAME_FORM_UPDATE,
          editMode: true,
        },
      },
    ],
  },
];

export default gameFormRoutes;
