import api from '@/plugins/axios/api';

/**
 * @param {String} candidateId
 * @param {String} recruitmentProcessId
 */
const candidateReport = async ({ candidateId, recruitmentProcessId }) => {
  const response = await api.get(
    `recruitment-processes/${recruitmentProcessId}/candidates/${candidateId}/report`,
    {
      responseType: 'blob',
    },
  );

  return response;
};

export default candidateReport;
