<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <div class="forget-form d-flex">
      <template v-if="!isSent">
        <div class="d-flex flex-column w-full">
          <div class="mt-16 mb-10 mx-3">
            <p class="headline mb-2 black--text">
              {{ $t("auth.forget.title") }}
            </p>
            <p class="subheading">
              {{ $t("auth.forget.subtitle") }}
            </p>
          </div>
          <v-form
            class="mx-3 d-flex flex-column justify-space-between"
            style="height: 100%"
            @submit.prevent="handleSubmit(submit)"
          >
            <div>
              <BaseInput
                v-model="email"
                rules="email_required|email"
                :label="$t('auth.form.email')"
              />
              <p
                v-if="isInvalid"
                class="validationError red--text"
              >
                <span
                  v-for="(error, index) in errors"
                  :key="`error-${index}`"
                >
                  {{ error }}
                </span>
              </p>
            </div>
            <div>
              <v-layout
                pt-6
                justify-space-between
              >
                <BaseButton
                  block
                  :disabled="isDisabled"
                  :loading="isLoading"
                  type="submit"
                >
                  {{ $t("auth.forget.button") }}
                </BaseButton>
              </v-layout>
              <v-layout
                pt-4
                justify-space-between
              >
                <BaseButton
                  block
                  outlined
                  @click="navigateToLogin"
                >
                  {{ $t("auth.back_to_login") }}
                </BaseButton>
              </v-layout>
            </div>
          </v-form>
        </div>
      </template>
      <template v-else>
        <div class="d-flex flex-column justify-space-between">
          <div class="mt-16 mb-10 mx-3">
            <p class="headline mb-2 black--text">
              {{ $t("auth.forget.title_sent") }}
            </p>
            <p class="subheading mb-6">
              <span>{{ $t("auth.forget.subtitle_sent") }}</span>
              <span
                class="px-1"
              ><b>{{ email }}</b></span>
              <span>{{ $t("auth.forget.subtitle_sent_continued") }}</span>
            </p>
            <div class="d-flex align-center">
              <span class="subheading">{{ $t("auth.reset.info") }}</span>
              <v-btn
                color="white"
                plain
                class="resend-button orange--text"
                :disabled="counting"
                @click="resentResetPassword"
              >
                <countdown
                  v-if="counting"
                  :time="countdownTime"
                  @end="handleCountdownFinish"
                >
                  <template slot-scope="props">
                    {{ $t("auth.reset.time") }} {{ props.totalSeconds }}s
                  </template>
                </countdown>
                <span v-else>{{ $t("auth.reset.resend") }}</span>
              </v-btn>
            </div>
            <v-alert
              v-if="isResent"
              class="mt-6 py-4"
              icon="mdi-information-outline"
              dense
              text
              type="success"
            >
              {{ $t("auth.reset.resend_successful") }}
            </v-alert>
          </div>
          <v-layout
            pt-6
            flex
            align-end
          >
            <BaseButton
              block
              @click="navigateToLogin"
            >
              {{ $t("auth.back_to_login") }}
            </BaseButton>
          </v-layout>
        </div>
      </template>
    </div>
  </ValidationObserver>
</template>
<script>
import { mapActions } from 'vuex';
import BaseButton from '@/components/Form/BaseButton';
import BaseInput from '@/components/Form/BaseInput';
import { LOGIN } from '@/router/routes.names';
import { HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';

export default {
  name: 'ForgetPasswordForm',
  components: {
    BaseButton,
    BaseInput,
  },
  data() {
    return {
      counting: false,
      countdownTime: 45000,
      isDisabled: false,
      isLoading: false,
      isSent: false,
      isResent: false,
      isInvalid: false,
      email: '',
      errors: [],
    };
  },
  methods: {
    ...mapActions('auth', ['forgetPassword']),
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      try {
        this.errors = [];
        this.isLoading = true;
        await this.forgetPassword({
          email: this.email,
        });
        this.isSent = true;
        this.startCountdown();
      } catch (e) {
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));
          this.isInvalid = true;

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    navigateToLogin() {
      this.$router.push({
        name: LOGIN,
        query: {
          ...this.$route.query,
        },
      });
    },
    resentResetPassword() {
      this.startCountdown();
      this.submit();
      this.isResent = true;
    },
    startCountdown() {
      this.counting = true;
    },
    handleCountdownFinish() {
      this.counting = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.forget-form {
  min-height: 490px;
}
.resend-button {
  padding: 0;
  text-transform: none;
}
.w-full {
  width: 100%;
}
.validationError {
  span {
    display: block;
  }
}
</style>
