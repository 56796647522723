import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {String} pageId
 */
const fetchGamePage = async ({
  gameId,
  pageId,
}) => {
  const { data } = await api.get(`games/${gameId}/game-pages/${pageId}`);

  return data.data;
};

export default fetchGamePage;
