<template>
  <v-tooltip
    right
    color="grayDark"
    max-width="380"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon color="grey lighten-1">
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <span>
      <slot />
    </span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'BaseTooltip',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>
