<template>
  <div class="base-expand-text-editor">
    <div class="base-expand-text-editor__heading">
      <span class="subtitle-2">
        {{ innerValue || showEditor ? label : '' }}
      </span>
      <v-icon
        v-if="showEditor"
        @click="toggleEditor"
      >
        mdi-unfold-less-horizontal
      </v-icon>
    </div>
    <div
      v-if="!showEditor"
      class="base-expand-text-editor__preview"
      :class="{ 'base-expand-text-editor__preview--empty': !innerValue }"
      @click="toggleEditor"
      v-html="innerValue ? innerValue : label"
    />
    <BaseRichTextEditor
      v-if="showEditor"
      v-model="innerValue"
      :rules="rules"
      :vid="vid"
      :label="label"
      hide-title
    />
  </div>
</template>

<script>
import BaseRichTextEditor from '@/components/Form/BaseRichTextEditor';

export default {
  name: 'BaseExpandTextEditor',
  components: {
    BaseRichTextEditor,
  },
  props: {
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      innerValue: '',
      showEditor: false,
    };
  },
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    toggleEditor() {
      this.showEditor = !this.showEditor;
    },
  },
};
</script>

<style lang="scss">
.base-expand-text-editor {
  padding-bottom: 2rem;
  &__heading {
    display: flex;
    justify-content: space-between;
  }
  &__preview {
    height: 75px;
    position: relative;
    padding: 1rem 0.65rem;
    border: 1px solid var(--grayLightest);
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow 0.2s ease;
    &:hover {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
    }
    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      height: 5rem;
      box-shadow: 0px -50px 20px -20px #fff inset;
    }
    &--empty {
      color: var(--grayLight);
      height: 60px;
      &::before {
        height: 0;
      }
    }
  }
}
</style>
