<template>
  <v-dialog
    v-model="innerValue"
    max-width="900"
    @click:outside="closeModal"
  >
    <v-card>
      <v-card-title class="text-h5 pt-6 pb-3">
        {{ $t('client.help_faq') }}
      </v-card-title>
      <v-card-text>
        <v-divider class="mt-2 mb-8" />
        <div v-html="faqContent" />
      </v-card-text>
      <v-card-actions>
        <v-row
          class="mb-2 mx-1"
          justify="center"
        >
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <BaseButton @click="closeModal">
              {{ $t("dashboard.close") }}
            </BaseButton>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import alerts from '@/plugins/alerts';
import BaseButton from '@/components/Form/BaseButton';

export default {
  name: 'FaqModal',
  components: {
    BaseButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      innerValue: false,
    };
  },
  computed: {
    ...mapGetters('session', ['faqContent']),
  },
  async created() {
    this.innerValue = this.value;
  },
  methods: {
    ...alerts,
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>
