import api from '@/plugins/axios/api';

/**
 * @param {String} recruitmentProcessId
 * @param {String} candidateId
 */
const deleteCandidate = async ({
  recruitmentProcessId,
  candidateId,
}) => {
  await api.delete(`recruitment-processes/${recruitmentProcessId}/candidates/${candidateId}`);
};

export default deleteCandidate;
