import api from '@/plugins/axios/api';

/**
 * @param {String} competenceId
 */
const deleteCompetence = async ({
  competenceId,
}) => {
  await api.delete(`competences/${competenceId}`);
};

export default deleteCompetence;
