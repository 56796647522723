import api from '@/plugins/axios/api';

/**
 * @param {String} workDepartmentId
 */
const deleteWorkDepartment = async ({
  workDepartmentId,
}) => {
  await api.delete(`work-departments/${workDepartmentId}`);
};

export default deleteWorkDepartment;
