import api from '@/plugins/axios/api';

/**
 * @param {String} limit
 * @param {?Object} sort
 * @param {?String} search
 * @param {?Number} page
 * @param {?Object} filters
 */
const fetchMessageTemplates = async ({
  limit,
  sort,
  search,
  page,
  filters,
}) => {
  const { data: { data, meta } } = await api.get('message-templates', {
    params: {
      limit,
      sort,
      search,
      page,
      filters,
    },
  });

  return { data, pagination: meta.pagination };
};

export default fetchMessageTemplates;
