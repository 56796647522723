import api from '@/plugins/axios/api';

/**
 * @param {String} recruitmentProcessesId
 * @param {Array} emails
 * @param {Object} customMessage
 */
const inviteCandidate = async ({
  recruitmentProcessesId,
  emails,
  customMessage,
}) => {
  await api.post('game-invitations', {
    recruitment_processes_id: recruitmentProcessesId,
    emails,
    custom_message: customMessage,
  });
};

export default inviteCandidate;
