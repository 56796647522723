import '@/assets/styles/main.scss';
import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';
import vuetify from '@/plugins/vuetify/vuetify';
import '@/plugins/axios/api';
import '@/plugins/sentry/sentry';
import '@/plugins/global/components';
import '@/plugins/validation/validator';
import App from '@/App';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
