<template>
  <div>
    <div
      v-if="isLoading"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <FadeInTransition>
      <div
        v-if="!isLoading"
        class="wrapper pb-6"
      >
        <v-row class="px-3 px-lg-6">
          <v-col
            sm="12"
            md="10"
            lg="8"
          >
            <FaqForm
              :edit-mode="editMode"
              :client="client"
              :is-loading="isLoading"
              :is-sending="isSending"
              :variables-list="variablesList"
              :backend-errors="errors"
              @handle-submit="submit"
              @handle-cancel="returnToList"
              @has-form-changes="hasFormChanges"
            />
          </v-col>
        </v-row>
      </div>
    </FadeInTransition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import eventBus from '@/plugins/eventBus';
import alerts from '@/plugins/alerts';
import {
  HTTP_UNAUTHORIZED,
  HTTP_NOT_FOUND,
  HTTP_UNPROCESSABLE_ENTITY,
} from '@/plugins/axios/codes';
import { NOT_FOUND, CLIENT_LIST } from '@/router/routes.names';
import { HAS_FORM_CHANGES } from '@/names/emits.names';
import fetchClient from '@/components/Dashboard/Client/fetchClient';
import patchFaq from '@/components/Dashboard/Client/patchFaq';
import fetchVariablesList from '@/components/Dashboard/Client/fetchVariablesList';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import FaqForm from '@/components/Dashboard/Client/FaqForm';
import FadeInTransition from '@/components/Transition/FadeInTransition';

export default {
  name: 'FaqEdit',
  components: {
    Preloader,
    FaqForm,
    FadeInTransition,
  },
  data() {
    return {
      client: null,
      isLoading: false,
      isSending: false,
      variablesList: null,
      errors: [],
    };
  },
  computed: {
    ...mapGetters('session', ['isSuperAdmin']),
  },
  async created() {
    this.editMode = this.$route.meta.editMode;
    try {
      this.isLoading = true;
      if (this.editMode) {
        this.client = await fetchClient({
          clientId: this.$route.params.clientId,
          includes: ['options'],
        });
        this.variablesList = await fetchVariablesList({
          clientId: this.$route.params.clientId,
        });
      }
    } catch (e) {
      if (e.response?.status === HTTP_UNAUTHORIZED) return;
      if (e.response?.status === HTTP_NOT_FOUND) {
        this.$router.push({ name: NOT_FOUND });

        return;
      }
      if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
        this.errorAlert();

        return;
      }
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...alerts,
    returnToList() {
      this.$router.push({ name: CLIENT_LIST });
    },
    async submit(payload) {
      try {
        this.errors = [];
        this.isSending = true;
        await patchFaq(payload);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.$emit('reset-form-changes');
        this.isSending = false;
        this.$notify.save.success();
        if (this.isSuperAdmin) {
          eventBus.$emit('fetchClientsInSidebar');
        }
      }
    },
    hasFormChanges(changed) {
      this.$emit(HAS_FORM_CHANGES, changed);
    },
  },
};
</script>
<style lang="scss" scoped>
.client-edit__title {
  font-size: 20px;
  font-weight: 500;
}
</style>
