import Vue from 'vue';
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';
import { LOGIN } from '@/router/routes.names';
import { HTTP_UNAUTHORIZED } from '@/plugins/axios/codes';

const redirectToLogin = () => {
  const { currentRoute } = router;
  const targetRoute = store.getters['session/currentRoute'];

  if (currentRoute.name === LOGIN) {
    return null;
  }

  return router.push({
    name: LOGIN,
    query: { redirect: currentRoute.name ? currentRoute.fullPath : targetRoute },
  });
};

const showErrorMessage = (errorStatus) => {
  Vue.notify.info(
    i18n.t(`notifications.code_${errorStatus}.content`),
    i18n.t(`notifications.code_${errorStatus}.title`),
  );
};

let error = false;

// eslint-disable-next-line import/prefer-default-export
export const errorResponseHandler = (e) => {
  if (!error && e.response?.status === HTTP_UNAUTHORIZED) {
    store.dispatch('auth/clearAuth');
    redirectToLogin();
    showErrorMessage(HTTP_UNAUTHORIZED);
    store.dispatch('session/clearSession');
    error = true;
  }

  return Promise.reject(e);
};
