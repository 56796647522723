<template>
  <div>
    <v-data-table
      v-bind="$attrs"
      :headers="tableHeaders"
      :items="tableData"
      :loading="isLoading"
      :options.sync="options"
      :hide-default-footer="true"
      v-on="$listeners"
    >
      <template
        v-if="!!$scopedSlots.body"
        #body="{ items, headers }"
      >
        <slot
          name="body"
          :items="items"
          :headers="headers"
        />
        <tbody v-if="!items.length">
          <tr>
            <td
              :colspan="headers.length"
              style="height: 500px; text-align: center"
            >
              <img
                :src="require(`@/assets/icons/no_results.svg`)"
                alt="noresults"
              >
              <div class="pt-3 pb-8">
                {{ isLoading ? $t("dashboard.table.loading") : noResultsText }}
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:no-data>
        {{ $t("dashboard.no_data_available") }}
      </template>
      <template v-slot:no-results>
        {{ $t("dashboard.no_data_available") }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: 'BaseList',
  props: {
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    noResultsText: {
      type: String,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      options: {
        itemsPerPage: this.itemsPerPage,
      },
    };
  },
};
</script>
<style lang="scss">
tbody tr {
  color: var(--grayDark) !important;
  &:hover {
    background: var(--gray) !important;
  }
}
</style>
