<template>
  <ValidationProvider
    ref="provider"
    v-slot="{ errors, validate }"
    :vid="vid"
    :name="label"
    :rules="rules"
  >
    <div class="mt-3 pb-6">
      <div class="upload-input">
        <span class="upload-input__title">
          {{ label }}
        </span>
        <div
          class="input-preview__background"
          :style="{ backgroundImage: `url(${require('@/assets/images/no_image.jpg')})` }"
        >
          <video
            v-if="previewUrl"
            id="video"
            width="180"
            height="100"
            controls
          >
            <source :src="previewUrl">
          </video>
        </div>
        <div class="border pl-6">
          <div
            v-if="!previewUrl"
            class="pb-3"
          >
            <span class="input-preview__name">{{
              placeholder
                ? placeholder
                : $t("dashboard.upload_video_details", {
                  exts: acceptedExt,
                  maxSize: maxSize / 1024
                })
            }}</span>
          </div>
          <div
            v-if="video"
            class="pb-3"
          >
            <span class="input-preview__name">{{ video.name }}</span>
            <span class="input-preview__size">({{ formatFileSize(video.size) }})</span>
          </div>
          <div v-if="!previewUrl">
            <label
              class="upload-input__button"
              :for="vid"
            >
              <span class="px-4">{{ $t("dashboard.upload_video") }}</span>
              <v-icon
                class="px-1"
                dark
              >
                mdi-cloud-upload
              </v-icon>
            </label>
            <input
              :id="vid"
              ref="inputFile"
              class="upload-input__button--input"
              type="file"
              :accept="videoAcceptedExts"
              tabindex="-1"
              @change="previewVideo($event, validate)"
              @click="resetPreview"
            >
          </div>
          <div v-if="previewUrl">
            <button
              class="upload-input__button upload-input__button--remove"
              type="button"
              @click="deleteVideo"
            >
              <span class="px-4">{{ $t("dashboard.remove") }}</span>
              <v-icon
                class="px-1"
                dark
              >
                mdi-close
              </v-icon>
            </button>
          </div>
        </div>
      </div>
      <p
        v-if="errors.length"
        class="validationError red--text px-3 py-2"
      >
        <span
          v-for="(error, index) in errors"
          :key="`error-${index}`"
        >
          {{ error }}
        </span>
      </p>
    </div>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'BaseVideoUploader',
  props: {
    vid: {
      type: String,
      default: '',
    },
    value: {
      type: [String, File, null],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    rules: {
      type: [Object, String, Array],
      default: '',
    },
    acceptedExt: {
      type: String,
      default: 'mp4',
    },
    placeholder: {
      type: String,
      default: '',
    },
    maxSize: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedItem: null,
      previewUrl: null,
      video: null,
      hasUploadedVideo: false,
    };
  },
  computed: {
    videoAcceptedExts() {
      return `.${this.acceptedExt}`;
    },
  },
  created() {
    if (this.value) {
      this.previewUrl = this.value;
      this.hasUploadedVideo = true;
    }
    if (this.value || this.value === null) {
      this.$emit('input', '');
    }
  },
  methods: {
    async previewVideo(event, validate) {
      const file = event.target.files[0];
      const result = await validate(event);
      if (!result.valid) {
        return;
      }

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewUrl = e.target.result;
        };
        // eslint-disable-next-line prefer-destructuring
        this.video = file;
        reader.readAsDataURL(file);
      }
      this.$emit('input', this.video);
    },
    deleteVideo() {
      this.resetPreview();
      if (this.hasUploadedVideo) {
        this.$emit('input', 'DELETE');

        return;
      }
      this.$emit('input', '');
    },
    resetPreview() {
      this.previewUrl = null;
      this.video = null;
    },
    formatFileSize(fileSize) {
      const fileSizes = ['Bytes', 'KB', 'MB', 'GB'];
      let i = 0;
      while (fileSize > 900) {
        fileSize /= 1024; // eslint-disable-line no-param-reassign
        i += 1;
      }

      return `${Math.round(fileSize * 100) / 100} ${fileSizes[i]}`;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .vel-img {
  box-shadow: none !important;
}
.upload-input {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 14px 15px;
  border: 1px solid;
  border-color: var(--grayLightest);
  &__title {
    position: absolute;
    font-size: 12px;
    padding: 0 3px;
    color: var(--grayDark);
    background: var(--white);
    top: -8px;
  }
  &__button {
    display: inline-block;
    color: var(--white);
    background: var(--primary);
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 3px;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
    &--input {
      display: none;
    }
    &--remove {
      background: var(--grayDark);
    }
  }
}
.input-preview {
  width: 180px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &__background {
    cursor: pointer;
    width: 180px;
    height: 100px;
    background: var(--gray);
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &--hover {
      cursor: pointer;
      &:hover > .input-preview__overlay {
        opacity: 1;
      }
      &:hover .input-preview__icon {
        transform: scale(1.5);
        transition: all 0.6s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }
  }
  &__name {
    word-break: break-word;
    color: var(--grayDark);
  }
  &__size {
    padding: 0 8px;
    color: var(--grayLight);
  }
}
.validationError {
  span {
    display: block;
    font-size: 12px;
  }
}
</style>
