<template>
  <v-data-table
    v-bind="$attrs"
    :expanded.sync="expanded"
    :headers="tableHeaders"
    :items="tableData"
    :server-items-length="pagination ? pagination.total : 0"
    :loading="isLoading"
    :options.sync="options"
    :sort-by.sync="sort.column"
    :sort-desc.sync="sort.direction"
    :no-data-text="noResultsText"
    :loading-text="$t('dashboard.table.loading')"
    :footer-props="footerProps"
    v-on="$listeners"
    @update:page="updatePage"
    @update:items-per-page="updateItemsPerPage"
    @update:sort-by="updateSort"
    @update:sort-desc="updateSort"
  >
    <template v-slot:item="{ item, expand, isExpanded }">
      <tr>
        <slot
          name="item"
          :item="item"
        />
        <td v-if="isExpandable">
          <v-btn
            icon
            @click="expand(!isExpanded)"
          >
            <v-icon>{{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <tr>
        <td :colspan="headers.length">
          <slot
            name="expanded-item"
            :item="item"
          />
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
import { ASC, DESC } from '@/components/Table/sort.types';

export default {
  name: 'BaseTable',
  props: {
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [20, 50, 100, 200],
    },
    noResultsText: {
      type: String,
      required: true,
    },
    pagination: {
      type: Object,
      default: () => ({}),
    },
    defaultSort: {
      type: Object,
      required: true,
    },
    isExpandable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: [],
      sortPending: false,
      sort: this.initializeSortData(),
      options: this.initializeOptions(),
    };
  },
  computed: {
    formattedSortDirection() {
      const querySort = this.$route.query.sort;

      return querySort ? querySort.direction === DESC : this.defaultSort.direction === DESC;
    },
    convertedSortDirection() {
      return this.sort.direction[0] ? DESC : ASC;
    },
    footerProps() {
      return {
        'items-per-page-text': this.$t('dashboard.table.pagination_results'),
        'items-per-page-options': this.itemsPerPageOptions,
        pageText: `{0} - {1} ${this.$t('dashboard.of')} {2}`,
      };
    },
  },
  watch: {
    pagination: {
      handler(pagination) {
        this.options.page = pagination.current_page;
      },
      immediate: true,
    },
    tableHeaders: {
      handler() {
        this.initializeSort(this.$route.query.sort);
      },
      immediate: true,
    },
  },
  methods: {
    initializeSortData() {
      return {
        column: [],
        direction: [],
        value: undefined,
        filterName: undefined,
      };
    },
    initializeOptions() {
      return {
        itemsPerPage: +this.$route.query.limit || 50,
        page: +this.$route.query.page || 1,
        mustSort: true,
      };
    },
    initializeSort(querySort) {
      if (this.$route.query.recruitment_process_id) {
        this.loadSortParamsFromLocalStorage();

        return;
      }
      if (querySort) {
        this.applyQuerySortParams(querySort);

        return;
      }
      this.setDefaultSortParams();
    },
    loadSortParamsFromLocalStorage() {
      const key = `candidatesSortParam-${this.$route.query.recruitment_process_id}`;
      const storedParams = JSON.parse(localStorage.getItem(key));

      if (storedParams) {
        this.applyStoredSortParams(storedParams);

        return;
      }
      this.setDefaultSortParams();
    },
    applyStoredSortParams(params) {
      this.sort.column = [params.column];
      this.sort.direction = [params.direction === DESC];
      this.sort.value = params.value || undefined;

      const sortedHeader = this.tableHeaders.find(
        (header) => header.id === params.value && header.filterName === params.column,
      );
      if (sortedHeader) {
        this.sort.column = [sortedHeader.value];
      }
    },
    applyQuerySortParams(querySort) {
      this.sort.column = [querySort.column];
      this.sort.direction = [this.formattedSortDirection];
      this.sort.value = querySort.value;

      const sortedHeader = this.tableHeaders.find(
        (header) => header.id === querySort.value && header.filterName === querySort.column,
      );
      if (sortedHeader) {
        this.sort.column = [sortedHeader.value];
      }
    },
    setDefaultSortParams() {
      this.sort.column = [this.defaultSort.column];
      this.sort.direction = [this.formattedSortDirection];
    },
    updatePage(page) {
      if (+this.$route.query.page !== page) {
        this.updateRouteQuery({ page });
        this.$emit('change-page', { page });
      }
    },
    updateItemsPerPage(limit) {
      this.updateRouteQuery({ limit });
      this.$emit('change-items-per-page', { limit });
    },
    updateSort() {
      if (this.sortPending) return;

      this.sortPending = true;
      this.$nextTick(() => {
        this.sortPending = false;

        const column = this.sort.column[0];
        const direction = this.convertedSortDirection;
        const sortedHeader = this.tableHeaders.find((header) => header.value === column);

        if (sortedHeader) {
          this.sort.value = sortedHeader.id;
          this.sort.filterName = sortedHeader.filterName;
        }

        const sortParams = {
          column: this.sort.filterName ?? column,
          direction,
          value: this.sort.value ?? undefined,
        };

        this.updateRouteQuery({ sort: sortParams });
        this.saveParamsToLocalStorage(sortParams);
        this.$emit('change-sort', { sort: sortParams });
      });
    },
    updateRouteQuery(params) {
      this.$router.replace({ query: { ...this.$route.query, ...params } });
    },
    saveParamsToLocalStorage(sortParams) {
      if (!this.$route.query.recruitment_process_id) return;

      const key = `candidatesSortParam-${this.$route.query.recruitment_process_id}`;
      localStorage.setItem(key, JSON.stringify(sortParams));
    },
  },
};
</script>
<style lang="scss" scoped>
.v-data-table {
  &::v-deep th,
  &::v-deep td {
    padding: 0 8px !important;

    @media (min-width: 1600px) {
      padding: 0 18px !important;
    }
  }

  &::v-deep {
    .v-data-table-header tr th {
      white-space: nowrap;
    }
    .v-data-footer__pagination {
      position: absolute;
      left: 0;
    }
    tbody tr {
      color: var(--grayDark) !important;

      &:hover {
        background: var(--gray) !important;
      }
    }
    .mdi-checkbox-marked.theme--light.v-icon,
    .mdi-minus-box.theme--light.v-icon {
      color: var(--primary) !important;
    }
  }
}
</style>
