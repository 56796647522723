import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Model from '@ckeditor/ckeditor5-ui/src/model';
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import { addListToDropdown, createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import i18n from '@/i18n';

const addDropdownItemsToItemsCollection = (items, dropdowns) => {
  dropdowns.forEach((item) => {
    items.add({
      type: 'button',
      model: new Model({
        withText: true,
        label: item,
      }),
    });
  });

  return items;
};

export default class CustomTags extends Plugin {
  init() {
    const { editor } = this;
    editor.ui.componentFactory.add('customTags', (locale) => {
      const dropdownView = createDropdown(locale);
      dropdownView.buttonView.set({
        withText: true,
        label: i18n.t('dashboard.tags'),
        tooltip: true,
      });
      let items = new Collection();
      const dropdowns = editor.config.get('customTagsData');
      items = addDropdownItemsToItemsCollection(items, dropdowns);
      addListToDropdown(dropdownView, items);
      dropdownView.on('execute', (evt) => {
        editor.model.change((writer) => {
          editor.model.insertContent(writer.createText(`${evt.source.label}`));
        });
      });

      return dropdownView;
    });
  }
}
