<template>
  <v-menu
    offset-y
    elevation="2"
    transition="fade-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-card class="actions-list">
      <v-list-item
        :to="{ name: workPositionEditRoute, params: { workPositionId } }"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("dashboard.edit") }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="handleDelete">
        <v-list-item-icon>
          <v-icon>mdi-delete</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("dashboard.remove") }}</v-list-item-title>
      </v-list-item>
    </v-card>
  </v-menu>
</template>
<script>
import alerts from '@/plugins/alerts';
import { WORK_POSITION_EDIT } from '@/router/routes.names';

export default {
  name: 'WorkPositionActions',
  props: {
    workPositionId: {
      type: String,
      required: true,
    },
  },
  computed: {
    workPositionEditRoute() {
      return WORK_POSITION_EDIT;
    },
  },
  methods: {
    ...alerts,
    async handleDelete() {
      const { workPositionId } = this;
      if (await this.removeAlert()) {
        this.$emit('handle-delete', { workPositionId });
      }
    },
  },
};
</script>
