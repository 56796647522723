export default {
  title: 'Departments',
  search: 'Search',
  name: 'Name',
  created_at: 'Creation date',
  no_results: 'No departments found',
  add: 'Add department',
  edit: 'Edit department',
  create: {
    name: 'Department name',
  },
};
