/* eslint-disable camelcase */
import { extend } from 'vee-validate';
import {
  required,
  email,
  min,
  max,
  confirmed,
  numeric,
  min_value,
  max_value,
  ext,
  size,
} from 'vee-validate/dist/rules';
import { EMAIL_REGEX, PASSWORD_REGEX } from '@/plugins/validation/regex';
import i18n from '@/i18n';

extend('select', {
  ...required,
  message: (value) => i18n.t('validation.select', {
    name: value.toLowerCase(),
  }),
});

extend('required', {
  ...required,
  message: (value) => i18n.t('validation.required', {
    name: value.toLowerCase(),
  }),
});

extend('email_required', {
  ...required,
  message: () => i18n.t('validation.email_required'),
});

extend('emails_required', {
  ...required,
  message: () => i18n.t('validation.emails_required'),
});

extend('email', {
  ...email,
  message: () => i18n.t('validation.email_incorrect'),
});

extend('min', {
  ...min,
  message: (value, args) => i18n.t('validation.min_length', {
    length: args.length,
  }),
});

extend('max', {
  ...max,
  message: (value, args) => i18n.t('validation.max_length', {
    length: args.length,
  }),
});

extend('min_value', {
  ...min_value,
  message: (value, args) => i18n.t('validation.min_value', {
    value: args.min,
  }),
});

extend('max_value', {
  ...max_value,
  message: (value, args) => i18n.t('validation.max_value', {
    value: args.max,
  }),
});

extend('decimal', {
  message: () => i18n.t('validation.scoring_incorrect'),
  validate: (value) => !!/^-?[0-9]+(?:.[0-9]{1})?$/.test(value),
});

extend('numeric', {
  ...numeric,
  message: () => i18n.t('validation.numeric'),
});

extend('search', {
  ...min,
  message: (value, args) => i18n.t('validation.search', {
    length: args.length,
  }),
});

extend('confirmed', {
  ...confirmed,
  message: () => i18n.t('validation.password_confirm'),
});

extend('full_text_search', {
  message: (value, { 0: length }) => i18n.t('validation.full_text_search', {
    length,
  }),
  validate: (value, [length]) => value.replace(/[^\p{L}\p{N}_']+/ug, '').length >= length,
});

let multipleEmailsErrorMessage;

extend('multiple_emails', {
  message: () => multipleEmailsErrorMessage,
  validate: (value) => {
    const wrongCharacterAtTheEnd = /[\s,;\t\n]+$/.test(value);
    if (wrongCharacterAtTheEnd) {
      multipleEmailsErrorMessage = i18n.t('validation.emails_end_characters');

      return false;
    }
    const emails = value.split(/[\s,;\t\n]+/);
    const invalidEmails = [];
    for (let i = 0; i < emails.length; i += 1) {
      const currentEmail = emails[i].trim();
      const isValid = EMAIL_REGEX.test(currentEmail);
      if (!isValid) {
        invalidEmails.push(currentEmail);
      }
    }
    if (invalidEmails.length) {
      multipleEmailsErrorMessage = `${i18n.t('validation.emails_incorrect')} ${invalidEmails
        .map((e) => e)
        .join(', ')}`;

      return false;
    }

    return true;
  },
});

let passwordErrorMessage;

extend('password', {
  message: () => passwordErrorMessage,
  validate: (value) => {
    const isValid = PASSWORD_REGEX.test(value);
    if (!isValid) {
      passwordErrorMessage = i18n.t('validation.password_incorrect');
    }

    return !!isValid;
  },
});

extend('size', {
  ...size,
  message: (value, args) => i18n.t('validation.max_file_size', {
    size: args.size / 1024,
  }),
});

extend('image_required', {
  ...required,
  message: () => i18n.t('validation.image_required'),
  validate: (value) => !(!value || !value.length),
});

extend('video_required', {
  ...required,
  message: () => i18n.t('validation.video_required'),
  validate: (value) => !(!value || !value.length),
});

extend('extensions', {
  ...ext,
  message: (value, args) => {
    const extsFromProvidedArgs = Object.keys(args)
      .filter((key) => !key.startsWith('_'))
      .reduce((obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = args[key];

        return obj;
      }, {});

    const convertObjectToString = Object.values(extsFromProvidedArgs).join(', ');

    return i18n.t('validation.extensions', {
      exts: convertObjectToString,
    });
  },
});

extend('time_h_m_s', {
  message: () => i18n.t('validation.time_limit'),
  validate: (value) => !!/\d{2}(h:)\d{2}(m:)\d{2}(s)/.test(value),
});

extend('time_m_s', {
  message: () => i18n.t('validation.time_limit'),
  validate: (value) => !!/\d{2}(m:)\d{2}(s)/.test(value),
});

extend('url', {
  message: () => i18n.t('validation.url'),
  validate: (value) => !!/^(https?):\/\/[^\s$.?#].[^\s]*$/.test(value),
});

extend('array_length', {
  message: (value, args) => i18n.t('validation.max_array_length', {
    length: args[0],
  }),
  validate: (value, args) => {
    const isValid = value.length <= args[0];

    return !!isValid;
  },
});

extend('is_not', {
  validate: (value, args) => Number(value) !== Number(args[0]),
  message: () => i18n.t('validation.is_not'),
});
