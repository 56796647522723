export default {
  title: 'Analytics',
  game: 'Game',
  dates_range: 'Date range',
  recruitment_process: 'Recruitment process',
  candidates_invited: 'Candidates invited',
  candidates_started: 'Candidates started the game',
  candidates_finished: 'Candidates finished the game',
  no_results: 'Statistics not found',
};
