export default {
  type: 'Step type',
  add: 'Add step',
  edit: 'Edit step',
  no_results: 'No steps found',
  list: {
    title: 'Steps list',
    order: 'No.',
    name: 'Step content',
    type: 'Type',
    competence: 'Competence',
  },
  types: {
    question_best_worst: 'Multiselect (thumb up, down)',
    question_closed: 'Singleselect',
    message: 'Message',
    open: 'Open ended',
    video: 'Video',
    image: 'Graphics',
    record: 'Video recording',
  },
  closed_question: {
    name: 'Edit singleselect question',
    competence: 'Competence',
    competence_hint: 'Select competence',
    header: 'Header',
    body: 'Question content',
    instruction: 'Call to action',
    question_image: 'Picture for the question',
    side_image: 'Side image',
    side_image_requirements: 'Recommended dimensions are: 436x985 px',
    mobile_side_image: 'Mobile side image',
    mobile_side_image_requirements: 'Recommended dimensions are: 750x420 px',
    answer: 'Answer',
    answer_empty: 'Add answers to the step',
    add: 'Add an answer',
    score: 'Scoring',
    feedback_title: 'Feedback (optional)',
    feedback: 'Feedback',
    feedback_image: 'Image for feedback',
  },
  bestworst_question: {
    name: 'Singleselect question edit',
    competence: 'Competence',
    competence_hint: 'Select competence',
    header: 'Header',
    body: 'Question content',
    instruction: 'Call to action',
    question_image: 'Picture for the question',
    side_image: 'Side image',
    side_image_requirements: 'Recommended dimensions are: 436x985 px',
    mobile_side_image: 'Mobile side image',
    mobile_side_image_requirements: 'Recommended dimensions are: 750x420 px',
    answer: 'Answer',
    answer_empty: 'Add answers to step',
    add: 'Add an answer',
    score_best: 'Score (thumbs up)',
    score_worst: 'Scoring (thumbs down)',
    feedback_title: 'Feedback (optional)',
    feedback: 'Feedback for thumbs up',
    feedback_image: 'Image for feedback',
  },
  game_message: {
    name: 'Edit message',
    title: 'Step name',
    header: 'Header',
    body: 'Question content',
    side_image: 'Side image',
    side_image_requirements: 'Recommended dimensions are: 436x985 px',
    mobile_side_image: 'Mobile side image',
    mobile_side_image_requirements: 'Recommended dimensions are: 750x420 px',
    feedback_title: 'Feedback (optional)',
    feedback: 'Feedback',
    feedback_image: 'Image for feedback',
  },
  video: {
    name: 'Edit video',
    title: 'Step name',
    video_mp4: 'Video MP4',
    video_webm: 'Video WEBM',
  },
  image: {
    name: 'Edit graphics',
    title: 'Step name',
    question_image: 'Picture for the question',
    continue_btn: 'Label of the Continue button',
  },
  game_record: {
    name: 'Edit video recording',
    title: 'Step name',
    header: 'Header',
    body: 'Question content',
    instruction: 'Call to action (optional)',
    limit: 'Time limit',
    limit_hint: '01m: 00s',
    required: 'Required',
    side_image: 'Side image',
    side_image_requirements: 'Recommended dimensions are: 436x985 px',
    mobile_side_image: 'Mobile side image',
    mobile_side_image_requirements: 'Recommended dimensions are: 750x420 px',
    feedback_title: 'Feedback (optional)',
    feedback: 'Feedback',
    feedback_image: 'Image for feedback',
  },
  open_question: {
    name: 'Open-ended question edit',
    competence: 'Competence',
    competence_hint: 'Select competence',
    title: 'Title',
    header: 'Header',
    description: 'Question text',
    call_tofinans: 'A call to action',
    max_length: 'Limit number of characters in response',
    question_image: 'Picture for the question',
    side_image: 'Side image',
    side_image_requirements: 'Recommended dimensions are: 436x985 px',
    mobile_side_image: 'Mobile side image',
    mobile_side_image_requirements: 'Recommended dimensions are: 750x420 px',
    feedback_title: 'Feedback (optional)',
    feedback: 'Feedback',
    feedback_image: 'Image for feedback',
  },
};
