<template>
  <v-menu
    v-model="isMenuOpen"
    offset-y
    elevation="2"
    transition="fade-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-card class="actions-list">
      <v-list-item
        :to="{ name: gameFormEditRoute, params: { gameFormId } }"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-pencil</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("dashboard.edit") }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click.stop="openTagsModal">
        <v-list-item-icon>
          <v-icon>mdi-tag</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("gameForm.manage_tags") }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="handleDelete">
        <v-list-item-icon>
          <v-icon>mdi-delete</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("dashboard.remove") }}</v-list-item-title>
      </v-list-item>
    </v-card>
    <AssignTagsModal
      v-model="isModalOpen"
      :game-form-id="gameFormId"
      @refetch-data="refetchData"
    />
  </v-menu>
</template>
<script>
import alerts from '@/plugins/alerts';
import { GAME_FORM_EDIT } from '@/router/routes.names';
import AssignTagsModal from '@/components/Dashboard/GameForm/AssignTagsModal';

export default {
  name: 'GameFormActions',
  components: {
    AssignTagsModal,
  },
  props: {
    gameFormId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isMenuOpen: false,
      isModalOpen: false,
    };
  },
  computed: {
    gameFormEditRoute() {
      return GAME_FORM_EDIT;
    },
  },
  methods: {
    ...alerts,
    async handleDelete() {
      const { gameFormId } = this;
      if (await this.removeAlert()) {
        this.$emit('handle-delete', { gameFormId });
      }
    },
    openTagsModal() {
      this.isModalOpen = true;
      this.closeMenu();
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    refetchData() {
      this.$emit('refetch-data');
    },
  },
};
</script>
