<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <v-dialog
        v-model="innerValue"
        max-width="680"
      >
        <v-card>
          <v-card-title class="text-h5 pb-8">
            {{ $t("candidate.tags") }}: {{ candidateFullname }}
          </v-card-title>
          <v-card-text>
            <BaseCombobox
              v-model="formData.assignedTags"
              rules="min:3|max:255"
              :items="tags"
              :label="$t('candidate.tags')"
              :loading="isLoading"
              :disabled="isLoading"
              item-text="name"
              item-value="name"
              persistent-hint
              multiple
              clearable
              chips
              deletable-chips
            />
            <BackendErrors :backend-errors="errors" />
          </v-card-text>
          <v-card-actions>
            <v-row
              class="mb-2 mx-1"
              justify="center"
            >
              <v-col cols="6">
                <BaseButton
                  outlined
                  @click="toggleModal"
                >
                  {{ $t("dashboard.back") }}
                </BaseButton>
              </v-col>
              <v-col cols="6">
                <BaseButton
                  type="submit"
                  :loading="isSending"
                  @click="submit"
                >
                  {{ $t("dashboard.save") }}
                </BaseButton>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import alerts from '@/plugins/alerts';
import { HTTP_UNAUTHORIZED, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { DESC } from '@/components/Table/sort.types';
import fetchTags from '@/components/Dashboard/Candidate/Api/fetchTags';
import fetchAssignedTags from '@/components/Dashboard/Candidate/Api/fetchAssignedTags';
import assignTags from '@/components/Dashboard/Candidate/Api/assignTags';
import BaseButton from '@/components/Form/BaseButton';
import BaseCombobox from '@/components/Form/BaseCombobox';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';

export default {
  name: 'AssignTagsModal',
  components: {
    ValidationObserver,
    BaseButton,
    BaseCombobox,
    BackendErrors,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    candidateId: {
      type: String,
      required: true,
    },
    recruitmentProcessId: {
      type: String,
      required: true,
    },
    candidateFullname: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tags: [],
      formData: {
        assignedTags: null,
      },
      innerValue: null,
      isLoading: false,
      isSending: false,
      errors: [],
    };
  },
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
      if (newVal) {
        this.fetchTags();
        this.fetchAssignedTags();
      }
    },
  },
  methods: {
    ...alerts,
    async fetchTags() {
      if (!this.$route.query?.recruitment_process_id) {
        return;
      }
      try {
        this.isLoading = true;
        const tags = await fetchTags({
          filters: {
            recruitment_process_id: this.recruitmentProcessId,
          },
          sort: {
            column: 'created_at',
            direction: DESC,
          },
          limit: 99999,
        });
        this.tags = tags.data.map((tag) => tag.name);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchAssignedTags() {
      try {
        this.isLoading = true;
        const assignedTags = await fetchAssignedTags({
          candidateId: this.candidateId,
          recruitmentProcessId: this.recruitmentProcessId,
        });
        this.formData.assignedTags = assignedTags.data.map((tag) => tag.name);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      try {
        this.errors = [];
        this.isSending = true;
        await assignTags(this.baseFormData());
        this.toggleModal();
        this.$notify.save.success();
        this.$emit('refetch-data');
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isSending = false;
      }
    },
    baseFormData() {
      return {
        candidateId: this.candidateId,
        recruitmentProcessId: this.recruitmentProcessId,
        candidateTagsNames: this.formData.assignedTags,
      };
    },
    toggleModal() {
      this.innerValue = !this.innerValue;
    },
  },
};
</script>
