import i18n from '@/i18n';
import RouterOutlet from '@/components/Dashboard/Partials/RouterOutlet/RouterOutlet';
import Index from '@/views/Dashboard/WorkPosition/Index';
import Edit from '@/views/Dashboard/WorkPosition/Edit';
import WorkDepartment from '@/views/Dashboard/WorkDepartment/Index';
import EditWorkDepartment from '@/views/Dashboard/WorkDepartment/Edit';
import {
  WORK_POSITION_LIST,
  WORK_POSITION_ADD,
  WORK_POSITION_EDIT,
  WORK_DEPARTMENT_LIST,
  WORK_DEPARTMENT_ADD,
  WORK_DEPARTMENT_EDIT,
} from '@/router/routes.names';
import {
  WORK_POSITION_INDEX,
  WORK_POSITION_CREATE,
  WORK_POSITION_UPDATE,
  WORK_DEPARTMENT_INDEX,
  WORK_DEPARTMENT_CREATE,
  WORK_DEPARTMENT_UPDATE,
} from '@/router/permissions.names';
import { isAuthenticated } from '@/router/permissions';

const workPositionRoutes = [
  {
    path: '/work-positions',
    component: RouterOutlet,
    children: [
      {
        path: '',
        name: WORK_POSITION_LIST,
        component: Index,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.work_position_list'),
              },
            ];
          },
          permissions: WORK_POSITION_INDEX,
        },
      },
      {
        path: 'add',
        name: WORK_POSITION_ADD,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.work_position_list'),
                to: { name: WORK_POSITION_LIST },
              },
              {
                label: i18n.t('breadcrumbs.work_position_add'),
              },
            ];
          },
          permissions: WORK_POSITION_CREATE,
          editMode: false,
        },
      },
      {
        path: ':workPositionId/edit',
        name: WORK_POSITION_EDIT,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.work_position_list'),
                to: { name: WORK_POSITION_LIST },
              },
              {
                label: i18n.t('breadcrumbs.work_position_edit'),
              },
            ];
          },
          permissions: WORK_POSITION_UPDATE,
          editMode: true,
        },
      },
      {
        path: 'work-departments',
        name: WORK_DEPARTMENT_LIST,
        component: WorkDepartment,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.work_position_list'),
                to: { name: WORK_POSITION_LIST },
              },
              {
                label: i18n.t('breadcrumbs.work_department_list'),
              },
            ];
          },
          permissions: WORK_DEPARTMENT_INDEX,
        },
      },
      {
        path: 'work-departments/add',
        name: WORK_DEPARTMENT_ADD,
        component: EditWorkDepartment,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.work_position_list'),
                to: { name: WORK_POSITION_LIST },
              },
              {
                label: i18n.t('breadcrumbs.work_department_list'),
                to: { name: WORK_DEPARTMENT_LIST },
              },
              {
                label: i18n.t('breadcrumbs.work_department_add'),
              },
            ];
          },
          permissions: WORK_DEPARTMENT_CREATE,
          editMode: false,
        },
      },
      {
        path: 'work-departments/:workDepartmentId/edit',
        name: WORK_DEPARTMENT_EDIT,
        component: EditWorkDepartment,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.work_position_list'),
                to: { name: WORK_POSITION_LIST },
              },
              {
                label: i18n.t('breadcrumbs.work_department_list'),
                to: { name: WORK_DEPARTMENT_LIST },
              },
              {
                label: i18n.t('breadcrumbs.work_department_edit'),
              },
            ];
          },
          permissions: WORK_DEPARTMENT_UPDATE,
          editMode: true,
        },
      },
    ],
  },
];

export default workPositionRoutes;
