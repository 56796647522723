import store from '@/store';
import {
  DASHBOARD,
  LOGIN,
  CANDIDATE_LIST,
} from '@/router/routes.names';

const authenticated = () => store.getters['auth/isLogged'];

export const isNotAuthenticated = (to, from, next) => {
  if (!authenticated()) {
    return next();
  }

  return next({ name: CANDIDATE_LIST });
};

export const isAuthenticated = (to, from, next) => {
  const hasPermission = store.getters['session/permissions'];

  if (authenticated()) {
    if (hasPermission.includes(to.meta.permissions)) {
      return next();
    }

    return next({ name: DASHBOARD });
  }

  return next({
    name: LOGIN,
    query: { redirect: to.path },
  });
};
