import api from '@/plugins/axios/api';

/**
 * @param {?Number} page
 * @param {?Number} limit
 * @param {?Object} sort
 * @param {?String} search
 */
const fetchClients = async ({
  page,
  limit,
  sort,
  search,
  includes,
}) => {
  const { data: { data, meta } } = await api.get('clients', {
    params: {
      page,
      limit,
      sort,
      search,
      includes,
    },
  });

  return { data, pagination: meta.pagination };
};

export default fetchClients;
