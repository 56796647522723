import {
  TOGGLE_SIDEBAR,
  TOGGLE_CANDIDATE_SIDEBAR,
  START_PRELOADING_DATA,
  STOP_PRELOADING_DATA,
} from '@/store/layout/mutations.names';

export default {
  toggleSidebar({ commit }) {
    commit(TOGGLE_SIDEBAR);
  },
  toggleCandidateSidebar({ commit }) {
    commit(TOGGLE_CANDIDATE_SIDEBAR);
  },
  startPreloadingData({ commit }) {
    commit(START_PRELOADING_DATA);
  },
  stopPreloadingData({ commit }) {
    commit(STOP_PRELOADING_DATA);
  },
};
