<template>
  <div class="accordion">
    <div
      class="accordion__trigger"
      :class="{ 'accordion__trigger--active': value.isOpen }"
      @click="toggleOpen"
    >
      <div>
        <span
          v-if="hasInvalidFields"
          class="accordion__invalid-field"
        >
          <v-icon color="red">
            mdi-alert-box
          </v-icon>
        </span>
        <button
          type="button"
          @click.stop
        >
          <v-icon
            class="handle"
            dark
            :class="{ active: dragging }"
          >
            mdi-dots-grid
          </v-icon>
        </button>
        <span class="px-3">
          {{ value.name ? stringLimit(value.name, 28) : translateField(value.type) }}
        </span>
      </div>
      <div class="accordion__actions">
        <button
          type="button"
          class="px-4"
          @click.stop="removeField"
        >
          <v-icon dark>
            mdi-delete
          </v-icon>
        </button>
        <v-icon color="white">
          {{ value.isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </div>
    </div>
    <div>
      <transition
        name="accordion"
        @enter="showContent"
        @after-enter="hideContent"
        @before-leave="showContent"
        @after-leave="hideContent"
      >
        <div
          v-show="value.isOpen"
          class="accordion__content"
        >
          <div class="pt-8">
            <BaseSelect
              :value="value.type"
              rules="select"
              :items="fieldTypes"
              :label="$t('gameForm.create.field_type')"
              :item-text="value => translateField(value.name)"
              item-value="name"
              persistent-hint
              :loading="isLoading"
              :disabled="isLoading"
              searchable
              @change="value => updateType(value)"
            />
          </div>
          <slot />
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { stringLimit } from '@/plugins/stringHelpers/stringLimit';
import BaseSelect from '@/components/Form/BaseSelect';

export default {
  name: 'BaseGameFormField',
  components: {
    BaseSelect,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    fieldTypes: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    dragging: {
      type: Boolean,
      required: true,
    },
    hasInvalidFields: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    stringLimit,
    showContent(el) {
      // eslint-disable-next-line no-param-reassign
      el.style.height = `${el.scrollHeight}px`;
    },
    hideContent(el) {
      // eslint-disable-next-line no-param-reassign
      el.style.height = '';
    },
    translateField(value) {
      return value !== null ? this.$t(`gameForm.field_types.${value.toLowerCase()}`) : '-';
    },
    removeField() {
      this.$emit('handle-remove');
    },
    updateType(type) {
      if (type === null) return;

      this.$emit('update-type', type);
    },
    toggleOpen() {
      this.$emit('toggle-open');
    },
  },
};
</script>
<style lang="scss" scoped>
.accordion {
  cursor: pointer;
  margin-bottom: 15px;
  position: relative;
  &__invalid-field {
    position: absolute;
    top: -12px;
    left: -12px;
  }
  &__content {
    padding: 0 20px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 0 0 4px 4px;
  }
  &__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-radius: 4px;
    background: var(--primary);
    color: var(--white);
    font-size: 20px;
    line-height: 28px;
    word-break: break-word;
    &--active {
      border-radius: 4px 4px 0 0;
    }
  }
  &__actions {
    min-width: 90px;
  }
}
.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}
.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
.handle {
  cursor: grab;
  &.active {
    cursor: grabbing !important;
  }
}
</style>
