import AuthLayout from '@/layouts/AuthLayout';
import Login from '@/views/Auth/Login';
import LoginPasswordChanged from '@/views/Auth/LoginPasswordChanged';
import ForgetPassword from '@/views/Auth/ForgetPassword';
import ResetPassword from '@/views/Auth/ResetPassword';
import {
  LOGIN, PASSWORD_CHANGED, FORGET_PASSWORD, RESET_PASSWORD,
} from '@/router/routes.names';
import { isNotAuthenticated } from '@/router/permissions';

const authRoutes = [
  {
    path: '/login',
    component: AuthLayout,
    redirect: {
      name: LOGIN,
    },
    children: [
      {
        path: '/',
        name: LOGIN,
        component: Login,
        beforeEnter: isNotAuthenticated,
      },
      {
        path: '/password-changed',
        name: PASSWORD_CHANGED,
        component: LoginPasswordChanged,
        beforeEnter: isNotAuthenticated,
      },
      {
        path: '/forget',
        name: FORGET_PASSWORD,
        component: ForgetPassword,
        beforeEnter: isNotAuthenticated,
      },
      {
        path: '/reset',
        name: RESET_PASSWORD,
        component: ResetPassword,
        props: (route) => ({ query: route.query.token }),
        beforeEnter: isNotAuthenticated,
      },
    ],
  },
];

export default authRoutes;
