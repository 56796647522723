<template>
  <ExportFile
    :file="file"
    :file-type="fileType"
    :is-loading="isLoading"
    @generate="generate"
  />
</template>

<script>
import alerts from '@/plugins/alerts';
import checkFileType from '@/plugins/files/checkFileType';
import exportFile from '@/plugins/files/exportFile';
import candidateFiles from '@/components/Dashboard/Candidate/Api/candidateFiles';
import ExportFile from '@/components/Dashboard/Partials/Export/ExportFile';
import { HTTP_UNAUTHORIZED } from '@/plugins/axios/codes';

export default {
  name: 'CandidateFiles',
  components: {
    ExportFile,
  },
  data() {
    return {
      file: null,
      fileType: null,
      isLoading: false,
    };
  },
  methods: {
    ...alerts,
    async generate() {
      try {
        this.isLoading = true;
        const { candidateId, recruitmentProcessId } = this.$route.params;
        const response = await candidateFiles({
          recruitmentProcessId,
          candidateId,
        });
        this.file = exportFile(response);
        this.fileType = checkFileType(response.data.type);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
