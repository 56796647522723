export const PL = 'PL';
export const EN = 'EN';
export const CS = 'CS';
export const DE = 'DE';
export const FR = 'FR';
export const HU = 'HU';
export const IT = 'IT';
export const NL = 'NL';
export const RO = 'RO';
export const SK = 'SK';
export const HR = 'HR';
export const PT = 'PT';
export const SL = 'SL';
export const UK = 'UK';
