import Notifications from 'vue-notification';
import i18n from '@/i18n';

const AVAILABLE_TYPES = ['error', 'success', 'info'];

export default (function ExtendedNotifications() {
  const parseArguments = (args) => {
    let [text, title, options] = args;

    if (typeof text === 'object') {
      options = {
        ...text,
      };
      text = null;
      title = null;
    }

    if (typeof title === 'object') {
      options = {
        ...title,
      };
      title = null;
    }

    if (typeof options !== 'object') {
      options = {};
    }

    return [text, title, options];
  };

  const notify = (instance, type, args) => {
    const [text, title, options] = parseArguments(args);

    return instance({
      type,
      text,
      title,
      ...options,
    });
  };

  return function install(Vue) {
    Vue.use(Notifications);

    const {
      $notify,
    } = Vue.prototype;

    const generic = (type) => notify(
      $notify,
      type,
      [i18n.t(`notifications.edit_${type}.title`)],
    );

    const generateSave = (type) => notify(
      $notify,
      type,
      [
        i18n.t(`notifications.edit_${type}.content`),
        i18n.t(`notifications.edit_${type}.title`),
      ],
    );

    const extendedNotify = {};

    AVAILABLE_TYPES.forEach((type) => {
      extendedNotify[type] = (...args) => notify($notify, type, args);
    });

    extendedNotify.error = (...args) => (args.length
      ? notify($notify, 'error', args)
      : generic('errors'));

    Object.assign($notify, {
      ...extendedNotify,
      save: {
        error: () => generateSave('error'),
        success: () => generateSave('success'),
      },
    });
  };
}());
