<template>
  <TranslationsMenu
    v-model="locale"
    class="mx-3 mx-lg-6"
    :default-locale="clientDefaultLocale"
    :is-loading="isLoading"
    :has-changes="hasChanges"
    @change-locale="updateLocale"
  >
    <v-row>
      <v-col>
        <ScoringScalesForm
          class="px-3 px-lg-6"
          :edit-mode="editMode"
          :scoring-scales="scoringScales"
          :is-loading="isLoading"
          :is-sending="isSending"
          :backend-errors="errors"
          @handle-submit="submit"
          @handle-cancel="returnToList"
          @has-form-changes="hasFormChanges"
        />
      </v-col>
    </v-row>
  </TranslationsMenu>
</template>
<script>
import { mapGetters } from 'vuex';
import alerts from '@/plugins/alerts';
import {
  HTTP_UNAUTHORIZED,
  HTTP_NOT_FOUND,
  HTTP_UNPROCESSABLE_ENTITY,
} from '@/plugins/axios/codes';
import { NOT_FOUND, CLIENT_LIST } from '@/router/routes.names';
import { HAS_FORM_CHANGES } from '@/names/emits.names';
import fetchClient from '@/components/Dashboard/Client/fetchClient';
import editScoringScales from '@/components/Dashboard/ScoringScale/editScoringScales';
import ScoringScalesForm from '@/components/Dashboard/Client/ScoringScalesForm';
import TranslationsMenu from '@/components/Dashboard/Partials/Translations/TranslationsMenu';

const CANDIDATE_FEEDBACK = 'CANDIDATE_FEEDBACK';

export default {
  name: 'FaqEdit',
  components: {
    ScoringScalesForm,
    TranslationsMenu,
  },
  data() {
    return {
      hasChanges: false,
      locale: null,
      scoringScales: [],
      isLoading: false,
      isSending: false,
      errors: [],
    };
  },
  computed: {
    ...mapGetters('session', ['clientDefaultLocale']),
  },
  async created() {
    this.locale = this.clientDefaultLocale.toLowerCase();
    this.editMode = this.$route.meta.editMode;
    await this.fetchClientScoringScales();
  },
  methods: {
    ...alerts,
    returnToList() {
      this.$router.push({ name: CLIENT_LIST });
    },
    async fetchClientScoringScales() {
      try {
        this.isLoading = true;
        if (this.editMode) {
          const {
            scoring_scales: { data },
          } = await fetchClient({
            clientId: this.$route.params.clientId,
            includes: ['scoring_scales.scoring_expected_levels'],
            locale: this.locale,
          });
          this.scoringScales = data.map((scoringScale) => ({
            scoring_scale_id: scoringScale.id,
            scoring_scale_percentage_to: scoringScale.percentage_to,
            expected_level_name: scoringScale.scoring_expected_levels.data.find(
              ({ type }) => type === CANDIDATE_FEEDBACK,
            )?.name,
          }));
        }
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_NOT_FOUND) {
          this.$router.push({ name: NOT_FOUND });

          return;
        }
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async submit(payload) {
      try {
        this.errors = [];
        this.isSending = true;
        await editScoringScales({
          ...payload,
          locale: this.locale,
        });
        this.$notify.save.success();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.$emit('reset-form-changes');
        this.isSending = false;
        this.hasChanges = false;
      }
    },
    hasFormChanges(changed) {
      this.hasChanges = changed;
      this.$emit(HAS_FORM_CHANGES, changed);
    },
    async updateLocale(newLocale) {
      this.locale = newLocale;
      await this.fetchClientScoringScales();
    },
  },
};
</script>
<style lang="scss" scoped>
.client-edit__title {
  font-size: 20px;
  font-weight: 500;
}
</style>
