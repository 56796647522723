import api from '@/plugins/axios/api';

/**
 * @param {String} name
 */
const addWorkDepartment = async ({
  name,
}) => {
  await api.post('work-departments', {
    name,
  });
};

export default addWorkDepartment;
