import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {String} badgeId
 * @param {Object} params
 */
const editBadge = async ({
  gameId,
  badgeId,
  params,
}) => {
  await api.post(`games/${gameId}/game-badges/${badgeId}`, params);
};

export default editBadge;
