<template>
  <div>
    <div
      v-for="(item, index) in candidateVideos"
      :key="`candidate-video-${index}`"
      class="p-0 mb-12"
    >
      <h3 class="font-weight-medium">
        {{ item.recruitmentProcessStageable.data.name }}
      </h3>
      <v-divider class="mt-2 mb-8" />
      <div
        v-for="(video, index2) in item.recruitmentProcessStageable.data.stages.data"
        :key="`candidate-video2-${index2}`"
        class="candidate-videos__item wrapper"
      >
        <div class="candidate-videos__video">
          <video
            v-if="isCandidateVideoAvailable(video.stageable.data.sessionRecords.data)"
            id="video"
            width="480"
            height="266"
            controls
          >
            <source :src="getCandidateVideo(video.stageable.data.sessionRecords.data[0].files)">
          </video>
          <div
            v-else
            class="candidate-videos__no-video"
          >
            <v-icon x-large>
              mdi-close-circle
            </v-icon>
            <span class="mt-4 d-block body-2">
              {{ $t("candidate.no_recorded_video_response") }}
            </span>
          </div>
        </div>
        <div class="candidate-videos__content">
          <div>
            <span class="primary--text font-weight-medium d-block mb-3">
              {{ video.stageable.data.title }}
            </span>
            <span
              v-if="video.stageable.data.body"
              class="d-block body-2"
              v-html="sanitizeHtml(video.stageable.data.body)"
            />
          </div>
          <router-link
            v-if="isCandidateVideoAvailable(video.stageable.data.sessionRecords.data)"
            :to="
              candidateFileUrl(getCandidateVideo(video.stageable.data.sessionRecords.data[0].files))
            "
            class="candidate-videos__download-btn"
            target="_blank"
          >
            <span>{{ $t("dashboard.download") }}</span>
          </router-link>
        </div>
      </div>
      <div v-if="!item.recruitmentProcessStageable.data.stages.data.length">
        <v-alert
          :text="true"
          type="info"
        >
          <span>{{ $t("candidate.no_record_stage") }}</span>
        </v-alert>
      </div>
    </div>
  </div>
</template>
<script>
import { FILE } from '@/router/routes.names';
import sanitize from '@/plugins/sanitize/sanitizeHtml';

export default {
  name: 'CandidateVideosForm',
  props: {
    candidateVideos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      videos: [],
    };
  },
  mounted() {
    this.videos = document.querySelectorAll('video');
    this.videos.forEach((video) => {
      video.addEventListener('play', this.pauseOtherVideos);
    });
  },
  destroyed() {
    this.videos.forEach((video) => {
      video.removeEventListener('play', this.pauseOtherVideos);
    });
  },
  methods: {
    ...sanitize,
    candidateFileUrl(fileUrl) {
      return {
        name: FILE,
        params: {
          fileUrl,
        },
      };
    },
    isCandidateVideoAvailable(recordsList) {
      return recordsList.length;
    },
    getCandidateVideo(filesUrl) {
      return Object.values(filesUrl)[0];
    },
    pauseOtherVideos({ target }) {
      this.videos.forEach((video) => {
        if (video !== target) {
          video.pause();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.candidate-videos {
  &__item {
    border: 1px solid;
    border-color: var(--grayLightest);
    padding: 25px;
    display: flex;
    margin-bottom: 25px;
    gap: 30px;
    flex-direction: column-reverse;

    @media (min-width: 1200px) {
      flex-direction: row;
    }
  }
  &__video {
    width: 480px;
  }
  &__no-video {
    color: var(--grayDark);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 266px;
    width: 480px;
    border: 1px solid;
    border-color: var(--grayLightest);
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__download-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #263238;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
    border-radius: 3px;
    width: 134px;
    height: 40px;
    margin: 10px 0;
    color: #fff !important;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
