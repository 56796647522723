<template>
  <ValidationProvider
    ref="validationProvider"
    v-slot="{ errors, valid }"
    :vid="vid"
    :name="label"
    :rules="rules"
  >
    <v-text-field
      v-model="innerValue"
      v-mask="mask"
      :error-messages="errors"
      outlined
      :label="label"
      v-bind="$attrs"
      v-on="$listeners"
      @update:error="$emit('validate-field', { label, isValid: valid })"
    />
  </ValidationProvider>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    vid: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
  },
  data() {
    return {
      innerValue: '',
    };
  },
  watch: {
    innerValue(newVal) {
      this.$emit('input', this.$attrs.type === 'number' ? Number(newVal) : newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
