<template>
  <div class="table-tabs">
    <label
      class="table-tabs__label"
      :class="{ 'table-tabs__label--active': selectedWorkDepartment === null }"
    >
      <input
        v-model="selectedWorkDepartment"
        class="d-none"
        type="radio"
        :value="null"
        :name="$t('recruitment.types.all')"
        :disabled="disabled"
      >
      <div
        class="table-tabs__text"
        :class="{ 'table-tabs__text--disabled': disabled }"
      >
        <span>{{ $t('recruitment.types.all') }}</span>
        <span class="ml-3">{{ recruitmentProcessesLength }}</span>
      </div>
    </label>
    <label
      v-for="workDepartment in workDepartments"
      :key="workDepartment.id"
      class="table-tabs__label"
      :class="{ 'table-tabs__label--active': selectedWorkDepartment === workDepartment.id }"
    >
      <input
        v-model="selectedWorkDepartment"
        class="d-none"
        type="radio"
        :value="workDepartment.id"
        :name="workDepartment.name"
        :disabled="disabled"
      >
      <div
        class="table-tabs__text"
        :class="{ 'table-tabs__text--disabled': disabled }"
      >
        <span>{{ workDepartment.name }}</span>
        <span class="ml-3">
          {{ workDepartment.current_recruitment_processes_count }}
        </span>
      </div>
    </label>
  </div>
</template>
<script>
export default {
  name: 'WorkDepartments',
  props: {
    workDepartments: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    recruitmentProcessesLength: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedWorkDepartment: null,
    };
  },
  watch: {
    selectedWorkDepartment(newVal) {
      this.$emit('input', newVal);
      this.$emit('change');
    },
    value(value) {
      this.selectedWorkDepartment = value;
    },
  },
  created() {
    if (this.value) {
      this.selectedWorkDepartment = this.value;
    }
  },
};
</script>
