<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <v-row>
          <v-col
            sm="12"
            lg="10"
          >
            <div v-if="formData.scoringScales.length">
              <div
                v-for="(scoringScale, index) in formData.scoringScales"
                :key="`scoring-scale-${index}`"
              >
                <p class="d-flex align-center text-subtitle-2 orange--text font-weight-medium text-red pb-1">
                  <span>{{ $t("client.create.level") }} {{ index + 1 }} / {{ formData.scoringScales.length }}</span>
                </p>
                <v-row>
                  <v-col
                    sm="12"
                    md="6"
                  >
                    <BaseInput
                      v-model="scoringScale.scoring_scale_percentage_to"
                      type="number"
                      step="1"
                      min="1"
                      max="100"
                      rules="min_value:1|max_value:100|required"
                      :label="$t('client.create.score_to')"
                      :vid="`${$t('client.create.score_to')}-${index}`"
                    />
                  </v-col>
                  <v-col
                    sm="12"
                    md="6"
                  >
                    <BaseInput
                      v-model="scoringScale.expected_level_name"
                      rules="min:3|required"
                      :label="$t('client.create.feedback_label')"
                      :vid="`${$t('client.create.feedback_label')}-${index}`"
                    />
                  </v-col>
                </v-row>
              </div>
            </div>
            <div
              v-else
              class="d-flex justify-center pt-8 pb-12"
            >
              {{ $t("client.create.empty_scoring_scales") }}
            </div>
            <v-row justify="end">
              <v-col md="3">
                <BaseButton
                  color="grayDark"
                  dark
                  @click="removeLastScoringScale"
                >
                  <span class="pl-4">{{ $t("dashboard.remove") }}</span>
                  <v-icon class="pl-2">
                    mdi-delete
                  </v-icon>
                </BaseButton>
              </v-col>
              <v-col md="3">
                <BaseButton
                  color="primary"
                  dark
                  @click="addScoringScale"
                >
                  <span class="pl-4">{{ $t("dashboard.add") }}</span>
                  <v-icon class="pl-2">
                    mdi-plus
                  </v-icon>
                </BaseButton>
              </v-col>
            </v-row>
            <v-row class="pt-6">
              <v-col>
                <v-alert
                  icon="mdi-information-outline"
                  color="grayDark"
                  text
                >
                  <span class="body-2">
                    {{ $t('client.scoring_scales_warning') }}
                  </span>
                </v-alert>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import { HAS_FORM_CHANGES } from '@/names/emits.names';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseInput from '@/components/Form/BaseInput';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import BaseButton from '@/components/Form/BaseButton';

export default {
  name: 'ScoringScalesForm',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseInput,
    BaseButton,
  },
  props: {
    scoringScales: {
      type: Array,
      default: () => [],
    },
    backendErrors: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        scoringScales: [],
      },
    };
  },
  watch: {
    formData: {
      handler(newVal, oldVal) {
        if (oldVal.length === 0) return;
        this.checkFormChanges();
      },
      deep: true,
    },
  },
  created() {
    if (this.editMode) {
      this.formData.scoringScales = this.scoringScales;
    }
  },
  methods: {
    cancel() {
      this.$emit('handle-cancel');
    },
    checkFormChanges() {
      const { changed } = this.$refs.form.flags;
      this.$emit(HAS_FORM_CHANGES, changed);
    },
    removeLastScoringScale() {
      const lastIndex = this.scoringScales.length - 1;
      this.formData.scoringScales.splice(lastIndex, 1);
    },
    addScoringScale() {
      this.formData.scoringScales.push({
        scoring_scale_id: '',
        scoring_scale_percentage_to: '',
        expected_level_name: '',
      });
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const { clientId } = this.$route.params;

      return { clientId, scoringScales: this.formData.scoringScales };
    },
  },
};
</script>
