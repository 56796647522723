<template>
  <div>
    <div
      v-if="isLoading"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title>
          <v-row>
            <v-col
              class="px-3 px-lg-6"
              sm="12"
            >
              <p class="headline black--text mt-4">
                {{ editMode ? $t("gameForm.edit") : $t("gameForm.add") }}
              </p>
              <v-divider class="mt-3 mb-4" />
            </v-col>
          </v-row>
        </v-card-title>
        <div class="wrapper">
          <v-row class="px-3 px-lg-6">
            <v-col
              sm="12"
              lg="10"
            >
              <GameForm
                :edit-mode="editMode"
                :game-form="gameForm"
                :clients="clients"
                :is-loading="isLoading"
                :is-sending="isSending"
                :backend-errors="errors"
                @handle-submit="submit"
                @handle-cancel="returnToList"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import alerts from '@/plugins/alerts';
import { HTTP_UNAUTHORIZED, HTTP_NOT_FOUND, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { NOT_FOUND, GAME_FORM_LIST } from '@/router/routes.names';
import { ASC } from '@/components/Table/sort.types';
import addGameForm from '@/components/Dashboard/GameForm/addGameForm';
import editGameForm from '@/components/Dashboard/GameForm/editGameForm';
import fetchClients from '@/components/Dashboard/Client/fetchClients';
import fetchGameForm from '@/components/Dashboard/GameForm/fetchGameForm';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import GameForm from '@/components/Dashboard/GameForm/Form';

export default {
  name: 'EditGameForm',
  components: {
    Preloader,
    GameForm,
  },
  data() {
    return {
      editMode: false,
      gameForm: null,
      clients: [],
      isLoading: false,
      isSending: false,
      errors: [],
    };
  },
  async created() {
    this.editMode = this.$route.meta.editMode;
    try {
      this.isLoading = true;
      await this.fetchClients();
      if (this.editMode) {
        await this.fetchGameForm();
      }
    } catch (e) {
      if (e.response?.status === HTTP_UNAUTHORIZED) return;
      if (e.response?.status === HTTP_NOT_FOUND) {
        this.$router.push({ name: NOT_FOUND });

        return;
      }
      if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
        this.errorAlert();

        return;
      }
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...alerts,
    returnToList() {
      this.$router.push({ name: GAME_FORM_LIST });
    },
    async fetchClients() {
      const { data } = await fetchClients({
        sort: {
          column: 'name',
          direction: ASC,
        },
      });
      this.clients = data;
    },
    async fetchGameForm() {
      this.gameForm = await fetchGameForm({
        gameFormId: this.$route.params.gameFormId,
        includes: ['client'],
      });
    },
    async submit(payload) {
      try {
        this.errors = [];
        this.isSending = true;
        this.editMode ? await editGameForm(payload) : await addGameForm(payload);
        this.$notify.save.success();
        this.returnToList();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isSending = false;
      }
    },
  },
};
</script>
