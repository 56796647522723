<template>
  <BaseFilterField
    :id="id"
    :title="label"
    :number-of-filters="(minValue ? 1 : 0) + (maxValue ? 1 : 0)"
    @update="onCreated"
  >
    <div class="number-field">
      <div class="number-field__item">
        <BaseNumber
          v-if="!isLoading"
          v-model="minValue"
          dense
          v-bind="$attrs"
          @change="change('min', minValue)"
        />
      </div>
      <div class="d-flex">
        <span class="mx-auto px-2 pt-2">-</span>
      </div>
      <div class="number-field__item">
        <BaseNumber
          v-if="!isLoading"
          v-model="maxValue"
          dense
          v-bind="$attrs"
          @change="change('max', maxValue)"
        />
      </div>
    </div>
    <button
      class="number-field__button"
      @click="remove"
    >
      {{ $t('candidate.clear_fields') }}
    </button>
  </BaseFilterField>
</template>
<script>
import BaseNumber from '@/components/Form/BaseNumber';
import BaseFilterField from '@/components/Dashboard/Candidate/Partials/BaseFilterField';

export default {
  name: 'NumberField',
  components: {
    BaseFilterField,
    BaseNumber,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      minValue: null,
      maxValue: null,
    };
  },
  methods: {
    change(key, value) {
      const updatedValue = value === '' ? undefined : value;
      if (key === 'min') {
        this.minValue = updatedValue;
      } else if (key === 'max') {
        this.maxValue = updatedValue;
      }

      const values = {};
      if (this.minValue && this.minValue !== undefined) values.min = this.minValue;
      if (this.maxValue && this.maxValue !== undefined) values.max = this.maxValue;

      this.$emit('change-filter', {
        id: this.id,
        values,
      });
    },
    remove() {
      this.isLoading = true;
      this.minValue = null;
      this.maxValue = null;
      this.$emit('change-filter', {
        id: this.id,
        values: null,
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 100);
    },
    onCreated(filters) {
      if (filters.min) {
        this.minValue = filters.min;
      }
      if (filters.max) {
        this.maxValue = filters.max;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.number-field {
  display: flex;
  &__item {
    max-width: 100px;
    width: 100%;
  }
  &__button {
    font-size: 12px;
    padding: 4px 8px;
    background: var(--primary);
    color: #fff;
    border-radius: 6px;
    margin-bottom: 12px;
  }
}
::v-deep .v-text-field input {
  font-size: 14px !important;
}
</style>
