<template>
  <div>
    <div
      v-if="isLoading"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title>
          <v-row>
            <v-col
              class="px-3 px-lg-6"
              sm="12"
            >
              <p class="headline black--text mt-4">
                {{ editMode ? $t("recruitment.edit") : $t("recruitment.add") }}
              </p>
              <v-divider class="mt-3 mb-4" />
            </v-col>
          </v-row>
        </v-card-title>
        <div class="wrapper">
          <v-row class="px-3 px-lg-6">
            <v-col
              sm="12"
              lg="10"
            >
              <RecruitmentForm
                :edit-mode="editMode"
                :stage-types="stageTypes"
                :report-template-types="reportTemplateTypes"
                :recruitment-process="recruitmentProcess"
                :work-positions="workPositions"
                :games="games"
                :second-stage-games="secondStageGames"
                :recruiters="recruiters"
                :client="client"
                :is-loading="isLoading"
                :is-sending="isSending"
                :backend-errors="errors"
                @handle-submit="submit"
                @handle-cancel="returnToList"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alerts from '@/plugins/alerts';
import { RECRUITER } from '@/router/roles.names';
import {
  PRESELECTION,
  INITIAL_INTERVIEW,
  SELECTION_INTERVIEW,
  MEET,
  HIRING,
  OTHER,
} from '@/names/recruitmentStages.names';
import {
  HTTP_UNAUTHORIZED,
  HTTP_FORBIDDEN,
  HTTP_NOT_FOUND,
  HTTP_UNPROCESSABLE_ENTITY,
} from '@/plugins/axios/codes';
import { NOT_FOUND, RECRUITMENT_PROCESS_LIST } from '@/router/routes.names';
import { SELECTIVE } from '@/names/gameTypes.names';
import addRecruitmentProcess from '@/components/Dashboard/Recruitment/addRecruitmentProcess';
import editRecruitmentProcess from '@/components/Dashboard/Recruitment/editRecruitmentProcess';
import fetchClient from '@/components/Dashboard/Client/fetchClient';
import fetchRecruitmentProcess from '@/components/Dashboard/Recruitment/fetchRecruitmentProcess';
import fetchWorkPositions from '@/components/Dashboard/WorkPosition/fetchWorkPositions';
import fetchGames from '@/components/Dashboard/Game/fetchGames';
import fetchUsers from '@/components/Dashboard/User/fetchUsers';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import RecruitmentForm from '@/components/Dashboard/Recruitment/Form';

export default {
  name: 'EditRecruitment',
  components: {
    Preloader,
    RecruitmentForm,
  },
  data() {
    return {
      editMode: false,
      stageTypes: [
        {
          name: this.$t('recruitment.types.preselection'),
          type: PRESELECTION,
        },
        {
          name: this.$t('recruitment.types.initial_interview'),
          type: INITIAL_INTERVIEW,
        },
        {
          name: this.$t('recruitment.types.selection_interview'),
          type: SELECTION_INTERVIEW,
        },
        {
          name: this.$t('recruitment.types.meet'),
          type: MEET,
        },
        {
          name: this.$t('recruitment.types.hiring'),
          type: HIRING,
        },
        {
          name: this.$t('recruitment.types.other'),
          type: OTHER,
        },
      ],
      reportTemplateTypes: [
        {
          name: this.$t('recruitment.report_template_types.basic'),
          type: 'BASIC',
        },
        {
          name: this.$t('recruitment.report_template_types.full'),
          type: 'FULL',
        },
      ],
      recruitmentProcess: null,
      workPositions: [],
      games: [],
      secondStageGames: [],
      recruiters: [],
      client: null,
      isLoading: false,
      isSending: false,
      errors: [],
    };
  },
  computed: {
    ...mapGetters('session', ['isRecruiter', 'clientId']),
  },
  async created() {
    this.editMode = this.$route.meta.editMode;
    try {
      this.isLoading = true;
      this.client = await fetchClient({
        clientId: this.clientId,
        includes: ['options'],
      });
      const workPositions = await fetchWorkPositions({ limit: 99999 });
      const games = await fetchGames({
        limit: 99999,
        filters: {
          client_id: this.clientId,
          active: this.editMode ? null : true,
        },
      });
      this.workPositions = workPositions.data;
      this.games = games.data;
      this.secondStageGames = games.data.filter((game) => game.type === SELECTIVE);
      if (!this.isRecruiter) {
        const users = await fetchUsers({
          limit: 99999,
          includes: ['client', 'role'],
          filters: {
            client_id: this.clientId,
            roles: [RECRUITER],
          },
        });
        this.recruiters = users?.data;
      }
      if (this.editMode) {
        this.recruitmentProcess = await fetchRecruitmentProcess({
          recruitmentProcessId: this.$route.params.recruitmentProcessId,
          includes: ['game', 'work_position', 'managing_users', 'stages', 'options', 'registration_url'],
        });
      }
    } catch (e) {
      if (e.response?.status === HTTP_UNAUTHORIZED) return;
      if (e.response?.status === HTTP_FORBIDDEN) {
        this.$notify.error(
          this.$t(`notifications.code_${HTTP_FORBIDDEN}.content`),
          this.$t(`notifications.code_${HTTP_FORBIDDEN}.title`),
        );
        this.returnToList();

        return;
      }
      if (e.response?.status === HTTP_NOT_FOUND) {
        this.$router.push({ name: NOT_FOUND });

        return;
      }
      if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
        this.errorAlert();

        return;
      }
      throw new Error(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...alerts,
    returnToList() {
      this.$router.push({ name: RECRUITMENT_PROCESS_LIST });
    },
    async submit(payload) {
      try {
        this.errors = [];
        this.isSending = true;
        // eslint-disable-next-line camelcase
        const { is_open, registration_url } = this.editMode ? await editRecruitmentProcess(payload) : await addRecruitmentProcess(payload);
        this.returnToList();
        // eslint-disable-next-line camelcase
        if (is_open) {
          this.successAlert({
            title: this.$t('dashboard.modal.recruitment_edit.title'),
            html: this.$t('dashboard.modal.recruitment_edit.content', { registrationUrl: registration_url }),
            confirmButtonText: this.$t('dashboard.modal.recruitment_edit.confirm'),
          });
        }
        this.$notify.save.success();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isSending = false;
      }
    },
  },
};
</script>
