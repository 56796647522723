import * as names from '@/store/layout/mutations.names';

export default {
  [names.TOGGLE_SIDEBAR](state) {
    state.isSidebarActive = !state.isSidebarActive;
    if (state.isCandidateSidebarActive) {
      state.isCandidateSidebarActive = false;
    }
  },
  [names.TOGGLE_CANDIDATE_SIDEBAR](state) {
    state.isCandidateSidebarActive = !state.isCandidateSidebarActive;
    if (state.isCandidateSidebarActive) {
      state.isSidebarActive = false;

      return;
    }
    state.isSidebarActive = true;
  },
  [names.START_PRELOADING_DATA](state) {
    state.isPreloadingData = true;
  },
  [names.STOP_PRELOADING_DATA](state) {
    state.isPreloadingData = false;
  },
};
