import api from '@/plugins/axios/api';

/**
 * @param {String} competenceId
 * @param {Array} includes
 * @param {String} locale
 */
const fetchCompetence = async ({
  competenceId,
  includes,
  locale,
}) => {
  const { data } = await api.get(`competences/${competenceId}`, {
    params: {
      includes,
      locale,
    },
  });

  return data.data;
};

export default fetchCompetence;
