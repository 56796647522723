<template>
  <v-btn
    :elevation="elevation"
    :color="color"
    :block="block"
    :type="type"
    :dark="dark"
    :large="large"
    v-bind="$attrs"
    @click="click"
  >
    <slot />
  </v-btn>
</template>
<script>
export default {
  name: 'BaseButton',
  props: {
    elevation: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: 'button',
    },
    block: {
      type: Boolean,
      default: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    large: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    click(e) {
      this.$emit('click', e);
    },
  },
};
</script>
<style lang="scss" scoped>
.v-btn {
  border-radius: 8px;
}
</style>
