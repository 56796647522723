<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <div class="login-form d-flex">
      <template v-if="!isBlocked">
        <div class="d-flex flex-column w-full">
          <div class="mt-16 mb-10 mx-3">
            <p class="headline mb-2 black--text">
              {{
                isResetPassword ? $t("auth.login.title_password_changed") : $t("auth.login.title")
              }}
            </p>
            <p class="subheading">
              {{
                isResetPassword
                  ? $t("auth.login.subtitle_password_changed")
                  : $t("auth.login.subtitle")
              }}
            </p>
          </div>
          <v-form
            class="mx-3 d-flex flex-column justify-space-between"
            style="height: 100%"
            @submit.prevent="handleSubmit(submit)"
          >
            <div>
              <BaseInput
                v-model="email"
                rules="email_required|email"
                :label="$t('auth.form.email')"
                @focus="isInvalid = false"
              />
              <BaseInput
                v-model="password"
                rules="required"
                :label="$t('auth.form.password')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                @focus="isInvalid = false"
              />
              <p
                v-if="isInvalid"
                class="red--text"
              >
                {{ $t("auth.login.error") }}
              </p>
            </div>
            <div>
              <v-layout
                pt-6
                justify-space-between
              >
                <BaseButton
                  block
                  :disabled="isDisabled"
                  :loading="isLoading"
                  type="submit"
                >
                  {{ $t("auth.login.button") }}
                </BaseButton>
              </v-layout>
              <v-layout
                pt-4
                justify-space-between
              >
                <BaseButton
                  block
                  outlined
                  @click="navigateToForgetPassword"
                >
                  {{ $t("auth.login.forgot_password") }}
                </BaseButton>
              </v-layout>
            </div>
          </v-form>
        </div>
      </template>
      <template v-else>
        <div class="d-flex flex-column justify-space-between w-full">
          <div class="mt-16 mb-10 mx-3">
            <p class="headline mb-2 black--text">
              {{ $t("auth.login.title_blocked") }}
            </p>
            <p class="subheading">
              {{ $t("auth.login.subtitle_blocked") }}
            </p>
          </div>
          <v-layout
            pt-6
            flex
            align-end
          >
            <BaseButton
              block
              @click="isBlocked = false"
            >
              {{ $t("auth.back_to_login") }}
            </BaseButton>
          </v-layout>
        </div>
      </template>
    </div>
  </ValidationObserver>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BaseButton from '@/components/Form/BaseButton';
import BaseInput from '@/components/Form/BaseInput';
import { CANDIDATE_LIST, CLIENT_LIST, FORGET_PASSWORD } from '@/router/routes.names';
import { HTTP_BAD_REQUEST, HTTP_TOO_MANY_REQUESTS } from '@/plugins/axios/codes';

export default {
  name: 'LoginForm',
  components: {
    BaseButton,
    BaseInput,
  },
  props: {
    isResetPassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPassword: false,
      isDisabled: false,
      isLoading: false,
      isBlocked: false,
      isInvalid: false,
      email: '',
      password: '',
    };
  },
  computed: {
    ...mapGetters('session', ['isSuperAdmin']),
  },
  methods: {
    ...mapActions('auth', ['login']),
    ...mapActions('session', ['fetchSession']),
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      this.isLoading = true;
      try {
        await this.login({
          email: this.email,
          password: this.password,
        });
        await this.fetchSession({
          includes: ['acl', 'client_scope', 'client'],
        });
        const redirectUrl = this.$route.query.redirect || { name: this.isSuperAdmin ? CLIENT_LIST : CANDIDATE_LIST };
        this.$router.push(redirectUrl);
        this.$notify.success(
          this.$t('notifications.login.content'),
          this.$t('notifications.login.title'),
        );
      } catch (e) {
        if (e.response?.status === HTTP_BAD_REQUEST) {
          this.isInvalid = true;

          return;
        }
        if (e.response?.status === HTTP_TOO_MANY_REQUESTS) {
          this.isBlocked = true;

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    navigateToForgetPassword() {
      this.$router.push({
        name: FORGET_PASSWORD,
        query: {
          ...this.$route.query,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.login-form {
  min-height: 490px;
}

.w-full {
  width: 100%;
}
</style>
