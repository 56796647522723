<template>
  <v-menu
    v-model="isMenuOpen"
    offset-y
    origin="center center"
    elevation="2"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-card class="actions-list">
      <v-list-item @click.stop="openSendMessageModal">
        <v-list-item-icon>
          <v-icon>mdi-email-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("candidate.send_message") }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click.stop="openTagsModal">
        <v-list-item-icon>
          <v-icon>mdi-tag</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("candidate.manage_tags") }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="candidateHasFiles"
        :to="candidateFilesPage"
        target="_blank"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-download</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("candidate.download_files") }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="handleDelete">
        <v-list-item-icon>
          <v-icon>mdi-delete</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t("dashboard.remove") }}</v-list-item-title>
      </v-list-item>
    </v-card>
    <AssignTagsModal
      v-model="isModalOpen"
      :candidate-id="candidateId"
      :recruitment-process-id="recruitmentProcessId"
      :candidate-fullname="candidateFullname"
      @refetch-data="refetchData"
    />
  </v-menu>
</template>
<script>
import alerts from '@/plugins/alerts';
import AssignTagsModal from '@/components/Dashboard/Candidate/Partials/AssignTagsModal';
import { CANDIDATE_FILES } from '@/router/routes.names';

export default {
  name: 'CandidateActions',
  components: {
    AssignTagsModal,
  },
  props: {
    candidateId: {
      type: String,
      required: true,
    },
    recruitmentProcessId: {
      type: String,
      required: true,
    },
    candidateFullname: {
      type: String,
      required: true,
    },
    candidateHasFiles: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isMenuOpen: false,
      isModalOpen: false,
    };
  },
  computed: {
    candidateFilesPage() {
      return {
        name: CANDIDATE_FILES,
        params: {
          candidateId: this.candidateId,
          recruitmentProcessId: this.recruitmentProcessId,
        },
      };
    },
  },
  methods: {
    ...alerts,
    openSendMessageModal() {
      this.$emit('open-send-message-modal');
      this.closeMenu();
    },
    openTagsModal() {
      this.isModalOpen = true;
      this.closeMenu();
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    refetchData() {
      this.$emit('refetch-data');
    },
    async handleDelete() {
      if (await this.removeAlert()) {
        this.$emit('handle-delete', {
          candidateId: this.candidateId,
          recruitmentProcessId: this.recruitmentProcessId,
        });
      }
    },
  },
};
</script>
