<template>
  <v-card elevation="0">
    <v-card-text class="py-8">
      <div class="topbar">
        <img
          class="mx-3"
          :src="logo"
          :alt="$t('auth.company')"
        >
        <LocaleMenu
          @handle-locale-change="({value}) => setLocale(value)"
        />
      </div>
      <ResetPasswordForm />
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex';
import ResetPasswordForm from '@/components/Auth/ResetPasswordForm';
import LocaleMenu from '@/components/Dashboard/Partials/Topbar/LocaleMenu';
import Logo from '@/assets/images/logo.svg';

export default {
  name: 'ResetPassword',
  components: {
    ResetPasswordForm,
    LocaleMenu,
  },
  computed: {
    logo() {
      return Logo;
    },
  },
  methods: {
    ...mapActions('session', ['setLocale']),
  },
};
</script>
<style lang="scss" scoped>
.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
