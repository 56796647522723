import i18n from '@/i18n';
import RouterOutlet from '@/components/Dashboard/Partials/RouterOutlet/RouterOutlet';
import Index from '@/views/Dashboard/User/Index';
import Edit from '@/views/Dashboard/User/Edit';
import { USER_LIST, USER_ADD, USER_EDIT } from '@/router/routes.names';
import { USER_INDEX, USER_CREATE, USER_UPDATE } from '@/router/permissions.names';
import { isAuthenticated } from '@/router/permissions';

const userRoutes = [
  {
    path: '/users',
    component: RouterOutlet,
    children: [
      {
        path: '',
        name: USER_LIST,
        component: Index,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.user_list'),
              },
            ];
          },
          permissions: USER_INDEX,
        },
      },
      {
        path: 'add',
        name: USER_ADD,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.user_list'),
                to: { name: USER_LIST },
              },
              {
                label: i18n.t('breadcrumbs.user_add'),
              },
            ];
          },
          permissions: USER_CREATE,
          editMode: false,
        },
      },
      {
        path: ':userId/edit',
        name: USER_EDIT,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.user_list'),
                to: { name: USER_LIST },
              },
              {
                label: i18n.t('breadcrumbs.user_edit'),
              },
            ];
          },
          permissions: USER_UPDATE,
          editMode: true,
        },
      },
    ],
  },
];

export default userRoutes;
