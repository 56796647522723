import i18n from '@/i18n';
import RouterOutlet from '@/components/Dashboard/Partials/RouterOutlet/RouterOutlet';
import Index from '@/views/Dashboard/Game/Index';
import Stages from '@/views/Dashboard/GameStage/Index';
import Badges from '@/views/Dashboard/Badge/Index';
import Pages from '@/views/Dashboard/GamePage/Index';
import Edit from '@/views/Dashboard/Game/Edit';
import EditStage from '@/views/Dashboard/GameStage/Edit';
import EditBadge from '@/views/Dashboard/Badge/Edit';
import EditPage from '@/views/Dashboard/GamePage/Edit';
import {
  GAME_LIST,
  GAME_ADD,
  GAME_EDIT,
  GAME_BADGE_LIST,
  GAME_BADGE_ADD,
  GAME_BADGE_EDIT,
  GAME_PAGE_LIST,
  GAME_PAGE_EDIT,
  GAME_STAGE_LIST,
  GAME_STAGE_ADD,
  GAME_STAGE_EDIT,
} from '@/router/routes.names';
import {
  GAME_INDEX,
  GAME_CREATE,
  GAME_UPDATE,
  GAME_BADGE_INDEX,
  GAME_BADGE_CREATE,
  GAME_BADGE_UPDATE,
  GAME_PAGE_INDEX,
  GAME_PAGE_UPDATE,
  GAME_STAGE_INDEX,
  GAME_STAGE_CREATE,
  GAME_STAGE_UPDATE,
} from '@/router/permissions.names';
import { isAuthenticated } from '@/router/permissions';

const gameRoutes = [
  {
    path: '/games',
    component: RouterOutlet,
    children: [
      {
        path: '',
        name: GAME_LIST,
        component: Index,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.game_list'),
              },
            ];
          },
          permissions: GAME_INDEX,
        },
      },
      {
        path: 'add',
        name: GAME_ADD,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.game_list'),
                to: { name: GAME_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_add'),
              },
            ];
          },
          permissions: GAME_CREATE,
          editMode: false,
        },
      },
      {
        path: ':gameId/edit',
        name: GAME_EDIT,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.game_list'),
                to: { name: GAME_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_edit'),
              },
            ];
          },
          permissions: GAME_UPDATE,
          editMode: true,
        },
      },
      {
        path: ':gameId/stages',
        name: GAME_STAGE_LIST,
        component: Stages,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.game_list'),
                to: { name: GAME_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_stage_list'),
              },
            ];
          },
          permissions: GAME_STAGE_INDEX,
        },
      },
      {
        path: ':gameId/stages/add',
        name: GAME_STAGE_ADD,
        component: EditStage,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.game_list'),
                to: { name: GAME_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_stage_list'),
                to: { name: GAME_STAGE_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_stage_add'),
              },
            ];
          },
          permissions: GAME_STAGE_CREATE,
          editMode: false,
        },
      },
      {
        path: ':gameId/stages/:stageId',
        name: GAME_STAGE_EDIT,
        component: EditStage,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.game_list'),
                to: { name: GAME_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_stage_list'),
                to: { name: GAME_STAGE_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_stage_edit'),
              },
            ];
          },
          permissions: GAME_STAGE_UPDATE,
          editMode: true,
        },
      },
      {
        path: ':gameId/badges',
        name: GAME_BADGE_LIST,
        component: Badges,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.game_list'),
                to: { name: GAME_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_badge_list'),
              },
            ];
          },
          permissions: GAME_BADGE_INDEX,
        },
      },
      {
        path: ':gameId/badges/add',
        name: GAME_BADGE_ADD,
        component: EditBadge,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.game_list'),
                to: { name: GAME_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_badge_list'),
                to: { name: GAME_BADGE_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_badge_add'),
              },
            ];
          },
          permissions: GAME_BADGE_CREATE,
          editMode: false,
        },
      },
      {
        path: ':gameId/badges/:badgeId',
        name: GAME_BADGE_EDIT,
        component: EditBadge,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.game_list'),
                to: { name: GAME_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_badge_list'),
                to: { name: GAME_BADGE_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_badge_edit'),
              },
            ];
          },
          permissions: GAME_BADGE_UPDATE,
          editMode: true,
        },
      },
      {
        path: ':gameId/pages',
        name: GAME_PAGE_LIST,
        component: Pages,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.game_list'),
                to: { name: GAME_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_page_list'),
              },
            ];
          },
          permissions: GAME_PAGE_INDEX,
        },
      },
      {
        path: ':gameId/pages/:pageId',
        name: GAME_PAGE_EDIT,
        component: EditPage,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.game_list'),
                to: { name: GAME_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_page_list'),
                to: { name: GAME_PAGE_LIST },
              },
              {
                label: i18n.t('breadcrumbs.game_page_edit'),
              },
            ];
          },
          permissions: GAME_PAGE_UPDATE,
          editMode: true,
        },
      },
    ],
  },
];

export default gameRoutes;
