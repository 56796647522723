<template>
  <v-menu
    v-model="isOpen"
    offset-y
    elevation="2"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <div
        class="candidate-status-select-label"
        :style="{
          color: `var(--${statusTextColor(value)})`,
          backgroundColor: `var(--${statusBackgroundColor(value)})`,
        }"
        v-on="on"
        @click="openMenu"
      >
        <span>{{ translateStatus({ name: value }) }}</span>
        <div class="candidate-status-select-arrow">
          <div class="candidate-status-select-arrow__icon" />
        </div>
      </div>
    </template>
    <v-card>
      <v-list
        v-if="isLoading"
        class="d-flex justify-center w-full py-4"
      >
        <v-progress-circular
          indeterminate
          :size="14"
          :width="2"
          color="#FCAF3B"
        />
      </v-list>
      <v-list
        v-else
        dense
      >
        <v-card-title class="candidate-status-select-menu">
          <div class="candidate-status-select-menu__title">
            <span>{{ $t("candidate.change_status") }}</span>
            <v-icon class="candidate-status-select-menu__close pl-2">
              mdi-close
            </v-icon>
          </div>
        </v-card-title>
        <v-list-item
          v-for="status in mappedRecruitmentProcessStatuses"
          :key="status.id"
          class="candidate-status-select-list-item"
          :class="{ 'candidate-status-select-list-item--current': status.name === value }"
          @click="select(status)"
        >
          <v-list-item-title>
            {{ translateStatus(status) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
import alerts from '@/plugins/alerts';
import { HTTP_UNAUTHORIZED } from '@/plugins/axios/codes';
import { translateStatus } from '@/plugins/objectHelpers/candidateHelpers';
import fetchCandidateStatuses from '@/components/Dashboard/Candidate/Api/fetchCandidateStatuses';
import * as candidateStageStatues from '@/names/candidateStageStatuses.names';

export default {
  name: 'CandidateStatusSelect',
  props: {
    value: {
      type: String,
      default: '',
    },
    recruitmentProcessStageId: {
      type: String,
      default: '',
    },
    recruitmentProcessId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      recruitmentProcessStatuses: [],
    };
  },
  computed: {
    mappedRecruitmentProcessStatuses() {
      return this.recruitmentProcessStatuses.map((status) => ({
        name: status,
      }));
    },
  },
  methods: {
    ...alerts,
    translateStatus,
    async select(option) {
      this.$emit('input', option.name);
    },
    async openMenu() {
      if (this.isOpen) return;
      try {
        this.isLoading = true;
        await this.fetchCandidateStatuses();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchCandidateStatuses() {
      const {
        data: { statuses },
      } = await fetchCandidateStatuses({
        recruitmentProcessStageId: this.recruitmentProcessStageId,
        recruitmentProcessId: this.recruitmentProcessId,
      });
      this.recruitmentProcessStatuses = statuses;
    },
    statusTextColor(status) {
      const statusColors = {
        [candidateStageStatues.INVITED]: 'orange',
        [candidateStageStatues.ACCEPTED]: 'green',
        [candidateStageStatues.REJECTED]: 'red',
        [candidateStageStatues.RESIGNATION]: 'purple',
        [candidateStageStatues.OTHER]: 'grayLight',
        [candidateStageStatues.STARTED]: 'blue',
        [candidateStageStatues.END_OF_TIME]: 'pink',
        [candidateStageStatues.FINISHED]: 'teal',
      };

      return statusColors[status];
    },
    statusBackgroundColor(status) {
      const statusColors = {
        [candidateStageStatues.INVITED]: 'orangeLight',
        [candidateStageStatues.ACCEPTED]: 'greenLight',
        [candidateStageStatues.REJECTED]: 'redLight',
        [candidateStageStatues.RESIGNATION]: 'purpleLight',
        [candidateStageStatues.OTHER]: 'grayLightest',
        [candidateStageStatues.STARTED]: 'blueLight',
        [candidateStageStatues.END_OF_TIME]: 'pinkLight',
        [candidateStageStatues.FINISHED]: 'tealLight',
      };

      return statusColors[status];
    },
  },
};
</script>
<style lang="scss" scoped>
.candidate-status-select {
  &-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: var(--grayLight);
    background: var(--grayLightest);
    border-radius: 17px;
    padding: 2px 4px;
    cursor: pointer;
    span {
      padding: 0 4px;
    }
  }
  &-menu {
    padding: 4px 8px 8px 16px !important;
    &__title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px !important;
      font-weight: 600;
      color: #263238;
    }
    &__close {
      cursor: pointer;
    }
  }
  &-list-item {
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #263238 !important;
    &:hover {
      color: #fcaf3b !important;
      background: rgba(252, 175, 59, 0.03) !important;
    }
    &--current {
      color: #fcaf3b !important;
      background-color: rgba(252, 175, 59, 0.12) !important;
    }
  }
  &-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #fff;
    min-width: 14px;
    min-height: 14px;
    &__icon {
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 5px solid #737373;
    }
  }
}
</style>
