<template>
  <div class="export-file">
    <ExportLoader
      v-if="isLoading"
      :is-loading="isLoading"
      :title="title"
    />
    <ExportFinish
      v-if="!isLoading && !file"
      @handle-download="generateFile"
    />
    <div v-if="!isLoading && file">
      <component
        :is="componentForFileType.name"
        v-bind="componentForFileType.params"
      />
      <button
        class="export-file__button"
        @click="downloadFile"
      >
        <v-icon color="white">
          mdi-download
        </v-icon>
      </button>
    </div>
  </div>
</template>
<script>
import ExportLoader from '@/components/Dashboard/Partials/Export/ExportLoader';
import ExportFinish from '@/components/Dashboard/Partials/Export/ExportFinish';
import { IMAGE, PDF } from '@/names/fileTypes.names';

export default {
  name: 'ExportFile',
  components: {
    ExportLoader,
    ExportFinish,
    PDFReader: () => import('@/components/Dashboard/Partials/Export/PDFReader'),
  },
  props: {
    file: {
      type: Object,
      default: null,
    },
    fileType: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    componentForFileType() {
      switch (this.fileType) {
        case PDF:
          return {
            name: 'PDFReader',
            params: {
              src: this.file.url.href,
            },
          };
        case IMAGE:
          return {
            name: 'img',
            params: {
              src: this.file.url.href,
              class: 'export-file__image',
            },
          };
        default: return null;
      }
    },
  },
  async created() {
    await this.generateFile();
  },
  mounted() {
    document.addEventListener('keydown', this.handleCtrlS);
  },
  beforeDestroy() {
    this.removeHandleCtrlSListener();
  },
  methods: {
    handleCtrlS(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();

        this.downloadFile();
      }
    },
    removeHandleCtrlSListener() {
      document.removeEventListener('keydown', this.handleCtrlS);
    },
    downloadFile() {
      this.file.url.setAttribute('download', this.file.filename);
      this.file.url.click();
    },
    generateFile() {
      this.$emit('generate');
    },
  },
};
</script>

<style lang="scss" scoped>
.export-file {
  min-height: 100vh;
  margin: 0;
  padding: 0;

  &__button {
    position: fixed;
    bottom: 44px;
    right: 44px;
    background: var(--primary);
    padding: 16px;
    border-radius: 50%;
  }

  &__image {
    display: block;
    margin-top: 72px;
    width: 100%;
    height: calc(100vh - 72px);
    object-fit: contain;
  }
}
</style>
