<template>
  <v-app :style="{ backgroundColor: $vuetify.theme.themes.light.gray }">
    <Topbar
      is-simplify
      @toggle-sidebar="toggleSidebar"
    />
    <FadeTransition mode="out-in">
      <router-view :key="$route.path" />
    </FadeTransition>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import { FadeTransition } from 'vue2-transitions';
import Topbar from '@/components/Dashboard/Partials/Topbar/Topbar';

export default {
  name: 'GenerateFileLayout',
  components: {
    FadeTransition,
    Topbar,
  },
  methods: {
    ...mapActions('layout', ['toggleSidebar']),
  },
};
</script>
