import api from '@/plugins/axios/api';

/**
 * @param {String} recruitmentProcessId
 */
const fetchRecruitmentProcessStatuses = async ({
  recruitmentProcessId,
}) => {
  const { data: { data } } = await api.get(`recruitment-processes/${recruitmentProcessId}/statuses`);

  return data;
};

export default fetchRecruitmentProcessStatuses;
