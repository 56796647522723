import api from '@/plugins/axios/api';

/**
 * @param {String} email
 * @param {String} name
 * @param {String} type
 * @param {String} clientId
 * @param {String} recruitmentProcessesId
 */
const addUser = async ({
  email,
  name,
  type,
  clientId,
  recruitmentProcessesId,
}) => {
  await api.post('users', {
    email,
    name,
    type,
    client_id: clientId,
    recruitment_processes_id: recruitmentProcessesId,
  });
};

export default addUser;
