import i18n from '@/i18n';
import RouterOutlet from '@/components/Dashboard/Partials/RouterOutlet/RouterOutlet';
import Index from '@/views/Dashboard/MessageTemplate/Index';
import Edit from '@/views/Dashboard/MessageTemplate/Edit';
import {
  MESSAGE_TEMPLATE_LIST,
  MESSAGE_TEMPLATE_ADD,
  MESSAGE_TEMPLATE_EDIT,
} from '@/router/routes.names';
import {
  MESSAGE_TEMPLATE_INDEX,
  MESSAGE_TEMPLATE_CREATE,
  MESSAGE_TEMPLATE_UPDATE,
} from '@/router/permissions.names';
import { isAuthenticated } from '@/router/permissions';

const messageTemplateRoutes = [
  {
    path: '/message-template',
    component: RouterOutlet,
    children: [
      {
        path: '',
        name: MESSAGE_TEMPLATE_LIST,
        component: Index,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.message_template_list'),
              },
            ];
          },
          permissions: MESSAGE_TEMPLATE_INDEX,
        },
      },
      {
        path: 'add',
        name: MESSAGE_TEMPLATE_ADD,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.message_template_list'),
                to: { name: MESSAGE_TEMPLATE_LIST },
              },
              {
                label: i18n.t('breadcrumbs.message_template_add'),
              },
            ];
          },
          permissions: MESSAGE_TEMPLATE_CREATE,
          editMode: false,
        },
      },
      {
        path: ':messageTemplateId/edit',
        name: MESSAGE_TEMPLATE_EDIT,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.message_template_list'),
                to: { name: MESSAGE_TEMPLATE_LIST },
              },
              {
                label: i18n.t('breadcrumbs.message_template_edit'),
              },
            ];
          },
          permissions: MESSAGE_TEMPLATE_UPDATE,
          editMode: true,
        },
      },
    ],
  },
];

export default messageTemplateRoutes;
