<template>
  <BaseFilterField
    :id="id"
    :title="label"
    :number-of-filters="value ? value.length : 0"
    @update="onCreated"
  >
    <v-select
      v-model="value"
      :items="items"
      outlined
      dense
      clearable
      item-text="name"
      item-value="value"
      @change="updateFilters"
    />
  </BaseFilterField>
</template>
<script>
import BaseFilterField from '@/components/Dashboard/Candidate/Partials/BaseFilterField';

export default {
  name: 'CheckboxField',
  components: {
    BaseFilterField,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: [],
      items: [
        {
          value: ['1'],
          name: this.$t('candidate.advanced_filter.1'),
        },
        {
          value: ['0'],
          name: this.$t('candidate.advanced_filter.0'),
        },
      ],
    };
  },
  methods: {
    updateFilters() {
      this.$emit('change-filter', { id: this.id, values: this.value });
    },
    onCreated(filter) {
      this.value = filter;
    },
  },
};
</script>
