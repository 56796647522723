import i18n from '@/i18n';
import store from '@/store';
import RouterOutlet from '@/components/Dashboard/Partials/RouterOutlet/RouterOutlet';
import Index from '@/views/Dashboard/Candidate/Index';
import Add from '@/views/Dashboard/Candidate/Add';
import Edit from '@/views/Dashboard/Candidate/Edit';
import { CANDIDATE_LIST, CANDIDATE_INVITATION, CANDIDATE_EDIT } from '@/router/routes.names';
import { CANDIDATE_INDEX, GAME_INVITATIONS_SEND, CANDIDATE_SHOW } from '@/router/permissions.names';
import { isAuthenticated } from '@/router/permissions';

const candidateRoutes = [
  {
    path: '/candidates',
    component: RouterOutlet,
    children: [
      {
        path: '',
        name: CANDIDATE_LIST,
        component: Index,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb() {
            return [
              {
                label: i18n.t('breadcrumbs.candidate_list'),
              },
            ];
          },
          permissions: CANDIDATE_INDEX,
        },
      },
      {
        path: 'add',
        name: CANDIDATE_INVITATION,
        component: Add,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb(route) {
            const candidateListRedirect = route.query.recruitment_process_id
              ? {
                name: CANDIDATE_LIST,
                query: { recruitment_process_id: route.query.recruitment_process_id },
              }
              : { name: CANDIDATE_LIST };

            return [
              {
                label: i18n.t('breadcrumbs.candidate_list'),
                to: candidateListRedirect,
              },
              {
                label: i18n.t('breadcrumbs.candidate_add'),
              },
            ];
          },
          permissions: GAME_INVITATIONS_SEND,
        },
      },
      {
        path: ':candidateId/:recruitmentProcessId/edit',
        name: CANDIDATE_EDIT,
        component: Edit,
        beforeEnter: isAuthenticated,
        meta: {
          breadcrumb(route) {
            return [
              {
                label: i18n.t('breadcrumbs.candidate_list'),
                to: {
                  name: CANDIDATE_LIST,
                  query: { recruitment_process_id: route.params.recruitmentProcessId },
                },
              },
              {
                label: store.getters['candidate/candidateFullName'],
              },
            ];
          },
          permissions: CANDIDATE_SHOW,
          editMode: true,
        },
      },
    ],
  },
];

export default candidateRoutes;
