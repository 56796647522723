import api from '@/plugins/axios/api';

/**
 * @param {?Object} sort
 * @param {?Object} filters
 * @param {?String} search
 * @param {?Number} page
 * @param {?Number} limit
 */
const fetchCompetences = async ({
  sort,
  filters,
  search,
  page,
  limit,
}) => {
  const { data: { data, meta } } = await api.get('competences', {
    params: {
      sort,
      filters,
      search,
      page,
      limit,
    },
  });

  return { data, pagination: meta.pagination };
};

export default fetchCompetences;
