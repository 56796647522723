<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <BaseInput
          v-model="formData.name"
          rules="min:3|max:255|required"
          :label="$t('messageTemplate.create.name')"
        />
        <p class="headline black--text mt-3">
          {{ $t('messageTemplate.body') }}
        </p>
        <v-divider class="mt-3 mb-8" />
        <BaseInput
          v-model="formData.title"
          rules="min:3|max:255|required"
          :label="$t('messageTemplate.create.title')"
        />
        <BaseRichTextEditor
          v-model="formData.body"
          rules="min:3|max:60000|required"
          :label="$t('messageTemplate.create.body')"
          :variables-list="variablesList"
          extended-version
          hide-video-btn
        />
        <div class="pt-2 pb-12">
          <p>{{ $t('messageTemplate.create.variables') }}:</p>
          <span
            v-for="(variableName, index) in variablesList"
            :key="`list-item-${index}`"
            class="d-block"
          >
            <span class="subtitle-2">{{ variableName }}</span> - <span class="text-caption">{{ $t(`messageTemplate.variables.${variableWithoutBrackets(variableName)}`) }}</span>
          </span>
        </div>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseRichTextEditor from '@/components/Form/BaseRichTextEditor';
import BaseInput from '@/components/Form/BaseInput';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import wrapImagesToTable from '@/plugins/ckeditor/wrapImagesToTable';

export default {
  name: 'MessageTemplateForm',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseRichTextEditor,
    BaseInput,
  },
  props: {
    messageTemplate: {
      type: Object,
      default: () => {},
    },
    variablesList: {
      type: Array,
      default: () => [],
    },
    backendErrors: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        name: null,
        title: null,
        body: null,
      },
    };
  },
  created() {
    if (this.editMode) {
      this.formData.name = this.messageTemplate.name;
      this.formData.title = this.messageTemplate.title;
      this.formData.body = this.messageTemplate.body;
    }
  },
  methods: {
    wrapImagesToTable,
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }
      this.$emit('handle-submit', await this.baseFormData());
    },
    async baseFormData() {
      const baseFormData = {
        name: this.formData.name,
        title: this.formData.title,
        body: await wrapImagesToTable(this.formData.body),
      };
      if (!this.editMode) {
        return baseFormData;
      }

      return { ...baseFormData, messageTemplateId: this.$route.params.messageTemplateId };
    },
    variableWithoutBrackets(variable) {
      return variable.replace(/[\])}[{(]/g, '');
    },
  },
};
</script>
