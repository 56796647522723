<template>
  <BaseFilterField
    :id="id"
    :title="label"
    :number-of-filters="(dateFrom ? 1 : 0) + (dateTo ? 1 : 0)"
    @update="onCreated"
  >
    <div class="date-field">
      <div class="date-field__item">
        <BaseDatePicker
          v-if="!isLoading"
          v-model="dateFrom"
          dense
          :has-icon="false"
          :clearable="false"
          @change="change('from')"
        />
      </div>
      <div class="d-flex">
        <span class="mx-auto px-2 pt-2">-</span>
      </div>
      <div class="date-field__item">
        <BaseDatePicker
          v-if="!isLoading"
          v-model="dateTo"
          dense
          :has-icon="false"
          :clearable="false"
          @change="change('to')"
        />
      </div>
    </div>
    <button
      class="date-field__button"
      @click="remove"
    >
      {{ $t('candidate.clear_dates') }}
    </button>
  </BaseFilterField>
</template>
<script>
import BaseDatePicker from '@/components/Form/BaseDatePicker';
import BaseFilterField from '@/components/Dashboard/Candidate/Partials/BaseFilterField';

export default {
  name: 'DateField',
  components: {
    BaseFilterField,
    BaseDatePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      dateFrom: null,
      dateTo: null,
    };
  },
  methods: {
    change(key) {
      if (!this.dateFrom || !this.dateTo) {
        const date = key === 'from' ? this.dateFrom : this.dateTo;
        this.$emit('change-filter', {
          id: this.id,
          values: { [key]: date },
        });

        return;
      }
      this.$emit('change-filter', {
        id: this.id,
        values: { from: this.dateFrom, to: this.dateTo },
      });
    },
    remove() {
      this.isLoading = true;
      this.dateFrom = null;
      this.dateTo = null;
      this.$emit('change-filter', {
        id: this.id,
        values: null,
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 100);
    },
    onCreated(filters) {
      if (filters.from) {
        this.dateFrom = filters.from;
      }
      if (filters.to) {
        this.dateTo = filters.to;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.date-field {
  display: flex;
  &__item {
    max-width: 100px;
    width: 100%;
  }
  &__button {
    font-size: 12px;
    padding: 4px 8px;
    background: var(--primary);
    color: #fff;
    border-radius: 6px;
    margin-bottom: 12px;
  }
}
::v-deep .v-text-field input {
  font-size: 14px !important;
}
</style>
