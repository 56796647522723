<template>
  <ValidationObserver
    ref="form"
  >
    <v-form @submit.prevent="submit">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <v-row>
          <v-col
            sm="12"
            lg="6"
            class="pr-lg-8"
          >
            <BaseInput
              v-model="formData.name"
              rules="min:3|max:255|required"
              :label="$t('gameForm.create.header')"
            />
            <BaseSelect
              v-model="formData.selectedClient"
              rules="select"
              :items="clients"
              :label="$t('gameForm.create.client')"
              :placeholder="$t('gameForm.create.client_hint')"
              item-text="name"
              item-value="id"
              persistent-hint
              searchable
              :loading="isLoading"
              :disabled="isLoading || editMode"
            />
          </v-col>
          <v-col
            sm="12"
            lg="6"
          >
            <div v-if="formData.fields.length">
              <draggable
                class="draggable-panels"
                :list="formData.fields"
                handle=".handle"
                @start="dragStart"
                @end="dragEnd(formData.fields)"
              >
                <component
                  :is="fieldComponentForType(field.type)"
                  v-for="(field, index) in formData.fields"
                  :key="field.id ? field.id : field.temporaryId"
                  v-model="formData.fields[index]"
                  :field-types="mappedFieldTypes"
                  :is-loading="isLoading"
                  :dragging="dragging"
                  @handle-remove="removeField(index)"
                />
              </draggable>
            </div>
            <div
              v-else
              class="d-flex justify-center pt-8 pb-12"
            >
              {{ $t("gameForm.create.fields_empty") }}
            </div>
            <v-row>
              <v-col lg="3">
                <BaseButton
                  class="mt-6"
                  color="grayDark"
                  dark
                  @click="addField"
                >
                  <span class="pl-4">{{ $t("gameForm.create.add") }}</span>
                  <v-icon class="pl-2">
                    mdi-plus
                  </v-icon>
                </BaseButton>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import draggable from 'vuedraggable';
import { ValidationObserver } from 'vee-validate';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseInput from '@/components/Form/BaseInput';
import BaseSelect from '@/components/Form/BaseSelect';
import BaseButton from '@/components/Form/BaseButton';
import TextField from '@/components/Dashboard/GameForm/Fields/TextField';
import RichTextField from '@/components/Dashboard/GameForm/Fields/RichTextField';
import SelectField from '@/components/Dashboard/GameForm/Fields/SelectField';
import MultiselectField from '@/components/Dashboard/GameForm/Fields/MultiselectField';
import NumberField from '@/components/Dashboard/GameForm/Fields/NumberField';
import FileField from '@/components/Dashboard/GameForm/Fields/FileField';
import HeaderField from '@/components/Dashboard/GameForm/Fields/HeaderField';
import CheckboxField from '@/components/Dashboard/GameForm/Fields/CheckboxField';
import DateField from '@/components/Dashboard/GameForm/Fields/DateField';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import * as fieldTypes from '@/components/Dashboard/GameForm/Fields/Field.types';

const FIELD_COMPONENTS = {
  TEXT: 'TextField',
  TEXTAREA: 'RichTextField',
  SELECT: 'SelectField',
  MULTISELECT: 'MultiselectField',
  NUMBER: 'NumberField',
  FILE: 'FileField',
  HEADER: 'HeaderField',
  CHECKBOX: 'CheckboxField',
  DATE: 'DateField',
};

export default {
  name: 'GameFormForm',
  components: {
    draggable,
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseInput,
    BaseSelect,
    BaseButton,
    TextField,
    RichTextField,
    SelectField,
    MultiselectField,
    NumberField,
    FileField,
    HeaderField,
    CheckboxField,
    DateField,
  },
  props: {
    backendErrors: {
      type: Array,
      default: () => [],
    },
    clients: {
      type: Array,
      default: () => [],
    },
    gameForm: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragging: false,
      expandedPanels: [],
      formData: {
        name: '',
        selectedClient: '',
        fields: [],
      },
    };
  },
  computed: {
    mappedFieldTypes() {
      return Object.values(fieldTypes).map((field) => ({
        name: field,
      }));
    },
  },
  created() {
    if (this.editMode) {
      this.formData.name = this.gameForm.name;
      this.formData.selectedClient = this.clients?.find(
        (client) => client.id === this.gameForm.client.data.id,
      ).id;
      this.formData.fields = this.fieldsForFormData();
    }
  },
  methods: {
    fieldComponentForType(type) {
      return FIELD_COMPONENTS[type];
    },
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        const invalidField = document.querySelector('.accordion__invalid-field');
        if (invalidField) {
          invalidField.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }

        return;
      }

      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const baseFormData = {
        name: this.formData.name,
        clientId: this.formData.selectedClient,
        fields: this.formData.fields.map(({ temporaryId, isOpen, ...params }) => ({ ...params })),
      };
      if (!this.editMode) {
        return baseFormData;
      }

      return { ...baseFormData, gameFormId: this.gameForm.id };
    },
    addField() {
      this.formData.fields.push({
        type: fieldTypes.TEXT,
        order: this.formData.fields.length,
        temporaryId: uuidv4(),
        isOpen: true,
      });
    },
    removeField(index) {
      const fields = this.formData.fields.filter((field, itemIndex) => itemIndex !== index);
      this.formData.fields = this.orderedFields(fields);
    },
    dragStart() {
      this.dragging = true;
    },
    dragEnd(fields) {
      this.dragging = false;
      this.formData.fields = this.orderedFields(fields);
    },
    orderedFields(fields) {
      return fields.map((field, index) => ({
        ...field,
        order: index,
      }));
    },
    fieldsForFormData() {
      // eslint-disable-next-line camelcase
      return this.gameForm.fields.data.map(({ form_name, ...params }) => ({
        isOpen: false,
        ...params,
      }));
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-expansion-panel {
  &::before {
    box-shadow: none;
  }
}
.draggable-panels {
  width: 100%;
}
</style>
