<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="label"
    :rules="rules"
  >
    <v-text-field
      v-model="color"
      v-mask="mask"
      class="pt-2 pb-8"
      :error-messages="errors"
      outlined
      hide-details
      :label="label"
    >
      <template v-slot:append>
        <v-menu
          v-model="menu"
          top
          nudge-bottom="15"
          nudge-left="16"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <div
              :style="previewStyles"
              v-on="on"
            />
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker
                v-model="color"
                hide-inputs
              />
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </v-text-field>
  </ValidationProvider>
</template>
<script>
export default {
  name: 'BaseColorPicker',
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
  },
  data() {
    return {
      color: '',
      mask: 'XXXXXXX',
      menu: false,
    };
  },
  computed: {
    previewStyles() {
      return {
        backgroundColor: this.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        marginTop: '-4px',
        border: `1px solid ${this.$vuetify.theme.themes.light.grayLightest}`,
        borderRadius: this.menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
      };
    },
  },
  watch: {
    color(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.color = newVal;
    },
  },
  created() {
    if (this.value) {
      this.color = this.value;
    }
  },
};
</script>
