import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {String} stageId
 */
const deleteGameStage = async ({
  gameId,
  stageId,
}) => {
  await api.delete(`games/${gameId}/game-stages/${stageId}`);
};

export default deleteGameStage;
