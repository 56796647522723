<template>
  <BaseFilterField
    :id="id"
    :title="label"
    :number-of-filters="value ? value.length : 0"
    @update="onCreated"
  >
    <v-autocomplete
      v-model="value"
      :items="items"
      :no-data-text="$t('dashboard.no_data_available')"
      chips
      dense
      multiple
      outlined
      @change="change"
    >
      <template v-slot:selection="{ attrs, item, selected }">
        <v-tooltip
          v-if="item.length > charactersLimit"
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-chip
              class="my-2"
              v-bind="attrs"
              :input-value="selected"
              close
              v-on="on"
              @click:close="remove(item)"
            >
              {{ stringLimit(item, charactersLimit) }}
            </v-chip>
          </template>
          {{ item }}
        </v-tooltip>
        <v-chip
          v-else
          class="my-2"
          v-bind="attrs"
          :input-value="selected"
          close
          @click:close="remove(item)"
        >
          {{ item }}
        </v-chip>
      </template>
    </v-autocomplete>
  </BaseFilterField>
</template>
<script>
import { stringLimit } from '@/plugins/stringHelpers/stringLimit';
import BaseFilterField from '@/components/Dashboard/Candidate/Partials/BaseFilterField';

export default {
  name: 'SelectField',
  components: {
    BaseFilterField,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      charactersLimit: 16,
      value: null,
    };
  },
  methods: {
    stringLimit,
    remove(item) {
      this.value.splice(this.value.indexOf(item), 1);
      this.updateFilters();
    },
    change() {
      this.updateFilters();
    },
    updateFilters() {
      this.$emit('change-filter', { id: this.id, values: this.value });
    },
    onCreated(filter) {
      this.value = filter;
    },
  },
};
</script>
