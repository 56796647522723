<template>
  <div :style="cssProps">
    <Preloader
      v-if="isPreloadingData"
      is-fullscreen
    />
    <v-app v-else>
      <BaseNotifications />
      <router-view />
    </v-app>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import BaseNotifications from '@/components/Dashboard/Partials/Notification/Notification';

export default {
  name: 'App',
  components: {
    Preloader,
    BaseNotifications,
  },
  computed: {
    ...mapState('layout', ['isPreloadingData']),
    cssProps() {
      const themeColors = {};
      Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
        themeColors[`--${color}`] = this.$vuetify.theme.themes.light[color];
      });

      return themeColors;
    },
  },
};
</script>

<style lang="scss">
.v-text-field--outlined fieldset {
  border-color: var(--grayLightest);
}
.v-menu__content {
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.5) !important;
}
.theme--light.v-card > .v-card__text {
  color: rgba(0, 0, 0, 0.87);
}
.v-application a {
  color: var(--grayLight) !important;
  text-decoration: none;
  &.router-link-exact-active {
    color: var(--grayDark) !important;
  }
}
.v-application .accent {
  background-color: red !important;
}
.v-input {
  color: var(--grayDark) !important;
}
.actions-list,
.user-details-list {
  .v-list-item__title {
    font-size: 14px !important;
    color: var(--grayDark) !important;
  }
}
.v-list-item {
  &:hover {
    background: var(--gray) !important;
  }
}
</style>
