import api from '@/plugins/axios/api';

/**
 * @param {String} workPositionId
 */
const deleteWorkPosition = async ({
  workPositionId,
}) => {
  await api.delete(`work-positions/${workPositionId}`);
};

export default deleteWorkPosition;
