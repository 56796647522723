import api from '@/plugins/axios/api';

/**
 * @param {Array} includes
 * @param {?Number} page
 * @param {?Number} limit
 * @param {?Object} sort
 * @param {?Object} filters
 * @param {?String} search
 * @param {String} recruitmentProcessId
 * @param {String} cancelToken
 */
const fetchCandidates = async ({
  includes,
  page,
  limit,
  sort,
  filters,
  search,
  recruitmentProcessId,
  cancelToken,
}) => {
  const { data: { data, meta } } = await api.get(`recruitment-processes/${recruitmentProcessId}/candidates`, {
    params: {
      includes,
      page,
      limit,
      sort,
      filters,
      search,
    },
    cancelToken: cancelToken.token,
  });

  return { data, pagination: meta.pagination };
};

export default fetchCandidates;
