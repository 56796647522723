import api from '@/plugins/axios/api';

/**
 * @param {String} userId
 * @param {Array} includes
 */
const fetchUser = async ({
  userId,
  includes,
}) => {
  const { data } = await api.get(`users/${userId}`, {
    params: { includes },
  });

  return data.data;
};

export default fetchUser;
