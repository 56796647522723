import api from '@/plugins/axios/api';

/**
 * @param {Number} limit
 * @param {?Object} filters
 * @param {Array} includes
 * @param {?Object} sort
 * @param {?String} search
 * @param {?Number} page
 */
const fetchRecruitmentProcesses = async ({
  limit,
  filters,
  includes,
  sort,
  search,
  page,
}) => {
  const { data: { data, meta } } = await api.get('recruitment-processes', {
    params: {
      limit,
      filters,
      includes,
      sort,
      search,
      page,
    },
  });

  return { data, pagination: meta.pagination };
};

export default fetchRecruitmentProcesses;
