<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="$attrs.label"
    :rules="rules"
  >
    <v-checkbox
      v-model="innerValue"
      :error-messages="errors"
      :no-data-text="$t('dashboard.no_data_available')"
      outlined
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template slot="label">
        <div class="pl-4">
          <p class="black--text mb-0">
            {{ label }}
          </p>
          <span
            v-if="description"
            class="d-block body-2 pt-1"
            v-html="sanitizeHtml(description)"
          />
          <span
            v-if="date"
            class="d-block body-2 pt-1"
          >
            {{ date }}
          </span>
        </div>
      </template>
    </v-checkbox>
  </ValidationProvider>
</template>

<script>
import sanitize from '@/plugins/sanitize/sanitizeHtml';

export default {
  name: 'BaseCheckbox',
  props: {
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: Boolean,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    ...sanitize,
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-input--selection-controls {
  margin: 0;
  padding: 0;
}
</style>
