<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <div class="reset-form d-flex">
      <template>
        <div class="d-flex flex-column w-full">
          <div class="mt-16 mb-10 mx-3">
            <p class="headline mb-2 black--text">
              {{ $t("auth.reset.title") }}
            </p>
            <p class="subheading">
              {{ $t("auth.reset.subtitle") }}
            </p>
          </div>
          <v-form
            class="mx-3 d-flex flex-column justify-space-between"
            style="height: 100%"
            @submit.prevent="handleSubmit(submit)"
          >
            <div>
              <BaseInput
                v-model="password"
                vid="password"
                rules="required|min:8|password"
                :label="$t('auth.form.password_new')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              />
              <BaseInput
                v-model="password_confirmation"
                rules="required|confirmed:password"
                :label="$t('auth.form.password_confirmation')"
                :append-icon="
                  showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'
                "
                :type="showPasswordConfirmation ? 'text' : 'password'"
                @click:append="
                  showPasswordConfirmation = !showPasswordConfirmation
                "
              />
              <p
                v-if="isTokenInvalid"
                class="red--text"
              >
                {{ $t("auth.reset.invalid_token") }}
              </p>
              <p
                v-if="isInvalid"
                class="validationError red--text"
              >
                <span
                  v-for="(error, index) in errors"
                  :key="`error-${index}`"
                >
                  {{ error }}
                </span>
              </p>
            </div>
            <div>
              <v-layout
                pt-6
                justify-space-between
              >
                <BaseButton
                  block
                  :loading="isLoading"
                  type="submit"
                >
                  {{ $t("auth.reset.button") }}
                </BaseButton>
              </v-layout>
              <v-layout
                pt-4
                justify-space-between
              >
                <BaseButton
                  block
                  outlined
                  @click="navigateToLogin"
                >
                  {{ $t("auth.back_to_login") }}
                </BaseButton>
              </v-layout>
            </div>
          </v-form>
        </div>
      </template>
    </div>
  </ValidationObserver>
</template>
<script>
import { mapActions } from 'vuex';
import BaseButton from '@/components/Form/BaseButton';
import BaseInput from '@/components/Form/BaseInput';
import { LOGIN, PASSWORD_CHANGED } from '@/router/routes.names';
import {
  HTTP_BAD_REQUEST,
  HTTP_UNPROCESSABLE_ENTITY,
} from '@/plugins/axios/codes';

export default {
  name: 'ResetPasswordForm',
  components: {
    BaseButton,
    BaseInput,
  },

  data() {
    return {
      showPassword: false,
      showPasswordConfirmation: false,
      isLoading: false,
      isInvalid: false,
      isTokenInvalid: false,
      password: '',
      password_confirmation: '',
      errors: [],
    };
  },

  methods: {
    ...mapActions('auth', ['resetPassword']),
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      try {
        this.errors = [];
        this.isLoading = true;
        await this.resetPassword({
          email: this.$route.query.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          token: this.$route.query.token,
        });
        this.$router.push({ name: PASSWORD_CHANGED });
      } catch (e) {
        if (e.response?.status === HTTP_BAD_REQUEST) {
          this.isTokenInvalid = true;

          return;
        }
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));
          this.isInvalid = true;

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    navigateToLogin() {
      this.$router.push({ name: LOGIN });
    },
  },
};
</script>
<style lang="scss" scoped>
.reset-form {
  min-height: 490px;
}
.resend-button {
  padding: 0;
  text-transform: none;
}
.w-full {
  width: 100%;
}
.validationError {
  span {
    display: block;
  }
}
</style>
