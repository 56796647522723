import api from '@/plugins/axios/api';

/**
 * @param {String} clientId
 * @param {String} gameId
 */
const copyGame = async ({
  clientId,
  gameId,
}) => {
  await api.post(`games/${gameId}/copy/${clientId}`);
};

export default copyGame;
