import api from '@/plugins/axios/api';

/**
 * @param {String} recruitmentProcessId
 * @param {String} stageId
 * @param {?Object} filters
 */
const fetchRecruitmentProcessesDefaultMessageTemplates = async ({
  recruitmentProcessId,
  stageId = null,
  filters,
}) => {
  const {
    data: { data },
  } = await api.get(
    stageId
      ? `recruitment-processes/${recruitmentProcessId}/stages/${stageId}/default-message-templates`
      : `recruitment-processes/${recruitmentProcessId}/default-message-templates`,
    {
      params: {
        filters,
      },
    },
  );

  return data;
};

export default fetchRecruitmentProcessesDefaultMessageTemplates;
