<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <v-row>
          <v-col
            sm="12"
            lg="6"
            class="pr-lg-8"
          >
            <BaseInput
              v-model="formData.name"
              rules="min:3|max:255|required"
              :label="$t('recruitment.create.name')"
            />
            <BaseSelect
              v-model="formData.workPosition"
              rules="select"
              :items="workPositions"
              :label="$t('recruitment.create.work_position')"
              item-text="name"
              item-value="id"
              persistent-hint
              :loading="isLoading"
              :disabled="isLoading || editMode"
            />
            <BaseInput
              v-model="formData.workPositionDisplayName"
              rules="min:3|max:255"
              :label="$t('recruitment.create.work_position_display_name')"
            />
            <BaseSelect
              v-model="formData.selectedRecruiters"
              :items="isRecruiter ? formData.selectedRecruiters : recruiters"
              :label="$t('recruitment.create.recruiters')"
              item-text="name"
              item-value="id"
              persistent-hint
              multiple
              chips
              return-object
              :loading="isLoading"
              :disabled="isLoading || isRecruiter"
            />
            <BaseTextarea
              v-model="formData.note"
              rules="min:3|max:3000"
              mode="eager"
              :label="$t('recruitment.create.notes')"
            />
            <div class="switch">
              <div>
                <span class="d-block black--text mb-1">{{ formData.isOpen ? $t("recruitment.create.is_open") : $t("recruitment.create.is_closed") }}</span>
                <span class="d-block body-2">
                  {{ formData.isOpen ? $t("recruitment.create.is_open_desc") : $t("recruitment.create.is_closed_desc") }}
                </span>
              </div>
              <v-switch v-model="formData.isOpen" />
            </div>
            <div
              v-if="formData.isOpen && formData.registrationUrl"
              class="registration-url"
            >
              <BaseInput
                v-model="formData.registrationUrl"
                rules="min:3|max:255"
                disabled
                :label="$t('recruitment.create.registration_url')"
              />
              <CopyText
                :content="formData.registrationUrl"
                show-button
              />
            </div>
          </v-col>
          <v-col
            sm="12"
            lg="6"
          >
            <p class="title pb-2">
              {{ $t("recruitment.create.stages") }}
            </p>
            <BaseSelect
              v-model="formData.recruitmentStage1"
              rules="select"
              :items="games"
              :label="$t('recruitment.create.stage_one')"
              item-text="name"
              item-value="id"
              persistent-hint
              :loading="isLoading"
              :disabled="isLoading || editMode"
            />
            <BaseSelect
              v-model="formData.recruitmentStage2"
              :items="games"
              :label="$t('recruitment.create.stage_two')"
              item-text="name"
              item-value="id"
              persistent-hint
              clearable
              :loading="isLoading"
              :disabled="isLoading || editMode"
            />
            <BaseSelect
              v-model="formData.otherRecruitmentStages"
              :items="stageTypes"
              :label="$t('recruitment.create.other_stages')"
              item-text="name"
              item-value="type"
              persistent-hint
              multiple
              chips
              return-object
              :loading="isLoading"
              :disabled="isLoading || editMode"
            />
            <p class="title pb-2">
              {{ $t("recruitment.create.report") }}
            </p>
            <BaseSelect
              v-model="formData.reportType"
              rules="select"
              :items="reportTemplateTypes"
              :label="$t('recruitment.create.report_template')"
              item-text="name"
              item-value="type"
              persistent-hint
              :loading="isLoading"
              :disabled="isLoading"
            />
            <BaseTextarea
              v-model="formData.reportDescription"
              rules="min:3|max:3000"
              mode="eager"
              :label="$t('recruitment.create.report_description')"
              :placeholder="reportDescriptionPlaceholder"
              persistent-placeholder
            />
          </v-col>
        </v-row>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseInput from '@/components/Form/BaseInput';
import BaseSelect from '@/components/Form/BaseSelect';
import BaseTextarea from '@/components/Form/BaseTextarea';
import CopyText from '@/components/Form/CopyText';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import { GAME } from '@/names/recruitmentStages.names';
import { REPORT_TEMPLATE, REPORT_DESCRIPTION } from '@/names/recruitmentOptions.names';

export default {
  name: 'RecruitmentForm',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseInput,
    BaseSelect,
    BaseTextarea,
    CopyText,
  },
  props: {
    stageTypes: {
      type: Array,
      default: () => [],
    },
    reportTemplateTypes: {
      type: Array,
      default: () => [],
    },
    recruitmentProcess: {
      type: Object,
      default: () => {},
    },
    workPositions: {
      type: Array,
      default: () => [],
    },
    games: {
      type: Array,
      default: () => [],
    },
    secondStageGames: {
      type: Array,
      default: () => [],
    },
    recruiters: {
      type: Array,
      default: () => [],
    },
    backendErrors: {
      type: Array,
      default: () => [],
    },
    client: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        name: '',
        workPositionDisplayName: '',
        workPosition: '',
        recruitmentStage1: '',
        recruitmentStage2: '',
        otherRecruitmentStages: [],
        selectedRecruiters: [],
        reportType: null,
        reportDescription: '',
        note: '',
        isOpen: false,
        registrationUrl: '',
      },
      reportDescriptionPlaceholder: null,
    };
  },
  computed: {
    ...mapGetters('session', ['isRecruiter']),
  },
  created() {
    this.reportDescriptionPlaceholder = this.client.options?.data.find((option) => option.key === REPORT_DESCRIPTION).value;
    if (this.editMode) {
      this.formData.name = this.recruitmentProcess.name;
      this.formData.workPositionDisplayName = this.recruitmentProcess.work_position_display_name;
      this.formData.workPosition = this.workPositions.find(
        (workPosition) => workPosition.id === this.recruitmentProcess.work_position.data.id,
      ).id;
      this.formData.selectedRecruiters = this.recruitmentProcess.managing_users.data;
      this.formData.reportType = this.recruitmentProcess.options?.data.find((option) => option.key === REPORT_TEMPLATE)?.value;
      this.formData.note = this.recruitmentProcess.note;
      this.formData.reportDescription = this.recruitmentProcess.options?.data.find(
        (option) => option.key === REPORT_DESCRIPTION,
      )?.value;
      this.formData.isOpen = this.recruitmentProcess.is_open;
      if (this.recruitmentProcess.is_open) {
        this.formData.registrationUrl = this.recruitmentProcess.registration_url;
      }
      const gameStages = this.recruitmentProcess.stages.data.filter((stage) => stage.type === GAME);
      const otherStages = this.recruitmentProcess.stages.data.filter((stage) => stage.type !== GAME);
      if (gameStages.length) {
        this.formData.recruitmentStage1 = gameStages[0].stageable_id;
        if (gameStages.length > 1) {
          this.formData.recruitmentStage2 = gameStages[1].stageable_id;
        }
      }
      if (otherStages.length) {
        const mapOtherStages = otherStages.map((stage) => stage.type);
        this.formData.otherRecruitmentStages = this.stageTypes.filter((stage) => mapOtherStages.includes(stage.type));
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      let baseFormData = {
        name: this.formData.name,
        workPositionDisplayName: this.formData.workPositionDisplayName,
        workPositionId: this.formData.workPosition,
        stages: this.stages(),
        reportType: this.formData.reportType,
        reportDescription: this.formData.reportDescription,
        note: this.formData.note,
        isOpen: this.formData.isOpen,
      };
      if (!this.isRecruiter) {
        baseFormData = {
          ...baseFormData,
          recruiters: this.formData.selectedRecruiters.map((recruiter) => recruiter.id),
        };
      }
      if (!this.editMode) {
        return baseFormData;
      }

      return {
        ...baseFormData,
        recruitmentProcessId: this.$route.params.recruitmentProcessId,
      };
    },
    stages() {
      const stages = [];
      const stage1 = this.formData.recruitmentStage1;
      const stage2 = this.formData.recruitmentStage2;
      const otherStages = this.formData.otherRecruitmentStages;
      let order = 0;

      if (stage1) {
        stages.push(this.createStage(GAME, order, stage1));
        order += 1;
      }

      if (stage2) {
        stages.push(this.createStage(GAME, order, stage2));
        order += 1;
      }

      if (otherStages.length > 0) {
        otherStages.forEach(({ type }) => {
          stages.push(this.createStage(type, order));
          order += 1;
        });
      }

      return stages;
    },
    createStage(type, order, stageId = null) {
      if (type === GAME) {
        return {
          type,
          stageable_id: stageId,
          order,
        };
      }

      return {
        type,
        order,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.switch {
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
}
.registration-url {
  display: flex;
  justify-content: space-between;
  span {
    width: 100%;
    margin-right: 10px;
  }
}
</style>
