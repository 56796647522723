import api from '@/plugins/axios/api';

/**
 * @param {String} recruitmentProcessId
 * @param {String} candidateId
 */
const candidateFiles = async ({ candidateId, recruitmentProcessId }) => {
  const response = await api.get(
    `recruitment-processes/${recruitmentProcessId}/candidates/${candidateId}/files`,
    {
      responseType: 'blob',
    },
  );

  return response;
};

export default candidateFiles;
