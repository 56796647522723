<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <v-row>
          <v-col
            sm="12"
            md="6"
            class="pr-md-2 pr-lg-8"
          >
            <BaseInput
              v-model="formData.name"
              rules="min:3|max:255|required"
              :label="$t('game.create.name')"
            />
            <BaseInput
              v-model="formData.metaTitle"
              rules="min:3|max:255|required"
              :label="$t('game.create.metaTitle')"
            />
            <BaseInput
              v-model="formData.topBarTitle"
              rules="min:3|max:255|required"
              :label="$t('game.create.topBarTitle')"
            />
            <BaseSelect
              v-model="formData.selectedClientId"
              rules="select"
              :items="clients"
              :label="$t('game.create.client')"
              :placeholder="$t('game.create.client_hint')"
              item-text="name"
              item-value="id"
              persistent-hint
              :loading="isLoading"
              :disabled="isLoading || editMode"
              searchable
              @change="updateClientId"
            />
            <BaseInput
              v-model="formData.timeLimit"
              v-mask="'##h:##m:##s'"
              rules="time_h_m_s"
              :label="$t('game.create.limit')"
              :placeholder="$t('game.create.limit_hint')"
            />
            <div class="switch">
              <div>
                <span class="d-block black--text mb-1">{{ formData.active ? $t("game.create.active") : $t("game.create.draft") }}</span>
                <span class="d-block body-2">
                  {{ formData.active ? $t("game.create.active_desc") : $t("game.create.draft_desc") }}
                </span>
              </div>
              <v-switch v-model="formData.active" />
            </div>
          </v-col>
          <v-col
            sm="12"
            md="6"
          >
            <BaseSelect
              v-model="formData.selectedGameType"
              rules="select"
              :items="gameTypes"
              :label="$t('game.create.type')"
              :placeholder="$t('game.create.type_hint')"
              item-text="name"
              item-value="value"
              persistent-hint
              return-object
              :loading="isLoading"
              :disabled="isLoading"
            />
            <BaseSelect
              v-model="formData.selectedLocale"
              rules="select"
              :items="locales"
              :label="$t('game.create.locale')"
              :placeholder="$t('game.create.locale_hint')"
              item-text="name"
              item-value="value"
              persistent-hint
              return-object
              :loading="isLoading"
              :disabled="isLoading"
            />
            <BaseSelect
              v-model="formData.languageCollection"
              :items="collectionGames"
              :label="$t('game.create.other_games')"
              item-text="name"
              item-value="id"
              persistent-hint
              multiple
              return-object
              :loading="isLoading"
              :disabled="isLoading"
            />
            <BaseSelect
              v-model="formData.selectedGameForm"
              :items="gameForms"
              :label="$t('game.create.recruitment_form')"
              item-text="name"
              item-value="id"
              persistent-hint
              return-object
              clearable
              :loading="isLoading || isSelectedClientDataLoading"
              :disabled="isLoading || isSelectedClientDataLoading || !formData.selectedClientId"
            />
            <BaseSelect
              v-model="formData.selectedInvitationMessage"
              rules="select"
              :items="messageTemplates"
              :label="$t('game.create.invitation_message')"
              item-text="name"
              item-value="id"
              persistent-hint
              return-object
              :loading="isLoading || isSelectedClientDataLoading"
              :disabled="isLoading || isSelectedClientDataLoading || !formData.selectedClientId"
            />
            <BaseSelect
              v-model="formData.selectedReminderMessage"
              rules="select"
              :items="messageTemplates"
              :label="$t('game.create.reminder_message')"
              item-text="name"
              item-value="id"
              persistent-hint
              return-object
              :loading="isLoading || isSelectedClientDataLoading"
              :disabled="isLoading || isSelectedClientDataLoading || !formData.selectedClientId"
            />
            <BaseSelect
              v-model="formData.selectedResultMessage"
              rules="select"
              :items="messageTemplates"
              :label="$t('game.create.result_message')"
              item-text="name"
              item-value="id"
              persistent-hint
              return-object
              :loading="isLoading || isSelectedClientDataLoading"
              :disabled="isLoading || isSelectedClientDataLoading || !formData.selectedClientId"
            />
          </v-col>
        </v-row>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import { convertSecondsToHms } from '@/plugins/dates/convertSecondsToHms';
import { convertHmsToSeconds } from '@/plugins/dates/convertHmsToSeconds';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseInput from '@/components/Form/BaseInput';
import BaseSelect from '@/components/Form/BaseSelect';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import {
  CANDIDATE_INVITATION,
  CANDIDATE_REMINDER,
  CANDIDATE_RESULT,
} from '@/names/gameMessageTypes.names';
import { LANGUAGE } from '@/names/gameCollections.names';

export default {
  name: 'GameForm',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseInput,
    BaseSelect,
  },
  props: {
    collectionGames: {
      type: Array,
      default: () => [],
    },
    game: {
      type: Object,
      default: () => {},
    },
    gameTypes: {
      type: Array,
      default: () => [],
    },
    locales: {
      type: Array,
      default: () => [],
    },
    clients: {
      type: Array,
      default: () => [],
    },
    messageTemplates: {
      type: Array,
      default: () => [],
    },
    gameForms: {
      type: Array,
      default: () => [],
    },
    backendErrors: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSelectedClientDataLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        name: '',
        selectedGameType: '',
        selectedLocale: '',
        selectedGameForm: '',
        selectedInvitationMessage: '',
        selectedReminderMessage: '',
        selectedResultMessage: '',
        selectedClientId: '',
        timeLimit: '',
        languageCollection: [],
        active: false,
      },
    };
  },
  created() {
    if (this.editMode) {
      this.formData.name = this.game.name;
      this.formData.metaTitle = this.game.meta_title;
      this.formData.topBarTitle = this.game.top_bar_title;
      this.formData.selectedGameType = this.gameTypes.find((type) => type.value === this.game.type);
      this.formData.selectedLocale = this.locales.find((locale) => locale.value === this.game.locale);
      this.formData.selectedGameForm = this.gameForms.find(
        (gameForm) => gameForm.id === this.game.form?.data?.id,
      );
      this.formData.selectedInvitationMessage = this.game.message_templates.data.find(
        (messageTemplate) => messageTemplate.type === CANDIDATE_INVITATION,
      );
      this.formData.selectedReminderMessage = this.game.message_templates.data.find(
        (messageTemplate) => messageTemplate.type === CANDIDATE_REMINDER,
      );
      this.formData.selectedResultMessage = this.game.message_templates.data.find(
        (messageTemplate) => messageTemplate.type === CANDIDATE_RESULT,
      );
      this.formData.selectedClientId = this.clients?.find(
        (client) => client.id === this.game.client.data.id,
      );
      if (this.game.time_limit) {
        this.formData.timeLimit = this.convertSecondsToHms(this.game.time_limit);
      }
      if (this.game.collections?.data?.find((messageTemplate) => messageTemplate.type === LANGUAGE)) {
        const languageCollectionIds = this.game.collections?.data?.find((collection) => collection.type === LANGUAGE).games?.data?.map((game) => game.id);
        this.formData.languageCollection = this.collectionGames.filter((collectionGame) => languageCollectionIds.includes(collectionGame.id));
      }
      this.formData.active = this.game.activated_at !== null;
    }
  },
  methods: {
    convertSecondsToHms,
    convertHmsToSeconds,
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const {
        name,
        metaTitle,
        topBarTitle,
        selectedLocale,
        selectedGameType,
        selectedGameForm,
        selectedClientId,
        timeLimit,
        selectedInvitationMessage,
        selectedReminderMessage,
        selectedResultMessage,
        languageCollection,
        active,
      } = this.formData;
      const baseFormData = {
        name,
        metaTitle,
        topBarTitle,
        locale: selectedLocale.value,
        type: selectedGameType.value,
        gameFormId: selectedGameForm ? selectedGameForm.id : null,
        clientId: selectedClientId,
        timeLimit: this.convertHmsToSeconds(timeLimit),
        messageTemplates: [
          {
            type: CANDIDATE_INVITATION,
            id: selectedInvitationMessage.id,
          },
          {
            type: CANDIDATE_REMINDER,
            id: selectedReminderMessage.id,
          },
          {
            type: CANDIDATE_RESULT,
            id: selectedResultMessage.id,
          },
        ],
        languageCollection: languageCollection.map((collection) => collection.id),
        active,
      };
      if (!this.editMode) {
        return baseFormData;
      }

      return { ...baseFormData, gameId: this.game.id };
    },
    updateClientId() {
      this.$emit('fetch-selected-client-data', this.formData.selectedClientId);
    },
  },
};
</script>
<style lang="scss" scoped>
.switch {
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
}
</style>
