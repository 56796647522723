import Vue from 'vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import VueMask from 'v-mask';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Notifications from '@/plugins/notifications/notifications';
import Gallery from 'vue-easy-lightbox';
import Multiselect from 'vue-multiselect';
import vClickOutside from 'v-click-outside';

Vue.use(VueMask);
Vue.use(CKEditor);
Vue.use(Notifications);
Vue.component(VueCountdown.name, VueCountdown);
Vue.component('LightBox', Gallery);
Vue.component('Multiselect', Multiselect);
Vue.use(vClickOutside);
