import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {String} pageId
 * @param {Object} params
 */
const editGamePage = async ({
  gameId,
  pageId,
  params,
}) => {
  await api.post(`games/${gameId}/game-pages/${pageId}`, params);
};

export default editGamePage;
