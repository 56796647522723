<template>
  <div
    v-if="hasErrors"
    class="backend-errors"
  >
    <v-alert
      :text="true"
      type="error"
    >
      <span
        v-for="(error, index) in backendErrors"
        :key="`error-${index}`"
      >
        {{ error }}
      </span>
    </v-alert>
  </div>
</template>
<script>
export default {
  name: 'BackendErrors',
  props: {
    backendErrors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasErrors() {
      return !!this.backendErrors.length;
    },
  },
};
</script>
<style lang="scss" scoped>
.backend-errors {
  color: var(--red);
  span {
    display: block;
  }
}
</style>
