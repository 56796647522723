import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {String} badgeId
 */
const deleteBadge = async ({
  gameId,
  badgeId,
}) => {
  await api.delete(`games/${gameId}/game-badges/${badgeId}`);
};

export default deleteBadge;
