<template>
  <v-app :style="{ backgroundColor: $vuetify.theme.themes.light.gray }">
    <Topbar @toggle-sidebar="toggleSidebar" />
    <Sidebar />
    <v-main
      flex
      justify-center
      style="padding-top: 72px"
    >
      <Breadcrumbs />
      <FadeTransition mode="out-in">
        <router-view :key="$route.path" />
      </FadeTransition>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import { FadeTransition } from 'vue2-transitions';
import Topbar from '@/components/Dashboard/Partials/Topbar/Topbar';
import Sidebar from '@/components/Dashboard/Partials/Sidebar/Sidebar';
import Breadcrumbs from '@/components/Dashboard/Partials/Breadcrumbs/Breadcrumbs';

export default {
  name: 'DashboardLayout',
  components: {
    FadeTransition,
    Topbar,
    Sidebar,
    Breadcrumbs,
  },
  methods: {
    ...mapActions('layout', ['toggleSidebar']),
  },
};
</script>
