import { PL, EN } from '@/names/locale.names';
import { getCookie } from '@/plugins/cookies';

const LOCALE = 'locale';

export const computed = {
  computed: {
    locale() {
      switch (this.activeLocale) {
        case PL:
          return 'pl-PL';
        case EN:
          return 'en-EN';
        default:
          return 'en-EN';
      }
    },
  },
};
export const methods = {
  methods: {
    currentLocale() {
      if (getCookie(LOCALE) !== undefined) {
        return getCookie(LOCALE);
      }

      return EN;
    },
  },
};
