import api from '@/plugins/axios/api';

/**
 * @param {?Number} limit
 * @param {?Object} sort
 * @param {?Object} filters
 */
const fetchTags = async ({
  limit,
  sort,
  filters,
}) => {
  const { data: { data, meta } } = await api.get('game-tags', {
    params: {
      limit,
      sort,
      filters,
    },
  });

  return { data, pagination: meta.pagination };
};

export default fetchTags;
