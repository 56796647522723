import api from '@/plugins/axios/api';

/**
 * @param {?Object} sort
 * @param {?Object} filters
 */
const exportAnalyticsData = async ({
  sort,
  filters,
}) => {
  const response = await api.get('game-session-event-logs/export-analytics-data', {
    params: {
      sort,
      filters,
    },
    responseType: 'blob',
  });

  return response;
};

export default exportAnalyticsData;
