<template>
  <div>
    <div
      v-if="isPreloadingData"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="wrapper px-3 px-lg-6 py-6"
    >
      <CandidateCardForm
        :edit-mode="editMode"
        :candidate-card="candidateCard"
        :is-loading="isLoading"
        :is-sending="isSending"
        :backend-errors="errors"
        @handle-submit="submit"
        @handle-cancel="returnToList"
        @has-form-changes="hasFormChanges"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import alerts from '@/plugins/alerts';
import { CANDIDATE_LIST, NOT_FOUND } from '@/router/routes.names';
import { HTTP_NOT_FOUND, HTTP_UNAUTHORIZED, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { HAS_FORM_CHANGES } from '@/names/emits.names';
import fetchCandidate from '@/components/Dashboard/Candidate/Api/fetchCandidate';
import fetchCandidateCard from '@/components/Dashboard/CandidateCard/fetchCandidateCard';
import editCandidateCard from '@/components/Dashboard/CandidateCard/editCandidateCard';
import CandidateCardForm from '@/components/Dashboard/CandidateCard/Form';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';

export default {
  name: 'CandidateCard',
  components: {
    Preloader,
    CandidateCardForm,
  },
  data() {
    return {
      candidateCard: null,
      isSending: false,
      isLoading: false,
      isPreloadingData: false,
      editMode: false,
      errors: [],
    };
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    ...mapActions('candidate', ['setCandidate']),
    ...alerts,
    async fetchData() {
      this.editMode = this.$route.meta.editMode;
      try {
        this.isPreloadingData = true;
        await Promise.all([
          this.fetchCandidate(),
          this.fetchCandidateCard(),
        ]);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_NOT_FOUND) {
          this.$router.push({ name: NOT_FOUND });

          return;
        }
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isPreloadingData = false;
      }
    },
    async fetchCandidateCard() {
      const { candidateId, recruitmentProcessId } = this.$route.params;
      const { data } = await fetchCandidateCard({
        candidateId,
        recruitmentProcessId,
      });
      this.candidateCard = data;
    },
    async fetchCandidate() {
      const { candidateId, recruitmentProcessId } = this.$route.params;
      const { data } = await fetchCandidate({
        recruitmentProcessId,
        candidateId,
      });
      this.setCandidate(data);
    },
    returnToList() {
      this.$router.push({ name: CANDIDATE_LIST, query: { recruitment_process_id: this.$route.params.recruitmentProcessId } });
    },
    async submit(payload) {
      try {
        this.errors = [];
        this.isSending = true;
        await editCandidateCard(payload);
        await this.fetchData();
        this.$notify.save.success();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isSending = false;
        this.$emit(HAS_FORM_CHANGES, false);
      }
    },
    hasFormChanges(changed) {
      this.$emit(HAS_FORM_CHANGES, changed);
    },
  },
};
</script>
