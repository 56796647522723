import * as names from '@/store/auth/mutations.names';

export default {
  [names.SET_USER](state, data) {
    state.user = data;
  },

  [names.CLEAR_AUTH](state) {
    state.user = null;
  },
};
