export const props = {
  props: {
    fieldTypes: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    dragging: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
};
export const variables = {
  data() {
    return {
      hasInvalidFields: false,
      fields: [],
    };
  },
};
export const computed = {
  computed: {
    uniqueKey() {
      return this.value.id ? this.value.id : this.value.temporaryId;
    },
  },
};
export const methods = {
  methods: {
    updateValue(data) {
      this.$emit('input', { ...this.value, ...data });
    },
    updateType(data) {
      this.$emit('input', this.resetData({ ...this.value, ...data }));
    },
    resetData(data) {
      return (({
        id, temporaryId, type, order, isOpen, name,
      }) => ({
        id, temporaryId, type, order, isOpen, name,
      }))(data);
    },
    validateField({ label, isValid }) {
      const newField = {
        label,
        isValid,
      };
      const fieldExists = this.fields.some((field) => (
        field.label === label
      ));
      if (!fieldExists) {
        this.fields.push(newField);
        this.checkInvalidFields();

        return;
      }
      const foundIndex = this.fields.findIndex((field) => field.label === label);
      this.fields[foundIndex] = newField;
      this.checkInvalidFields();
    },
    checkInvalidFields() {
      this.hasInvalidFields = this.fields.some((field) => (
        field.isValid === false
      ));
    },
  },
};
