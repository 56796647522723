<template>
  <v-expansion-panels>
    <v-expansion-panel class="mr-1">
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <span
            class="base-text body-2"
            :class="{'title-one-line': !open}"
          >
            {{ title }}
            <span
              v-if="numberOfFilters"
              class="font-weight-bold"
            >
              ({{ numberOfFilters }})
            </span>
          </span>
        </template>
        <template v-slot:actions>
          <v-icon>
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  name: 'BaseFilterField',
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    numberOfFilters: {
      type: Number,
      default: 0,
    },
  },
  created() {
    if (this.$route.query?.filters?.form_fields && this.$route.query?.filters?.form_fields[this.id]) {
      this.$emit('update', this.$route.query?.filters?.form_fields[this.id]);
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: auto;
}
::v-deep .v-expansion-panel::before {
  box-shadow: none;
}
::v-deep .v-expansion-panel,
::v-deep .v-expansion-panel-header,
::v-deep .v-expansion-panel-content {
  transition: none !important;
}
.title-one-line {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
