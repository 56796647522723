import Axios from 'axios';
import qs from 'qs';
import { errorResponseHandler } from '@/plugins/axios/error';
import { env } from '@/helper';
import { getCookie } from '@/plugins/cookies';

const instance = Axios.create({
  baseURL: env.VUE_APP_API_URL,
  auth: env.VUE_APP_BASIC_AUTH_USERNAME ? {
    username: env.VUE_APP_BASIC_AUTH_USERNAME,
    password: env.VUE_APP_BASIC_AUTH_PASSWORD,
  } : null,
  withCredentials: true,
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
  headers: {
    'Content-Language': 'en',
  },
});

instance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers['Content-Language'] = getCookie('locale') !== undefined ? getCookie('locale').toLowerCase() : 'en';

  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => errorResponseHandler(error),
);

export default instance;
