export default {
  type: 'Typ kroku',
  add: 'Dodaj krok',
  edit: 'Edytuj krok',
  no_results: 'Nie znaleziono kroków',
  list: {
    title: 'Lista kroków',
    order: 'Lp.',
    name: 'Treść kroku',
    type: 'Typ',
    competence: 'Kompetencja',
  },
  types: {
    question_best_worst: 'Zamknięte podwójnie',
    question_closed: 'Zamknięte',
    message: 'Wiadomość',
    open: 'Otwarte',
    video: 'Video',
    image: 'Grafika',
    record: 'Nagrywanie video',
  },
  closed_question: {
    name: 'Edycja pytania zamkniętego',
    competence: 'Kompetencja',
    competence_hint: 'Wybierz kompetencję',
    header: 'Nagłówek',
    body: 'Treść pytania',
    instruction: 'Wezwanie do działania',
    question_image: 'Obrazek do pytania',
    side_image: 'Obrazek boczny',
    side_image_requirements: 'Rekomendowane wymiary to: 436x985 px',
    mobile_side_image: 'Obrazek boczny mobile',
    mobile_side_image_requirements: 'Rekomendowane wymiary to: 750x420 px',
    answer: 'Odpowiedź',
    answer_empty: 'Dodaj odpowiedzi do kroku',
    add: 'Dodaj odpowiedź',
    score: 'Punktacja',
    feedback_title: 'Feedback (opcjonalnie)',
    feedback: 'Feedback',
    feedback_image: 'Obrazek do feedbacku',
  },
  bestworst_question: {
    name: 'Edycja pytania zamkniętego',
    competence: 'Kompetencja',
    competence_hint: 'Wybierz kompetencję',
    header: 'Nagłówek',
    body: 'Treść pytania',
    instruction: 'Wezwanie do działania',
    question_image: 'Obrazek do pytania',
    side_image: 'Obrazek boczny',
    side_image_requirements: 'Rekomendowane wymiary to: 436x985 px',
    mobile_side_image: 'Obrazek boczny mobile',
    mobile_side_image_requirements: 'Rekomendowane wymiary to: 750x420 px',
    answer: 'Odpowiedź',
    answer_empty: 'Dodaj odpowiedzi do kroku',
    add: 'Dodaj odpowiedź',
    score_best: 'Punktacja (kciuk w górę)',
    score_worst: 'Punktacja (kciuk w dół)',
    feedback_title: 'Feedback (opcjonalnie)',
    feedback: 'Feedback za kciuk w górę',
    feedback_image: 'Obrazek do feedbacku',
  },
  game_message: {
    name: 'Edycja wiadomości',
    title: 'Nazwa kroku',
    header: 'Nagłówek',
    body: 'Treść pytania',
    side_image: 'Obrazek boczny',
    side_image_requirements: 'Rekomendowane wymiary to: 436x985 px',
    mobile_side_image: 'Obrazek boczny mobile',
    mobile_side_image_requirements: 'Rekomendowane wymiary to: 750x420 px',
    feedback_title: 'Feedback (opcjonalnie)',
    feedback: 'Feedback',
    feedback_image: 'Obrazek do feedbacku',
  },
  video: {
    name: 'Edycja video',
    title: 'Nazwa kroku',
    video_mp4: 'Video MP4',
    video_webm: 'Video WEBM',
  },
  image: {
    name: 'Edycja grafiki',
    title: 'Nazwa kroku',
    question_image: 'Obrazek do pytania',
    continue_btn: 'Treść przycisku kontynuacji',
  },
  game_record: {
    name: 'Edycja nagrywanie video',
    title: 'Nazwa kroku',
    header: 'Nagłówek',
    body: 'Treść pytania',
    instruction: 'Wezwanie do działania (opcjonalnie)',
    limit: 'Limit czasu nagrania',
    limit_hint: '01m: 00s',
    required: 'Obowiązkowe',
    side_image: 'Obrazek boczny',
    side_image_requirements: 'Rekomendowane wymiary to: 436x985 px',
    mobile_side_image: 'Obrazek boczny mobile',
    mobile_side_image_requirements: 'Rekomendowane wymiary to: 750x420 px',
    feedback_title: 'Feedback (opcjonalnie)',
    feedback: 'Feedback',
    feedback_image: 'Obrazek do feedbacku',
  },
  open_question: {
    name: 'Edycja pytania otwartego',
    competence: 'Kompetencja',
    competence_hint: 'Wybierz kompetencję',
    title: 'Tytuł',
    header: 'Nagłówek',
    description: 'Treść pytania',
    call_to_action: 'Wezwanie do działania',
    max_length: 'Limit liczby znaków w odpowiedzi',
    question_image: 'Obrazek do pytania',
    side_image: 'Obrazek boczny',
    side_image_requirements: 'Rekomendowane wymiary to: 436x985 px',
    mobile_side_image: 'Obrazek boczny mobile',
    mobile_side_image_requirements: 'Rekomendowane wymiary to: 750x420 px',
    feedback_title: 'Feedback (opcjonalnie)',
    feedback: 'Feedback',
    feedback_image: 'Obrazek do feedbacku',
  },
};
