<template>
  <BaseInput
    ref="input"
    v-model.trim="keyword"
    :label="$t('game.search')"
    :rules="isFullTextSearch ? `full_text_search:${minLength}`: `search:${minLength}`"
    :disabled="disabled"
    append-icon="mdi-magnify"
    @input="search"
  />
</template>

<script>
import debounce from '@/plugins/debounce/debounce';
import BaseInput from '@/components/Form/BaseInput';

export default {
  name: 'TableSearch',
  components: {
    BaseInput,
  },
  mixins: [debounce],
  props: {
    isFullTextSearch: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keyword: '',
      minLength: 3,
      waiting: false,
    };
  },
  watch: {
    $route(to) {
      if (typeof to.query.search !== 'undefined') {
        this.keyword = to.query.search;

        return;
      }

      this.keyword = '';
    },
  },
  created() {
    if (this.$route.query.search) {
      this.keyword = this.$route.query.search;
    }
  },
  methods: {
    search() {
      this.debounce(async () => {
        if (!(await this.$refs.input.$refs.validationProvider.validate()).valid) {
          return;
        }

        this.waiting = true;
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.keyword,
            page: 1,
          },
        }, () => {
        });
        this.$emit('input', { search: this.keyword, page: 1 });
      });
    },
  },
};
</script>
