import uploadImage from '@/components/Dashboard/MessageTemplate/uploadImage';

export default class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  async upload() {
    try {
      const formData = new FormData();
      const image = await this.loader.file;
      formData.append('image', image);

      const { url } = await uploadImage({ params: formData });

      return { default: url };
    } catch (e) {
      throw new Error(e);
    }
  }
}
