<template>
  <div
    class="preloader"
    :class="{
      'preloader--fullscreen': isFullscreen,
      'preloader--small': small
    }"
  >
    <svg
      class="preloader__loader"
      viewBox="25 25 50 50"
    >
      <circle
        class="preloader__path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'Preloader',
  props: {
    isFullscreen: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
.preloader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 80vh;
}
.preloader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.preloader {
  position: relative;
  height: 80px;
  width: 80px;
  &__loader {
    width: 100%;
    height: 100%;
    animation: rotate 2s linear infinite;
    transform-origin: center center;
  }
  &__path {
    stroke-linecap: round;
    stroke: var(--primary);
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
  }
  &--fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    width: 100%;
    height: 100%;
    display: flex;
    background: var(--white);
    z-index: 2;
  }
  &--small {
    height: 42px;
    width: 42px;
  }
  &--fullscreen &__loader {
    width: 100px;
    height: 100px;
    margin: auto;
  }
}
</style>
