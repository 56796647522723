<template>
  <div>
    <div
      v-if="isPreloadingData"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title class="py-6">
          <div class="table-heading">
            <h5 class="table-heading__title text-h5">
              {{ $t("gameForm.title") }}
            </h5>
            <div class="table-heading__actions">
              <div class="table-heading__item">
                <BaseButton @click="goToAddGameForm">
                  {{ $t("dashboard.add") }}
                  <v-icon right>
                    mdi-plus
                  </v-icon>
                </BaseButton>
              </div>
            </div>
          </div>
        </v-card-title>
        <TableFilter
          v-model="activeFilters"
          @change-filter="fetchGameForms"
        >
          <template #default="{ updateFilter }">
            <div class="table-filters">
              <TableSearch
                is-full-text-search
                @input="fetchGameForms"
              />
              <BaseSelect
                v-model="activeFilters.tags_assigned"
                :items="tags"
                :label="$t('gameForm.tags')"
                :disabled="isLoading"
                item-text="name"
                item-value="id"
                persistent-hint
                multiple
                hide-details
                searchable
                clearable
                @change="updateFilter('tags_assigned', activeFilters.tags_assigned)"
              />
            </div>
          </template>
        </TableFilter>
        <v-divider />
        <BaseTable
          :table-headers="tableHeaders"
          :table-data="tableData"
          :is-loading="isLoading"
          :no-results-text="$t('gameForm.no_results')"
          :pagination="pagination"
          :default-sort="sort"
          @change-page="fetchGameForms"
          @change-items-per-page="fetchGameForms"
          @change-sort="fetchGameForms"
        >
          <template v-slot:item="{ item }">
            <td>{{ item.name ? item.name : "-" }}</td>
            <td>{{ item.client ? item.client.data.name : "-" }}</td>
            <td v-if="item.tags.data.length">
              <GameFormTags
                item-key="name"
                :game-form-id="item.id"
                :items="item.tags.data"
                @refetch-data="fetchCompleteData"
              />
            </td>
            <td v-else>
              -
            </td>
            <td>{{ formatDate(item.updated_at) }}</td>
            <td>
              <Actions
                :game-form-id="item.id"
                @refetch-data="fetchCompleteData"
                @handle-delete="deleteGameForm"
              />
            </td>
          </template>
        </BaseTable>
      </v-card>
    </div>
  </div>
</template>
<script>
import alerts from '@/plugins/alerts';
import { GAME_FORM_ADD } from '@/router/routes.names';
import { HTTP_UNAUTHORIZED, HTTP_UNPROCESSABLE_ENTITY, HTTP_CONFLICT } from '@/plugins/axios/codes';
import { ASC, DESC } from '@/components/Table/sort.types';
import { formatDate } from '@/plugins/dates/dates';
import fetchGameForms from '@/components/Dashboard/GameForm/fetchGameForms';
import deleteGameForm from '@/components/Dashboard/GameForm/deleteGameForm';
import fetchTags from '@/components/Dashboard/GameForm/fetchTags';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import BaseButton from '@/components/Form/BaseButton';
import BaseTable from '@/components/Table/BaseTable';
import TableSearch from '@/components/Table/TableSearch';
import Actions from '@/components/Dashboard/GameForm/Actions';
import GameFormTags from '@/components/Dashboard/GameForm/GameFormTags';
import TableFilter from '@/components/Table/TableFilter';
import BaseSelect from '@/components/Form/BaseSelect';

export default {
  name: 'GameFormList',
  components: {
    Preloader,
    BaseButton,
    BaseTable,
    TableSearch,
    Actions,
    GameFormTags,
    TableFilter,
    BaseSelect,
  },
  data() {
    return {
      tableHeaders: [
        {
          value: 'name',
          text: this.$t('gameForm.name'),
        },
        {
          value: 'client_name',
          text: this.$t('gameForm.client'),
        },
        {
          value: 'tags',
          text: this.$t('gameForm.tags'),
          sortable: false,
        },
        {
          value: 'created_at',
          text: this.$t('gameForm.created_at'),
        },
        {
          value: 'actions',
          sortable: false,
          align: 'right',
          width: '50px',
        },
      ],
      tags: [],
      tableData: [],
      pagination: null,
      isLoading: false,
      isPreloadingData: false,
      sort: {
        column: 'name',
        direction: ASC,
      },
      activeFilters: {
        tags_assigned: [],
      },
    };
  },
  async created() {
    this.isPreloadingData = true;
    await this.fetchCompleteData();
    this.isPreloadingData = false;
  },
  methods: {
    ...alerts,
    formatDate,
    setParams(params = {}) {
      return {
        limit: 99999,
        page: 1,
        sort: this.sort,
        includes: ['client', 'tags'],
        ...this.$route.query,
        ...params,
      };
    },
    goToAddGameForm() {
      this.$router.push({ name: GAME_FORM_ADD });
    },
    async fetchCompleteData() {
      this.isLoading = true;
      try {
        await Promise.all([this.fetchGameForms(), this.fetchGameFormTags()]);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchGameForms(params) {
      try {
        this.isLoading = true;
        const { data, pagination } = await fetchGameForms(this.setParams(params));
        this.tableData = data;
        this.pagination = pagination;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchGameFormTags() {
      try {
        this.isLoading = true;
        const { data } = await fetchTags({
          sort: {
            column: 'created_at',
            direction: DESC,
          },
          limit: 99999,
          filters: {
            only_assigned: 1,
          },
        });
        this.tags = data;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteGameForm({ gameFormId }) {
      try {
        this.isLoading = true;
        await deleteGameForm({ gameFormId });
        const elementIndex = this.tableData.findIndex((game) => game.id === gameFormId);
        this.tableData.splice(elementIndex, 1);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_CONFLICT) {
          this.errorAlert({ text: e.response.data.message });

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
