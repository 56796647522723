<template>
  <div>
    <div
      v-if="isPreloadingData"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title class="py-6">
          <div class="table-heading">
            <h5 class="table-heading__title text-h5">
              {{ $t("candidate.title") }}
            </h5>
            <div class="table-heading__actions">
              <div
                v-if="selectedRecruitmentProcessId"
                class="table-heading__item"
              >
                <BaseButton
                  color="grayDark"
                  dark
                  :disabled="!selectedRecruitmentProcessId"
                  @click="candidatesExportByRecruitmentId"
                >
                  {{ $t("dashboard.export") }}
                  <v-icon right>
                    mdi-file-export
                  </v-icon>
                </BaseButton>
              </div>
              <div
                v-if="selectedRecruitmentProcessId"
                class="table-heading__item"
              >
                <CandidateGroupActionsMenu
                  :recruitment-process-stages="recruitmentProcessStages"
                  :has-selected-rows="Boolean(!selectedRows.length)"
                  @change-group-stage="(stage) => confirmGroupChangesModal(stage)"
                  @send-group-message="openGroupSendMessageModal"
                  @delete-group-candidates="deleteGroupCandidates"
                />
              </div>
              <div class="table-heading__item">
                <BaseButton @click="goToCandidatesInvite">
                  {{ $t("candidate.invite") }}
                  <v-icon right>
                    mdi-plus
                  </v-icon>
                </BaseButton>
              </div>
            </div>
          </div>
        </v-card-title>
        <div class="candidate-filters">
          <div class="candidate-filters__item candidate-filters__item--recruitment-processes">
            <BaseSelect
              v-model="selectedRecruitmentProcessId"
              :items="recruitmentProcesses"
              :label="$t('candidate.recruitment_process')"
              :hint="hint"
              :disabled="isLoading"
              item-text="localized_name"
              item-value="id"
              persistent-hint
              hide-details
              searchable
              @change="updateRecruitmentProcess"
            />
          </div>
          <TableFilter
            v-model="activeFilters"
            @change-filter="fetchRecruitmentProcessAndCandidates"
          >
            <template #default="{ updateFilter }">
              <div
                v-if="selectedRecruitmentProcessId"
                class="d-flex"
              >
                <div class="candidate-filters__item">
                  <TableSearch
                    is-full-text-search
                    @input="fetchRecruitmentProcessAndCandidates"
                  />
                </div>
                <div class="candidate-filters__item">
                  <BaseSelect
                    v-model="selectedStatus"
                    :items="recruitmentStatuses"
                    :label="$t('candidate.recruitment_status')"
                    :disabled="isLoading"
                    item-text="name"
                    item-value="value"
                    persistent-hint
                    hide-details
                    searchable
                    clearable
                    @change="updateFilter('current_stage_status', selectedStatus)"
                  />
                </div>
                <div class="candidate-filters__item">
                  <BaseSelect
                    v-model="selectedTags"
                    :items="tags"
                    :label="$t('candidate.tags')"
                    :disabled="isLoading"
                    item-text="name"
                    item-value="id"
                    persistent-hint
                    multiple
                    hide-details
                    searchable
                    clearable
                    @change="updateFilter('tags_assigned', selectedTags)"
                  />
                </div>
              </div>
            </template>
          </TableFilter>
        </div>
        <div>
          <div class="candidate-advanced-filters">
            <div
              v-if="isLoading"
              class="preloader-wrapper"
            >
              <Preloader small />
            </div>
            <AdvancedFiltersChips
              v-else
              :is-loading="isLoading"
              :form-fields="advancedFilterFields"
              @update-table-data="fetchRecruitmentProcessAndCandidates"
              @refresh-sidebar-filters="refreshSidebarFilters"
            />
            <AdvancedFiltersActions
              @update-table-data="fetchRecruitmentProcessAndCandidates"
              @refresh-sidebar-filters="refreshSidebarFilters"
            />
          </div>
          <v-divider />
        </div>
        <div
          v-if="recruitmentProcessStages.length"
          class="table-filters-details"
        >
          <TableFilter
            v-model="activeFilters"
            @change-filter="fetchRecruitmentProcessAndCandidates"
          >
            <template #default="{ updateFilter }">
              <RecruitmentStages
                v-model="selectedRecruitmentStage"
                :recruitment-process-stages="recruitmentProcessStages"
                :disabled="isLoading"
                @change="
                  updateFilter('current_recruitment_process_stage_id', selectedRecruitmentStage)
                "
              />
            </template>
          </TableFilter>
          <div v-if="selectedRecruitmentProcessId">
            <CandidateScoringColumns
              v-model="selectedRecruitmentStageColumns"
              :recruitment-process-stages="recruitmentProcessStages"
              @change="handleSelectedStagesUpdate"
              @refetch-data="fetchRecruitmentProcessAndCandidates"
            />
          </div>
        </div>
        <v-divider />
        <div class="candidates-table">
          <BaseTable
            v-if="!isRecruitmentProcessLoading"
            :key="selectedRecruitmentProcessId"
            v-model="selectedRows"
            show-select
            :table-headers="tableHeaders"
            :table-data="tableData"
            :is-loading="isLoading"
            :no-results-text="$t('candidate.no_results')"
            :pagination="pagination"
            :default-sort="sort"
            @change-page="fetchRecruitmentProcessAndCandidates"
            @change-items-per-page="fetchRecruitmentProcessAndCandidates"
            @change-sort="fetchRecruitmentProcessAndCandidates"
          >
            <template v-slot:item="{ item }">
              <td>
                <v-checkbox
                  :value="hasSelectedRows(item)"
                  @click="toggleRow(item)"
                />
              </td>
              <td>
                <CandidateBasicDataColumn :candidate="item" />
              </td>
              <td>
                <v-chip small>
                  {{ scorePercentage(item.candidate_recruitment_process.data.score_percentage) }}
                </v-chip>
              </td>
              <td
                v-for="(rating, index) in scoringColumns(item.candidate_recruitment_process)"
                :key="`${rating.name}-${index}`"
              >
                <v-chip small>
                  {{
                    rating.value && rating.value?.score_percentage !== null
                      ? `${rating.value.score_percentage}%`
                      : "-"
                  }}
                </v-chip>
              </td>
              <td class="py-2">
                <div class="text-caption pb-1 text-center">
                  {{ formattedDate(item.candidate_recruitment_process.data.status_changed_at) }}
                </div>
                <CandidateStatusSelect
                  :value="item.candidate_recruitment_process.data.current_stage_status"
                  :recruitment-process-stage-id="
                    item.candidate_recruitment_process.data.current_recruitment_process_stage.data
                      .id
                  "
                  :recruitment-process-id="selectedRecruitmentProcessId"
                  @input="
                    (status) =>
                      confirmChangesModal(
                        item,
                        status,
                        item.candidate_recruitment_process.data.current_recruitment_process_stage
                          .data
                      )
                  "
                />
              </td>
              <td class="py-2">
                <div class="text-caption pb-1 text-center">
                  {{ formattedDate(item.candidate_recruitment_process.data.stage_changed_at) }}
                </div>
                <CandidateStageSelect
                  :value="item.candidate_recruitment_process.data.current_recruitment_process_stage"
                  :candidate-id="item.id"
                  :recruitment-process-stages="recruitmentProcessStages"
                  @input="
                    (stage) =>
                      confirmChangesModal(
                        item,
                        item.candidate_recruitment_process.data.current_stage_status,
                        stage
                      )
                  "
                />
              </td>
              <td v-if="item.candidate_recruitment_process.data.tags.data.length">
                <CandidateTags
                  item-key="name"
                  :candidate-id="item.id"
                  :candidate-fullname="fullName(item.first_name, item.last_name)"
                  :recruitment-process-id="selectedRecruitmentProcessId"
                  :items="item.candidate_recruitment_process.data.tags.data"
                  @refetch-data="fetchRecruitmentProcessCompleteData"
                />
              </td>
              <td v-else>
                -
              </td>
              <td>
                {{ formattedDate(item.candidate_recruitment_process.data.created_at) }}
              </td>
              <td>
                <v-layout
                  flex
                  justify-space-between
                  align-center
                >
                  <div class="mr-3">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <router-link :to="candidateCardUrl(item)">
                            <v-icon>mdi-folder-account</v-icon>
                          </router-link>
                        </div>
                      </template>
                      <span>{{ $t("candidate.card") }}</span>
                    </v-tooltip>
                  </div>
                  <div class="mr-3">
                    <router-link
                      :to="candidateReportPage({ candidateId: item.id })"
                      target="_blank"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">
                            mdi-file-document
                          </v-icon>
                        </template>
                        <span>{{ $t("candidate.report") }}</span>
                      </v-tooltip>
                    </router-link>
                  </div>
                  <Actions
                    :candidate-id="item.id"
                    :candidate-fullname="fullName(item.first_name, item.last_name)"
                    :candidate-has-files="item.candidate_has_files"
                    :recruitment-process-id="selectedRecruitmentProcessId"
                    @refetch-data="fetchRecruitmentProcessCompleteData"
                    @open-send-message-modal="
                      openSendMessageModal(
                        item,
                        item.candidate_recruitment_process.data.current_stage_status,
                        item.candidate_recruitment_process.data.current_recruitment_process_stage
                          .data
                      )
                    "
                    @handle-delete="deleteCandidate"
                  />
                </v-layout>
              </td>
            </template>
          </BaseTable>
          <div
            v-if="isRecruitmentProcessLoading"
            class="preloader-wrapper py-16"
          >
            <Preloader />
          </div>
          <transition
            name="fade"
            appear
          >
            <div
              v-if="isCandidatesRemoving"
              class="candidates-table__loader"
            >
              <Preloader />
            </div>
          </transition>
        </div>
        <ConfirmStageModal
          v-if="isStageModalOpen"
          v-model="isStageModalOpen"
          :has-been-sent="hasBeenSent"
          :is-loading="isLoading"
          :chosen-variant="showModalWithoutOpenedMessageSection"
          :recruitment-process-stages="recruitmentProcessStages"
          :recruitment-process-id="selectedRecruitmentProcessId"
          :chosen-candidates="selectedCandidates"
          :chosen-candidate-status="selectedCandidateStatus"
          :chosen-candidate-stage="selectedCandidateStage"
          @close-modal="closeEditStageModal"
          @handle-submit="submitConfirmModal"
        />
        <AdvancedFiltersSidebar
          :is-loading="isFiltersLoading"
          :form-fields="advancedFilterFields"
          @update-table-data="fetchRecruitmentProcessAndCandidates"
        />
      </v-card>
    </div>
  </div>
</template>
<script>
import Axios from 'axios';
import alerts from '@/plugins/alerts';
import { removeNullProperties } from '@/plugins/objectHelpers/removeNullProperties';
import {
  HTTP_UNAUTHORIZED,
  HTTP_UNPROCESSABLE_ENTITY,
  HTTP_NOT_FOUND,
} from '@/plugins/axios/codes';
import {
  CANDIDATE_INVITATION,
  CANDIDATE_EDIT,
  CANDIDATE_REPORT,
  CANDIDATES_EXPORT_BY_RECRUITMENT_ID,
} from '@/router/routes.names';
import { GAME } from '@/names/recruitmentStages.names';
import { translateStage } from '@/plugins/objectHelpers/candidateHelpers';
import { formatDate } from '@/plugins/dates/dates';
import { ASC, DESC } from '@/components/Table/sort.types';
import fetchCandidates from '@/components/Dashboard/Candidate/Api/fetchCandidates';
import fetchCandidate from '@/components/Dashboard/Candidate/Api/fetchCandidate';
import deleteCandidate from '@/components/Dashboard/Candidate/Api/deleteCandidate';
import deleteMultipleCandidates from '@/components/Dashboard/Candidate/Api/deleteMultipleCandidates';
import fetchTags from '@/components/Dashboard/Candidate/Api/fetchTags';
import updateCandidateStage from '@/components/Dashboard/Candidate/Api/updateCandidateStage';
import fetchCandidateFormFields from '@/components/Dashboard/Candidate/Api/fetchCandidateFormFields';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import BaseButton from '@/components/Form/BaseButton';
import BaseSelect from '@/components/Form/BaseSelect';
import BaseTable from '@/components/Table/BaseTable';
import TableFilter from '@/components/Table/TableFilter';
import TableSearch from '@/components/Table/TableSearch';
import Actions from '@/components/Dashboard/Candidate/Actions';
import CandidateScoringColumns from '@/components/Dashboard/Candidate/Partials/CandidateScoringColumns';
import AdvancedFiltersSidebar from '@/components/Dashboard/Candidate/AdvancedFilters/AdvancedFiltersSidebar';
import AdvancedFiltersChips from '@/components/Dashboard/Candidate/AdvancedFilters/AdvancedFiltersChips';
import AdvancedFiltersActions from '@/components/Dashboard/Candidate/AdvancedFilters/AdvancedFiltersActions';
import fetchRecruitmentProcess from '@/components/Dashboard/Recruitment/fetchRecruitmentProcess';
import fetchRecruitmentProcesses from '@/components/Dashboard/Recruitment/fetchRecruitmentProcesses';
import fetchRecruitmentProcessStatuses from '@/components/Dashboard/Recruitment/fetchRecruitmentProcessStatuses';
import RecruitmentStages from '@/components/Dashboard/Partials/Filters/RecruitmentStages';
import CandidateStatusSelect from '@/components/Dashboard/Partials/CustomSelect/CandidateStatusSelect';
import CandidateStageSelect from '@/components/Dashboard/Partials/CustomSelect/CandidateStageSelect';
import CandidateTags from '@/components/Dashboard/Candidate/Partials/CandidateTags';
import ConfirmStageModal from '@/components/Dashboard/Candidate/Partials/ConfirmStageModal';
import CandidateGroupActionsMenu from '@/components/Dashboard/Candidate/Partials/GroupActionsMenu';
import CandidateBasicDataColumn from '@/components/Dashboard/Candidate/Partials/CandidateBasicDataColumn';

let cancelToken;

export default {
  name: 'CandidateList',
  components: {
    Preloader,
    BaseButton,
    BaseSelect,
    BaseTable,
    TableFilter,
    TableSearch,
    CandidateTags,
    RecruitmentStages,
    CandidateStatusSelect,
    CandidateStageSelect,
    Actions,
    ConfirmStageModal,
    CandidateGroupActionsMenu,
    AdvancedFiltersSidebar,
    AdvancedFiltersChips,
    AdvancedFiltersActions,
    CandidateBasicDataColumn,
    CandidateScoringColumns,
  },
  data() {
    return {
      recruitmentProcesses: [],
      tags: [],
      recruitmentProcessStages: [],
      recruitmentStatuses: [],
      tableData: [],
      tableHeaders: [],
      pagination: this.getInitialPagination(),
      isPreloadingData: false,
      isLoading: false,
      isRecruitmentProcessLoading: false,
      isCandidatesRemoving: false,
      isFiltersLoading: false,
      hasBeenSent: false,
      isStageModalOpen: false,
      showModalWithoutOpenedMessageSection: null,
      selectedRecruitmentStage: null,
      selectedRecruitmentProcessId: null,
      selectedStatus: null,
      selectedTags: null,
      selectedRecruitmentStageColumns: [],
      activeFilters: {
        current_recruitment_process_stage_id: null,
        current_stage_status: null,
        recruitment_process_id: null,
        tags_assigned: [],
      },
      sort: this.getDefaultSort(),
      selectedRows: [],
      selectedCandidates: [],
      selectedCandidateStage: null,
      selectedCandidateStatus: null,
      advancedFilterFields: [],
    };
  },
  computed: {
    hint() {
      return this.selectedRecruitmentProcessId
        ? null
        : this.$t('candidate.recruitment_process_hint');
    },
    filtersWithoutNullProperties() {
      return this.removeNullProperties(this.activeFilters);
    },
  },
  watch: {
    selectedRecruitmentProcess(newVal) {
      if (newVal) {
        this.fetchTags();
      }
    },
  },
  async created() {
    this.isPreloadingData = true;
    await this.fetchRecruitmentProcesses();
    await this.setRouteFilters();
    this.isPreloadingData = false;
  },
  methods: {
    ...alerts,
    removeNullProperties,
    formatDate,
    translateStage,
    getDefaultSort() {
      return {
        column: 'candidate_recruitment_process_created_at',
        direction: DESC,
        value: undefined,
      };
    },
    getInitialPagination() {
      return {
        count: 0,
        current_page: 1,
        per_page: 50,
        total: 0,
        total_pages: 1,
      };
    },
    scoringColumns(item) {
      if (!this.selectedRecruitmentStageColumns.length) return [];

      const filteredStages = this.selectedRecruitmentStageColumns.length
        ? this.recruitmentProcessStages.filter((stage) => this.selectedRecruitmentStageColumns.map((col) => col.id).includes(stage.id))
        : [];

      return filteredStages.map((stage) => ({
        id: stage.id,
        name: `${this.$t(
          `recruitment.types.${
            this.isGameType(stage.type) ? stage.sub_type.toLowerCase() : stage.type.toLowerCase()
          }`,
        )}`,
        value: item.data?.overall_ratings?.data.find(
          (rating) => rating.recruitment_process_stage_id === stage.id,
        ),
      }));
    },
    isGameType(type) {
      return type === GAME;
    },
    goToCandidatesInvite() {
      const route = this.selectedRecruitmentProcessId
        ? {
          name: CANDIDATE_INVITATION,
          query: { recruitment_process_id: this.selectedRecruitmentProcessId },
        }
        : { name: CANDIDATE_INVITATION };
      this.$router.push(route);
    },
    async updateRecruitmentProcess() {
      if (!this.selectedRecruitmentProcessId) {
        this.resetTableData();
        this.$router.replace({ query: null });

        return;
      }
      this.isRecruitmentProcessLoading = true;
      this.$router.replace({
        query: { recruitment_process_id: this.selectedRecruitmentProcessId },
      });
      await this.fetchRecruitmentProcessCompleteData(true);
      await this.fetchCandidateAdvancedFilters();
      this.isRecruitmentProcessLoading = false;
    },
    async fetchRecruitmentProcessCompleteData(withDataTableClean = false) {
      if (!this.selectedRecruitmentProcessId) return;

      this.isLoading = true;
      if (withDataTableClean) this.resetTableData();

      try {
        await Promise.all([
          this.fetchRecruitmentProcessTags(),
          this.fetchRecruitmentProcessStatuses(),
        ]);
        await this.fetchRecruitmentProcessAndCandidates();
      } finally {
        this.isLoading = false;
      }
    },
    resetTableData() {
      this.selectedRows = [];
      this.tableData = [];
      this.recruitmentProcessStages = [];
      this.selectedRecruitmentStage = null;
      this.selectedTags = null;
      this.selectedStatus = null;
      this.pagination = this.getInitialPagination();
    },
    validateAndSetPageNumber(removedCandidatesCount) {
      this.pagination.total -= removedCandidatesCount;
      this.pagination.total_pages = Math.ceil(this.pagination.total / this.pagination.per_page);
      const currentPage = this.$route.query.page || 1;
      if (currentPage > this.pagination.total_pages) {
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            page: this.pagination.total_pages,
          },
        });
      }
    },
    removePageFromQueryIfEmpty() {
      if (this.tableData.length === 0) {
        const newQuery = { ...this.$route.query };
        delete newQuery.page;
        this.$router.replace({
          path: this.$route.path,
          query: newQuery,
        });
      }
    },
    async fetchRecruitmentProcessAndCandidates() {
      if (!this.selectedRecruitmentProcessId) return;

      this.isLoading = true;
      try {
        await this.fetchRecruitmentProcess();
        await this.fetchCandidates();
      } finally {
        this.isLoading = false;
      }
    },
    async fetchCandidates() {
      try {
        if (typeof cancelToken !== typeof undefined) {
          cancelToken.cancel('Operation canceled due to new request.');
        }
        cancelToken = Axios.CancelToken.source();
        this.selectedRows = [];
        const { data, pagination } = await fetchCandidates({
          recruitmentProcessId: this.selectedRecruitmentProcessId,
          includes: this.selectedRecruitmentStageColumns.length
            ? {
              ...[
                'candidate_recruitment_process.tags',
                'candidate_recruitment_process.current_recruitment_process_stage',
                'candidate_recruitment_process.current_recruitment_process_stage.sub_type',
                'candidate_has_files',
              ],
              ...{
                'candidate_recruitment_process.overall_ratings':
                    this.selectedRecruitmentStageColumns.map((col) => col.id),
              },
            }
            : [
              'candidate_recruitment_process.tags',
              'candidate_recruitment_process.current_recruitment_process_stage',
              'candidate_recruitment_process.current_recruitment_process_stage.sub_type',
              'candidate_has_files',
            ],
          sort: this.sort,
          ...this.$route.query,
          cancelToken,
        });
        this.tableData = data;
        this.pagination = pagination;
        this.tableHeaders = this.setTableHeaders();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();
        }
        throw new Error(e);
      }
    },
    async fetchRecruitmentProcesses() {
      try {
        this.isLoading = true;
        this.resetTableData();
        const { data } = await fetchRecruitmentProcesses({
          limit: 99999,
          sort: { column: 'name', direction: ASC },
        });
        this.recruitmentProcesses = data;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchRecruitmentProcess() {
      try {
        const {
          stages: { data },
        } = await fetchRecruitmentProcess({
          recruitmentProcessId: this.selectedRecruitmentProcessId,
          includes: ['stages', 'stages.current_candidates_count', 'stages.sub_type'],
          filters: {
            'stages.current_candidates_count': {
              ...this.$route.query?.filters,
              tags_assigned: this.selectedTags !== null ? this.selectedTags : undefined,
              current_stage_status: this.selectedStatus !== null ? this.selectedStatus : undefined,
              search: this.$route.query.search,
              current_recruitment_process_stage_id: undefined,
            },
          },
        });
        this.recruitmentProcessStages = data;
        this.loadScoreTableColumns();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_NOT_FOUND) return;
        this.errorAlert();
        throw new Error(e);
      }
    },
    async fetchRecruitmentProcessStatuses() {
      try {
        const { statuses } = await fetchRecruitmentProcessStatuses({
          recruitmentProcessId: this.selectedRecruitmentProcessId,
        });
        const mapStatuses = statuses.map((status) => ({
          name: this.$t(`candidate.statuses.${status.toLowerCase()}`),
          value: status,
        }));
        mapStatuses.unshift({
          name: this.$t('candidate.statuses.all'),
          value: null,
        });
        this.recruitmentStatuses = mapStatuses;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_NOT_FOUND) return;
        this.errorAlert();
        throw new Error(e);
      }
    },
    async fetchRecruitmentProcessTags() {
      try {
        const { data } = await fetchTags({
          filters: { recruitment_process_id: this.selectedRecruitmentProcessId },
          sort: { column: 'name', direction: ASC },
          limit: 99999,
        });
        this.tags = data;
        if (
          this.$route.query.filters?.tags_assigned
          && this.selectedTags !== null
          && !this.areAllSelectedTagsAvailable()
        ) {
          this.setAvailableTags();
          this.$router.replace({
            query: { ...this.$route.query, filters: this.filtersWithoutNullProperties },
          });
        }
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();
        }
        throw new Error(e);
      }
    },
    setAvailableTags() {
      const mapTags = this.tags.map((tag) => tag.id);
      const availableTags = this.selectedTags.filter((tag) => mapTags.includes(tag));
      this.selectedTags = availableTags;
      this.activeFilters.tags_assigned = availableTags;
    },
    areAllSelectedTagsAvailable() {
      const mapTags = this.tags.map((tag) => tag.id);

      return this.selectedTags.every((tag) => mapTags.includes(tag));
    },
    async setRouteFilters() {
      const { filters } = this.$route.query;
      if (filters?.tags_assigned) {
        this.selectedTags = filters.tags_assigned;
        this.activeFilters.tags_assigned = filters.tags_assigned;
      }
      if (filters?.current_stage_status) {
        this.selectedStatus = filters.current_stage_status;
        this.activeFilters.current_stage_status = filters.current_stage_status;
      }
      if (filters?.current_recruitment_process_stage_id) {
        this.selectedRecruitmentStage = filters.current_recruitment_process_stage_id;
        this.activeFilters.current_recruitment_process_stage_id = filters.current_recruitment_process_stage_id;
      }
      if (this.$route.query.recruitment_process_id) {
        this.selectedRecruitmentProcessId = this.$route.query.recruitment_process_id;
        await this.fetchRecruitmentProcessCompleteData();
        await this.fetchCandidateAdvancedFilters();
      }
    },
    confirmChangesModal(candidate, status, stage) {
      this.selectedCandidates.push(candidate);
      this.selectedCandidateStatus = status;
      this.selectedCandidateStage = stage;
      this.openEditStageModalWithoutOpenedMessageSection();
    },
    openSendMessageModal(candidate, status, stage) {
      this.selectedCandidates.push(candidate);
      this.selectedCandidateStatus = status;
      this.selectedCandidateStage = stage;
      this.openEditStageModalWithOpenedMessageSection();
    },
    confirmGroupChangesModal(stage) {
      this.selectedCandidates = this.selectedRows;
      this.selectedCandidateStage = stage;
      this.openEditStageModalWithoutOpenedMessageSection();
    },
    openGroupSendMessageModal() {
      this.selectedCandidates = this.selectedRows;
      this.openEditStageModalWithOpenedMessageSection();
    },
    async submitConfirmModal(payload) {
      try {
        this.hasBeenSent = false;
        this.isLoading = true;
        await updateCandidateStage(payload);
        if (payload.candidateIds.length > 1) {
          await this.fetchRecruitmentProcessAndCandidates();

          return;
        }
        await Promise.all([
          this.fetchRecruitmentProcess(),
          this.fetchCandidate(payload.candidateIds[0]),
        ]);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.hasBeenSent = true;
        this.isLoading = false;
        this.closeEditStageModal();
        this.selectedRows = [];
        this.$notify.success(
          this.$t('notifications.confirm_modal.content'),
          this.$t('notifications.confirm_modal.title'),
        );
      }
    },
    async fetchCandidate(candidateId) {
      try {
        const { data } = await fetchCandidate({
          candidateId,
          recruitmentProcessId: this.selectedRecruitmentProcessId,
          includes: [
            'candidate_recruitment_process.tags',
            'candidate_recruitment_process.current_recruitment_process_stage',
            'candidate_recruitment_process.current_recruitment_process_stage.sub_type',
            'candidate_has_files',
          ],
        });
        const elementIndex = this.tableData.findIndex((candidate) => candidate.id === candidateId);
        this.tableData.splice(elementIndex, 1, data);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      }
    },
    async fetchCandidateAdvancedFilters() {
      try {
        this.isFiltersLoading = true;
        this.advancedFilterFields = await fetchCandidateFormFields({
          recruitmentProcessId: this.selectedRecruitmentProcessId,
        });
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isFiltersLoading = false;
      }
    },
    refreshSidebarFilters() {
      this.isFiltersLoading = true;
      setTimeout(() => {
        this.isFiltersLoading = false;
      }, 300);
    },
    async candidatesExportByRecruitmentId() {
      try {
        const selectedCandidatesIds = this.selectedRows.map((row) => row.id);
        const candidatesExportRoute = this.$router.resolve({
          name: CANDIDATES_EXPORT_BY_RECRUITMENT_ID,
          query: {
            recruitmentProcessId: this.selectedRecruitmentProcessId,
            candidateIds: selectedCandidatesIds,
          },
        });
        window.open(candidatesExportRoute.href, '_blank');
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      }
    },
    candidateCardUrl(candidate) {
      return {
        name: CANDIDATE_EDIT,
        params: {
          candidateId: candidate.id,
          recruitmentProcessId: this.selectedRecruitmentProcessId,
        },
      };
    },
    async deleteGroupCandidates() {
      try {
        if (await this.removeAlert()) {
          this.isCandidatesRemoving = true;
          const selectedCandidatesIds = this.selectedRows.map((row) => row.id);
          await deleteMultipleCandidates({
            recruitmentProcessId: this.selectedRecruitmentProcessId,
            candidateIds: selectedCandidatesIds,
          });
          this.isCandidatesRemoving = false;
          this.validateAndSetPageNumber(selectedCandidatesIds.length);
          await this.fetchRecruitmentProcessCompleteData();
          this.removePageFromQueryIfEmpty();
          this.$notify.save.success();
        }
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      }
    },
    async deleteCandidate({ candidateId, recruitmentProcessId }) {
      try {
        this.isCandidatesRemoving = true;
        await deleteCandidate({
          candidateId,
          recruitmentProcessId,
        });
        this.isCandidatesRemoving = false;
        this.validateAndSetPageNumber(1);
        await this.fetchRecruitmentProcessCompleteData();
        this.removePageFromQueryIfEmpty();
        this.$notify.save.success();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      }
    },
    fullName(firstName, lastName) {
      return firstName && lastName
        ? `${firstName} ${lastName}`
        : this.$t('candidate.empty_fullname');
    },
    toggleRow(row) {
      this.toggleSelection(row);
    },
    toggleSelection(row) {
      if (this.selectedRows.includes(row)) {
        this.selectedRows = this.selectedRows.filter((item) => item.id !== row.id);

        return;
      }

      this.selectedRows.push(row);
    },
    openEditStageModalWithOpenedMessageSection() {
      this.showModalWithoutOpenedMessageSection = false;
      this.isStageModalOpen = true;
    },
    openEditStageModalWithoutOpenedMessageSection() {
      this.showModalWithoutOpenedMessageSection = true;
      this.isStageModalOpen = true;
    },
    closeEditStageModal() {
      this.isStageModalOpen = false;
      this.showModalWithoutOpenedMessageSection = null;
      this.selectedCandidates = [];
      this.selectedCandidateStage = null;
      this.selectedCandidateStatus = null;
    },
    candidateReportPage({ candidateId }) {
      return {
        name: CANDIDATE_REPORT,
        params: {
          candidateId,
          recruitmentProcessId: this.selectedRecruitmentProcessId,
        },
      };
    },
    handleSelectedStagesUpdate(stages) {
      this.selectedRecruitmentStageColumns = stages;
      this.saveScoreTableColumns(stages);
    },
    loadScoreTableColumns() {
      const key = `activeCandidateColumns-${this.selectedRecruitmentProcessId}`;
      const storedStages = localStorage.getItem(key);
      if (storedStages) {
        const stagesIds = JSON.parse(storedStages);
        const validStagesIds = stagesIds.filter((stageId) => this.recruitmentProcessStages.some((stage) => stage.id === stageId.id));
        this.selectedRecruitmentStageColumns = validStagesIds;
      }
    },
    saveScoreTableColumns(stages) {
      const key = `activeCandidateColumns-${this.selectedRecruitmentProcessId}`;
      const value = JSON.stringify(stages);
      localStorage.setItem(key, value);
    },
    scorePercentage(score) {
      return score !== null ? `${score}%` : '-';
    },
    formattedDate(date) {
      return date ? formatDate(date) : '-';
    },
    hasSelectedRows(item) {
      return this.selectedRows.indexOf(item) > -1;
    },
    setTableHeaders() {
      const filteredStages = this.selectedRecruitmentStageColumns.length
        ? this.recruitmentProcessStages.filter((stage) => this.selectedRecruitmentStageColumns.map((col) => col.id).includes(stage.id))
        : [];

      const transformedStages = filteredStages.map((stage) => ({
        id: stage.id,
        value: `candidate_recruitment_process_overall_rating_${stage.id}`,
        filterName: 'candidate_recruitment_process_overall_rating',
        text: `${this.$t(
          `recruitment.types.${
            this.isGameType(stage.type) ? stage.sub_type.toLowerCase() : stage.type.toLowerCase()
          }`,
        )}`,
      }));

      return [
        { value: 'last_name', text: this.$t('candidate.candidate') },
        {
          value: 'candidate_recruitment_process_score_percentage',
          text: this.$t('candidate.score_percentage'),
        },
        ...transformedStages,
        {
          value: 'candidate_recruitment_process_status_changed_at',
          text: this.$t('candidate.status'),
          width: '180px',
        },
        {
          value: 'candidate_recruitment_process_stage_changed_at',
          text: this.$t('candidate.stage'),
          width: '180px',
        },
        { value: 'tags', text: this.$t('candidate.tags'), sortable: false },
        {
          value: 'candidate_recruitment_process_created_at',
          text: this.$t('candidate.created_at'),
        },
        {
          value: 'actions', sortable: false, align: 'right', width: '50px',
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: var(--grayDark) !important;
  cursor: not-allowed;
  pointer-events: all !important;
}
.candidates-table {
  position: relative;

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    background: rgba(255, 255, 255, 0.4) !important;
  }
}
</style>
