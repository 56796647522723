import api from '@/plugins/axios/api';

/**
 * @param {String} email
 * @param {String} name
 * @param {String} type
 * @param {String} clientId
 * @param {String} recruitmentProcessesId
 * @param {String} userId
 */
const editUser = async ({
  email,
  name,
  type,
  clientId,
  recruitmentProcessesId,
  userId,
}) => {
  await api.put(`users/${userId}`, {
    email,
    name,
    type,
    client_id: clientId,
    recruitment_processes_id: recruitmentProcessesId,
  });
};

export default editUser;
