<template>
  <div>
    <template v-if="activeFilters.length">
      <BaseChip
        v-for="{ id, name, type } in activeFilters"
        :id="id"
        :key="id"
        :label="name"
        :type="type"
        @change-filter="changeFilter"
      />
    </template>
  </div>
</template>
<script>
import { removeNullProperties } from '@/plugins/objectHelpers/removeNullProperties';
import { DATE, NUMBER } from '@/components/Dashboard/GameForm/Fields/Field.types';
import BaseChip from '@/components/Dashboard/Candidate/AdvancedFilters/BaseChip';

export default {
  name: 'AdvancedFiltersChips',
  components: {
    BaseChip,
  },
  props: {
    formFields: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      activeFilters: [],
      filters: {
        form_fields: null,
      },
    };
  },
  computed: {
    filtersWithoutNullProperties() {
      return this.removeNullProperties(this.filters.form_fields);
    },
  },
  created() {
    if (!this.$route.query.filters?.form_fields) return;

    const activeFiltersIds = Object.keys(this.$route.query.filters?.form_fields);
    this.activeFilters = this.formFields.filter((formField) => activeFiltersIds.includes(formField.id));
  },
  methods: {
    removeNullProperties,
    changeFilter({
      id,
      values,
      type,
    }) {
      if ((type === DATE || type === NUMBER) && Object.keys(values).length > 1) {
        const multipleFilters = {
          ...values,
        };
        const filteredValues = this.removeNullProperties(multipleFilters);
        Object.assign(this.filters, {
          form_fields: {
            ...this.$route.query.filters?.form_fields,
            [id]: filteredValues,
          },
        });
      } else {
        Object.assign(this.filters, {
          form_fields: {
            ...this.$route.query.filters?.form_fields,
            [id]: values,
          },
        });
      }
      this.$router.replace(
        {
          query: {
            ...this.$route.query,
            filters: {
              ...this.$route.query?.filters,
              form_fields: this.removeNullProperties(this.filters.form_fields),
            },
          },
        },
        () => {},
      );
      this.$emit('update-table-data');
      this.$emit('refresh-sidebar-filters');
    },
  },
};
</script>
