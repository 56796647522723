import NotFound from '@/views/404';
import { NOT_FOUND } from '@/router/routes.names';

const errorRoutes = [
  {
    path: '/404',
    alias: '*',
    name: NOT_FOUND,
    component: NotFound,
  },
];

export default errorRoutes;
