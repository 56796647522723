<template>
  <div
    class="export-finish"
  >
    <span>{{ $t('candidate.file_downloading') }}.</span>
    <span>{{ $t('candidate.file_action') }}
      <span
        class="export-finish__button"
        @click="$emit('handle-download')"
      >
        {{ $t('candidate.here') }}.
      </span>
    </span>
  </div>
</template>
<script>

export default {
  name: 'ExportFinish',
};
</script>

<style lang="scss" scoped>
.export-finish {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  span {
    max-width: 600px;
    text-align: center;
    font-size: 18px;
  }

  &__button {
    cursor: pointer;
    font-weight: bold;
  }
}
</style>
