export default {
  title: 'Templates of messages to candidates',
  search: 'Search',
  name: 'Template Name',
  title_message: 'Title',
  updated_at: 'Update date',
  no_results: 'Template not found',
  edit: 'Editing the message template',
  body: 'Message content',
  create: {
    name: 'Template Name',
    title: 'Title',
    body: 'Message content',
    variables: 'Tags to use',
  },
  variables: {
    IMIE_KANDYDATA_TKI: 'candidate\'s name',
    NAZWISKO_KANDYDATA_TKI: 'candidate surname',
    EMAIL_DO_SUPPORTU: 'e-mail for technical support',
    NAZWA_FIRMY: 'employer name',
    STRONA_WWW: 'website address',
    NAZWA_STANOWISKA: 'job name',
    NAZWA_REKRUTACJI: 'name of the recruitment process',
    NAZWA_NARZĘDZIA: 'tool name (if it exists in a given stage)',
    CZAS_TRWANIA_GRY: 'time limit in the tool - in the format "X minutes" (if it exists)',
    'PRZYCISK/PRZYCISK': 'button linking to the tool (if it exists in a given stage)',
    LINK: 'link to the tool (if it exists in a given stage)',
    WYNIK: 'result from the tool (if it exists in a given stage)',
    WYNIK_TOTAL: 'score from all recruitment stages',
    FEEDBACK: 'extensive feedback to the Candidate from the tool (if defined)',
    FEEDBACK_TOTAL: 'extensive feedback to the Candidate from all stages (if defined)',
  },
};
