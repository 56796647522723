export default {
  title: 'Competency score',
  recruitment_process: 'Recruitment process',
  name: 'Name',
  form: 'Recruitment form',
  game: 'Selection game',
  summary: 'Summary',
  overall_score: 'Overall Score',
  no_results: 'No competency ratings found',
};
