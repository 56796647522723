<template>
  <div>
    <div
      v-if="isPreloadingData"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title class="py-6">
          <div class="table-heading">
            <h5 class="table-heading__title text-h5">
              {{ $t("competence.title") }}
            </h5>
            <div class="table-heading__actions">
              <div class="table-heading__item">
                <BaseButton @click="goToAddCompetence">
                  {{ $t("dashboard.add") }}
                  <v-icon right>
                    mdi-plus
                  </v-icon>
                </BaseButton>
              </div>
            </div>
          </div>
        </v-card-title>
        <div class="table-filters">
          <TableSearch
            is-full-text-search
            @input="fetchCompetences"
          />
        </div>
        <v-divider />
        <BaseTable
          :table-headers="tableHeaders"
          :table-data="tableData"
          :is-loading="isLoading"
          :no-results-text="$t('competence.no_results')"
          :pagination="pagination"
          :default-sort="sort"
          @change-page="fetchCompetences"
          @change-items-per-page="fetchCompetences"
          @change-sort="fetchCompetences"
        >
          <template v-slot:item="{ item }">
            <td>{{ item.label ? item.label : "-" }}</td>
            <td>{{ formatDate(item.created_at) }}</td>
            <td>
              <Actions
                :competence-id="item.id"
                @handle-delete="deleteCompetence"
              />
            </td>
          </template>
        </BaseTable>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alerts from '@/plugins/alerts';
import { COMPETENCE_ADD } from '@/router/routes.names';
import { HTTP_UNAUTHORIZED, HTTP_CONFLICT, HTTP_UNPROCESSABLE_ENTITY } from '@/plugins/axios/codes';
import { formatDate } from '@/plugins/dates/dates';
import { ASC } from '@/components/Table/sort.types';
import fetchCompetences from '@/components/Dashboard/Competence/fetchCompetences';
import deleteCompetence from '@/components/Dashboard/Competence/deleteCompetence';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';
import BaseButton from '@/components/Form/BaseButton';
import BaseTable from '@/components/Table/BaseTable';
import TableSearch from '@/components/Table/TableSearch';
import Actions from '@/components/Dashboard/Competence/Actions';

export default {
  name: 'CompetenceList',
  components: {
    Preloader,
    BaseButton,
    BaseTable,
    TableSearch,
    Actions,
  },
  data() {
    return {
      tableHeaders: [
        {
          value: 'label',
          text: this.$t('competence.label'),
        },
        {
          value: 'created_at',
          text: this.$t('competence.created_at'),
          width: '150px',
        },
        {
          value: 'actions',
          sortable: false,
          align: 'right',
          width: '50px',
        },
      ],
      tableData: [],
      pagination: null,
      isLoading: false,
      isPreloadingData: false,
      sort: {
        column: 'label',
        direction: ASC,
      },
    };
  },
  computed: {
    ...mapGetters('session', ['clientId']),
  },
  async created() {
    this.isPreloadingData = true;
    await this.fetchCompetences();
    this.isPreloadingData = false;
  },
  methods: {
    ...alerts,
    formatDate,
    goToAddCompetence() {
      this.$router.push({ name: COMPETENCE_ADD });
    },
    setParams(params = {}) {
      return {
        includes: ['competency_profile', 'work_department'],
        filters: {
          client_id: this.clientId,
        },
        sort: this.sort,
        ...this.$route.query,
        ...params,
      };
    },
    async fetchCompetences(params) {
      try {
        this.isLoading = true;
        const response = await fetchCompetences(this.setParams(params));
        this.tableData = response.data;
        this.pagination = response.pagination;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteCompetence({ competenceId }) {
      try {
        this.isLoading = true;
        await deleteCompetence({ competenceId });
        const elementIndex = this.tableData.findIndex(((competence) => competence.id === competenceId));
        this.tableData.splice(elementIndex, 1);
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_CONFLICT) {
          this.errorAlert({ text: e.response.data.message });

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
