import store from '@/store';
import api from '@/plugins/axios/api';
import * as names from '@/store/session/mutations.names';

export default {
  async fetchSession({ commit }, { includes }) {
    const response = await api.get('session', {
      params: { includes },
    });
    const { data } = response.data;
    const user = {
      id: data.id,
      name: data.name,
      email: data.email,
    };
    const client = store.getters['session/isSuperAdmin'] ? data.client_scope?.data : data.client?.data;

    commit(names.SET_PERMISSIONS, data.acl.data.permissions);
    commit(names.SET_ROLE, data.acl.data.roles[0]);
    commit(names.SET_CLIENT, client);
    commit(names.SET_USER, user);
    commit(names.SET_LOCALE, data.locale);

    return data;
  },
  setClient({ commit }, payload) {
    commit(names.SET_CLIENT, payload);
  },
  clearSession({ commit }) {
    commit(names.RESET_SESSION);
  },
  setCurrentRoute({ commit }, { currentRoute }) {
    commit(names.SET_CURRENT_ROUTE, currentRoute);
  },
  setLocale({ commit }, locale) {
    commit(names.SET_LOCALE, locale);
  },
};
