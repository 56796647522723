import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {Array} gameTagsNames
 */
const assignTags = async ({
  gameId,
  gameTagsNames,
}) => {
  await api.post(`games/${gameId}/tags`, {
    game_tags_names: gameTagsNames,
  });
};

export default assignTags;
