import api from '@/plugins/axios/api';

/**
 * @param {String} gameId
 * @param {Object} params
 */
const addQuestion = async ({
  gameId,
  params,
}) => {
  await api.post(`games/${gameId}/game-stages`, params);
};

export default addQuestion;
