<template>
  <div>
    <span
      v-for="(item, index) in limitedList"
      :key="`list-item-${index}`"
    >
      <template v-if="index < limit">
        <span>{{ item[itemKey] }}</span>
        <span v-if="index != limitedList.length - 1">, </span>
      </template>
    </span>
    <template v-if="items.length > limit">
      <span>...</span>
    </template>
  </div>
</template>
<script>
export default {
  name: 'List',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      required: true,
    },
    limit: {
      type: String,
      default: '3',
    },
  },
  computed: {
    limitedList() {
      return this.items.slice(0, this.limit);
    },
  },
};
</script>
