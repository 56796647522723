export default {
  title: 'Job titles',
  search: 'Search',
  edit_departments: 'Edit Departments',
  name: 'Name',
  competency_profile_name: 'Competency profile',
  work_department_name: 'Department',
  created_at: 'Created at',
  no_results: 'No jobs found',
  add: 'Add position',
  edit: 'Edit position',
  create: {
    name: 'Job title',
    work_department: 'Department',
    work_department_hint: 'Select department',
    competency_profile: 'Competency profile',
    competency_profile_hint: 'Select a competency profile',
  },
};
