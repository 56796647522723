import api from '@/plugins/axios/api';

/**
 * @param {String} gameFormId
 * @param {Array} gameFormTagsNames
 */
const assignTags = async ({
  gameFormId,
  gameFormTagsNames,
}) => {
  await api.post(`game-forms/${gameFormId}/tags`, {
    game_form_tags_names: gameFormTagsNames,
  });
};

export default assignTags;
