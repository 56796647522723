import api from '@/plugins/axios/api';

/**
 * @param {String} recruitmentProcessId
 * @param {Array} candidateIds
 */
const candidatesExportByRecruitmentId = async ({
  recruitmentProcessId,
  candidateIds,
}) => {
  const response = await api.get(`recruitment-processes/${recruitmentProcessId}/candidates/export-candidate-data`, {
    params: {
      ids: candidateIds,
    },
    responseType: 'blob',
  });

  return response;
};

export default candidatesExportByRecruitmentId;
