import api from '@/plugins/axios/api';

/**
 * @param {String} clientId
 */
const selectClientScope = async (clientId) => {
  await api.patch(`users/select-client-scope/${clientId}`);
};

export default selectClientScope;
