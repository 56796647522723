<template>
  <v-menu
    :close-on-content-click="false"
    offset-y
    elevation="2"
  >
    <template v-slot:activator="{ on }">
      <BaseButton
        color="grayDark"
        dark
        :large="false"
        :height="36"
        :width="36"
        v-on="on"
      >
        <v-icon>
          mdi-dots-horizontal
        </v-icon>
      </BaseButton>
    </template>
    <v-card>
      <v-list>
        <v-list-item
          v-for="(col, index) in scoringColumns"
          :key="index"
          dense
        >
          <v-list-item-action>
            <v-checkbox
              v-model="modelValue"
              :value="col"
              hide-details
            >
              <template #label>
                <span class="mx-2">
                  {{ $t("candidate.score_percentage") }}
                  {{
                    $t(
                      `recruitment.types.${
                        isGameType(col.type)
                          ? col.sub_type.toLowerCase()
                          : col.type.toLowerCase()
                      }`
                    )
                  }}
                </span>
              </template>
            </v-checkbox>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
import BaseButton from '@/components/Form/BaseButton';
import { GAME } from '@/names/recruitmentStages.names';

const SCORING = 'SCORING';

export default {
  name: 'CandidateColumns',
  components: {
    BaseButton,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    recruitmentProcessStages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modelValue: this.value || [],
      scoringColumns: [],
    };
  },
  watch: {
    modelValue() {
      this.$emit('change', this.modelValue);
      this.$emit('refetch-data');
    },
  },
  created() {
    this.scoringColumns = this.recruitmentProcessStages.map((recruitmentProcessStage) => ({
      id: recruitmentProcessStage.id,
      sub_type: recruitmentProcessStage.sub_type,
      type: recruitmentProcessStage.type,
      column_type: SCORING,
    }));
  },
  methods: {
    isGameType(type) {
      return type === GAME;
    },
  },
};
</script>
