import api from '@/plugins/axios/api';

/**
 * @param {String} competencyProfileId
 */
const deleteCompetencyProfile = async ({
  competencyProfileId,
}) => {
  await api.delete(`competency-profiles/${competencyProfileId}`);
};

export default deleteCompetencyProfile;
