import api from '@/plugins/axios/api';

/**
 * @param {String} userId
 */
const unsuspendUser = async ({
  userId,
}) => {
  await api.patch(`users/${userId}/unsuspend`);
};

export default unsuspendUser;
