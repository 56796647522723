<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <v-row>
          <v-col
            sm="12"
            lg="6"
            class="pr-lg-8"
          >
            <slot />
            <BaseInput
              v-model="formData.name"
              rules="min:3|max:255|required"
              :label="$t('badge.create.name')"
            />
            <BaseTextarea
              v-model="formData.description"
              rules="min:3|max:255|required"
              mode="eager"
              :label="$t('badge.create.description')"
            />
            <BaseIconSelect
              v-model="formData.selectedIcon"
              rules="select"
              :items="badgeIcons"
              :label="$t('badge.create.icon')"
              item-text="name"
              item-value="file_name"
              persistent-hint
              return-object
              :loading="isLoading"
              :disabled="isLoading"
            >
              <template v-slot:selection="{ item }">
                <img
                  class="pl-2 pr-4"
                  height="46"
                  width="46"
                  :src="item.url"
                >
                <span>{{ item.name }}</span>
              </template>
              <template v-slot:item="{ item }">
                <img
                  class="pl-1 pr-4"
                  height="41"
                  width="41"
                  :src="item.url"
                >
                <span>{{ item.name }}</span>
              </template>
            </BaseIconSelect>
            <BaseImageUploader
              v-model="formData.image"
              :rules="imageValidationRules()"
              :label="$t('badge.create.image')"
              :vid="$t('badge.create.image')"
            />
            <BaseVideoUploader
              v-model="formData.video.MP4"
              :label="$t('badge.create.video_mp4')"
              :vid="$t('badge.create.video_mp4')"
              :rules="videoValidationRules('MP4')"
              accepted-ext="mp4"
              :max-size="maxSize"
            />
            <BaseVideoUploader
              v-model="formData.video.WEBM"
              :label="$t('badge.create.video_webm')"
              :vid="$t('badge.create.video_webm')"
              :rules="videoValidationRules('WEBM')"
              accepted-ext="webm"
              :max-size="maxSize"
            />
          </v-col>
          <v-col
            sm="12"
            lg="6"
          >
            <BaseSelect
              v-model="formData.selectedStage"
              rules="select"
              :items="stages"
              :label="$t('badge.create.stage')"
              :item-text="
                stage =>
                  stringLimit(
                    `${stage.order + 1}. [${$t(
                      `badge.types.${stage.type.toLowerCase()}`
                    )}] ${stageTitle(stage)}`,
                    80
                  )
              "
              item-value="id"
              persistent-hint
              return-object
              :loading="isLoading"
              :disabled="isLoading"
              searchable
              @change="formData.hasSelectedAnswer = false"
            />
            <BaseRichTextEditor
              v-if="formData.selectedStage"
              v-model="formData.selectedStage.stageable.data.body"
              :label="$t('badge.create.stage_body')"
              disabled
            />
            <BaseCheckbox
              v-if="stageHasAnswers(formData.selectedStage.type)"
              v-model="formData.hasSelectedAnswer"
              :label="$t('badge.create.add_to_answer')"
              :description="$t('badge.create.select_add_to_answer')"
            />
            <BaseSelect
              v-if="stageHasAnswers(formData.selectedStage.type) && formData.hasSelectedAnswer"
              v-model="formData.stageAnswer"
              :rules="formData.hasSelectedAnswer ? 'select' : ''"
              :items="formData.selectedStage.stageable.data.answers.data"
              :label="$t('badge.create.stage_answer')"
              :item-text="answer => stringLimit(strippedHtml(answer.body), 80)"
              item-value="id"
              persistent-hint
              return-object
              :loading="isLoading"
              :disabled="isLoading"
            />
            <v-row class="pt-6">
              <v-col sm="8">
                <v-alert
                  icon="mdi-information-outline"
                  color="grayDark"
                  text
                >
                  <span class="body-2">
                    {{ $t("badge.create.tip_one") }}
                  </span>
                </v-alert>
                <v-alert
                  icon="mdi-information-outline"
                  color="grayDark"
                  text
                >
                  <span class="body-2">
                    {{ $t("badge.create.tip_two") }}
                  </span>
                </v-alert>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import { stringLimit } from '@/plugins/stringHelpers/stringLimit';
import { strippedHtml } from '@/plugins/stringHelpers/strippedHtml';
import {
  QUESTION_CLOSED,
  QUESTION_BEST_WORST,
  MESSAGE,
  VIDEO,
  IMAGE,
  RECORD,
} from '@/names/gameStageTypes.names';
import { DELETE } from '@/names/global.names';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseTextarea from '@/components/Form/BaseTextarea';
import BaseRichTextEditor from '@/components/Form/BaseRichTextEditor';
import BaseInput from '@/components/Form/BaseInput';
import BaseImageUploader from '@/components/Form/BaseImageUploader';
import BaseVideoUploader from '@/components/Form/BaseVideoUploader';
import BaseSelect from '@/components/Form/BaseSelect';
import BaseIconSelect from '@/components/Form/BaseIconSelect';
import BaseCheckbox from '@/components/Form/BaseCheckbox';

export default {
  name: 'BadgeForm',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseTextarea,
    BaseRichTextEditor,
    BaseInput,
    BaseImageUploader,
    BaseVideoUploader,
    BaseSelect,
    BaseIconSelect,
    BaseCheckbox,
  },
  props: {
    badge: {
      type: Object,
      default: () => {},
    },
    stages: {
      type: Array,
      default: () => [],
    },
    badgeIcons: {
      type: Array,
      default: () => [],
    },
    backendErrors: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxSize: 102400,
      formData: {
        name: '',
        description: '',
        image: null,
        selectedIcon: '',
        selectedStage: '',
        stageAnswer: '',
        hasSelectedAnswer: false,
        video: {
          MP4: '',
          WEBM: '',
        },
      },
    };
  },
  computed: {
    uploadedVideos() {
      if (!this.editMode) return false;

      return this.badge.video === null;
    },
  },
  created() {
    if (this.editMode) {
      this.formData.name = this.badge.body;
      this.formData.description = this.badge.description;
      this.formData.image = this.badge.image_url;
      if (this.badge.video) {
        this.formData.video = this.badge.video;
      }
      this.formData.selectedStage = this.stages.find((stage) => stage.id === this.badge.stage_id);
      this.formData.selectedIcon = this.badgeIcons.find((icon) => icon.file_name === this.badge.icon);
      const hasSelectedAnswer = !!this.badge.badgeable.data.scoring;
      if (hasSelectedAnswer) {
        this.formData.hasSelectedAnswer = true;
        this.formData.stageAnswer = this.formData.selectedStage.stageable.data.answers.data.find(
          (answer) => answer.id === this.badge.badgeable.data.id,
        );
      }
    }
  },
  methods: {
    stringLimit,
    strippedHtml,
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const formData = new FormData();
      const { gameId, badgeId } = this.$route.params;
      formData.set('name', this.formData.name);
      formData.set('description', this.formData.description);
      formData.set('icon', this.formData.selectedIcon.file_name);
      formData.set('game_stage_id', this.formData.selectedStage.id);
      formData.set('image', this.formData.image);
      if ((this.formData.video.MP4 && this.formData.video.WEBM) || this.editMode) {
        formData.set('video[MP4]', this.formData.video.MP4);
        formData.set('video[WEBM]', this.formData.video.WEBM);
      }
      if (this.formData.hasSelectedAnswer) {
        formData.set('game_question_answer_id', this.formData.stageAnswer.id);
      }
      if (this.editMode) {
        formData.set('_method', 'PUT');

        return { gameId, badgeId, params: formData };
      }

      return { gameId, params: formData };
    },
    stageTitle(stage) {
      return [MESSAGE, VIDEO, IMAGE, RECORD].includes(stage.type)
        ? stage.stageable.data.title
        : stage.stageable.data.header;
    },
    imageValidationRules() {
      return `${
        !this.editMode || (this.formData.image === DELETE && this.editMode) ? 'image_required|' : ''
      }size:10240|extensions:jpeg,jpg,png,gif`;
    },
    videoValidationRules(ext) {
      const isMp4 = ext === 'MP4';
      const secondExtVideoField = this.formData.video[isMp4 ? 'WEBM' : 'MP4'];

      return `${
        (!(secondExtVideoField === DELETE && this.formData.video[ext] === DELETE)
          && this.formData.video[ext] === 'DELETE'
          && (secondExtVideoField || secondExtVideoField === ''))
        || ((this.uploadedVideos || !this.editMode)
          && this.formData.video[ext] === ''
          && secondExtVideoField)
          ? 'video_required|'
          : ''
      }size:${this.maxSize}|extensions:${ext.toLowerCase()}`;
    },
    stageHasAnswers(stageType) {
      return [QUESTION_BEST_WORST, QUESTION_CLOSED].includes(stageType);
    },
  },
};
</script>
