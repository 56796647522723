import { GAME } from '@/names/recruitmentStages.names';

/* eslint-disable import/prefer-default-export */
export function isGameType(type) {
  return type === GAME;
}

export function translateStatus(value) {
  return value !== null ? this.$t(`candidate.statuses.${value.name.toLowerCase()}`) : '-';
}

export function translateStage(value) {
  return value !== null ? this.$t(`recruitment.types.${isGameType(value.type) ? value.sub_type.toLowerCase() : value.type.toLowerCase()}`) : '-';
}
