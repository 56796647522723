export default {
  title: 'Stanowiska',
  search: 'Wyszukaj',
  edit_departments: 'Edytuj działy',
  name: 'Nazwa',
  competency_profile_name: 'Profil kompetencyjny',
  work_department_name: 'Dział',
  created_at: 'Data utworzenia',
  no_results: 'Nie znaleziono stanowisk',
  add: 'Dodaj stanowisko',
  edit: 'Edytuj stanowisko',
  create: {
    name: 'Nazwa stanowiska',
    work_department: 'Dział',
    work_department_hint: 'Wybierz dział',
    competency_profile: 'Profil kompetencyjny',
    competency_profile_hint: 'Wybierz profil kompetencyjny',
  },
};
