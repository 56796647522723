<template>
  <div class="client-edit__container px-6">
    <v-card outlined>
      <v-card-title>
        <v-row>
          <v-col
            class="px-3 px-lg-6"
            sm="12"
          >
            <v-layout
              pt-3
              flex
              justify-start
            >
              {{ editMode ? $t('client.edit') : $t("client.add") }}
            </v-layout>
          </v-col>
          <v-col
            v-if="editMode"
            class="px-3 px-lg-6"
            sm="12"
          >
            <button
              v-for="(tab, index) in tabs"
              :key="`tab-${index}`"
              class="client-edit__tab"
              :class="{ 'client-edit__tab--active': tab.path === currentTab.path }"
              type="button"
              @click="changeTab(tab)"
            >
              {{ tab.name }}
            </button>
          </v-col>
        </v-row>
      </v-card-title>
      <component
        :is="clientComponent.name"
        :key="currentTab.path"
        @has-form-changes="hasFormChanges"
        @reset-form-changes="resetFormChanges"
      />
    </v-card>
  </div>
</template>
<script>
import alerts from '@/plugins/alerts';
import ClientEdit from '@/components/Dashboard/Client/EditTabs/ClientEdit';
import ScoringScalesEdit from '@/components/Dashboard/Client/EditTabs/ScoringScalesEdit';
import FaqEdit from '@/components/Dashboard/Client/EditTabs/FaqEdit';

const clientEditPath = 'client-edit';
const scoringScalesEditPath = 'scoring-scales-edit';
const faqEditPath = 'faq-edit';

export default {
  name: 'EditCandidate',
  components: {
    ClientEdit,
    ScoringScalesEdit,
    FaqEdit,
  },
  data() {
    return {
      editMode: false,
      tabs: [
        {
          name: this.$t('client.basic_data'),
          path: clientEditPath,
        },
        {
          name: this.$t('client.scoring_scales'),
          path: scoringScalesEditPath,
        },
        {
          name: this.$t('client.help_faq'),
          path: faqEditPath,
        },
      ],
      currentTab: {
        name: this.$t('client.basic_data'),
        path: clientEditPath,
      },
      hasChanges: false,
    };
  },
  computed: {
    clientComponent() {
      switch (this.currentTab.path) {
        case clientEditPath:
          return {
            name: 'ClientEdit',
          };
        case scoringScalesEditPath:
          return {
            name: 'ScoringScalesEdit',
          };
        case faqEditPath:
          return {
            name: 'FaqEdit',
          };
        default:
          throw new Error('Invalid type');
      }
    },
  },
  created() {
    this.editMode = this.$route.meta.editMode;
    if (!this.editMode) {
      this.currentTab = {
        name: this.$t('client.basic_data'),
        path: clientEditPath,
      };

      return;
    }
    if (this.$route.query?.tab) {
      this.currentTab = this.tabs.find((tab) => tab.path === this.$route.query.tab);
    }
  },
  methods: {
    ...alerts,
    async changeTab(tab) {
      if (this.hasChanges) {
        if (!(await this.cancelAlert())) return;
      }
      this.currentTab = tab;
      this.setRouteQuery();
      this.resetFormChanges();
    },
    setRouteQuery() {
      if (this.$route.query?.tab === this.currentTab.path) return;
      const query = { ...this.$route.query };
      query.tab = this.currentTab.path;
      this.$router.replace({ query });
    },
    hasFormChanges(changed) {
      this.hasChanges = changed;
    },
    resetFormChanges() {
      this.hasChanges = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.client-edit {
  &__container {
    padding-bottom: 120px;
  }
  &__tab {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 15px 28px;
    color: #263238;
    border-bottom: 3px solid #F3F3F3;
    margin-bottom: 30px;
    transition: 0.3s background, 0.6s border;
    &--active {
      background: rgba(252, 175, 59, 0.14);
      border-color: rgb(252, 175, 59);
      box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.2);
    }
  }
}
</style>
