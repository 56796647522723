import api from '@/plugins/axios/api';

/**
 * @param {String} candidateId
 * @param {String} recruitmentProcessId
 */
const fetchAssignedTags = async ({
  candidateId,
  recruitmentProcessId,
}) => {
  const { data: { data, meta } } = await api.get(`recruitment-processes/${recruitmentProcessId}/candidates/${candidateId}/tags`);

  return { data, pagination: meta.pagination };
};

export default fetchAssignedTags;
