<template>
  <div>
    <div
      v-if="isPreloadingData"
      class="preloader-container"
    >
      <Preloader />
    </div>
    <div
      v-else
      class="px-6 pb-6"
    >
      <v-card outlined>
        <v-card-title>
          <v-row>
            <v-col
              class="px-3 px-lg-6"
              sm="12"
            >
              <p class="headline black--text mt-4">
                {{ $t("candidate.add") }}
              </p>
              <v-divider class="mt-3" />
            </v-col>
          </v-row>
        </v-card-title>
        <div class="wrapper">
          <v-row class="px-3 px-lg-6">
            <v-col>
              <div class="mt-2 mb-5">
                {{ $t("candidate.create.description") }}
                <BaseTooltip icon="mdi-information-outline">
                  {{ $t("candidate.create.tooltip") }}
                </BaseTooltip>
              </div>
              <CandidateForm
                :recruitment-process="recruitmentProcess"
                :recruitment-processes="recruitmentProcesses"
                :message-templates="messageTemplates"
                :variables-list="variablesList"
                :candidate-invitation-message="candidateInvitationMessage"
                :is-loading="isLoading"
                :is-sending="isSending"
                :backend-errors="errors"
                @handle-submit="submit"
                @handle-cancel="returnToList"
                @fetch-message-templates="fetchDefaultMessageTemplates"
              />
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import alerts from '@/plugins/alerts';
import { CANDIDATE_LIST } from '@/router/routes.names';
import { HTTP_UNPROCESSABLE_ENTITY, HTTP_UNAUTHORIZED } from '@/plugins/axios/codes';
import { CANDIDATE_INVITATION } from '@/names/gameMessageTypes.names';
import fetchRecruitmentProcesses from '@/components/Dashboard/Recruitment/fetchRecruitmentProcesses';
import fetchRecruitmentProcessesDefaultMessageTemplates from '@/components/Dashboard/Recruitment/fetchRecruitmentProcessesDefaultMessageTemplates';
import fetchVariablesList from '@/components/Dashboard/MessageTemplate/fetchVariablesList';
import inviteCandidate from '@/components/Dashboard/Candidate/Api/inviteCandidate';
import BaseTooltip from '@/components/Form/BaseTooltip';
import CandidateForm from '@/components/Dashboard/Candidate/Form';
import Preloader from '@/components/Dashboard/Partials/Preloader/Preloader';

export default {
  name: 'AddCandidate',
  components: {
    BaseTooltip,
    CandidateForm,
    Preloader,
  },
  data() {
    return {
      recruitmentProcess: null,
      recruitmentProcesses: [],
      messageTemplates: [],
      variablesList: [],
      candidateInvitationMessage: null,
      isPreloadingData: false,
      isLoading: false,
      isSending: false,
      errors: [],
    };
  },
  async created() {
    this.isPreloadingData = true;
    await this.fetchRecruitmentProcesses();
    this.setRouteFilters();
    this.isPreloadingData = false;
  },
  methods: {
    ...alerts,
    returnToList() {
      this.$router.push({
        name: CANDIDATE_LIST,
        query: {
          recruitment_process_id: this.$route.query.recruitment_process_id,
        },
      });
    },
    async submit(payload) {
      try {
        this.errors = [];
        this.isSending = true;
        await inviteCandidate(payload);
        this.$notify.save.success();
        this.returnToList();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const response = e.response.data.errors;
          Object.values(response).filter((error) => this.errors.push(error[0]));

          return;
        }
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isSending = false;
      }
    },
    async fetchRecruitmentProcesses() {
      try {
        this.isLoading = true;
        const response = await fetchRecruitmentProcesses({ limit: 99999, includes: ['game'] });
        this.recruitmentProcesses = response.data;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchDefaultMessageTemplates() {
      try {
        this.isLoading = true;
        const data = await fetchRecruitmentProcessesDefaultMessageTemplates({
          recruitmentProcessId: this.$route.query.recruitment_process_id,
        });
        if (data.some(({ type }) => type === CANDIDATE_INVITATION)) {
          this.messageTemplates = data.filter(({ type }) => type === CANDIDATE_INVITATION);
          this.candidateInvitationMessage = data.find(({ type }) => type === CANDIDATE_INVITATION);
        }
        this.addCustomOptionToMessageTemplates();
        if (data) {
          this.variablesList = await fetchVariablesList({
            messageTemplateId: null,
          });
        }
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isLoading = false;
      }
    },
    addCustomOptionToMessageTemplates() {
      this.messageTemplates.push({
        name: this.$t('candidate.create.custom_message'),
        title: '',
        body: '',
      });
    },
    setRouteFilters() {
      if (this.$route.query.recruitment_process_id) {
        this.recruitmentProcess = this.$route.query.recruitment_process_id;
      }
    },
  },
};
</script>
