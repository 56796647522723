export default {
  title: 'Szablony wiadomości do kandydatów',
  search: 'Wyszukaj',
  name: 'Nazwa szablonu',
  title_message: 'Tytuł',
  updated_at: 'Data aktualizacji',
  no_results: 'Nie znaleziono szablonu',
  edit: 'Edycja szablonu wiadomości',
  body: 'Treść wiadomości',
  create: {
    name: 'Nazwa szablonu',
    title: 'Tytuł',
    body: 'Treść wiadomości',
    variables: 'Znaczniki do wykorzystania',
  },
  variables: {
    IMIE_KANDYDATA_TKI: 'imię kandydata',
    NAZWISKO_KANDYDATA_TKI: 'nazwisko kandydata',
    EMAIL_DO_SUPPORTU: 'e-mail do wsparcia technicznego',
    NAZWA_FIRMY: 'nazwa pracodawcy',
    STRONA_WWW: 'adres strony www pobierany z tabelki clients',
    NAZWA_STANOWISKA: 'nazwa stanowiska',
    NAZWA_REKRUTACJI: 'nazwa procesu rekrutacyjnego',
    NAZWA_NARZĘDZIA: 'nazwa narzędzia (jeśli istnieje w danym etapie)',
    CZAS_TRWANIA_GRY: 'limit czasu w narzędziu - w formacie “X minut” (jeśli istnieje)',
    'PRZYCISK/PRZYCISK': 'przycisk kierujący do narzędzia (jeśli istnieje w danym etapie)',
    LINK: 'link do narzędzia (jeśli istnieje w danym etapie)',
    WYNIK: 'wynik z narzędzia (jeśli istnieje w danym etapie)',
    WYNIK_TOTAL: 'wynik z wszystkich etapów rekrutacji',
    FEEDBACK: 'rozbudowana informacja zwrotna dla Kandydata z narzędzia (jeśli został zdefiniowany)',
    FEEDBACK_TOTAL: 'rozbudowana informacja zwrotna dla Kandydata z wszystkich etapów (jeśli został zdefiniowany)',
  },
};
