export default {
  title: 'Clients',
  search: 'Search',
  logo: 'Logo',
  name: 'Name',
  created_at: 'Date added',
  no_results: 'No clients found',
  choose_client_scope: 'Enter client name',
  ats_types: {
    e_recruiter: 'E-Recruiter',
    e_recruiter_marketplace: 'E-Recruiter - Marketplace',
    new_generation_recruitment: '313C',
    smart_recruiters: 'SmartRecruiters',
    hr_link: 'HRlink',
    hr_link_anonymous_version: 'HR Link - anonymous version',
    sap: 'SAP',
  },
  add: 'Add client',
  edit: 'Edit client',
  create: {
    name: 'Name',
    website: 'Website',
    subdomain: 'Subdomain address',
    email: 'Support e-mail',
    email_desc: 'Address of the subdomain used in the Client\'s tools, e.g. xxx.313c.com.pl',
    ats: 'ATS',
    ats_hint: 'Select ATS',
    locale: 'Default language for the report and for new users',
    logo: 'Logotype',
    favicon: 'Favicon',
    color: 'Color',
    color_hint: 'Choose a color',
    report_description: 'Default description in report',
    faq_content: 'Contents of the Help / FAQ',
    variables: 'Tags to use',
    level: 'Level',
    score_to: 'To ( in % )',
    game_session_reminder_after_time: 'Send game reminder email after (in hours)',
    feedback_label: 'Feedback label',
    empty_scoring_scales: 'Add levels',
    notify_admins: 'Send summary emails to administrators',
    notify_recruiters: 'Send summary emails to recruiters',
  },
  basic_data: 'Basic information',
  help_faq: 'Help / FAQ',
  scoring_scales: 'Scoring scale management',
  scoring_scales_warning: 'WARNING: Removing a competency level (even if a new level is subsequently added) is an irreversible operation and results in the deletion of descriptions from all existing competencies.',
  default: 'Default',
  translations: 'Translations',
};
