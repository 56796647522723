import api from '@/plugins/axios/api';

/**
 * @param {String} name
 * @param {String} metaTitle
 * @param {String} topBarTitle
 * @param {String} type
 * @param {String} locale
 * @param {String} clientId
 * @param {String} gameFormId
 * @param {Number} timeLimit
 * @param {Array} messageTemplates
 * @param {Array} languageCollection
 * @param {Boolean} active
 */
const addGame = async ({
  name,
  metaTitle,
  topBarTitle,
  type,
  locale,
  clientId,
  gameFormId,
  timeLimit,
  messageTemplates,
  languageCollection,
  active,
}) => {
  await api.post('games', {
    name,
    meta_title: metaTitle,
    top_bar_title: topBarTitle,
    type,
    locale,
    client_id: clientId,
    game_form_id: gameFormId,
    time_limit: timeLimit,
    message_templates: messageTemplates,
    language_collection: languageCollection,
    active,
  });
};

export default addGame;
