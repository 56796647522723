import api from '@/plugins/axios/api';

/**
 * @param {String} workPositionId
 * @param {Array} includes
 */
const fetchWorkPosition = async ({
  workPositionId,
  includes,
}) => {
  const { data } = await api.get(`work-positions/${workPositionId}`, {
    params: {
      includes,
    },
  });

  return data.data;
};

export default fetchWorkPosition;
