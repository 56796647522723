import api from '@/plugins/axios/api';

/**
 * @param {String} candidateId
 * @param {String} recruitmentProcessId
 * @param {Object} params
 */
const editCandidateCard = async ({
  candidateId,
  recruitmentProcessId,
  params,
}) => {
  await api.post(`recruitment-processes/${recruitmentProcessId}/candidates/${candidateId}/card`, params);
};

export default editCandidateCard;
