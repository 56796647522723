import api from '@/plugins/axios/api';

/**
 * @param {String} messageTemplateId
 */
const fetchVariablesList = async ({
  messageTemplateId,
}) => {
  const { data: { data } } = await api.get(`message-templates/variables-list${messageTemplateId ? `/${messageTemplateId}` : ''}`);

  return data;
};

export default fetchVariablesList;
