import api from '@/plugins/axios/api';

/**
 * @param {String} messageTemplateId
 * @param {Array} includes
 */
const fetchMessageTemplate = async ({
  messageTemplateId,
  includes,
}) => {
  const { data: { data } } = await api.get(`message-templates/${messageTemplateId}`, {
    params: { includes },
  });

  return data;
};

export default fetchMessageTemplate;
