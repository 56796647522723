import api from '@/plugins/axios/api';

/**
 * @param {String} recruitmentProcessId
 * @param {String} recruitmentProcessStageId
 */
const fetchCandidateStatuses = async ({
  recruitmentProcessId,
  recruitmentProcessStageId,
}) => {
  const { data } = await api.get(`recruitment-processes/${recruitmentProcessId}/stages/${recruitmentProcessStageId}/statuses`);

  return { data: data.data };
};

export default fetchCandidateStatuses;
