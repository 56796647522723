<template>
  <div class="candidate-edit__container px-6">
    <v-card outlined>
      <v-card-title>
        <v-row>
          <v-col
            class="px-3 px-lg-6"
            sm="12"
          >
            <v-layout
              pt-3
              flex
              justify-start
            >
              <div>{{ $t("candidate.candidate") }}: {{ candidateFullName }}</div>
            </v-layout>
          </v-col>
          <v-col
            class="px-3 px-lg-6"
            sm="12"
          >
            <button
              v-for="(tab, index) in tabs"
              :key="`tab-${index}`"
              class="candidate-edit__tab"
              :class="{ 'candidate-edit__tab--active': tab.path === currentTab.path }"
              type="button"
              @click="changeTab(tab)"
            >
              {{ tab.name }}
            </button>
          </v-col>
        </v-row>
      </v-card-title>
      <component
        :is="candidateComponent.name"
        :key="currentTab.path"
        @has-form-changes="hasFormChanges"
      />
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alerts from '@/plugins/alerts';
import CandidateCard from '@/components/Dashboard/Candidate/EditTabs/CandidateCard';
import CompetencyRating from '@/components/Dashboard/Candidate/EditTabs/CompetencyRating';
import CandidateVideos from '@/components/Dashboard/Candidate/EditTabs/CandidateVideos';

const candidateCardPath = 'candidate-card';
const competencyRatingPath = 'competency-rating';
const candidateVideosPath = 'candidate-videos';

export default {
  name: 'EditCandidate',
  components: {
    CandidateCard,
    CompetencyRating,
    CandidateVideos,
  },
  data() {
    return {
      tabs: [
        {
          name: this.$t('candidate.card'),
          path: candidateCardPath,
        },
        {
          name: this.$t('candidate.ratings'),
          path: competencyRatingPath,
        },
        {
          name: this.$t('candidate.videos'),
          path: candidateVideosPath,
        },
      ],
      currentTab: {
        name: this.$t('candidate.card'),
        path: candidateCardPath,
      },
      hasChanges: false,
    };
  },
  computed: {
    ...mapGetters('candidate', ['candidateFullName']),
    candidateComponent() {
      switch (this.currentTab.path) {
        case candidateCardPath:
          return {
            name: 'CandidateCard',
          };
        case competencyRatingPath:
          return {
            name: 'CompetencyRating',
          };
        case candidateVideosPath:
          return {
            name: 'CandidateVideos',
          };
        default:
          throw new Error('Invalid type');
      }
    },
  },
  created() {
    if (this.$route.query?.tab) {
      this.currentTab = this.tabs.find((tab) => tab.path === this.$route.query.tab);
    }
  },
  methods: {
    ...alerts,
    async changeTab(tab) {
      if (this.hasChanges) {
        if (!(await this.cancelAlert())) return;
      }
      this.currentTab = tab;
      this.setRouteQuery();
      this.resetFormChanges();
    },
    setRouteQuery() {
      if (this.$route.query?.tab === this.currentTab.path) return;
      const query = { ...this.$route.query };
      query.tab = this.currentTab.path;
      this.$router.replace({ query });
    },
    hasFormChanges(changed) {
      this.hasChanges = changed;
    },
    resetFormChanges() {
      this.hasChanges = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.candidate-edit {
  &__container {
    padding-bottom: 120px;
  }
  &__tab {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 15px 28px;
    color: #263238;
    border-bottom: 3px solid #F3F3F3;
    transition: 0.3s background, 0.6s border;
    &--active {
      background: rgba(252, 175, 59, 0.14);
      border-color: rgb(252, 175, 59);
      box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.2);
    }
  }
}
</style>
