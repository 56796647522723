<template>
  <ValidationObserver
    ref="modal"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <v-dialog
        v-model="value"
        max-width="900"
        :retain-focus="false"
        @click:outside="closeModal"
      >
        <v-card>
          <v-card-text>
            <div class="confirm-modal__title">
              <span>{{ $t("candidate.modal.change_stage") }}</span>
              <v-switch v-model="showStageSection" />
            </div>
            <v-divider class="my-2 mb-6" />
            <div v-if="showStageSection">
              <BaseSelect
                v-model="recruitmentProcessStage"
                :items="recruitmentProcessStages"
                :label="$t('candidate.modal.stage')"
                :item-text="(id) => translateStage(id)"
                return-object
                :disabled="isStatusesLoading"
                :rules="`${showStageSection ? 'select' : ''}`"
                @change="fetchStatusesAndMessages"
              />
              <BaseSelect
                v-model="recruitmentProcessStatus"
                :items="mappedRecruitmentProcessStatuses"
                :label="$t('candidate.modal.status')"
                :item-text="(name) => translateStatus(name)"
                item-value="name"
                :loading="isStatusesLoading || isPreloading"
                :disabled="isStatusesLoading || isPreloading"
                :rules="`${showStageSection ? 'select' : ''}`"
              />
            </div>
            <div class="confirm-modal__title">
              <span>{{ $t("candidate.modal.message") }}</span>
              <v-switch
                v-model="showMessageSection"
                :disabled="isInvitedStatus && isGameType"
              />
            </div>
            <v-divider class="my-2 mb-6" />
            <div v-if="showMessageSection">
              <BaseSelect
                v-model="messageTemplate"
                :items="messageTemplates"
                :label="$t('candidate.modal.message_template')"
                item-text="name"
                item-value="id"
                return-object
                :loading="isMessagesLoading || isPreloading"
                :disabled="isMessagesLoading || isPreloading"
              />
              <div v-if="!isMessagesLoading && !isPreloading">
                <BaseInput
                  v-model="messageTitle"
                  :label="$t('candidate.modal.message_title')"
                  :rules="`${showMessageSection ? 'min:3|max:255|required' : ''}`"
                />
                <BaseRichTextEditor
                  v-model="messageBody"
                  :label="$t('candidate.modal.message_body')"
                  :variables-list="variablesList"
                  :rules="`${showMessageSection ? 'min:3|max:60000|required' : ''}`"
                  extended-version
                  hide-video-btn
                />
                <div
                  v-if="variablesList.length"
                  class="pt-2 pb-12"
                >
                  <p>{{ $t("messageTemplate.create.variables") }}:</p>
                  <span
                    v-for="(variableName, index) in variablesList"
                    :key="`list-item-${index}`"
                    class="d-block"
                  >
                    <span class="subtitle-2">{{ variableName }}</span> -
                    <span class="text-caption">
                      {{ $t(`messageTemplate.variables.${variableWithoutBrackets(variableName)}`) }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-row
              class="mb-2 mx-1"
              justify="center"
            >
              <v-col cols="6">
                <BaseButton
                  outlined
                  @click="closeModal"
                >
                  {{ $t("dashboard.cancel") }}
                </BaseButton>
              </v-col>
              <v-col cols="6">
                <BaseButton
                  type="submit"
                  :loading="isLoading"
                  :disabled="!showStageSection && !showMessageSection"
                  @click="submit"
                >
                  {{ $t("dashboard.confirm") }}
                </BaseButton>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </ValidationObserver>
</template>
<script>
import Axios from 'axios';
import { ValidationObserver } from 'vee-validate';
import alerts from '@/plugins/alerts';
import { polishPlurals } from '@/plugins/stringHelpers/polishPlurals';
import { translateStage, translateStatus } from '@/plugins/objectHelpers/candidateHelpers';
import BaseButton from '@/components/Form/BaseButton';
import {
  HTTP_UNAUTHORIZED,
  HTTP_UNPROCESSABLE_ENTITY,
  HTTP_NOT_FOUND,
} from '@/plugins/axios/codes';
import fetchCandidates from '@/components/Dashboard/Candidate/Api/fetchCandidates';
import fetchVariablesList from '@/components/Dashboard/MessageTemplate/fetchVariablesList';
import BaseSelect from '@/components/Form/BaseSelect';
import BaseRichTextEditor from '@/components/Form/BaseRichTextEditor';
import BaseInput from '@/components/Form/BaseInput';
import fetchCandidateStatuses from '@/components/Dashboard/Candidate/Api/fetchCandidateStatuses';
import fetchRecruitmentProcessesDefaultMessageTemplates from '@/components/Dashboard/Recruitment/fetchRecruitmentProcessesDefaultMessageTemplates';
import wrapImagesToTable from '@/plugins/ckeditor/wrapImagesToTable';
import { INVITED } from '@/names/candidateStageStatuses.names';
import { CANDIDATE_INVITATION } from '@/router/routes.names';
import { GAME } from '@/names/recruitmentStages.names';
import { ASC } from '@/components/Table/sort.types';

let cancelToken;

export default {
  name: 'ConfirmStageModal',
  components: {
    ValidationObserver,
    BaseButton,
    BaseSelect,
    BaseRichTextEditor,
    BaseInput,
  },
  props: {
    chosenVariant: {
      type: [Boolean, Object],
      default: null,
    },
    chosenCandidates: {
      type: Array,
      default: () => [],
    },
    chosenCandidateStatus: {
      type: String,
      default: '',
    },
    chosenCandidateStage: {
      type: [String, Object],
      default: null,
    },
    recruitmentProcessStages: {
      type: Array,
      default: () => [],
    },
    recruitmentProcessId: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      required: true,
    },
    hasBeenSent: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isPreloading: false,
      isStatusesLoading: false,
      isMessagesLoading: false,
      showStageSection: false,
      showMessageSection: false,
      variablesList: [],
      recruitmentProcessStatuses: [],
      recruitmentProcessStatus: null,
      recruitmentProcessStage: null,
      messageTemplates: [],
      messageTemplate: null,
      messageTitle: '',
      messageBody: '',
      existingCandidates: [],
    };
  },
  computed: {
    mappedRecruitmentProcessStatuses() {
      return this.recruitmentProcessStatuses.map((status) => ({
        name: status,
      }));
    },
    isInvitedStatus() {
      return this.recruitmentProcessStatus === INVITED;
    },
    isGameType() {
      return this.recruitmentProcessStage?.type === GAME;
    },
  },
  watch: {
    hasBeenSent(newVal) {
      if (newVal) {
        this.closeModal();
      }
    },
    messageTemplate(newVal) {
      if (newVal) {
        this.messageTitle = newVal.title;
        this.messageBody = newVal.body;
      }
    },
    showMessageSection(newVal) {
      if (
        newVal
        && this.messageTemplates.some((message) => message.type === CANDIDATE_INVITATION)
      ) {
        this.messageTemplate = this.messageTemplates.find(
          (message) => message.type === CANDIDATE_INVITATION,
        );
      }
    },
    recruitmentProcessStatus(newVal) {
      if (newVal === INVITED) {
        this.showMessageSection = true;
      }
    },
  },
  async created() {
    this.isPreloading = true;
    this.recruitmentProcessStage = this.chosenCandidateStage;
    this.recruitmentProcessStatus = this.chosenCandidateStatus;
    this.changeVariant();
    await this.fetchStatusesAndMessages();
    this.isPreloading = false;
  },
  methods: {
    ...alerts,
    polishPlurals,
    translateStage,
    translateStatus,
    wrapImagesToTable,
    changeVariant() {
      if (this.chosenVariant === null) return;

      if (this.chosenVariant) {
        this.showStageSection = true;
        this.isInvitedStatus ? (this.showMessageSection = true) : (this.showMessageSection = false);

        return;
      }
      this.showStageSection = false;
      this.showMessageSection = true;
    },
    closeModal() {
      this.$emit('close-modal');
    },
    async fetchStatusesAndMessages() {
      if (this.recruitmentProcessStage !== null) {
        await this.fetchRecruitmentProcessStatuses();
      }
      await Promise.all([this.fetchDefaultMessageTemplates(), this.fetchVariablesList()]);
    },
    async fetchRecruitmentProcessStatuses() {
      try {
        this.isStatusesLoading = true;
        const {
          data: { statuses },
        } = await fetchCandidateStatuses({
          recruitmentProcessStageId: this.recruitmentProcessStage.id,
          recruitmentProcessId: this.recruitmentProcessId,
        });
        this.recruitmentProcessStatuses = statuses;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_NOT_FOUND) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isStatusesLoading = false;
      }
    },
    async fetchDefaultMessageTemplates() {
      try {
        this.isMessagesLoading = true;
        this.messageTemplates = await fetchRecruitmentProcessesDefaultMessageTemplates({
          recruitmentProcessId: this.recruitmentProcessId,
          stageId: this.recruitmentProcessStage?.id || null,
          filters: {
            with_unassigned_to_games: true,
          },
        });
        this.addCustomOptionToMessageTemplates();
        if (this.messageTemplates.some((message) => message.type === CANDIDATE_INVITATION)) {
          this.messageTemplate = this.messageTemplates.find(
            (message) => message.type === CANDIDATE_INVITATION,
          );

          return;
        }
        // eslint-disable-next-line prefer-destructuring
        this.messageTemplate = this.messageTemplates[0];
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        this.errorAlert();
        throw new Error(e);
      } finally {
        this.isMessagesLoading = false;
      }
    },
    addCustomOptionToMessageTemplates() {
      this.messageTemplates.push({
        name: this.$t('candidate.custom_message'),
        title: '',
        body: '',
      });
    },
    async fetchVariablesList() {
      try {
        this.isMessagesLoading = true;
        this.variablesList = await fetchVariablesList({
          messageTemplateId: null,
        });
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();

          return;
        }
        throw new Error(e);
      } finally {
        this.isMessagesLoading = false;
      }
    },
    async submit() {
      const result = await this.$refs.modal.validate();
      if (!result) {
        return;
      }
      if (this.isInvitedStatus && this.isGameType) {
        await this.fetchExistingCandidates();
        if (this.existingCandidates.length) {
          const isConfirmed = await this.showConfirmationAlert();
          if (!isConfirmed) {
            return;
          }
        }
      }
      if (
        this.showStageSection
        && !this.recruitmentProcessStatuses.includes(this.recruitmentProcessStatus)
      ) {
        this.recruitmentProcessStatus = null;

        return;
      }
      this.$emit('handle-submit', await this.baseFormData());
    },
    async baseFormData() {
      const message = {
        title: this.messageTitle,
        body: await wrapImagesToTable(this.messageBody),
      };

      return {
        stageId:
          this.recruitmentProcessStage && this.showStageSection
            ? this.recruitmentProcessStage.id
            : '',
        status:
          this.recruitmentProcessStatus && this.showStageSection
            ? this.recruitmentProcessStatus
            : '',
        candidateIds: this.chosenCandidates.map((candidate) => candidate.id),
        customMessage: this.showMessageSection ? message : {},
        recruitmentProcessId: this.recruitmentProcessId,
      };
    },
    async fetchExistingCandidates() {
      try {
        if (typeof cancelToken !== typeof undefined) {
          cancelToken.cancel('Operation canceled due to new request.');
        }
        cancelToken = Axios.CancelToken.source();
        const { data } = await fetchCandidates({
          recruitmentProcessId: this.recruitmentProcessId,
          filters: {
            has_finished_game_stage: {
              recruitment_process_stage_id: this.chosenCandidateStage.id,
            },
            ids: this.chosenCandidates.map((candidate) => candidate.id),
          },
          sort: {
            column: 'email',
            direction: ASC,
          },
          cancelToken,
        });
        this.existingCandidates = data;
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) return;
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          this.errorAlert();
        }
        throw new Error(e);
      }
    },
    async showConfirmationAlert() {
      const { isConfirmed } = await this.defaultAlert({
        title: this.existingCandidates.length > 1 ? this.$t('dashboard.modal.change_stage_or_status.title_for_multiple_candidates') : this.$t('dashboard.modal.change_stage_or_status.title_for_single_candidate'),
        html: this.confirmAlertContent(),
        confirmButtonText: this.$t('dashboard.modal.change_stage_or_status.confirm'),
        cancelButtonText: this.$t('dashboard.modal.change_stage_or_status.cancel'),
      });

      return isConfirmed;
    },
    confirmAlertContent() {
      return this.existingCandidates.length > 1
        ? `<p><strong class="swal2-text-uppercase">${this.$t('dashboard.note')}:</strong> ${this.$t(
          'dashboard.modal.change_stage_or_status.content_for_multiple_candidates',
          {
            existingCandidatesLength: this.existingCandidates.length,
          },
        )}</p> <div class="listing">${this.existingEmails()}</div>`
        : `<p><strong class="swal2-text-uppercase">${this.$t('dashboard.note')}:</strong> ${this.$t(
          'dashboard.modal.change_stage_or_status.content_for_single_candidate',
          {
            email: this.existingCandidates[0].email,
          },
        )}</p>`;
    },
    existingEmails() {
      return this.existingCandidates
        .map(
          ({ email }, index) => `<p>${email}${index < this.existingCandidates.length - 1 ? ',' : ''}</p>`,
        )
        .join('');
    },
    variableWithoutBrackets(variable) {
      return variable.replace(/[\])}[{(]/g, '');
    },
  },
};
</script>
<style lang="scss" scoped>
.confirm-modal {
  &__title {
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #263238;
    padding-top: 25px;
    span {
      padding-right: 25px;
    }
  }
}
</style>
