import api from '@/plugins/axios/api';

/**
 * @param {String} name
 * @param {String} workDepartmentId
 */
const editWorkDepartment = async ({
  name,
  workDepartmentId,
}) => {
  await api.put(`work-departments/${workDepartmentId}`, {
    name,
  });
};

export default editWorkDepartment;
