export default {
  title: 'Analityka Zarządcza',
  game: 'Gra',
  dates_range: 'Zakres dat',
  recruitment_process: 'Proces rekrutacyjny',
  candidates_invited: 'Kandydatów zaproszono',
  candidates_started: 'Kandydatów rozpoczęło grę',
  candidates_finished: 'Kandydatów zakończyło grę',
  no_results: 'Nie znaleziono statystyk',
};
