/* eslint-disable import/prefer-default-export */
export function stringLimit(value, size) {
  if (!value) return '';
  value.toString();

  if (value.length <= size) {
    return value;
  }

  return `${value.substr(0, size)}...`;
}
