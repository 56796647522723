<template>
  <ValidationObserver
    ref="form"
    v-slot="{ handleSubmit }"
  >
    <v-form @submit.prevent="handleSubmit(submit)">
      <div class="form-wrapper">
        <BackendErrors :backend-errors="backendErrors" />
        <v-row>
          <v-col
            sm="12"
            lg="6"
            class="pr-lg-8"
          >
            <slot />
            <BaseInput
              v-model="formData.title"
              rules="min:3|max:255|required"
              :label="$t('stages.game_record.title')"
            />
            <BaseInput
              v-model="formData.header"
              rules="min:3|max:255|required"
              :label="$t('stages.game_record.header')"
            />
            <BaseRichTextEditor
              v-model="formData.body"
              rules="min:3|max:60000|required"
              :label="$t('stages.game_record.body')"
            />
            <BaseInput
              v-model="formData.instruction"
              rules="min:3|max:255"
              :label="$t('stages.game_record.instruction')"
            />
            <BaseInput
              v-model="formData.timeLimit"
              v-mask="'##m:##s'"
              rules="time_m_s|required"
              :label="$t('stages.game_record.limit')"
              :placeholder="$t('stages.game_record.limit_hint')"
            />
            <div class="switch">
              <div>
                <span class="d-block black--text mb-1">{{ $t("stages.game_record.required") }}</span>
              </div>
              <v-switch v-model="formData.required" />
            </div>
            <BaseImageUploader
              v-model="formData.sideImage"
              rules="size:10240|extensions:jpeg,jpg,png,gif"
              :label="$t('stages.game_record.side_image')"
              :vid="$t('stages.game_record.side_image')"
            />
            <v-alert
              class="mb-10"
              icon="mdi-information-outline"
              color="grayDark"
              text
            >
              <span class="body-2">
                {{ $t('stages.game_record.side_image_requirements') }}
              </span>
            </v-alert>
            <BaseImageUploader
              v-model="formData.mobileSideImage"
              rules="size:10240|extensions:jpeg,jpg,png,gif"
              :label="$t('stages.game_record.mobile_side_image')"
              :vid="$t('stages.game_record.mobile_side_image')"
            />
            <v-alert
              icon="mdi-information-outline"
              color="grayDark"
              text
            >
              <span class="body-2">
                {{ $t('stages.game_record.mobile_side_image_requirements') }}
              </span>
            </v-alert>
          </v-col>
          <v-col
            sm="12"
            lg="6"
          >
            <p class="d-flex align-center title black--text pb-2">
              <span>{{ $t('stages.game_record.feedback_title') }}</span>
            </p>
            <BaseInput
              v-model="formData.feedback.body"
              :rules="feedbackValidationRules()"
              :label="$t('stages.game_record.feedback')"
            />
            <BaseImageUploader
              v-model="formData.feedback.image"
              :rules="imageValidationRules()"
              :label="$t('stages.game_record.feedback_image')"
              :vid="$t('stages.game_record.feedback_image')"
            />
          </v-col>
        </v-row>
      </div>
      <EditButtons
        :edit-mode="editMode"
        :is-sending="isSending"
        @submit="submit"
        @cancel="cancel"
      />
    </v-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import { convertSecondsToHms } from '@/plugins/dates/convertSecondsToHms';
import { convertHmsToSeconds } from '@/plugins/dates/convertHmsToSeconds';
import BackendErrors from '@/components/Dashboard/Partials/BackendErrors/BackendErrors';
import BaseRichTextEditor from '@/components/Form/BaseRichTextEditor';
import BaseInput from '@/components/Form/BaseInput';
import BaseImageUploader from '@/components/Form/BaseImageUploader';
import EditButtons from '@/components/Dashboard/Partials/EditButtons/EditButtons';
import { DELETE } from '@/names/global.names';

export default {
  name: 'GameRecord',
  components: {
    ValidationObserver,
    EditButtons,
    BackendErrors,
    BaseRichTextEditor,
    BaseInput,
    BaseImageUploader,
  },
  props: {
    backendErrors: {
      type: Array,
      default: () => [],
    },
    currentStage: {
      type: String,
      required: true,
    },
    stage: {
      type: Object,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        title: '',
        header: '',
        body: '',
        instruction: '',
        timeLimit: '',
        required: false,
        sideImage: null,
        mobileSideImage: null,
        feedback: {
          body: '',
          image: '',
        },
      },
    };
  },
  created() {
    if (this.editMode) {
      this.formData.title = this.stage.stageable.data.title;
      this.formData.header = this.stage.stageable.data.header;
      this.formData.body = this.stage.stageable.data.body;
      this.formData.instruction = this.stage.stageable.data.instruction;
      if (this.stage.stageable.data.time_limit) {
        this.formData.timeLimit = this.convertSecondsToHms(this.stage.stageable.data.time_limit);
      }
      this.formData.required = this.stage.stageable.data.required;
      this.formData.sideImage = this.stage.side_image_url;
      this.formData.mobileSideImage = this.stage.mobile_side_image_url;
      if (this.stage.stageable.data.feedback) {
        this.formData.feedback.body = this.stage.stageable.data.feedback.data.body;
        this.formData.feedback.image = this.stage.stageable.data.feedback.data.image_url;
      }
    }
  },
  methods: {
    convertSecondsToHms,
    convertHmsToSeconds,
    cancel() {
      this.$emit('handle-cancel');
    },
    async submit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }

      this.$emit('handle-submit', this.baseFormData());
    },
    baseFormData() {
      const formData = new FormData();
      const { gameId, stageId } = this.$route.params;
      formData.set('type', this.currentStage);
      formData.set('title', this.formData.title);
      formData.set('header', this.formData.header);
      formData.set('body', this.formData.body);
      formData.set('instruction', this.formData.instruction);
      formData.set('time_limit', this.convertHmsToSeconds(this.formData.timeLimit));
      formData.set('required', +this.formData.required);
      formData.set('side_image', this.formData.sideImage);
      formData.set('mobile_side_image', this.formData.mobileSideImage);
      if (this.formData.feedback.body) {
        formData.set('feedback[body]', this.formData.feedback.body);
        formData.set('feedback[image]', this.formData.feedback.image);
      }
      if (this.editMode) {
        formData.set('_method', 'PUT');

        return { gameId, stageId, params: formData };
      }

      return { gameId, params: formData };
    },
    imageValidationRules() {
      return `${
        (this.formData.feedback.body && !this.editMode)
        || (this.formData.feedback.body && this.formData.feedback.image === DELETE)
          ? 'image_required|'
          : ''
      }size:10240|extensions:jpeg,jpg,png,gif`;
    },
    feedbackValidationRules() {
      return `${
        this.formData.feedback.image && this.formData.feedback.image !== DELETE ? 'required|' : ''
      }min:3|max:255`;
    },
  },
};
</script>
<style lang="scss" scoped>
.switch {
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
}
</style>
