<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="$attrs.label"
    :rules="rules"
  >
    <v-autocomplete
      v-if="searchable"
      v-model="innerValue"
      :menu-props="{ bottom: true, offsetY: true }"
      :error-messages="errors"
      :no-data-text="$t('dashboard.no_data_available')"
      outlined
      v-bind="$attrs"
      v-on="$listeners"
    />
    <v-select
      v-else
      v-model="innerValue"
      :menu-props="{ bottom: true, offsetY: true }"
      :error-messages="errors"
      :no-data-text="$t('dashboard.no_data_available')"
      outlined
      v-bind="$attrs"
      v-on="$listeners"
    />
  </ValidationProvider>
</template>

<script>
export default {
  name: 'BaseSelect',
  props: {
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: [String, Number, Object, Array],
      default: null,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
