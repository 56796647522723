import api from '@/plugins/axios/api';

/**
 * @param {String} recruitmentProcessId
 * @param {Array} includes
 * @param {?Object} filters
 */
const fetchRecruitmentProcess = async ({
  recruitmentProcessId,
  includes,
  filters,
}) => {
  const { data } = await api.get(`recruitment-processes/${recruitmentProcessId}`, {
    params: {
      includes,
      filters,
    },
  });

  return data.data;
};

export default fetchRecruitmentProcess;
