import Vue from 'vue';
import VueRouter from 'vue-router';
import qs from 'qs';
import { env } from '@/helper';
import authRoutes from '@/router/auth';
import dashboardRoutes from '@/router/dashboard';
import downloadRoutes from '@/router/download';
import errorRoutes from '@/router/error';

Vue.use(VueRouter);

const routes = [
  ...authRoutes,
  ...dashboardRoutes,
  ...downloadRoutes,
  ...errorRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: env.BASE_URL,
  routes,
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);

    return result ? `?${result}` : '';
  },
});

export default router;
