export const SUPPORT_EMAIL = 'SUPPORT_EMAIL';
export const LOGO = 'LOGO';
export const FAVICON = 'FAVICON';
export const PRIMARY_COLOR = 'PRIMARY_COLOR';
export const ATS = 'ATS';
export const REPORT_DESCRIPTION = 'REPORT_DESCRIPTION';
export const DEFAULT_LOCALE = 'DEFAULT_LOCALE';
export const STATIC_PAGES_HELP_SCREEN = 'STATIC_PAGES_HELP_SCREEN';
export const GAME_SESSION_REMINDER_AFTER_TIME = 'GAME_SESSION_REMINDER_AFTER_TIME';
export const ADMINS_SUMMARY_EMAILS_ENABLED = 'ADMINS_SUMMARY_EMAILS_ENABLED';
export const RECRUITERS_SUMMARY_EMAILS_ENABLED = 'RECRUITERS_SUMMARY_EMAILS_ENABLED';
